import { postCleanHtml } from "@udecode/plate";

type LiEmit = (newLi: HTMLLIElement) => void;

const cloneLi = (li: HTMLLIElement): HTMLLIElement => {
  const newLi = li.cloneNode(true) as HTMLLIElement;
  for (const child of newLi.children) {
    child.remove();
  }
  return newLi;
};

const moveAllLiNodesToRoot = (
  list: HTMLUListElement,
  level: number,
  emitLi: LiEmit
) => {
  for (const child of list.children) {
    if (child.tagName === "LI") {
      const li = cloneLi(child as HTMLLIElement);
      li.setAttribute("aria-level", level.toString());
      li.style.listStyleType = list.tagName === "UL" ? "disc" : "decimal";
      emitLi(li);
      processNestedListsOfLi(child as HTMLLIElement, level, emitLi);
    }
  }
};

const processNestedListsOfLi = (
  li: HTMLLIElement,
  level: number,
  emitLi: LiEmit
) => {
  let i = 0;
  while (i < li.children.length) {
    const child = li.children[0];
    if (child.tagName === "UL" || child.tagName === "OL") {
      const list = child as HTMLUListElement;
      moveAllLiNodesToRoot(list, level + 1, emitLi);
      list.remove();
    } else {
      i++;
    }
  }
};

const transormList = (list: HTMLUListElement): HTMLUListElement => {
  // Move all LI nodes to this ul while also setting the aria-level attribute
  for (let i = 0; i < list.children.length; i++) {
    let child = list.children[i];
    if (child.tagName === "LI") {
      const li = child as HTMLLIElement;
      li.setAttribute("aria-level", "1");
      li.style.listStyleType = list.tagName === "UL" ? "disc" : "decimal";
      processNestedListsOfLi(li, 1, (newLi) => {
        if (i + 1 < list.children.length) {
          const ref = list.children[i + 1];
          list.insertBefore(newLi, ref);
        } else {
          list.appendChild(newLi);
        }
        i++;
      });
    }
  }
  return list;
};

const transformIndentListNodeData = (data: string): string => {
  // Move all LI nodes to root UL nodes while also setting the aria-level attribute
  const document = new DOMParser().parseFromString(data, "text/html");
  const { body } = document;

  for (const child of body.children) {
    if (child.tagName === "UL" || child.tagName === "OL") {
      body.replaceChild(transormList(child as HTMLUListElement), child);
    }
  }

  return postCleanHtml(body.innerHTML);
};

export default transformIndentListNodeData;
