import { isText } from "@udecode/plate";
import { isElement } from "@udecode/plate-common";
import withLastParagraphNormalization from "./withLastParagraphNormalization.js";
import withHeadingIds from "./withHeadingIds.js";
import withStructureFixing from "./withStructureFixing.js";
import withTableNormalizing from "./withTableNormalizing.js";
class OdoEditorPlugin {
}
const withOdoEditorOverrides = (editor, plugin) => {
    const { insertText } = editor;
    editor = withLastParagraphNormalization(editor);
    editor = withHeadingIds(editor);
    editor = withStructureFixing(editor);
    editor = withTableNormalizing(editor);
    const odoEditor = editor;
    odoEditor.internalInsertText = insertText;
    odoEditor.isOdoEditor = true;
    odoEditor.pointAtEndOf = (path) => {
        if (editor.hasPath(path)) {
            return editor.point(path, { edge: "end" });
        }
        const parentPath = path.slice(0, path.length - 1);
        return editor.point(parentPath, { edge: "end" });
    };
    odoEditor.areAllChildrenEmpty = (node) => {
        if (isText(node)) {
            return node.text.length === 0;
        }
        if (!isElement(node)) {
            return true;
        }
        for (const child of node.children) {
            if (!odoEditor.areAllChildrenEmpty(child)) {
                return false;
            }
        }
        return true;
    };
    odoEditor.removeTextAtPath = (path) => {
        let emptyPath = [...path];
        let currentPath = emptyPath.slice(0, -1);
        while (currentPath.length > 0) {
            const node = editor.node(currentPath)[0];
            if (isElement(node) && node.children.length > 1) {
                // If the node has more than one child, we can't remove it because it will not be empty
                break;
            }
            emptyPath = currentPath;
            currentPath = emptyPath.slice(0, -1);
        }
        // We are never going to remove the root node so we can just remove the empty one
        editor.removeNodes({ at: emptyPath });
        return emptyPath.length !== path.length;
    };
    odoEditor.mergeWithPrevious = (path) => {
        const previousPath = [...path];
        previousPath[previousPath.length - 1] -= 1;
        if (previousPath[previousPath.length - 1] < 0) {
            return;
        }
        const [previousNode] = editor.node(previousPath);
        const previousAttributes = Object.assign({}, previousNode);
        // @ts-ignore
        delete previousAttributes.children;
        editor.mergeNodes({ at: path });
        const [mergedNode] = editor.node(previousPath);
        for (const key of Object.keys(mergedNode)) {
            if (key === "children") {
                continue;
            }
            // @ts-ignore
            if (previousAttributes[key] === mergedNode[key]) {
                // We can leave the property as is
                delete previousAttributes[key];
            }
            else if (!previousAttributes[key]) {
                // We need to clear this property
                previousAttributes[key] = null;
            }
        }
        editor.setNodes(previousAttributes, { at: previousPath });
    };
    odoEditor.performWithUndoMeta = (meta, fn) => {
        if (!editor.undoManager) {
            fn();
        }
        const manager = editor.undoManager;
        manager.once("stack-item-added", ({ stackItem }) => {
            for (const key in meta) {
                stackItem.meta.set(key, meta[key]);
            }
        });
        fn();
    };
    odoEditor.startNewUndoItem = () => {
        if (!editor.undoManager) {
            return;
        }
        const manager = editor.undoManager;
        manager.stopCapturing();
    };
    return odoEditor;
};
export default withOdoEditorOverrides;
