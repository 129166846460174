import { TElement } from "@udecode/plate-common";
import { isOdoSuggestedText, isSuggestedElement } from "odo";
import { ReactEditor } from "slate-react";

export const useIsFirstElement = (element: TElement, editor: ReactEditor) => {
  const path = ReactEditor.findPath(editor, element);
  if (!path) return false;
  if (path[0] === 0) return true;

  for (let i = 0; i < path[0]; i++) {
    const [nextElement] = editor.node([i]);
    if ("type" in nextElement && nextElement.type !== "deleted") {
      return false;
    }
  }
  return true;
};

export const useHasSuggestions = (element: TElement) => {
  if (isSuggestedElement(element)) return true;

  if ("children" in element) {
    for (const child of element.children) {
      if (isOdoSuggestedText(child)) return true;
    }
  }

  return false;
};
