import { OdoUser } from "odo";
import { OdoUser as APIUser } from "api/Api";

export interface FrontendUser extends OdoUser {
  color?: string;
  isPresent: boolean;
}

export const odoUserFromRemote = (data: APIUser): OdoUser => ({
  publicId: data.public_id!,
  displayName: data.display_name!,
  email: data.email,
});
