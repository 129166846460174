import { useEffect, useState } from "react";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { useEditorRef } from "@udecode/plate-common";

const useYjsProvider = () => {
  const editor = useEditorRef();
  const [yjsProvider, setYjsProvider] = useState<HocuspocusProvider | null>(
    null
  );

  useEffect(() => {
    // Get the YJS provider from the editor
    if (!editor) {
      setYjsProvider(null);
      return;
    }

    const yjs = editor.yjs;
    // @ts-ignore
    if (!(yjs && "provider" in yjs)) {
      setYjsProvider(null);
      return;
    }
    const newProvider = yjs.provider;
    setYjsProvider(newProvider as HocuspocusProvider);
  }, [editor]);

  return yjsProvider;
};

export default useYjsProvider;
