import CoreApi from "api/CoreApi";
import { getSelectedEnv } from "api/env";
import { AxiosError } from "axios";
import {
  getCookie as typescriptGetCookie,
  removeCookie,
  setCookie as typescriptSetCookie,
} from "typescript-cookie";
import * as Sentry from "@sentry/react";

export const message_from_exception = (
  exception: unknown,
  default_error: string = "Unknown error"
) => {
  const normalizedException = (message: string): string => {
    if (message.length > 200) {
      console.warn("Truncating error message:", message);
      return message.slice(0, 130) + "...";
    }
    return message;
  };

  if (exception instanceof AxiosError) {
    if (exception.response?.data.error) {
      const errorData = exception.response.data.error;
      // Check if errorData is an object and has keys
      if (
        typeof errorData === "object" &&
        errorData !== null &&
        Object.keys(errorData).length > 0
      ) {
        // Format each field error into a readable string
        return Object.entries(errorData)
          .map(([field, message]) => message)
          .join(", ");
      }
      return String(errorData);
    }
    return normalizedException(exception.message);
  } else {
    try {
      // @ts-ignore
      return normalizedException(exception.error.error.message);
    } catch {
      try {
        // @ts-ignore
        return normalizedException(exception.error.message);
      } catch {
        try {
          // @ts-ignore
          return normalizedException(exception.message);
        } catch {
          return default_error;
        }
      }
    }
  }
};

export const validateUrl = (value: string) => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
};

const REDIRECT_AFTER_LOGIN_COOKIE = "redirect_after_login";

export const getCookie = (name: string): string | undefined => {
  const { name: envName } = getSelectedEnv();
  if (envName === "prod") {
    return typescriptGetCookie(name);
  }
  const envSpecificName = `${envName}_${name}`;
  const result = typescriptGetCookie(envSpecificName);
  return result;
};

export const setCookie = (name: string, value: string, options?: any): void => {
  const { name: envName } = getSelectedEnv();
  if (envName === "prod") {
    typescriptSetCookie(name, value, options);
    return;
  }
  const envSpecificName = `${envName}_${name}`;
  typescriptSetCookie(envSpecificName, value, options);
};

export const getRedirectAfterLogin = (): string | undefined => {
  return getCookie(REDIRECT_AFTER_LOGIN_COOKIE);
};

export const setRedirectAfterLogin = (): void => {
  if (
    window.location.pathname.startsWith("/verify") ||
    window.location.pathname.startsWith("/login") ||
    window.location.pathname.startsWith("/logout")
  ) {
    // Don't redirect to verify page after login
    return;
  }
  const url = window.location.pathname + window.location.search;
  setCookie(REDIRECT_AFTER_LOGIN_COOKIE, url, { expires: 1, path: "/" });
};

export const clearRedirectAfterLogin = (): void => {
  removeCookie(REDIRECT_AFTER_LOGIN_COOKIE);
};

export const applyCode = async (apiClient: CoreApi) => {
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get("c");
  if (!code) {
    return;
  }
  try {
    await apiClient.applyCode.applyCodeCreate({
      code,
    });
    // Remove the code from the URL
    searchParams.delete("c");
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );
  } catch (e) {
    console.error("Failed to apply code", e);
    Sentry.captureException(e);
  }
};

export const isOnMobile = () => {
  return window.innerWidth < 600;
};
