import { ExitBreakRule, KEYS_HEADING } from "@udecode/plate";

const exitBreakRules: ExitBreakRule[] = [
  {
    hotkey: "enter",
    query: {
      start: true,
      end: true,
      allow: KEYS_HEADING,
    },
    relative: true,
    level: 1,
  },
];

export default exitBreakRules;
