import React from "react";
import { useIndentButton } from "@udecode/plate-indent";

import { ToolbarButton } from "./Toolbar";
import Icon from "../../common/Icon";

export function IndentToolbarButton({ disabled }: { disabled?: boolean }) {
  const { props } = useIndentButton();

  return (
    <ToolbarButton tooltip="Indent" {...props} disabled={disabled}>
      <Icon name="indent" />
    </ToolbarButton>
  );
}
