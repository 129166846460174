import { ElementRef, forwardRef } from "react";
import { PlateElement, PlateElementProps } from "@udecode/plate-common";
import { cn } from "../../../../lib/utils";
import { Placeholder } from "../../../EditorView/Elements/Placeholder";

const PromptParagraphElement = forwardRef<
  ElementRef<typeof PlateElement>,
  PlateElementProps
>(({ children, className, ...props }, ref) => {
  return (
    <Placeholder placeholder="Type something..." hideOnBlur={false} {...props}>
      <PlateElement ref={ref} {...props} className={cn("mt-8", className)}>
        {children}
      </PlateElement>
    </Placeholder>
  );
});

export default PromptParagraphElement;
