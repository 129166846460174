import "styles/fonts.css";
import "styles/global.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ApiClientProvider } from "providers/ApiClientProvider";
import ChangePasswordRoute from "./change-password/ChangePasswordRoute";
import adminRoutes from "./admin/AdminRoutes";
import userRoutes from "./UserRoutes";
import { wrapInAdminBar } from "components/admin/AdminBar";
import { TooltipProvider } from "components/EditorView/Menus/Tooltip";
import { AlertProvider } from "providers/AlertProvider";
import { AuthenticatedUserProvider } from "providers/AuthenticatedUserProvider";
import { RouteErrorPage } from "components/ErrorPage";
import LogoutRoute from "app/logout/LogoutRoute";
import LoginRoute from "app/login/LoginRoute";
import UnsubscribeRoute from "app/unsubscribe/UnsubscribeRoute";
import ErrorBoundary from "components/common/containers/ErrorBoundary";
import Rows from "components/common/containers/Rows";
import { useEffect, useState } from "react";
import FinalCheckRoute from "./final-check/FinalCheckRoute";
import FinalCheckDetailsRoute from "./final-check/FinalCheckDetailsRoute";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <RouteErrorPage />,
      children: wrapInAdminBar([
        {
          path: "/login",
          element: <LoginRoute />,
        },
        {
          path: "/logout",
          element: <LogoutRoute />,
        },
        {
          path: "/change-password",
          element: <ChangePasswordRoute />,
        },
        {
          path: "/unsubscribe",
          element: <UnsubscribeRoute />,
        },
        {
          path: "/final-check/",
          element: <FinalCheckRoute />,
        },
        {
          path: "/final-check/:id/",
          element: <FinalCheckDetailsRoute />,
        },
        ...(userRoutes as any),
        ...(adminRoutes as any),
      ]),
    },
  ]);

  return (
    <ErrorBoundary>
      <ApiClientProvider>
        <AuthenticatedUserProvider>
          <TooltipProvider>
            <AlertProvider>
              <Rows className="h-screen w-full">
                <RouterProvider router={router} />
              </Rows>
            </AlertProvider>
          </TooltipProvider>
        </AuthenticatedUserProvider>
      </ApiClientProvider>
    </ErrorBoundary>
  );
}

export default App;
