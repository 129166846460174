import { RequirementStatus } from "api/Api";
import Icon from "components/common/Icon";

interface StatusIconProps {
  statusIcon: string;
}

const StatusIcon: React.FC<StatusIconProps> = ({ statusIcon }) => {
  switch (statusIcon as RequirementStatus["icon"]) {
    case "not_started":
    case "blank":
      return (
        <Icon variant="solid" name="circle-minus" className="text-secondary" />
      );
    case "completed":
      return (
        <Icon variant="solid" name="circle-check" className="text-[#7CB632]" />
      );
    case "drafted":
      return (
        <Icon
          variant="solid"
          name="circle-half-stroke"
          className="text-[#F9D041]"
        />
      );
    case "orange":
      return <Icon variant="solid" name="circle" className="text-[#f78708]" />;
    case "red":
      return <Icon variant="solid" name="circle" className="text-[red]" />;
    case "blue":
      return <Icon variant="solid" name="circle" className="text-[#4B9CD3]" />;
    case "purple":
      return <Icon variant="solid" name="circle" className="text-[#A67BB8]" />;
    case "pink":
      return <Icon variant="solid" name="circle" className="text-[#f60091]" />;
    default:
      return <Icon variant="solid" name="circle" className="text-secondary" />;
  }
};

export default StatusIcon;
