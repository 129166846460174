import { DependencyList, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";

interface UseFetchedDataOptions {
  purpose?: string;
  onError?: (error: any) => void;
  refreshAfterSeconds?: number;
}

type UseFetchedDataReturnType<T> = [
  T | null,
  React.Dispatch<React.SetStateAction<T | null>>,
  {
    status: "waiting" | "loading" | "loaded" | "error";
    error: unknown | null;
    // A number that can be depended on to force a re-fetch
    // of data that depends on this data.
    // (internally it is incremented every time the data is fetched)
    dependencyRefresh: number;
    // Refresh manually
    refresh: () => void;
  }
];

/**
 * Fascilitates fetching data from the backend and refreshing it after a certain amount of time.
 *
 * Purpose is an optional string that can make errors clearer.
 * If no refreshAfterSeconds is provided, the data will not be refreshed.
 * If an onError function is provided, it will be called when the fetch function throws an error.
 * If no onError function is provided and a purpose is provided, the error will be displayed in a toast.
 *
 * @param url
 */
const useFetchedData = <T>(
  fetch: () => Promise<T | null>,
  deps?: DependencyList,
  options?: UseFetchedDataOptions
) => {
  const [data, setData] = useState<T | null>(null);
  const [status, setStatus] = useState<
    "waiting" | "loading" | "loaded" | "error"
  >("waiting");
  const [error, setError] = useState<unknown | null>(null);
  const [fetchedCount, setFetchedCount] = useState(0);

  const fetchData = useCallback(async () => {
    setStatus("loading");
    try {
      const result = await fetch();
      setData(result);
      setStatus("loaded");
      setFetchedCount((prev) => prev + 1);
    } catch (error) {
      setError(error);
      setStatus("error");
      if (options?.onError) {
        options.onError(error);
      } else if (options?.purpose) {
        const message = message_from_exception(error);
        toast.error(`Failed to ${options.purpose}: ${message}`);
      }
    }
    // Explicitly not including the fetch and instead defining the deps
    // array passed by the caller to let them decide when to refetch.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ?? []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (options?.refreshAfterSeconds) {
      const interval = setInterval(
        fetchData,
        options.refreshAfterSeconds * 1000
      );
      return () => clearInterval(interval);
    }
  }, [fetchData, options?.refreshAfterSeconds]);

  return [
    data,
    setData,
    {
      status,
      error,
      dependencyRefresh: fetchedCount,
      refresh: fetchData,
    },
  ] as UseFetchedDataReturnType<T>;
};

export default useFetchedData;
