import { cn } from "lib/utils";
import { ReactNode } from "react";
import Spinner from "components/common/Spinner";

interface AsyncLoadedDivProps<T> extends React.HTMLAttributes<HTMLDivElement> {
  value: T | null;
  error?: string | null;
  whileLoaded: (value: T) => React.ReactNode;
}

const AsyncLoadedDiv = <T,>({
  children,
  error,
  value,
  whileLoaded,
  className,
  ...props
}: AsyncLoadedDivProps<T>) => {
  let content: ReactNode;
  if (error) {
    content = <p className="text-destructive">{error}</p>;
  } else if (value) {
    content = whileLoaded(value);
  } else if (children) {
    content = children;
  } else {
    content = (
      <div className="flex gap-md">
        <Spinner />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div
      className={cn(
        className,
        (error || (children === undefined && value === null)) &&
          "flex items-center justify-center"
      )}
    >
      {content}
    </div>
  );
};

export default AsyncLoadedDiv;
