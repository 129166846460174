import React from "react";
import { ContextMenuContentProps } from "@radix-ui/react-context-menu";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuTrigger,
} from "../Menus/ContextMenu";
import Icon, { IconName } from "components/common/Icon";
import { Command, CommandItem, CommandList } from "./Command";
import {
  colCountInSelection,
  rowCountInSelection,
  useTableState,
} from "./TableElementProvider";

const TableContextMenu = React.forwardRef<
  React.ElementRef<typeof ContextMenuContent>,
  ContextMenuContentProps
>(({ children, ...props }, ref) => {
  const {
    deleteTable,
    insertRow,
    insertColumn,
    selection,
    deleteColumns,
    deleteRows,
    setContextMenuActive,
  } = useTableState();

  const selectedColumns = colCountInSelection(selection);
  const selectedRows = rowCountInSelection(selection);

  const items: (
    | {
        key: string;
        onSelect: () => void;
        icon: IconName;
        text: string;
      }
    | string
  )[] = [
    {
      key: "insert-row-above",
      icon: "plus",
      text: "Insert row above",
      onSelect: () => {
        insertRow(true);
      },
    },
    {
      key: "insert-row-below",
      icon: "plus",
      text: "Insert row below",
      onSelect: () => {
        insertRow(false);
      },
    },
    {
      key: "insert-column-left",
      icon: "plus",
      text: "Insert column left",
      onSelect: () => {
        insertColumn(true);
      },
    },
    {
      key: "insert-column-right",
      icon: "plus",
      text: "Insert column right",
      onSelect: () => {
        insertColumn(false);
      },
    },
    "sep1",
    {
      key: "delete-row",
      icon: "trash",
      text: selectedRows === 1 ? "Delete row" : "Delete rows",
      onSelect: () => {
        deleteRows();
      },
    },
    {
      key: "delete-column",
      icon: "trash",
      text: selectedColumns === 1 ? "Delete column" : "Delete columns",
      onSelect: () => {
        deleteColumns();
      },
    },
    {
      key: "delete-table",
      icon: "trash",
      text: "Delete table",
      onSelect: () => {
        deleteTable();
      },
    },
  ];

  return (
    <ContextMenu onOpenChange={setContextMenuActive} modal={false}>
      <ContextMenuTrigger>{children}</ContextMenuTrigger>
      <ContextMenuContent ref={ref} className="p-0" {...props}>
        <Command>
          <CommandList>
            {items.map((item) => {
              if (typeof item === "string") {
                return <div className="h-thin bg-border" key={item} />;
              }
              return (
                <CommandItem
                  key={item.key}
                  onSelect={() => {
                    item.onSelect();
                  }}
                >
                  <Icon name={item.icon} />
                  {item.text}
                </CommandItem>
              );
            })}
          </CommandList>
        </Command>
      </ContextMenuContent>
    </ContextMenu>
  );
});

export default TableContextMenu;
