import { AxiosError } from "axios";
import Button from "components/common/Button";
import Icon, { IconName } from "components/common/Icon";
import NavigationView from "components/common/NavigationView";
import MessageView from "components/common/containers/MessageView";
import { useApiClient } from "providers/ApiClientProvider";
import { ReactNode, useState } from "react";
import { message_from_exception } from "utils";

interface ProposalLoadErrorProps {
  error: unknown;
  proposalId: string;
}

const ProposalLoadError: React.FC<ProposalLoadErrorProps> = ({
  error,
  proposalId,
}) => {
  const apiClient = useApiClient();
  let title = "Failed to load proposal";
  let icon: IconName = "circle-exclamation";
  let message: ReactNode = <>{message_from_exception(error)}</>;
  const [requestetingAccess, setRequestingAccess] = useState(false);
  const [requestedAccess, setRequestedAccess] = useState(false);
  const [requestedAccessError, setRequestedAccessError] = useState<
    string | null
  >(null);

  console.error("error", error);

  if (error instanceof AxiosError) {
    switch (error.response?.status) {
      case 404:
        icon = "circle-question";
        title = "Not Found";
        message = (
          <p>
            If you think this is an error,
            <br />
            please{" "}
            <a href="mailto:support@odo.do" className="underline inline">
              contact support
            </a>
            .
          </p>
        );
        break;
      case 403:
        icon = "do-not-enter";
        title = "Permission Denied";
        message = (
          <>
            You do not currently have permission to access this proposal.
            <Button
              className="mx-auto"
              variant="solid-secondary"
              text={requestedAccess ? "Requested" : "Request Permission"}
              disabled={requestetingAccess || requestedAccess}
              onClick={async () => {
                setRequestingAccess(true);
                setRequestedAccessError(null);
                try {
                  await apiClient.rfp.rfpProposalRequestPermissionCreate(
                    proposalId
                  );
                  setRequestedAccess(true);
                } catch (e) {
                  setRequestedAccessError(message_from_exception(e));
                } finally {
                  setRequestingAccess(false);
                }
              }}
            />
            {requestedAccessError && (
              <p className="text-destructive text-center">
                {requestedAccessError}
              </p>
            )}
          </>
        );
    }
  }

  return (
    <NavigationView containerClassName="bg-background-secondary">
      <MessageView icon={icon} title={title}>
        <p className="text-secondary text-center max-w-[300px] flex flex-col gap-md">
          {message}
        </p>
      </MessageView>
    </NavigationView>
  );
};

export default ProposalLoadError;
