import React, { useCallback, useRef, CSSProperties } from "react";
import useLocalStorage from "../../../lib/useLocalStorage";
import { cn } from "../../../lib/utils";

interface DragResizableProps extends React.HTMLAttributes<HTMLDivElement> {
  defaultSize: number;
  maxSize?: number;
  minSize?: number;
  children: React.ReactNode;
  savedStateKey?: string;
  direction: "horizontally" | "vertically";
  controlSide?: "leading" | "trailing";
}

export const DragResizable: React.FC<DragResizableProps> = ({
  direction,
  defaultSize,
  children,
  maxSize,
  minSize,
  savedStateKey,
  className,
  controlSide,
  ...props
}) => {
  const [size, setSize] = useLocalStorage(savedStateKey, defaultSize);
  const ref = useRef<HTMLDivElement>(null);

  const displaySize = Math.max(Math.min(size, maxSize ?? size), minSize ?? 0);

  const handleWidthChange = useCallback(
    (newSize: number) => {
      setSize((prev) => prev + newSize);
    },
    [setSize]
  );

  const handleSizeSolidify = () => {
    setSize((prev) => Math.max(Math.min(prev, maxSize ?? prev), minSize ?? 0));
  };

  let style: CSSProperties = {
    position: "relative",
  };

  if (direction === "horizontally") {
    style["width"] = `${displaySize}px`;
  } else {
    style["height"] = `${displaySize}px`;
  }

  return (
    <div
      ref={ref}
      style={style}
      className={cn("relative", className)}
      {...props}
    >
      {children}
      <DragResizableControl
        direction={direction}
        controlSide={controlSide ?? "leading"}
        onResize={handleWidthChange}
        onStopResize={handleSizeSolidify}
      />
    </div>
  );
};

interface DragResizableControlProps {
  direction: "horizontally" | "vertically";
  onResize: (newWidth: number) => void;
  onStopResize?: () => void;
  controlSide: "leading" | "trailing";
  className?: string;
  style?: CSSProperties;
  contentEditable?: boolean;
}

export const DragResizableControl: React.FC<DragResizableControlProps> = ({
  onResize,
  onStopResize,
  direction,
  controlSide,
  className,
  contentEditable,
  style,
}) => {
  const startResizing = useCallback(
    (mouseDownEvent: React.MouseEvent) => {
      mouseDownEvent.preventDefault();
      mouseDownEvent.stopPropagation();

      let prevPos = 0;
      if (direction === "horizontally") {
        prevPos = mouseDownEvent.clientX;
      } else {
        prevPos = mouseDownEvent.clientY;
      }

      const doDrag = (mouseMoveEvent: MouseEvent) => {
        if (direction === "horizontally") {
          const currentX = mouseMoveEvent.clientX;
          const newWidth = prevPos - currentX;
          if (controlSide === "trailing") {
            onResize(-newWidth);
          } else {
            onResize(newWidth);
          }
          prevPos = currentX;
        } else {
          const currentY = mouseMoveEvent.clientY;
          const newHeight = prevPos - currentY;
          onResize(newHeight);
          prevPos = currentY;
        }
        mouseMoveEvent.preventDefault();
      };

      const stopDrag = () => {
        document.removeEventListener("mousemove", doDrag);
        document.removeEventListener("mouseup", stopDrag);
        onStopResize?.();
      };

      document.addEventListener("mousemove", doDrag);
      document.addEventListener("mouseup", stopDrag);
    },
    [controlSide, direction, onResize, onStopResize]
  );

  let positioningClassName = "";
  if (direction === "horizontally") {
    positioningClassName = "top-0 bottom-0 w-sm cursor-col-resize";
    if (controlSide === "leading") {
      positioningClassName += " left-[-4px]";
    } else {
      positioningClassName += " right-[-4px]";
    }
  } else {
    positioningClassName = "left-0 right-0 h-sm cursor-row-resize";
    if (controlSide === "leading") {
      positioningClassName += " top-0";
    } else {
      positioningClassName += " bottom-0";
    }
  }

  return (
    <div
      onMouseDown={startResizing}
      className={cn("absolute", positioningClassName, className)}
      contentEditable={contentEditable}
      style={style}
    />
  );
};
