import { createPluginFactory } from "@udecode/plate-common";
import { ELEMENT_MENTION, MentionsPlugin } from "./types";
import withMentionsOverrides from "./withMentionsOverrides";

const createMentionsPlugin = createPluginFactory<MentionsPlugin>({
  key: ELEMENT_MENTION,
  withOverrides: withMentionsOverrides,
  isElement: true,
  isInline: true,
  isVoid: true,
  then: (_, { key, options: { trigger } }) => ({
    options: {
      id: key,
      trigger,
    },
  }),
});

export default createMentionsPlugin;
