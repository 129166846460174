import Icon from "components/common/Icon";
import { ToolbarButton } from "./Toolbar";
import { FC, useCallback, useEffect, useState } from "react";
import Overlay from "components/common/containers/overlays/Overlay";
import usePaginatedData from "hooks/usePaginatedData";
import { useApiClient } from "providers/ApiClientProvider";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Toggle from "components/common/forms/Toggle";
import Rows from "components/common/containers/Rows";
import Columns from "components/common/containers/Columns";
import Button from "components/common/Button";
import { ProjectDetails } from "api/Api";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import { cn } from "lib/utils";
import PlainText from "components/common/PlainText";
import { useProposalRequirementData } from "providers/ProposalRequirementDetailsProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { message_from_exception } from "utils";
import { useOverlayContext } from "components/common/containers/overlays/OverlayContextProvider";
import { useConfirm } from "providers/AlertProvider";

const KnowledgeBaseButton = () => {
  const [adjustingKnowledge, setAdjustingKnowledge] = useState(false);
  return (
    <>
      <ToolbarButton
        variant="ai"
        className="px-md gap-sm"
        onClick={() => setAdjustingKnowledge(true)}
      >
        <Icon name="book-open-cover" />
        Knowledge
      </ToolbarButton>
      {adjustingKnowledge && (
        <Overlay
          title="Knowledge Base"
          className="w-full h-[70vh]"
          maxWidth={1500}
          onClose={() => setAdjustingKnowledge(false)}
        >
          <KnowledgeBaseMenu />
        </Overlay>
      )}
    </>
  );
};

interface KnowledgeBaseMenuProps {}

const KnowledgeBaseMenu: FC<KnowledgeBaseMenuProps> = ({}) => {
  const apiClient = useApiClient();
  const [projects, , paginatedData] = usePaginatedData({
    endpoint: apiClient.rfp.rfpProposalProjectList,
    map: (project) => project,
  });
  const { requirement, requirementMetadata, setRequirementMetadata } =
    useProposalRequirementData();
  const [activeProject, setActiveProject] = useState<ProjectDetails | null>(
    null
  );
  const { details: proposalDetials } = useProposalData();
  const [error, setError] = useState<string | null>(null);
  const confirm = useConfirm();
  const { dismiss, registerBeforeDismiss, unregisterBeforeDismiss } =
    useOverlayContext();
  const [hasChanged, setHasChanged] = useState(false);
  const [selectedProjectIds, doSetSelectedProjectIds] = useState(
    requirementMetadata?.selectedProjectIds || {}
  );

  const canSave =
    hasChanged &&
    Object.values(selectedProjectIds).filter((value) => value).length > 0;

  useEffect(() => {
    const handle = async (forced: boolean) => {
      if (canSave && !forced) {
        const result = await confirm("Would you like to save the changes?", {
          yesText: "Save",
          defaultYes: true,
          noText: "Discard",
        });
        if (result) {
          await handleSave();
        }
      }
      return true;
    };

    registerBeforeDismiss(handle);
    return () => {
      unregisterBeforeDismiss(handle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerBeforeDismiss, hasChanged, canSave]);

  const setSelectedProjectIds = (selectedProjectIds: {
    [key: string]: boolean;
  }) => {
    doSetSelectedProjectIds(selectedProjectIds);
    if (!requirementMetadata?.selectedProjectIds) {
      setHasChanged(true);
      return;
    }
    const orig = JSON.stringify(requirementMetadata.selectedProjectIds);
    const curr = JSON.stringify(selectedProjectIds);
    console.log(orig !== curr);
    setHasChanged(orig !== curr);
  };

  const handleSave = useCallback(async () => {
    if (!hasChanged) return;
    try {
      let projectIds = [];
      for (let key in selectedProjectIds) {
        if (selectedProjectIds[key]) {
          projectIds.push(key);
        }
      }
      await apiClient.rfp.rfpProposalRequirementGeneratePastExperienceCreate(
        proposalDetials.id,
        requirement.id,
        {
          selected_project_ids: projectIds,
        }
      );
      setRequirementMetadata((prev) => ({
        ...prev,
        selectedProjectIds,
      }));
      dismiss?.(true);
    } catch (e) {
      setError(message_from_exception(e));
    }
  }, [
    apiClient.rfp,
    dismiss,
    hasChanged,
    proposalDetials.id,
    requirement.id,
    selectedProjectIds,
    setRequirementMetadata,
  ]);

  return (
    <Rows className="grow gap-md">
      <Columns className="border rounded-sm grow">
        <Rows className="gap-md grow basis-0 border-r">
          <PaginatedTableView
            results={projects}
            className="grow"
            variant="without-padding"
            paginatedData={paginatedData}
            searchable={true}
            columns={[{ size: "min" }, {}]}
            onSelect={(project) => {
              if (!selectedProjectIds) return;
              setSelectedProjectIds({
                ...selectedProjectIds,
                [project.id!]: !selectedProjectIds[project.id!],
              });
            }}
            rowSeparators={true}
            renderRow={(project, Cell, Row) => {
              let subtitle = "-";
              if (project.customer && project.location) {
                subtitle = `${project.customer} - ${project.location}`;
              } else if (project.customer) {
                subtitle = project.customer;
              } else if (project.location) {
                subtitle = project.location;
              }
              return (
                <Row key={project.id!}>
                  <Cell
                    onMouseEnter={() => setActiveProject(project)}
                    className={cn(
                      "-mr-2m -ml-sm",
                      project.id === activeProject?.id
                        ? "bg-background-selected"
                        : ""
                    )}
                  >
                    <Toggle
                      size="large"
                      on={selectedProjectIds[project.id!]}
                      toggleType="checkbox"
                      className="pointer-events-none"
                    />
                  </Cell>
                  <Cell
                    onMouseEnter={() => setActiveProject(project)}
                    className={cn(
                      "pl-sm",
                      project.id === activeProject?.id
                        ? "bg-background-selected"
                        : ""
                    )}
                  >
                    <Rows>
                      <div>{project.title || "Unnamed Project"}</div>
                      <div className="text-sm text-secondary">{subtitle}</div>
                    </Rows>
                  </Cell>
                </Row>
              );
            }}
          />
        </Rows>
        <Rows className="grow-[2] basis-0 p-lg overflow-y-auto">
          {activeProject && (
            <LabeledFormInput label="Project Description">
              <Rows
                className={cn(
                  "gap-sm",
                  !activeProject.description && "text-secondary"
                )}
              >
                <PlainText
                  text={
                    !!activeProject.description
                      ? activeProject.description
                      : "No Description"
                  }
                />
              </Rows>
            </LabeledFormInput>
          )}
        </Rows>
      </Columns>
      <Columns className="shrink-0 items-center gap-md">
        <Button
          icon="xmark"
          text="Cancel"
          className="px-sm py-xs"
          variant="outline"
          onClick={() => {
            dismiss?.(true);
          }}
        />
        <Button
          icon="floppy-disk"
          text="Save"
          variant="solid"
          disabled={!canSave}
          onClick={handleSave}
        />
        {error && <div className="text-destructive">{error}</div>}
      </Columns>
    </Rows>
  );
};

export default KnowledgeBaseButton;
