import { cn } from "../../lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import Rows from "./containers/Rows";

const containerVariants = cva("overflow-hidden", {
  variants: {
    size: {
      DEFAULT: "w-[32px] aspect-square",
      inherit: "w-[1em] aspect-square",
    },
  },
  defaultVariants: {
    size: "DEFAULT",
  },
});

const spinnerVariants = cva(
  "absolute inset-0 border-background-selected border-t-primary rounded-full animate-spin w-full",
  {
    variants: {
      size: {
        DEFAULT: "aspect-square border-[4px]",
        inherit: "aspect-squre border-[0.2em]",
      },
    },
    defaultVariants: {
      size: "DEFAULT",
    },
  }
);

export interface SpinnerProps extends VariantProps<typeof spinnerVariants> {
  className?: string;
  text?: string;
}

function Spinner({ size, className, text }: SpinnerProps) {
  const content = (
    <div
      className={cn(
        "relative",
        containerVariants({ size }),
        !text && className
      )}
    >
      <div className={cn(spinnerVariants({ size }))} />
    </div>
  );

  if (text) {
    return (
      <Rows className={cn("gap-md items-center text-secondary", className)}>
        {content}
        <p>{text}</p>
      </Rows>
    );
  }
  return content;
}

export default Spinner;
