import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { cva, VariantProps } from "class-variance-authority";
import { OverlayContextProvider } from "./OverlayContextProvider";
import { TooltipProvider } from "components/EditorView/Menus/Tooltip";
import { cn } from "lib/utils";
import Button from "components/common/Button";
import Rows from "../Rows";

const overlayPortalVariants = cva(
  "flex flex-col items-stretch fixed top-0 left-0 bottom-0 right-0 bg-overlay",
  {
    variants: {
      variant: {
        DEFAULT: "items-center justify-center p-lg",
        full: "",
        bordered: "tablet:p-3xl",
        transparent: "items-center justify-center",
      },
    },
    defaultVariants: {
      variant: "DEFAULT",
    },
  }
);

const contentPortalVariants: typeof overlayPortalVariants = cva(
  "flex flex-col transition-[height]",
  {
    variants: {
      variant: {
        DEFAULT: "rounded-md max-w-[800px] mx-auto p-lg bg-background",
        full: "p-3xl bg-background",
        bordered: "p-lg tablet:rounded-md bg-background",
        transparent: "p-lg",
      },
    },
    defaultVariants: {
      variant: "DEFAULT",
    },
  }
);

interface OverlayPortalProps
  extends VariantProps<typeof overlayPortalVariants> {
  children: ReactNode;
  className?: string;
  open?: boolean;
  title?: string;
  scrollable?: boolean;
  maxWidth?: number;
  position?: "center" | "top";
  onClose?: (force: boolean) => void;
}

const OverlayPortalContainer: FC<OverlayPortalProps> = ({
  children,
  className,
  open = true,
  scrollable = true,
  title,
  variant,
  maxWidth,
  position,
  onClose: onDismiss,
}) => {
  const [mousePosition, setMousePosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const [beforeDismissCallbacks, setBeforeDismissCallbacks] = useState<
    ((forced: boolean) => Promise<boolean>)[]
  >([]);

  const dismiss = async (force: boolean) => {
    for (const callback of beforeDismissCallbacks) {
      const shouldDismiss = await callback(force);
      if (!shouldDismiss) {
        return;
      }
    }
    onDismiss?.(force);
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);

  let topToolbar: ReactNode = null;
  if (title) {
    switch (variant ?? "DEFAULT") {
      case "full":
      case "bordered":
        topToolbar = (
          <div className="flex items-start shrink">
            <h1 className="text-xl font-semibold">{title}</h1>
            {onDismiss ? (
              <>
                <div className="grow" />
                <Button
                  icon="xmark"
                  size="large"
                  onClick={() => dismiss(false)}
                />
              </>
            ) : null}
          </div>
        );
        break;
      case "DEFAULT":
        topToolbar = (
          <div className="flex items-center pb-md shrink">
            <h1 className="text-xl font-semibold">{title}</h1>
            {onDismiss ? (
              <>
                <div className="grow" />
                <Button
                  icon="xmark"
                  className="text-secondary"
                  size="DEFAULT"
                  onClick={() => dismiss(false)}
                />
              </>
            ) : null}
          </div>
        );
        break;
    }
  }

  const handleBackgroundMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Only dismiss if the mouse hasn't moved significantly
    if (
      mousePosition &&
      Math.abs(mousePosition.x - e.clientX) < 2 &&
      Math.abs(mousePosition.y - e.clientY) < 2
    ) {
      dismiss(false);
    }
  };

  const registerBeforeDismiss = useCallback(
    (callback: (forced: boolean) => Promise<boolean>) => {
      setBeforeDismissCallbacks((callbacks) => [...callbacks, callback]);
    },
    []
  );

  const unregisterBeforeDismiss = useCallback(
    (callback: (forced: boolean) => Promise<boolean>) => {
      setBeforeDismissCallbacks((callbacks) =>
        callbacks.filter((cb) => cb !== callback)
      );
    },
    []
  );

  return (
    <OverlayContextProvider
      value={{
        isOpen: open,
        dismiss: (force) => dismiss(force ?? false),
        registerBeforeDismiss,
        unregisterBeforeDismiss,
      }}
    >
      <TooltipProvider>
        {/* Full screen, semi-transparent overlay */}
        <div
          className={cn(
            overlayPortalVariants({ variant }),
            open ? "opacity-100" : "opacity-0 pointer-events-none",
            position === "top" && "justify-start"
          )}
          onMouseDown={handleBackgroundMouseDown}
          onClick={handleBackgroundClick}
        >
          {position === "top" && <div className="h-[10vh]" />}
          {/* Solid background */}
          <div
            className={cn(
              "overflow-hidden",
              contentPortalVariants({ variant }),
              className
            )}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
            style={{ maxWidth }}
          >
            {topToolbar}
            <Rows
              className={cn(
                "grow relative max-h-[80vh]",
                scrollable && "overflow-y-auto",
                variant === "transparent" &&
                  "bg-background rounded-md overflow-x-hidden"
              )}
            >
              {children}
            </Rows>
          </div>
        </div>
      </TooltipProvider>
    </OverlayContextProvider>
  );
};

const Overlay: FC<OverlayPortalProps> = ({ ...props }) => {
  useEffect(() => {
    const portalDiv = document.createElement("div");
    const portalRoot = document.getElementById("overlay-portal");

    if (!portalRoot) {
      throw new Error(
        "Overlay portal root not found. Must add #overlay-portal to the DOM."
      );
    }

    portalRoot.appendChild(portalDiv);

    // Cleanup: remove the div when unmounted
    return () => {
      portalDiv.remove();
    };
  }, []);

  const portalRoot = document.getElementById("overlay-portal");
  return portalRoot
    ? createPortal(<OverlayPortalContainer {...props} />, portalRoot)
    : null;
};

export default Overlay;
