import { PlateElement, PlateElementProps, Value } from "@udecode/plate-common";
import {
  ElementRef,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { cn } from "../../../../lib/utils";
import { usePromptRefineryContext } from "../PromptRefineryProvider";
import Icon from "../../../common/Icon";
import IfElseEditor from "../IfElseEditor";
import { useDismissibleLayer } from "../../../DismissibleLayerContainer";
import { RenderError } from "../PromptRenderer";
import Spinner from "../../../common/Spinner";
import { IfElseVarProps } from "../plugins/IfElsePlugin";

const IfElseVarElement = forwardRef<
  ElementRef<typeof PlateElement>,
  PlateElementProps<Value, IfElseVarProps>
>(({ children, className, ...props }, ref) => {
  const { data, previewing } = usePromptRefineryContext();
  const conditionaVariable = data.getVariable(props.element.conditionVarId);
  const [text, setText] = useState<ReactNode>("");
  const {
    activateDismissibleLayer,
    dismissDismissibleLayer,
    DismissibleLayer,
  } = useDismissibleLayer();
  const [status, setStatus] = useState<"valid" | "loading" | "invalid">(
    "loading"
  );

  const updateText = useCallback(async () => {
    if (previewing) {
      setStatus("loading");
      setText(<Spinner size="inherit" />);
      try {
        setText(await data.renderNode(props.element));
        setStatus("valid");
      } catch (e) {
        if (e === RenderError.VARIABLE_VALUE_NOT_SET) {
          setText("Variable value not set");
          setStatus("invalid");
        }
      }
    } else {
      if (conditionaVariable) {
        setText(conditionaVariable.name);
        setStatus("valid");
      } else {
        setText("Not Found");
        setStatus("invalid");
      }
    }
  }, [previewing, props.element, conditionaVariable, data]);

  useEffect(() => {
    updateText();
  }, [updateText]);

  const handleClicked = () => {
    activateDismissibleLayer(() => {
      updateText();
    });
  };

  return (
    <PlateElement
      ref={ref}
      {...props}
      className={cn("inline mx-[2px]", className)}
    >
      <span
        className={cn(
          "cursor-pointer px-xs py-xs rounded-sm",
          previewing ? "bg-background-selected" : "bg-comment-odo",
          status === "invalid" && "text-destructive"
        )}
        onClick={handleClicked}
      >
        <Icon name="circle-question" variant="solid" className="mr-xs" />
        {text}
        {children}
      </span>
      <DismissibleLayer className="flex text-foreground bg-popover max-w-[1400px] items-stretch border rounded-sm">
        <IfElseEditor
          element={props.element}
          className="w-full"
          onClose={dismissDismissibleLayer}
        />
      </DismissibleLayer>
    </PlateElement>
  );
});

export default IfElseVarElement;
