import { cn } from "lib/utils";
import { Org } from "api/Api";
import { useApiClient } from "providers/ApiClientProvider";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "components/EditorView/Menus/DropdownMenu";
import Spinner from "components/common/Spinner";
import usePaginatedData from "hooks/usePaginatedData";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import { useEffect, useState } from "react";

interface OrgSelectProps {
  value: Org | string | null;
  onChange: (value: Org) => void;
}

const OrgSelect: React.FC<OrgSelectProps> = ({ onChange, value }) => {
  const apiClient = useApiClient();

  const [orgs, , paginatedData] = usePaginatedData({
    endpoint: apiClient.orgs.orgsList,
    map: (org) => org,
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);

  let actualValue = value;
  if (actualValue && typeof actualValue === "string") {
    // Attempt to find the org by id
    console.log({ orgs, actualValue });
    const org = orgs?.find((o) => o.public_id === actualValue);
    if (org) {
      actualValue = org;
    }
  }

  return (
    <DropdownMenu
      open={dropdownOpen}
      onOpenChange={(open) => setDropdownOpen(open)}
    >
      <DropdownMenuTrigger>
        <div
          className={cn(
            "flex gap-xs items-center border rounded-sm text-sm h-[44px] p-sm",
            actualValue ? "text-foreground" : "text-secondary"
          )}
        >
          {orgs ? (
            actualValue ? (
              typeof actualValue === "string" ? (
                ""
              ) : (
                actualValue.name
              )
            ) : (
              "Select an organization to search within"
            )
          ) : (
            <>
              <Spinner size="inherit" />
              Loading...
            </>
          )}
        </div>
      </DropdownMenuTrigger>
      {orgs && (
        <DropdownMenuContent
          className="max-h-[400px] overflow-hidden flex"
          align="start"
        >
          <PaginatedTableView
            results={orgs}
            variant="without-padding"
            paginatedData={paginatedData}
            columns={1}
            onSelect={(org) => {
              onChange(org);
              setDropdownOpen(false);
            }}
            renderRow={(org, Cell, Row) => (
              <Row key={org.public_id!}>
                <Cell>{org.name}</Cell>
              </Row>
            )}
          />
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  );
};

export default OrgSelect;
