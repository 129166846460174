import CenteredContainer from "../../components/common/containers/CenteredContainer";
import Form from "../../components/common/forms/Form";
import OdoLogo from "../../components/common/OdoLogo";
import Button from "../../components/common/Button";
import { getRedirectAfterLogin, message_from_exception } from "../../utils";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApiClient } from "../../providers/ApiClientProvider";
import log, { LogType } from "../../log";
import Input from "../../components/common/forms/Input";
import PasswordRequirements from "../../components/login/PasswordRequirements";
import {
  useAuthenticatedUser,
  useRefreshAuthenticatedUser,
} from "providers/AuthenticatedUserProvider";

const ChangePasswordRoute = () => {
  const apiClient = useApiClient();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [sent, setSent] = useState(false);
  const refreshAuthenticatedUser = useRefreshAuthenticatedUser();

  useEffect(() => {
    if (!email || !token) {
      setError("Invalid link");
    }
  }, [email, token]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setError(null);

    try {
      setSent(true);
      const response = await apiClient.auth.authRecoverCreate({
        email: email!,
        token: token!,
        password: password,
      });
      if (response.status === 204) {
        await refreshAuthenticatedUser();
        const path = getRedirectAfterLogin() ?? "/";
        navigate(path);
      } else {
        setError("Something went wrong");
        setSent(false);
      }
    } catch (e: any) {
      setSent(false);
      log(LogType.Auth, e);
      setError(message_from_exception(e, "An unexpected error occurred"));
    }
  };

  return (
    <CenteredContainer>
      <Form className="max-w-[300px]" onSubmit={handleSubmit}>
        <OdoLogo />
        <h2 className="text-xl w-full text-center">Change Your Password</h2>
        <Input
          icon="envelope"
          type="email"
          id="email"
          placeholder="Email"
          variant="form"
          value={email ?? ""}
          readOnly={true}
          tabIndex={-1}
        />
        <Input
          type="password"
          icon="lock"
          placeholder="New Password"
          variant="form"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required={true}
          tabIndex={1}
        />
        <PasswordRequirements />
        <Input
          type="password"
          icon="lock"
          placeholder="Confirm Password"
          variant="form"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required={true}
          tabIndex={2}
        />
        {error && (
          <p className="text-destructive text-center max-w-[350px]">{error}</p>
        )}
        <Button
          variant="solid"
          type="submit"
          disabled={sent || !email || !token}
          tabIndex={3}
          text="Submit"
        />
      </Form>
    </CenteredContainer>
  );
};

export default ChangePasswordRoute;
