import React from "react";
import { useOutdentButton } from "@udecode/plate-indent";

import { ToolbarButton } from "./Toolbar";
import Icon from "../../common/Icon";

export function OutdentToolbarButton({ disabled }: { disabled?: boolean }) {
  const { props } = useOutdentButton();

  return (
    <ToolbarButton tooltip="Outdent" {...props} disabled={disabled}>
      <Icon name="outdent" />
    </ToolbarButton>
  );
}
