import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { Requirement } from "odo";
import { HocuspocusProvider } from "@hocuspocus/provider";
import useYjsObject from "api/useYjsObject";

export interface RequirementMetadata {
  details: string;
  relevantContext: string;
  selectedProjectIds?: Record<string, boolean>;
}

export interface ProposalRequirementDetailsContextData {
  requirement: Requirement;
  setRequirement: Dispatch<SetStateAction<Requirement>>;

  requirementMetadata: RequirementMetadata | null;
  setRequirementMetadata: Dispatch<SetStateAction<RequirementMetadata>>;

  yjsProvider: HocuspocusProvider | null;
  setYjsProvider: (provider: HocuspocusProvider | null) => void;
}

const ProposalRequirementDetailsContext =
  createContext<ProposalRequirementDetailsContextData | null>(null);

interface ProposalRequirementDetailsProviderParams {
  children?: ReactNode;
  requirement: Requirement;
  setRequirement: Dispatch<SetStateAction<Requirement>>;
}

export const ProposalRequirementDetailsProvider: React.FC<
  ProposalRequirementDetailsProviderParams
> = ({ children, requirement, setRequirement }) => {
  const [yjsProvider, setYjsProvider] = useState<HocuspocusProvider | null>(
    null
  );

  const [requirementMetadata, setRequirementMetadata] =
    useYjsObject<RequirementMetadata>({
      yjsProvider,
      key: "meta",
      syncingTypes: {
        details: "safe",
        relevantContext: "safe",
        selectedProjectIds: "safe",
      },
    });

  return (
    <ProposalRequirementDetailsContext.Provider
      value={{
        requirement,
        setRequirement,
        requirementMetadata,
        setRequirementMetadata,
        yjsProvider,
        setYjsProvider,
      }}
    >
      {children}
    </ProposalRequirementDetailsContext.Provider>
  );
};

export const useProposalRequirementData = () => {
  const data = useContext(ProposalRequirementDetailsContext);
  if (!data) {
    return {
      requirement: { id: "test", title: "Test Requirement", status: "blank" },
      setRequirement: () => {},
      yjsProvider: null,
      setYjsProvider: () => {},
      requirementMetadata: null,
      setRequirementMetadata: () => {},
    };
  }
  return data;
};
