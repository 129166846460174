import { ELEMENT_SECTION_CONTROL } from "./types.js";
export function updateRefineElements(editor) {
    editor.withoutNormalizing(() => {
        var _a, _b;
        let sectionData = null;
        let index = 0;
        while (index < editor.children.length) {
            const child = editor.children[index];
            if ((_b = (_a = child.type) === null || _a === void 0 ? void 0 : _a.startsWith("h")) !== null && _b !== void 0 ? _b : false) {
                if (!!sectionData) {
                    index += updateSectionControl(editor, sectionData, index - 1);
                }
                if (typeof child.id === "string") {
                    sectionData = {
                        headerIndex: index,
                        generatable: child.generatable === true,
                        id: child.id,
                        hasChildWithContent: false,
                        controlIndexes: [],
                        contentChildCount: 0,
                        refined: child.refined === true,
                    };
                }
                else {
                    sectionData = null;
                }
            }
            else if (!!sectionData) {
                if (child.type === ELEMENT_SECTION_CONTROL) {
                    sectionData.controlIndexes.push(index);
                }
                else if (child.type !== "p" ||
                    !!child.listStyleType ||
                    !!child.indent ||
                    (child.children.length > 0 && child.children[0].text !== "")) {
                    sectionData.hasChildWithContent = true;
                    sectionData.contentChildCount += 1;
                }
                else {
                    sectionData.contentChildCount += 1;
                }
            }
            else if (child.type === ELEMENT_SECTION_CONTROL) {
                // We need to delete it because we aren't currently tracking a section
                editor.removeNodes({ at: [index] });
                continue;
            }
            index += 1;
        }
        if (!!sectionData) {
            updateSectionControl(editor, sectionData, index - 1);
        }
    });
}
/**
 * Ensures the control is set up correctly for the given section and
 * returns the net elements added/removed
 * @param editor
 * @param sectionData
 * @param lastIndex
 */
function updateSectionControl(editor, sectionData, lastIndex) {
    let indexCountChange = 0;
    let controlIndex = lastIndex;
    // Remove extra controls
    while (sectionData.controlIndexes.length > 1) {
        editor.removeNodes({ at: [sectionData.controlIndexes[0]] });
        sectionData.controlIndexes.shift();
        for (let i = 0; i < sectionData.controlIndexes.length; i++) {
            sectionData.controlIndexes[i] -= 1;
        }
        controlIndex -= 1;
        indexCountChange -= 1;
    }
    if (sectionData.refined || sectionData.contentChildCount === 0) {
        // All controls should be removed
        if (sectionData.controlIndexes.length > 0) {
            editor.removeNodes({ at: [sectionData.controlIndexes[0]] });
            return indexCountChange - 1;
        }
        return indexCountChange;
    }
    if (sectionData.controlIndexes.length === 0) {
        // We need to add a control
        const index = lastIndex + 1;
        editor.insertNodes({
            // @ts-ignore
            type: ELEMENT_SECTION_CONTROL,
            section_id: sectionData.id,
            children: [{ text: "" }],
        }, { at: [index] });
        indexCountChange += 1;
        controlIndex += 1;
    }
    else if (sectionData.controlIndexes[0] !== controlIndex) {
        // We need to move the control
        editor.moveNodes({
            at: [sectionData.controlIndexes[0]],
            to: [controlIndex],
        });
    }
    // At this point, the control is in the right place (at the end index)
    const hasContent = sectionData.hasChildWithContent || sectionData.contentChildCount > 1;
    if (editor.children[controlIndex].generatable !== sectionData.generatable ||
        editor.children[controlIndex].hasContent !== hasContent ||
        editor.children[controlIndex].section_id !== sectionData.id) {
        editor.setNodes({
            // @ts-ignore
            generatable: sectionData.generatable,
            hasContent: hasContent,
            section_id: sectionData.id,
        }, { at: [controlIndex] });
    }
    return indexCountChange;
}
