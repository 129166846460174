import { v4 } from "uuid";

export interface PromptToolInputProperty extends Record<string, string> {
  type: "string";
  description: string;
}

export interface PromptToolInputSchema {
  type: "object";
  properties: Record<string, PromptToolInputProperty>;
  required: string[];
}

export interface PromptTool {
  name: string;
  description: string;

  input_schema: PromptToolInputSchema;
}

export interface WorkingProperty extends PromptToolInputProperty {
  id: string;
  name: string;
}

/**
 * The end tool format doesn't lend itself well to our UI, so we use
 * this `WorkingPromptTool` for editing but the save out the `PromptTool`
 */
export interface WorkingPromptTool {
  name: string;
  description: string;

  properties: WorkingProperty[];
}

export function promptToolToWorking(
  tool: PromptTool | null
): WorkingPromptTool {
  if (!tool) {
    return {
      name: "",
      description: "",
      properties: [],
    };
  }
  return {
    name: tool.name,
    description: tool.description,
    properties: Object.entries(tool.input_schema.properties).map(
      ([name, property]) => ({
        ...property,
        id: v4(),
        name,
      })
    ),
  };
}

export function workingToPromptTool(working: WorkingPromptTool): PromptTool {
  return {
    name: working.name,
    description: working.description,
    input_schema: {
      type: "object",
      properties: working.properties.reduce(
        (acc, property) => ({
          ...acc,
          [property.name]: { ...property, name: undefined },
        }),
        {}
      ),
      required: working.properties.map((property) => property.name),
    },
  };
}
