import Button from "components/common/Button";
import LoadableView from "components/common/containers/LoadableView";
import MessageView from "components/common/containers/MessageView";
import { useApiClient } from "providers/ApiClientProvider";
import { useState } from "react";

interface ConfirmStartStepProps {
  rfpId: string;
  rfpTitle: string;
  onConfirmed: (proposalId: string) => void;
}

const ConfirmStartStep: React.FC<ConfirmStartStepProps> = ({
  rfpId,
  rfpTitle,
  onConfirmed,
}) => {
  const apiClient = useApiClient();
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleConfirm = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const response = await apiClient.rfp.rfpProposalCreate({
        rfp_id: rfpId,
      });
      onConfirmed(response.data.id!);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadableView isLoading={isLoading} className="grow w-full flex">
      <MessageView title="Start Proposal" icon="file-plus" iconVariant="solid">
        <p className="max-w-[400px] text-center">
          Are you ready to get started on {rfpTitle}?
        </p>
        <Button
          text="Get Started"
          onClick={handleConfirm}
          variant="solid"
          className="mt-md"
        />
      </MessageView>
    </LoadableView>
  );
};

export default ConfirmStartStep;
