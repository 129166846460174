import { isOdoElement, isOdoText, } from "../../odo-editor/index.js";
export function isOdoSuggestedText(node) {
    return node.suggestion !== undefined;
}
export function isSuggestedTextInsert(node) {
    return isOdoSuggestedText(node) && node.suggestion === "insert";
}
export function isSuggestedTextSplit(node) {
    return isOdoSuggestedText(node) && node.suggestion === "split";
}
export function isSuggestedTextFormat(node) {
    return (isOdoSuggestedText(node) &&
        node.suggestion === "format" &&
        node.suggestion_meta !== undefined &&
        node.suggestion_meta.attributes !== undefined);
}
export function isDeletedText(node) {
    return isOdoText(node);
}
export function isDeletedBlockElement(node) {
    return isOdoElement(node) && node.is_block_element === true;
}
export function isDeletedElementStart(node) {
    return isOdoElement(node);
}
export function isSuggestedTextDelete(node) {
    return (isOdoSuggestedText(node) &&
        node.suggestion === "delete" &&
        node.suggestion_meta !== undefined &&
        node.suggestion_meta.deleted !== undefined);
}
