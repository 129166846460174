import { ProposalListCreate as APIProposal } from "api/Api";
import { date_from_string, format_absolute_date_long } from "lib/utils";

/**
 * The high level details used when listing multiple proposals.
 */
export interface Proposal {
  id: string;
  title: string;
  dueDate: string;
}

export const proposalFromAPI = (data: APIProposal): Proposal => {
  if (!data.id) {
    throw new Error("Proposal ID is required");
  }

  if (!data.title) {
    throw new Error("Proposal title is required");
  }

  return {
    id: data.id,
    title: data.title,
    dueDate: data.due_date
      ? format_absolute_date_long(date_from_string(data.due_date))
      : "-",
  };
};
