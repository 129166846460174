import { AutoformatRule } from "@udecode/plate-autoformat";
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import {
  ELEMENT_CODE_BLOCK,
  insertEmptyCodeBlock,
} from "@udecode/plate-code-block";
import { ELEMENT_DEFAULT } from "@udecode/plate-common";
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
} from "@udecode/plate-heading";

import { preFormat } from "lib/plate/autoformat/autoformatUtils";
import { isSelectionNotInTable } from "../../utils";

export const autoformatBlocks: AutoformatRule[] = [
  {
    mode: "block",
    type: ELEMENT_H1,
    query: isSelectionNotInTable,
    match: "# ",
    preFormat,
  },
  {
    mode: "block",
    type: ELEMENT_H2,
    query: isSelectionNotInTable,
    match: "## ",
    preFormat,
  },
  {
    mode: "block",
    type: ELEMENT_H3,
    query: isSelectionNotInTable,
    match: "### ",
    preFormat,
  },
  {
    mode: "block",
    type: ELEMENT_H4,
    query: isSelectionNotInTable,
    match: "#### ",
    preFormat,
  },
  {
    mode: "block",
    type: ELEMENT_H5,
    query: isSelectionNotInTable,
    match: "##### ",
    preFormat,
  },
  {
    mode: "block",
    type: ELEMENT_H6,
    query: isSelectionNotInTable,
    match: "###### ",
    preFormat,
  },
  {
    mode: "block",
    type: ELEMENT_BLOCKQUOTE,
    query: isSelectionNotInTable,
    match: "> ",
    preFormat,
  },
  {
    mode: "block",
    type: ELEMENT_CODE_BLOCK,
    query: isSelectionNotInTable,
    match: "```",
    preFormat,
    format: (editor) => {
      insertEmptyCodeBlock(editor, {
        defaultType: ELEMENT_DEFAULT,
        insertNodesOptions: { select: true },
      });
    },
  },
];
