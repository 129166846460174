import { FC, useEffect, useRef, useState } from "react";
import OdoCursor from "../cursors/OdoCursor";
import { cn } from "lib/utils";

type AnimationState =
  | "writing-word"
  | "deleting-word"
  | "blink-off"
  | "blink-on";

class AnimationData {
  type: "loop" | "once";
  options: string[];
  state: AnimationState;
  wordIndex: number;
  letterIndex: number;
  waitCount: number;

  constructor(options: string[], type: "loop" | "once") {
    this.type = type;
    this.options = options;
    this.state = "writing-word";
    this.wordIndex = 0;
    this.letterIndex = 0;
    this.waitCount = 0;
  }

  getText() {
    return this.options[this.wordIndex].slice(0, this.letterIndex);
  }

  getShowCursor() {
    return this.state !== "blink-off";
  }

  advanceFrame() {
    switch (this.state) {
      case "writing-word": {
        if (this.letterIndex === this.options[this.wordIndex].length) {
          // We're at the end of the word
          // -> switch to deleting
          if (this.type === "once") {
            this.state = "blink-on";
          } else {
            this.state = "deleting-word";
          }
          this.waitCount = 0;
        } else if (this.waitCount < 10) {
          // We're waiting
          // -> increment the wait count
          this.waitCount++;
        } else {
          // We're still writing the word
          // -> add the next letter
          this.letterIndex++;
        }
        break;
      }
      case "deleting-word": {
        if (this.letterIndex === 0) {
          // We're at the start of the word
          // -> advance the word and switch to writing
          this.wordIndex = (this.wordIndex + 1) % this.options.length;
          this.state = "writing-word";
          this.waitCount = 0;
        } else if (this.waitCount < 25) {
          // We're waiting
          // -> increment the wait count
          this.waitCount++;
        } else {
          // We're still deleting the word
          // -> remove the last letter
          this.letterIndex--;
        }
        break;
      }
      case "blink-on": {
        if (this.waitCount < 10) {
          this.waitCount++;
        } else {
          this.state = "blink-off";
          this.waitCount = 0;
        }
        break;
      }
      case "blink-off": {
        if (this.waitCount < 10) {
          this.waitCount++;
        } else {
          this.state = "blink-on";
          this.waitCount = 0;
        }
        break;
      }
    }
  }
}

interface TypingAnimationProps {
  className?: string;
  hardcodedText?: string;
  options: string[];
  type?: "loop" | "once";
}

const TypingAnimation: FC<TypingAnimationProps> = ({
  className,
  hardcodedText,
  options,
  type = "loop",
}) => {
  const data = useRef(new AnimationData(options, type));
  const [text, setText] = useState("");
  const [showingCursor, setShowingCursor] = useState(true);

  const advanceFrame = async () => {
    if (!data.current) return;

    data.current.advanceFrame();
    setText(data.current.getText());
    setShowingCursor(data.current.getShowCursor());
  };

  useEffect(() => {
    // Set up a timer to change the text every second (1000ms)
    const interval = setInterval(() => {
      advanceFrame();
    }, 50); // Adjust time as needed

    return () => clearInterval(interval);
  }, []);

  const noScriptText = options
    .map((option, index) => {
      if (index === options.length - 1 && options.length > 1)
        return `and ${option}`;
      return option;
    })
    .join(", ");

  return (
    <div className={cn("inline-flex", className)}>
      {hardcodedText && <span>{hardcodedText}&nbsp;</span>}
      <span className="text-primary min-h-[1.5em]">
        {text}
        <noscript>{noScriptText}</noscript>
      </span>
      <OdoCursor showing={showingCursor} />
    </div>
  );
};

export default TypingAnimation;
