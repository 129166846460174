import { createPluginFactory, TDescendant } from "@udecode/plate";

export const ELEMENT_DELETED = "deleted";

export const createDeletedPlugin = createPluginFactory({
  key: ELEMENT_DELETED,
  isElement: true,
  isVoid: true,
  withOverrides: (editor) => {
    const { getFragment } = editor;

    editor.getFragment = () => {
      const fragment = getFragment();
      const newFragment: TDescendant[] = [];

      fragment.forEach((node) => {
        if (node.type !== ELEMENT_DELETED) {
          newFragment.push(node);
        }
      });

      return newFragment as any;
    };
    return editor;
  },
});
