import { PromptEditorRef } from "../../../components/PromptRefinery/Prompt/PromptEditor";
import PromptVariableHeader from "../../../components/PromptRefinery/Prompt/PromptVariableHeader";
import { PromptVariable } from "../../../components/PromptRefinery/Prompt/types";
import { FC, useEffect, useState } from "react";
import PromptRefineryProvider, {
  usePromptRefineryContext,
} from "../../../components/PromptRefinery/Prompt/PromptRefineryProvider";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/common/Spinner";
import Button from "../../../components/common/Button";
import usePromptDetails from "../../../components/PromptRefinery/Prompt/usePromptDetails";
import { toast } from "react-toastify";
import PromptVariation from "../../../components/PromptRefinery/Prompt/PromptVariation";
import { message_from_exception } from "../../../utils";
import { useConfirm } from "providers/AlertProvider";
import Rows from "components/common/containers/Rows";
import Columns from "components/common/containers/Columns";
import Spacer from "components/common/containers/Spacer";
import CenteredContainer from "components/common/containers/CenteredContainer";

const PromptDetailsRoute = () => {
  const { id: rawId } = useParams();
  const id = Number.parseInt(rawId!)!;
  // TODO: Implement unsaved prompt detection
  const hasUnsavedPrompt = false;
  const data = usePromptDetails(id, !hasUnsavedPrompt);

  // const handleDiscard = () => {
  //   setSavedPromptId(id);
  // };

  // if (hasUnsavedPrompt) {
  //   return (
  //     <Columns className="w-full grow items-center justify-center">
  //       <UnsavedPromptWarning
  //         unSavedPromptId={savedPromptId}
  //         onDiscard={handleDiscard}
  //       />
  //     </Columns>
  //   );
  // }

  if (!data) {
    return (
      <CenteredContainer>
        <Spinner text="Loading..." />
      </CenteredContainer>
    );
  }

  return (
    <PromptRefineryProvider data={data}>
      <PromptRefineryContent />
    </PromptRefineryProvider>
  );
};

const PromptRefineryContent: FC = () => {
  const navigate = useNavigate();
  const [focusedEditor, setFocusedEditor] = useState<PromptEditorRef | null>(
    null
  );
  const { data } = usePromptRefineryContext();
  const confirm = useConfirm();
  const content = data.data.content;
  const [isSaving, setIsSaving] = useState(false);
  const [hasChanges, setHasChanges] = useState(data.data.content.hasChanges);
  const { previewing, setPreviewing } = usePromptRefineryContext();

  const handleSelectedVariable = (
    variable: PromptVariable,
    as: "text" | "condition"
  ) => {
    if (!focusedEditor) {
      toast.error("Please select an editor to insert the variable");
      return;
    }
    focusedEditor.insertVariable(variable, as);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await content.saveToServer(
        data.data.variables,
        data.data.valueGroups,
        data.data.variations,
        data.data.variationModel,
        data.data.variationNotes,
        data.data.variationTools,
        data.data.activeVariation
      );
    } catch (e) {
      toast.error(message_from_exception(e));
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const doSetHasChanges = (changes: boolean) => {
      setHasChanges(changes);
    };
    content.registerOnChanged(doSetHasChanges);
    return () => {
      content.unregisterOnChanged(doSetHasChanges);
    };
  }, [content, setHasChanges]);

  const handleDiscard = async () => {
    // Confirm user wants to discard
    if (await confirm("Are you sure you want to discard changes?")) {
      content.clearLocally();
      window.location.reload();
    }
  };

  return (
    <Rows className="gap-md grow">
      <Columns className="gap-md items-center shrink-0">
        <h1 className="text-xl font-semibold mr-auto">{data.data.name}</h1>
        <Button
          text="Save"
          variant="solid"
          icon="floppy-disk"
          isLoading={isSaving}
          onClick={handleSave}
          disabled={!hasChanges}
        />
        <Button
          text="Discard Changes"
          variant="outline-full"
          className="text-destructive"
          onClick={handleDiscard}
          disabled={!hasChanges}
        />
        <Button
          icon={previewing ? "eye" : "eye-slash"}
          onClick={() => setPreviewing(!previewing)}
        />
        <Spacer />
        <Button
          icon="list"
          text="Prompt Library"
          onClick={() => navigate("/admin/prompts/")}
        />
      </Columns>
      <Rows className="w-full grow flex flex-col gap-md relative">
        <PromptVariableHeader
          className="border-b"
          onSelectedVariable={handleSelectedVariable}
        />
        <Columns className="grow overflow-x-auto">
          <PromptVariation
            className="grow basis-0 min-w-[400px]"
            id={null}
            onFocusedEditor={setFocusedEditor}
            active={data.data.activeVariation === null}
          />
          {data.data.variations.map((variation) => (
            <PromptVariation
              key={variation}
              className="grow basis-0 min-w-[400px]"
              id={variation}
              onFocusedEditor={setFocusedEditor}
              active={data.data.activeVariation === variation}
            />
          ))}
        </Columns>
      </Rows>
    </Rows>
  );
};

export default PromptDetailsRoute;
