import { ComponentPropsWithoutRef, FC } from "react";
import MessageView from "./MessageView";
import { message_from_exception } from "utils";

interface ErrorViewProps extends ComponentPropsWithoutRef<"div"> {
  title?: string;
  error: unknown;
}

const ErrorView: FC<ErrorViewProps> = ({ error, title, ...props }) => {
  return (
    <MessageView icon="square-exclamation" title={title ?? "Error"} {...props}>
      <p className="text-destructive text-center">
        {message_from_exception(error)}
      </p>
    </MessageView>
  );
};

export default ErrorView;
