import { cn } from "lib/utils";

const Legal = ({ cta, className }: { cta: string; className?: string }) => {
  return (
    <>
      <p
        className={cn(
          "text-xs text-secondary mx-auto t-md max-w-[260px] text-center",
          className
        )}
      >
        By clicking “{cta}” above, you acknowledge that you have read,
        understood, and agree to Odo Labs'{" "}
        <a
          href="https://odo.do/policy/privacy"
          target="_blank"
          className="underline"
          rel="noreferrer"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          href={"https://odo.do/policy/terms"}
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>
      </p>
    </>
  );
};

export default Legal;
