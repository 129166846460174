import { cn } from "lib/utils";
import { ComponentPropsWithoutRef, forwardRef } from "react";

interface ScrollableProps extends ComponentPropsWithoutRef<"div"> {
  vertical?: boolean;
  horizontal?: boolean;
  alwaysShowScrollbar?: boolean;
}

const Scrollable = forwardRef<HTMLDivElement, ScrollableProps>((props, ref) => {
  const {
    children,
    vertical = true,
    horizontal = false,
    className,
    alwaysShowScrollbar = false,
    ...rest
  } = props;

  let suffix = alwaysShowScrollbar ? "scroll" : "auto";
  return (
    <div
      ref={ref}
      className={cn(
        vertical ? `overflow-y-${suffix}` : "overflow-y-hidden",
        horizontal ? `overflow-x-${suffix}` : "overflow-x-hidden",
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
});

export default Scrollable;
