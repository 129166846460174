import { createContext, useContext, useState, MouseEvent } from "react";
import { cn } from "../lib/utils";

interface DismissibleLayerProviderData {
  isActive: boolean;
  dismiss: () => void;
  activate: (onDismiss: () => void) => void;
}

const DismissibleLayerContext =
  createContext<DismissibleLayerProviderData | null>(null);

export const DismissibleLayerContainer: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = ({ children, className, ...props }) => {
  const [isActive, setActive] = useState(false);
  const [onDismiss, setOnDismiss] = useState<null | (() => void)>(null);

  const dismiss = () => {
    setActive(false);
    setOnDismiss(null);
  };

  const activate = (onDismiss: () => void) => {
    setActive(true);
    setOnDismiss(() => onDismiss);
  };

  const handleClicked = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onDismiss?.();
    setActive(false);
    setOnDismiss(null);
  };

  return (
    <DismissibleLayerContext.Provider value={{ isActive, dismiss, activate }}>
      <div className={cn("w-full grow flex relative", className)} {...props}>
        {children}
        {isActive && (
          <div
            className="bg-overlay absolute left-0 right-0 top-0 bottom-0 z-[555]"
            onClick={handleClicked}
          />
        )}
      </div>
    </DismissibleLayerContext.Provider>
  );
};

interface DismissibleLayerProps {
  children: React.ReactNode;
  className?: string;
}

const DismissibleLayer = (isActive: boolean) => {
  const component: React.FC<DismissibleLayerProps> = ({
    children,
    className,
  }) => {
    if (!isActive) {
      return null;
    }
    return (
      <div
        className={cn(
          "relative z-[999] top-0 bottom-0 left-0 right-0 bg-popover",
          className
        )}
      >
        {children}
      </div>
    );
  };
  return component;
};

export const useDismissibleLayer = () => {
  const context = useContext(DismissibleLayerContext);
  if (!context) {
    throw new Error(
      "useDismissibleLayer must be used within DismissibleLayerContainer"
    );
  }
  return {
    activateDismissibleLayer: context.activate,
    dismissDismissibleLayer: context.dismiss,
    DismissibleLayer: DismissibleLayer(context.isActive),
  };
};
