import { cn } from "lib/utils";
import Input from "../forms/Input";
import { HTMLAttributes, ReactNode, useEffect, useState } from "react";
import React from "react";
import Spinner from "../Spinner";
import {
  Popover,
  PopoverAnchor,
  PopoverContent,
} from "components/EditorView/Menus/Popover";
import { has } from "lodash";

export interface ResultBase {
  id: string;
  category?: string;
}

interface ResultHeading {
  type: "heading";
  label: string;
}

interface ComboBoxProps<T> {
  options: T[] | null;
  onActiveChanged?: (active: boolean) => void;
  filterOptions?: (option: T[], text: string) => T[];
  renderOption: (option: T) => ReactNode;
  noResultsText?: string;
  onSelected?: (option: T) => void;
  className?: string;
  placeholder?: string;
  autoFocus?: boolean;
  alwaysShowResults?: boolean;
  defaultKeyboardFocusIndex?: number | null;
  maxResults?: number;
}

function ComboBox<T extends ResultBase>({
  options,
  placeholder,
  renderOption,
  noResultsText,
  onSelected,
  onActiveChanged,
  filterOptions,
  alwaysShowResults,
  defaultKeyboardFocusIndex = 0,
  className,
  autoFocus,
  maxResults = 10,
}: ComboBoxProps<T>) {
  const [isActive, setIsActive] = useState(false);
  const [text, setText] = useState("");
  const [results, setResults] = useState<(T | ResultHeading)[] | null>(null);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [contentWidth, setContentWidth] = useState(0);
  const [keyboardSelectedIndex, setKeyboardSelectedIndex] = useState<
    number | null
  >(defaultKeyboardFocusIndex);

  useEffect(() => {
    // Keep the filtered results in sync with the options and filter
    if (!options) return;

    let results: (ResultHeading | T)[];
    if (filterOptions) {
      results = groupOptions(filterOptions(options, text), maxResults);
    } else {
      results = groupOptions(options, maxResults);
    }
    setResults(results);

    setKeyboardSelectedIndex((prev) => {
      if (prev === null) {
        if (results.length > 0 && text !== "") return 0;
        return null;
      }
      if (prev >= results.length) return results.length - 1;
      return prev;
    });
  }, [options, text, filterOptions]);

  useEffect(() => {
    // Let the parent know if the dropdown is active
    // This will often be used to refresh the data
    onActiveChanged?.(isActive);
    // Not including onActiveChanged in the dependencies array because we don't
    // need to call a new handler with an old value. We only need to call the new
    // handler when the value changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);

  useEffect(() => {
    if (anchorRef.current) {
      setContentWidth(anchorRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (
      results &&
      keyboardSelectedIndex !== null &&
      isResultHeading(results[keyboardSelectedIndex])
    ) {
      setKeyboardSelectedIndex(keyboardSelectedIndex + 1);
    }
  }, [keyboardSelectedIndex, results]);

  const handleSelect = (result: T) => {
    onSelected?.(result);
    setText("");
    setIsActive(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!isActive) {
      setIsActive(true);
      return;
    }
    if (!results) return;

    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (keyboardSelectedIndex === null) {
        setKeyboardSelectedIndex(0);
      } else {
        setKeyboardSelectedIndex(
          Math.min(results.length - 1, keyboardSelectedIndex + 1)
        );
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (keyboardSelectedIndex === null) {
        setKeyboardSelectedIndex(results.length - 1);
      } else {
        setKeyboardSelectedIndex(Math.max(0, keyboardSelectedIndex - 1));
      }
    } else if (e.key === "Enter") {
      if (keyboardSelectedIndex !== null) {
        if (results && results[keyboardSelectedIndex]) {
          const result = results[keyboardSelectedIndex];
          if (!isResultHeading(result)) {
            handleSelect(result);
          }
        }
      }
    }
  };

  let content: ReactNode;
  if (results) {
    if (results.length === 0) {
      content = (
        <div className="text-secondary p-sm text-center">
          {noResultsText ?? "No results found"}
        </div>
      );
    } else {
      content = (
        <>
          {results.map((result, i) => {
            if (isResultHeading(result)) {
              return (
                <div
                  key={result.label}
                  className="text-secondary px-sm pt-sm text-sm"
                >
                  {result.label}
                </div>
              );
            }
            return (
              <ComboBoxOption
                key={result.id}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSelect(result);
                }}
                className={
                  i === keyboardSelectedIndex ? "bg-background-selected" : ""
                }
              >
                {renderOption(result)}
              </ComboBoxOption>
            );
          })}
        </>
      );
    }
  } else {
    content = <Spinner className="m-8 mx-auto" />;
  }

  const input = (
    <Input
      value={text}
      ref={inputRef}
      onChange={(e) => setText(e.target.value)}
      placeholder={placeholder}
      onFocus={() => setIsActive(true)}
      className={cn(
        className,
        isActive ? "rounded-none rounded-t-md" : "",
        alwaysShowResults && "border-0 border-b"
      )}
      onKeyDown={handleKeyDown}
    />
  );

  if (alwaysShowResults) {
    return (
      <>
        {input}
        {content}
      </>
    );
  }

  return (
    <div>
      <Popover open={isActive} onOpenChange={setIsActive}>
        <PopoverAnchor asChild={false} ref={anchorRef}>
          {input}
        </PopoverAnchor>
        <PopoverContent
          className="p-0 m-0 rounded-none rounded-b"
          sideOffset={-1}
          alignOffset={0}
          avoidCollisions={false}
          style={{ width: contentWidth }}
          onOpenAutoFocus={(e) => e.preventDefault()}
          onFocusOutside={(e) => e.preventDefault()}
        >
          {content}
        </PopoverContent>
      </Popover>
    </div>
  );
}

interface ComboBoxOptionProps extends HTMLAttributes<HTMLDivElement> {}

const ComboBoxOption: React.FC<ComboBoxOptionProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={cn("p-sm hover:bg-background-selected", className)}
      {...props}
    >
      {children}
    </div>
  );
};

const groupOptions = <T extends ResultBase>(
  options: T[],
  maxResults: number
): (T | ResultHeading)[] => {
  const allOptions: (T | ResultHeading)[] = [];
  const groups: { [key: string]: T[] } = {};
  const groupOrder: string[] = [];
  for (const option of options.slice(0, maxResults)) {
    if (!option.category) {
      allOptions.push(option);
    } else {
      if (!groups[option.category]) {
        groups[option.category] = [];
        groupOrder.push(option.category);
      }
      groups[option.category].push(option);
    }
  }

  for (const group of groupOrder) {
    allOptions.push({ type: "heading", label: group });
    allOptions.push(...groups[group]);
  }

  return allOptions;
};

const isResultHeading = (result: any): result is ResultHeading => {
  if (!has(result, "type")) return false;
  return result.type === "heading";
};

export default ComboBox;
