import {
  clearRedirectAfterLogin,
  getRedirectAfterLogin,
  message_from_exception,
} from "utils";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApiClient } from "providers/ApiClientProvider";
import log, { LogType } from "log";
import {
  useAuthenticatedUser,
  useRefreshAuthenticatedUser,
} from "providers/AuthenticatedUserProvider";
import Form from "components/common/forms/Form";
import CenteredContainer from "components/common/containers/CenteredContainer";
import OdoLogo from "components/common/OdoLogo";
import Button from "components/common/Button";

const VerifyRoute = () => {
  const apiclient = useApiClient();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [sent, setSent] = useState(false);
  const refreshUser = useRefreshAuthenticatedUser();
  const user = useAuthenticatedUser();

  useEffect(() => {
    if (user === undefined) {
      return;
    }
    if (!user) {
      navigate("/login");
    } else if (user.isVerified) {
      const path = getRedirectAfterLogin() ?? "/";
      clearRedirectAfterLogin();
      navigate(path);
    }
  }, [user, navigate]);

  useEffect(() => {
    const verify = async () => {
      if (!token || !user) {
        return;
      }
      try {
        await apiclient.auth.authVerifyCreate({
          email: user.email,
          verify_token: token,
        });
        refreshUser();
        const path = getRedirectAfterLogin() ?? "/";
        navigate(path);
      } catch (e: any) {
        log(LogType.Auth, e);
        setError(message_from_exception(e, "An unexpected error occurred"));
      }
    };
    verify();
  }, [apiclient.auth, user, navigate, token, refreshUser]);

  const handleResend = async () => {
    try {
      setSent(true);
      await apiclient.auth.authResendVerifyCreate({ email: user?.email ?? "" });
      setTimeout(() => {
        setSent(false);
      }, 5000);
    } catch (e: any) {
      setSent(false);
      log(LogType.Auth, e);
      setError(message_from_exception(e, "An unexpected error occurred"));
    }
  };

  return (
    <CenteredContainer>
      <Form className="max-w-[300px]">
        <OdoLogo />
        <h2 className="text-xl w-full text-center">Verify Your Account</h2>
        <p className="text-center">
          Check your email for a verification link. Make sure to check your spam
          folder.
        </p>
        {user?.email && <p className="text-center font-bold">{user.email}</p>}
        <p className="text-center">
          If your account already exists, you'll receive a password reset
          instead.
        </p>
        {error && (
          <div className="text-destructive text-center max-w-[340px]">
            {error}
          </div>
        )}
        <Button
          text={sent ? "Sent" : "Send Email Again"}
          variant={sent ? "outline" : "solid"}
          className={sent ? "px-2m py-sm" : ""}
          type="button"
          disabled={sent}
          onClick={handleResend}
        />
      </Form>
    </CenteredContainer>
  );
};

export default VerifyRoute;
