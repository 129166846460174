import { createPluginFactory } from "@udecode/plate";
import { MARK_COMMENT } from "./types.js";
function filterOutCommentAttribute(node) {
    if (Array.isArray(node)) {
        node.forEach(filterOutCommentAttribute);
        return;
    }
    if (typeof node !== "object" || node === null) {
        return;
    }
    for (const key in node) {
        if (key.startsWith(MARK_COMMENT)) {
            delete node[key];
        }
        filterOutCommentAttribute(node[key]);
    }
}
export const createCommentsPlugin = createPluginFactory({
    key: MARK_COMMENT,
    isLeaf: true,
    withOverrides: (editor) => {
        const { getFragment } = editor;
        editor.getFragment = () => {
            const fragment = getFragment();
            filterOutCommentAttribute(fragment);
            return fragment;
        };
        return editor;
    },
});
