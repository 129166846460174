import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/EditorView/Menus/DropdownMenu";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import OdoUserAvatar from "components/common/OdoUserAvatar";
import Overlay from "components/common/containers/overlays/Overlay";
import ComboBox from "components/common/menus/ComboBox";
import { cn } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { useOrgUsers } from "providers/OrgUserProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { HTMLAttributes, useEffect, useState } from "react";
import { FrontendUser } from "types/FrontendUser";
import { PermissionedProposalParticipant } from "types/PermissionedProposalParticipant";
import { ProposalParticipant } from "types/ProposalParticipant";

interface ProposalParticipantsProps {}

const ProposalCollaborators: React.FC<ProposalParticipantsProps> = () => {
  const apiClient = useApiClient();
  const { details, participants, setParticipants } = useProposalData();
  const { users, refresh, isCurrentUserOwner, transferedOwnership } =
    useOrgUsers();
  const [configuring, setConfiguring] = useState(false);
  const [transferingOwnershipTo, setTransferingOwnershipTo] =
    useState<PermissionedProposalParticipant | null>(null);
  const currentUser = useAuthenticatedUser();
  const canShare = isCurrentUserOwner || currentUser.isStaff;
  const handleAddParticipant = async (user: ProposalParticipant) => {
    await apiClient.rfp.rfpProposalUsersCreate(details.id, {
      user_id: user.publicId,
    });
    setParticipants((prev) => [...prev, user]);
  };
  const handleRemoveParticipant = async (user: ProposalParticipant) => {
    await apiClient.rfp.rfpProposalUsersDelete(details.id, user.publicId);
    setParticipants((prev) => {
      return prev.filter((u) => u.publicId !== user.publicId);
    });
  };
  const handleTransferOwnership = async (user: ProposalParticipant) => {
    await apiClient.rfp.rfpProposalUsersCreate(details.id, {
      user_id: user.publicId,
      make_owner: true,
    });
    transferedOwnership({ ...user, isOwner: true });
    setTransferingOwnershipTo(null);
  };
  useEffect(() => {
    if (configuring) refresh();
  }, [configuring, refresh]);
  return (
    <div className="flex items-center gap-md">
      <Button
        text="Share"
        icon="share"
        variant="outline-full"
        onClick={() => setConfiguring(true)}
      />
      <div className="flex gap-[5px]">
        {participants.map((user) => (
          <OdoUserAvatar key={user.publicId} user={user} size="small" />
        ))}
      </div>
      <Overlay
        title="Collaborate On This Proposal"
        scrollable={false}
        maxWidth={600}
        className="overflow-visible"
        open={configuring}
        onClose={() => setConfiguring(false)}
      >
        <div className="flex flex-col gap-md">
          {canShare && (
            <ComboBox<PermissionedProposalParticipant & { id: string }>
              placeholder="Add collaborator"
              noResultsText="No Matches"
              onActiveChanged={(active) => active && refresh()}
              onSelected={handleAddParticipant}
              options={users?.map((u) => ({ ...u, id: u.publicId })) ?? []}
              filterOptions={(options, text) => {
                return options.filter((user) => {
                  if (user.permission !== "none") return false; // Already added
                  // Perform a case-insensitive fuzzy search across display name and email
                  return (
                    user.displayName
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    user.email?.toLowerCase().includes(text.toLowerCase())
                  );
                });
              }}
              renderOption={(user) => <UserDisplay user={user} />}
            />
          )}
          <div className="flex flex-col gap-md">
            <h3 className="text-lg mt-md">Current Collaborators</h3>
            {participants
              .sort((a, b) => a.displayName.localeCompare(b.displayName))
              .map((user) => {
                const permissionedUser = users?.find(
                  (u) => u.publicId === user.publicId
                );
                return (
                  <div key={user.publicId} className="flex items-center gap-md">
                    <UserDisplay key={user.publicId} user={user} />
                    <div className="grow" />
                    {permissionedUser &&
                      (permissionedUser.permission === "owner" ? (
                        <p className="text-sm text-secondary">Owner</p>
                      ) : (
                        <>
                          <p className="text-sm text-secondary">Collaborator</p>
                          {canShare && (
                            <DropdownMenu>
                              <DropdownMenuTrigger>
                                <Icon
                                  name="ellipsis-vertical"
                                  className="w-lg"
                                />
                              </DropdownMenuTrigger>
                              <DropdownMenuContent>
                                <DropdownMenuItem
                                  onClick={() =>
                                    handleRemoveParticipant(permissionedUser)
                                  }
                                >
                                  <Icon name="xmark" />
                                  Remove as Collaborator
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                  onClick={() =>
                                    setTransferingOwnershipTo(permissionedUser)
                                  }
                                >
                                  <Icon name="right-left" />
                                  Transfer Ownership
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          )}
                        </>
                      ))}
                  </div>
                );
              })}
          </div>
          {!canShare && (
            <p className="text-secondary text-sm text-center mt-md">
              Contact the owner to add or remove collaborators
            </p>
          )}
        </div>
      </Overlay>
      <Overlay
        title="Transfer Ownership"
        maxWidth={300}
        open={!!transferingOwnershipTo}
        onClose={() => setTransferingOwnershipTo(null)}
      >
        <div className="flex flex-col gap-md text-sm">
          <p>
            Are you sure you want to transfer ownership to{" "}
            {transferingOwnershipTo?.displayName}?
          </p>
          <p>You will not be able to take ownership back yourself.</p>
          <Button
            text="Transfer Ownership"
            variant="solid"
            className="mt-md bg-destructive"
            onClick={() => {
              handleTransferOwnership(transferingOwnershipTo!);
            }}
          />
        </div>
      </Overlay>
    </div>
  );
};

const UserDisplay = ({
  user,
  className,
  ...props
}: {
  user: FrontendUser;
} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn("flex items-center gap-md", className)} {...props}>
      <OdoUserAvatar user={user} size="small" />
      <div>
        <p className="font-semibold">{user.displayName}</p>
        <p className="text-sm mt-[-4px]">{user.email}</p>
      </div>
    </div>
  );
};

export default ProposalCollaborators;
