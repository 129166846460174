import { FC } from "react";
import { Comment, getCommentText, getCommentValue } from "odo";
import { cn } from "lib/utils";
import CommentAttributionView from "./CommentAttributionView";
import MarkdownView from "components/MarkdownView";
import CommentEditorView from "./CommentEditorView";

interface CommentViewProps {
  comment: Comment;
  className?: string;
  onChange?: (value: any[]) => void;
  onResolve?: (comment: Comment) => void;
}

const CommentView: FC<CommentViewProps> = ({
  comment,
  className,
  onChange,
  onResolve,
}) => {
  return (
    <div className={cn("flex flex-col gap-md px-2m", className)}>
      <CommentAttributionView comment={comment} onResolve={onResolve} />
      {!comment.isPending ? (
        <div className="px-xs">
          {comment.userId === "odo" ? (
            <MarkdownView markdown={getCommentText(comment)} />
          ) : (
            <CommentEditorView
              id={comment.id}
              value={getCommentValue(comment)}
              readonly={!comment.isPending}
              onChange={onChange}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CommentView;
