import { PlateElementProps } from "@udecode/plate-common";
import { SuggestableElement } from "components/proposals/refine/SuggestableElement";
import { useHasSuggestions, useIsFirstElement } from "lib/plate/isFirstElement";
import { useHasFollowingContentBeforeNextSection } from "lib/plate/useHasFollowingContentBeforeNextSection";
import { useIsInRefine } from "lib/plate/useIsInRefine";
import { textFromOdoElement } from "odo";
import { useApiClient } from "providers/ApiClientProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { useState } from "react";
import { toast } from "react-toastify";
import { ReactEditor } from "slate-react";
import { message_from_exception } from "utils";

const topOffsetFromVariant = (variant: string | null) => {
  switch (variant) {
    case "h1":
      return 12;
    case "h2":
      return 7;
    case "h3":
      return 5;
    case "h6":
      return 2;
    default:
      return undefined;
  }
};

export function HeadingElement({
  className,
  variant = "h1",
  children,
  ...props
}: PlateElementProps & { variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" }) {
  const apiClient = useApiClient();
  const { details } = useProposalData();
  const { element, editor } = props;
  const { isWriter } = useAuthenticatedUser();
  const isFirstElement = useIsFirstElement(element, editor as any);
  const isSuggested = useHasSuggestions(element);
  const isInRefine = useIsInRefine(element);
  const hasContent = useHasFollowingContentBeforeNextSection(element);

  const Element = variant!;
  const status: string = (element.status as string) ?? "blank";
  const statusName = element.statusName as string | undefined;
  const [generating, setGenerating] = useState(false);

  const handleStatusChange = ({
    status,
    statusName,
  }: {
    status: string;
    statusName: string;
  }) => {
    const path = ReactEditor.findPath(editor as any, element);
    editor.setNodes(
      {
        // @ts-ignore
        status,
        statusName,
      },
      { at: path }
    );
  };

  const handleGenerate = async () => {
    try {
      setGenerating(true);
      await apiClient.rfp.rfpProposalRequirementGenerateCreate(
        details.id,
        element.id as string,
        { use_fixture: false, title: textFromOdoElement(element as any) }
      );
    } catch (e) {
      toast.error(message_from_exception(e));
    } finally {
      setGenerating(false);
    }
  };

  return (
    <SuggestableElement
      asChild
      suggestionControlTopOffset={topOffsetFromVariant(variant)}
      className={className}
      {...props}
    >
      <Element className="relative">
        <div className="relative">
          {children}
          {/* {!isSuggested && !isInRefine && (
            <div
              className="absolute top-thin bottom-0 -left-2xl w-2xl flex flex-col items-stretch"
              contentEditable={false}
            >
              <StatusDropDown
                statusName={statusName}
                status={status}
                onChange={handleStatusChange}
              />
            </div>
          )} */}
        </div>
        {/* {!hasContent && !isSuggested && !isInRefine && isWriter && (
          <Button
            contentEditable={false}
            text="Generate Draft"
            variant="placeholder"
            className="text-sm mt-sm select-none"
            disabled={generating}
            onClick={handleGenerate}
          />
        )} */}
      </Element>
    </SuggestableElement>
  );
}
