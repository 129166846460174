import { useParams, useSearchParams } from "react-router-dom";
import ProposalRequirementDetails from "components/proposal/ProposalRequirementDetails";
import { useDoExtraWorkBeforeLoading } from "providers/ApiClientProvider";
import { ProposalDetailsProvider } from "providers/ProposalDetailsProvider";
import { useEffect, useState } from "react";
import ProposalNotReady from "components/proposal/ProposalNotReady";
import ProposalLoadError from "components/proposal/ProposalLoadError";
import GrantView from "components/proposal/GrantView";
import Overlay from "components/common/containers/overlays/Overlay";
import ProposalTitleBar from "components/proposal/ProposalTitleBar";
import useProposalDetails from "hooks/useProposalDetails";
import ProposalConnectionLost from "components/proposal/ProposalConnectionLost";
import Rows from "components/common/containers/Rows";
import Columns from "components/common/containers/Columns";

interface ProposalProps {}

const ProposalDetailsRoute: React.FC<ProposalProps> = () => {
  const { proposalId } = useParams();
  const proposalData = useProposalDetails(proposalId!);
  const [searchParams, setSearchParams] = useSearchParams();
  const [grantUserId, setGrantUserId] = useState<string | null>(
    searchParams.get("grant_permission") ?? null
  );
  const finishLoading = useDoExtraWorkBeforeLoading();
  const hasLoaded = proposalData != null;

  useEffect(() => {
    if (hasLoaded) {
      finishLoading();
    }
  }, [hasLoaded, finishLoading]);

  switch (proposalData) {
    case "not-processed":
      return <ProposalNotReady className="grow" />;
    case null:
      return null;
    default:
      if ("error" in proposalData) {
        return (
          <ProposalLoadError
            error={proposalData.error}
            proposalId={proposalId!}
          />
        );
      }
      break;
  }
  switch (proposalData.metadataStatus) {
    case "ready":
      break;
    case "lost":
      return <ProposalConnectionLost />;
    case "not-ready":
      return null;
  }
  return (
    <ProposalDetailsProvider
      details={proposalData.details}
      setDetails={proposalData.setDetails}
      metadata={proposalData.metadata}
      setMetadata={proposalData.setMetadata}
      participants={proposalData.participants}
      setParticipants={proposalData.setParticipants}
    >
      <Rows className="grow min-w-[800px]">
        <Columns className="grow justify-stretch overflow-hidden absolute top-[74px] bottom-0 right-0 left-0">
          <ProposalRequirementDetails />
        </Columns>
      </Rows>
      {grantUserId && (
        <Overlay
          title="Share"
          maxWidth={360}
          onClose={() => {
            setGrantUserId(null);
            setSearchParams((prev) => {
              prev.delete("grant_permission");
              return prev;
            });
          }}
        >
          <GrantView
            grantUserId={grantUserId}
            refreshProposal={proposalData.refreshDetails}
          />
        </Overlay>
      )}
      {/* Ordered at bottom to show up above main content */}
      <ProposalTitleBar className="w-full h-[74px] border-b fixed top-0 bg-background" />
    </ProposalDetailsProvider>
  );
};

export default ProposalDetailsRoute;
