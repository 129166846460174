import { RFPComparison } from "api/Api";
import * as sentry from "@sentry/browser";

export interface RFPOrgComparisonKeyDate {
  name: string;
  date: string;
}

export interface RFPOrgComparison {
  id: string;
  comparisonId: number | null;
  rfpId: string;
  rfpSlug: string;
  title: string;
  supplier: string;
  description: string;
  dueDate: string;
  budget: string | null;
  location: string | null;
  tasks: string[];
  keyDates: RFPOrgComparisonKeyDate[];
  minimumQualifications: string[];
}

export function rfpOrgComparisonFromApi(
  data: RFPComparison
): RFPOrgComparison | null {
  const id = data.id!;

  if (!data) {
    console.error(`Comparison '${id}' does not have an RFP`);
    return null;
  }

  let budget: string | null = null;
  if (data.budget && typeof data.budget !== "string") {
    console.error(`Comparison '${id}' has a non-string budget`);
    budget = null;
  } else {
    budget = data.budget ?? null;
  }

  let tasks: string[] = [];
  if (data.tasks && !Array.isArray(data.tasks)) {
    console.error(`Comparison '${id}' has a non-array tasks`);
    tasks = [];
  } else {
    tasks = (data.tasks ?? []) as string[];
  }

  let minimumQualifications: string[] = [];
  if (
    data.minimum_qualifications &&
    !Array.isArray(data.minimum_qualifications)
  ) {
    sentry.captureMessage(
      `Comparison '${id}' has a non-array minimum qualifications`
    );
    minimumQualifications = [];
  } else {
    minimumQualifications = (data.minimum_qualifications ?? []) as string[];
  }

  return {
    id: data.id!,
    comparisonId: data.comparison?.id ?? null,
    rfpId: data.id!,
    rfpSlug: data.slug!,
    title: data.title!,
    supplier: data.issuing_org!,
    description: data.description!,
    dueDate: data.due_date!,
    location: data.location ?? null,
    keyDates: (data.other_key_dates || []).concat([
      { name: "Proposal Due Date", date: data.due_date! },
    ]),
    budget,
    tasks,
    minimumQualifications,
  };
}
