import { PromptContent } from "./PromptContent";
import { PromptVariable } from "./types";
import { TEXT_VAR, TextVarProps } from "./components/TextVar";
import { TElement } from "@udecode/plate-common";
import { TDescendant } from "@udecode/plate";
import { IF_ELSE_VAR, IfElseVarProps } from "./plugins/IfElsePlugin";

export enum RenderError {
  VARIABLE_VALUE_NOT_SET = "VARIABLE_VALUE_NOT_SET",
}

class PromptRenderer {
  content: PromptContent;
  variables: Record<string, PromptVariable>;

  constructor(
    content: PromptContent,
    variables: Record<string, PromptVariable>
  ) {
    this.content = content;
    this.variables = variables;
  }

  public async renderEditor(id: string) {
    let rendered = "";
    const children = this.content.getContent(id);
    for (const paragraph of children) {
      if (rendered.length > 0) {
        rendered += "\n";
      }
      for (const node of paragraph.children) {
        rendered += await this.renderNode(node);
      }
    }
    return rendered;
  }

  public async renderNode(node: TElement | TDescendant): Promise<string> {
    if (node.type === TEXT_VAR) {
      return await this.renderTextVar(node as TextVarProps);
    } else if (node.type === IF_ELSE_VAR) {
      return this.renderIfElseVar(node as IfElseVarProps);
    } else {
      return node.text as string;
    }
  }

  private async renderTextVar(node: TextVarProps) {
    const variable = this.forceVariable(node.id);
    return this.forceVariableValue(variable);
  }

  private async renderIfElseVar(node: IfElseVarProps) {
    const variable = this.forceVariable(node.conditionVarId);
    let renderer = new PromptRenderer(this.content, this.variables);
    const value = await this.forceVariableValue(variable);
    if (value) {
      return await renderer.renderEditor(
        PromptContent.idForIfElseVar(node.id, true)
      );
    } else {
      return await renderer.renderEditor(
        PromptContent.idForIfElseVar(node.id, false)
      );
    }
  }

  private forceVariable(id: string) {
    const variable = this.variables[id];
    if (!variable) {
      throw new Error("Please delete the `Not Found` variable(s).");
    }
    return variable;
  }

  private async forceVariableValue(variable: PromptVariable) {
    return variable.content;
  }
}

export default PromptRenderer;
