import { TElement, useEditorRef } from "@udecode/plate-common";
import { getSectionInfoFromElement } from "odo";
import { useEditorDocData } from "providers/RequirementContentEditorProvider";
import { ReactEditor } from "slate-react";

export interface ParentSectionInfo {
  id: string;
  startIndex: number;
  endIndex: number;
  isFirstInSection: boolean;
  isLastInSection: boolean;
  isLastSectionInDocument: boolean;
  hasActiveRequirement: boolean;
}

/**
 * Get the id of the section this element belongs to
 */
const useParentSectionInfo = (element: TElement): ParentSectionInfo | null => {
  const editor = useEditorRef();
  const path = ReactEditor.findPath(editor as any, element);
  const { highlightedRequirements, highlightedBlocks } = useEditorDocData();

  if (!path || path.length === 0 || editor.children.length === 0) {
    return null;
  }

  let index = path[0];
  let minIndex: number | null = null;
  let maxIndex = index;
  let sectionId: string | null = null;

  let nextIndex = index;
  let hasActiveRequirement = false;
  while (nextIndex >= 0) {
    const [element] = editor.node([nextIndex]);
    const sectionInfo = getSectionInfoFromElement(element);
    if (sectionInfo) {
      minIndex = nextIndex;
      // @ts-ignore
      sectionId = element.id;
      if (sectionInfo.blocks) {
        if (
          sectionInfo.blocks.some((block) => highlightedBlocks.includes(block))
        ) {
          hasActiveRequirement = true;
        }
      }
      if (sectionInfo.requirements) {
        for (const id of sectionInfo.requirements) {
          if (highlightedRequirements.includes(id.toString())) {
            hasActiveRequirement = true;
            break;
          }
        }
      }
      break;
    }
    nextIndex--;
  }

  if (minIndex === null || sectionId === null) {
    return null;
  }

  nextIndex = index + 1;
  while (nextIndex < editor.children.length) {
    const [element] = editor.node([nextIndex]);
    if (getSectionInfoFromElement(element)) {
      break;
    }
    maxIndex = nextIndex;
    nextIndex++;
  }

  return {
    id: sectionId,
    startIndex: minIndex,
    endIndex: maxIndex,
    isFirstInSection: minIndex === index,
    isLastInSection: maxIndex === index,
    hasActiveRequirement: hasActiveRequirement,
    isLastSectionInDocument: maxIndex === editor.children.length - 1,
  };
};

export default useParentSectionInfo;
