import { cn } from "lib/utils";
import { forwardRef } from "react";

const Grid = forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<"div">>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn("grid", className)} {...props}>
        {children}
      </div>
    );
  }
);

export default Grid;
