import {
  ComboboxItemProps,
  ComboboxOnSelectItem,
  ComboboxProps,
  Data,
  NoData,
  TComboboxItemWithData,
} from "@udecode/plate-combobox";
import { Combobox } from "components/EditorView/Menus/Combobox";
import { useComboboxSelectors } from "@udecode/plate-combobox";
import { Transforms } from "slate";
import { ELEMENT_MENTION } from "../types";
import { OdoUser } from "odo";
import OdoUserAvatar from "../../../../../components/common/OdoUserAvatar";
import { focusEditor } from "@udecode/plate-common";
import { FrontendUser } from "types/FrontendUser";
import { useOrgUsers } from "providers/OrgUserProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";

export interface TMentionsComboBox<TData extends Data = NoData>
  extends Partial<ComboboxProps<TData>> {
  pluginKey?: string;
  onSelectItem?: ComboboxOnSelectItem<TData> | null;
}

const MentionsComboBoxItem = ({ item }: ComboboxItemProps<FrontendUser>) => {
  const isBot = item.data.publicId === "odo";
  return (
    <>
      <OdoUserAvatar
        size="small"
        user={item.data}
        userType={isBot ? "bot" : undefined}
      />
      <p className={isBot ? "text-primary" : ""}>{item.text}</p>
    </>
  );
};
const MentionsComboBox = ({
  pluginKey = ELEMENT_MENTION,
  id = pluginKey,
  ...props
}: TMentionsComboBox<OdoUser>) => {
  const targetRange = useComboboxSelectors.targetRange();
  const { isWriter, hasRegenerate } = useAuthenticatedUser();
  const { users } = useOrgUsers();

  const items: TComboboxItemWithData<OdoUser>[] =
    users?.map((user) => {
      return {
        key: user.publicId,
        text: user.displayName,
        data: user,
        targetRange: targetRange,
      };
    }) ?? [];

  if (isWriter || hasRegenerate) {
    items.splice(0, 0, {
      key: "odo",
      text: "Odo",
      data: { displayName: "Odo", publicId: "odo" },
      // @ts-ignore
      targetRange: targetRange,
    });
  }

  const handleSelectItem: ComboboxOnSelectItem<OdoUser> = (editor, item) => {
    // @ts-ignore
    const targetRange = item.targetRange;
    if (!targetRange) return;
    Transforms.delete(editor as any, { at: targetRange });
    editor.insertNodes([
      {
        // @ts-ignore
        type: ELEMENT_MENTION,
        user: item.data,
        children: [{ text: "" }],
      },
      {
        text: " ",
      },
    ]);

    // Move the cursor to after the next space
    let path = [...targetRange.anchor.path];
    path[path.length - 1] += 2;
    const point = editor.point(path, { edge: "start" });
    point.offset += 1;
    focusEditor(editor, point);
  };

  return (
    <Combobox
      id={id}
      trigger={"@"}
      filter={(filter) => (item) => {
        const user = item.data;
        const search = filter.toLowerCase();
        return (
          user.displayName.toLowerCase().includes(search) ||
          (user.email?.toLowerCase() ?? "").includes(search)
        );
      }}
      onSelectItem={handleSelectItem}
      items={items}
      onRenderItem={MentionsComboBoxItem}
      {...props}
    />
  );
};

export const mentionsComboboxWithId = (newId: string) => {
  return (props: Record<string, any>) => {
    // You can modify or add additional props here
    const hocProps = {
      ...props,
      id: newId,
    };

    // Return the wrapped component with the new props
    return <MentionsComboBox {...hocProps} />;
  };
};
