import {
  isSuggestedTextDelete,
  isSuggestedTextSplit,
  OdoSuggestedText,
  getSuggestionIdFromKey,
  getSuggestionKeys,
} from "odo";
import { FC, useEffect, useRef, useState } from "react";
import { PlateLeafProps, Value } from "@udecode/plate-common";
import { PlateLeaf, TText } from "@udecode/plate";
import { cn } from "lib/utils";
import { v4 } from "uuid";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/EditorView/Menus/Popover";
import Icon from "components/common/Icon";

export const DeletedIndicator: FC = () => {
  return (
    <>
      <Icon
        name="angle-up"
        size="small"
        className="absolute bottom-[-4px] left-[6px] text-destructive"
      />
    </>
  );
};

const SuggestionLeaf: FC<PlateLeafProps<Value, TText & OdoSuggestedText>> = ({
  className,
  ...props
}) => {
  const { children, nodeProps, leaf } = props;
  const leafRef = useRef<HTMLElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  let content: any = children;

  if (isSuggestedTextDelete(leaf)) {
    content = (
      <span
        className="relative inline-block w-0"
        data-slate-zero-width="z"
        onMouseEnter={() => setIsPopoverOpen(true)}
        onMouseLeave={() => setIsPopoverOpen(false)}
      >
        <Popover open={isPopoverOpen}>
          <PopoverTrigger className="cursor-default">
            <div className="absolute left-[-12px] right-[-10px] top-[1.5px] bottom-[-1px]">
              <DeletedIndicator />
              {/*{children}*/}
            </div>
          </PopoverTrigger>
          <PopoverContent className="text-destructive max-w-[400px] line-through">
            {leaf.suggestion_meta.description}
          </PopoverContent>
        </Popover>
      </span>
    );
  } else if (isSuggestedTextSplit(leaf)) {
    content = (
      <span className="relative" data-slate-zero-width="z">
        <span className="absolute left-0 right-0 top-0 bottom-0 flex items-center">
          <Icon name="turn-down-right" variant="solid" size="tiny" />
        </span>
        {children}
      </span>
    );
  }

  return (
    <PlateLeaf
      {...props}
      ref={leafRef}
      nodeProps={{
        className: cn(className, "text-primary"),
        ...nodeProps,
      }}
    >
      {content}
    </PlateLeaf>
  );
};

export default SuggestionLeaf;
