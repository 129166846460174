import { getCommentKeyFromId, CommentLeafProps, MARK_COMMENT } from "odo";
import { isText, setNodes, useEditorRef } from "@udecode/plate";
import { useCreatePendingComment } from "../../../providers/CommentsProvider";

function useAddComment() {
  const editor = useEditorRef();
  const createPendingComment = useCreatePendingComment();

  return (startingContent: any[], parentId?: string) => {
    const comment = createPendingComment(startingContent, parentId);
    const leaf: CommentLeafProps = {
      [MARK_COMMENT]: true,
      [getCommentKeyFromId(comment.id)]: true,
    };
    setNodes(editor, leaf, { match: isText, split: true });
  };
}

export default useAddComment;
