"use client";

import "./CodeBlockElement.css";

import React, { forwardRef } from "react";
import {
  TCodeBlockElement,
  useCodeBlockElementState,
} from "@udecode/plate-code-block";
import { PlateElementProps, Value } from "@udecode/plate-common";

import { cn } from "lib/utils";

import { CodeBlockCombobox } from "../Menus/CodeBlockCombobox";
import { SuggestableElement } from "components/proposals/refine/SuggestableElement";

const CodeBlockElement = forwardRef<
  HTMLDivElement,
  PlateElementProps<Value, TCodeBlockElement>
>(({ className, ...props }, ref) => {
  const { children, element } = props;

  const state = useCodeBlockElementState({ element });

  return (
    <SuggestableElement
      ref={ref}
      className={cn("relative", state.className, className)}
      {...props}
    >
      <pre className="overflow-x-auto rounded-md bg-code-background px-lg py-2m font-mono text-sm leading-[normal] [tab-size:2]">
        <code>{children}</code>
      </pre>

      {state.syntax && (
        <div
          className="absolute right-md top-0 z-10 select-none"
          contentEditable={false}
        >
          <CodeBlockCombobox />
        </div>
      )}
    </SuggestableElement>
  );
});
CodeBlockElement.displayName = "CodeBlockElement";

export { CodeBlockElement };
