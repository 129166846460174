import { TElement, useEditorRef } from "@udecode/plate-common";
import { getSectionInfoFromElement, isOdoHeading } from "odo";
import { ReactEditor } from "slate-react";

export const useHasFollowingContentBeforeNextSection = (element: TElement) => {
  const editor = useEditorRef();
  const path = ReactEditor.findPath(editor as any, element);
  if (!path || path.length === 0) return false;

  const nextSibling = path[0] + 1;
  if (nextSibling >= editor.children.length) return false;

  const nextElement = editor.children[nextSibling];
  return !getSectionInfoFromElement(nextElement);
};
