import Button from "components/common/Button";
import { useApiClient } from "providers/ApiClientProvider";
import { useState } from "react";
import { message_from_exception } from "utils";
import useFileHash from "hooks/useFileHash";
import Icon from "components/common/Icon";
import FileInput from "components/common/forms/FileInput";
import LoadableView from "components/common/containers/LoadableView";

interface UploadRFPStepProps {
  onRFPUploaded: (proposalId: string) => void;
}

const UploadRFPStep: React.FC<UploadRFPStepProps> = ({ onRFPUploaded }) => {
  const apiClient = useApiClient();
  const hashFile = useFileHash();
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleUploadRFPFile = async (file: File) => {
    // 1. Create the RFP record on the server
    // 2. Upload the file to S3
    // 3. Create a new proposal for the RFP
    try {
      setIsLoading(true);

      // First, get a hash of the file
      const hash = await hashFile(file);

      // Get name without extension
      const fileName = file.name.split(".").slice(0, -1).join(".");
      const rfpCreateResponse = await apiClient.rfp.rfpRfpCreate({
        hash,
        name: fileName,
      });

      if (rfpCreateResponse.data.upload_url) {
        // We have to upload the file to this url
        await fetch(rfpCreateResponse.data.upload_url, {
          method: "PUT",
          body: file,
        });
      }

      // Next, create a new proposal for this RFP
      const proposalCreateResponse = await apiClient.rfp.rfpProposalCreate({
        rfp_id: rfpCreateResponse.data.id,
      });
      onRFPUploaded(proposalCreateResponse.data.id!);
    } catch (e) {
      setError(message_from_exception(e));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadableView
      isLoading={isLoading}
      className="w-full flex flex-col relative items-center justify-center gap-md grow"
    >
      <Icon name="file-arrow-up" size="huge" variant="solid" />
      <h1 className="text-2xl font-semibold">Upload the RFP</h1>
      <p className="text-secondary text-sm text-center mb-sm">
        If the RFP includes multiple files, please email them to{" "}
        <a href="mailto:support@odo.do" className="underline">
          support@odo.do
        </a>
      </p>
      <div className="relative">
        <FileInput
          className="min-w-[300px]"
          onFileChanged={setFile}
          accept="application/pdf"
        />
        {error && (
          <p className="text-sm text-center text-destructive mt-sm absolute top-[100%] left-0 right-0 text-left">
            {error}
          </p>
        )}
      </div>
      <Button
        text="Next"
        variant="solid"
        className="absolute right-0 bottom-0"
        disabled={!file}
        onClick={() => file && handleUploadRFPFile(file)}
      />
    </LoadableView>
  );
};

export default UploadRFPStep;
