import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Rows from "components/common/containers/Rows";
import usePaginatedData from "hooks/usePaginatedData";
import { useApiClient } from "providers/ApiClientProvider";
import { useNavigate } from "react-router-dom";
import { proposalFromAPI } from "types/Proposal";

const ProposalsRoute = () => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const [proposals, , paginatedData] = usePaginatedData({
    endpoint: apiClient.rfp.rfpProposalAdminList,
    map: (proposal) => ({
      ...proposalFromAPI(proposal),
      org: proposal.org?.name ?? "Uknown",
    }),
  });

  return (
    <PaginatedTableView
      results={proposals}
      paginatedData={paginatedData}
      onSelect={(p) => navigate(`/proposal/${p.id}`)}
      columns={[
        { name: "Title" },
        { name: "Org", size: "min" },
        { name: "Due Date", size: "min" },
      ]}
      rowSeparators={true}
      rowHeight={84}
      renderRow={(proposal, Cell, Row) => (
        <Row key={proposal.id}>
          <Cell>{proposal.title}</Cell>
          <Cell>{proposal.org}</Cell>
          <Cell>{proposal.dueDate}</Cell>
        </Row>
      )}
    />
  );
};

export default ProposalsRoute;
