import { FC, useEffect, useState } from "react";
import Button from "../common/Button";
import { useApiClient } from "../../providers/ApiClientProvider";
import { message_from_exception } from "../../utils";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { useOverlayContext } from "components/common/containers/overlays/OverlayContextProvider";

interface GrantViewProps {
  grantUserId: string;
  refreshProposal: () => void;
}

const GrantView: FC<GrantViewProps> = ({ grantUserId, refreshProposal }) => {
  const apiClient = useApiClient();
  const { details } = useProposalData();
  const [isGranted, setIsGranted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { dismiss } = useOverlayContext();
  useEffect(() => {
    if (isGranted) {
      return;
    }
    apiClient.rfp
      .rfpProposalUsersCreate(details.id, {
        user_id: grantUserId,
      })
      .then(() => {
        setIsGranted(true);
        setError(null);
        refreshProposal();
      })
      .catch((e: any) => {
        setError(message_from_exception(e));
      });
  }, [apiClient.rfp, grantUserId, isGranted, details.id, refreshProposal]);
  const okButton = (
    <div>
      <Button
        className="mx-auto min-w-[80px]"
        variant="solid"
        onClick={() => {
          dismiss?.();
        }}
        text="OK"
      />
    </div>
  );
  if (isGranted) {
    return (
      <>
        <p className="text-center b-lg">Permission granted</p>
        {okButton}
      </>
    );
  } else if (error) {
    return (
      <>
        <p className="text-destructive text-center b-lg">{error}</p>
        {okButton}
      </>
    );
  } else {
    return <p className="text-center m-24">Approving...</p>;
  }
};

export default GrantView;
