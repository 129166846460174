import { useState } from "react";
import { useApiClient } from "providers/ApiClientProvider";
import { Prompt } from "api/Api";
import AddPromptForm from "components/PromptRefinery/AddPromptForm";
import usePaginatedData from "hooks/usePaginatedData";
import Icon from "components/common/Icon";
import { useNavigate } from "react-router-dom";
import Button from "components/common/Button";
import Overlay from "components/common/containers/overlays/Overlay";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import { format_date } from "lib/utils";
import Rows from "components/common/containers/Rows";
import Columns from "components/common/containers/Columns";

const PromptsRoute = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const [prompts, , paginatedData] = usePaginatedData({
    endpoint: apiClient.promptRefinery.promptRefineryPromptsList,
    map: (prompt): Prompt & { id: number } => {
      return {
        ...prompt,
        id: prompt.id!,
      };
    },
  });
  const [addingPrompt, setAddingPrompt] = useState(false);

  return (
    <Rows className="p-lg w-full gap-md">
      <Columns className="items-center gap-md shrink-0">
        <h1 className="text-2xl font-semibold">Prompts</h1>
        <Button
          icon="plus"
          variant="solid"
          onClick={() => setAddingPrompt(true)}
        />
      </Columns>
      <PaginatedTableView
        searchable={true}
        results={prompts}
        paginatedData={paginatedData}
        columns={[
          { name: "Used" },
          { name: "Name" },
          { name: "Description" },
          { name: "Created" },
        ]}
        onSelect={(prompt) => {
          navigate(`/admin/prompts/${prompt.id}`);
        }}
        renderRow={(prompt, Cell, Row) => (
          <Row key={prompt.id}>
            <Cell center={true}>
              {prompt.used_in_product ?? false ? (
                <Icon name="star" variant="solid" />
              ) : null}
            </Cell>
            <Cell>{prompt.name}</Cell>
            <Cell>{prompt.description}</Cell>
            <Cell>{format_date(prompt.created!)}</Cell>
          </Row>
        )}
      />
      <Overlay
        title="Add Prompt"
        maxWidth={500}
        open={addingPrompt}
        onClose={() => setAddingPrompt(false)}
      >
        <AddPromptForm />
      </Overlay>
    </Rows>
  );
};

export default PromptsRoute;
