import { isElement } from "@udecode/plate-common";
import { isText } from "@udecode/plate";
import { isOdoP } from "./types.js";
// Types that normal content can not be nested in
const UNESTABLE_TYPES = {
    h1: true,
    h2: true,
    h3: true,
    p: true,
};
/**
 * Ensure that the structure of the document is valid
 *
 * This primarily focuses on normalizing content after being imported/pasted in
 * where it can get significantly out of whack due to the variability in HTML
 */
const withStructureFixing = (editor) => {
    const { normalizeNode } = editor;
    editor.normalizeNode = ([node, path]) => {
        if (isElement(node) && node.type === "p" && path.length > 1) {
            const parent = editor.node(path.slice(0, -1))[0];
            if (isElement(parent) && !!UNESTABLE_TYPES[parent.type]) {
                // There is a nested paragraph (This can happen when pasting a list)
                // Move the children of the nested paragraph up to the parent
                editor.unwrapNodes({ at: path });
                return;
            }
        }
        if (isText(node) && !!node["elementToRestore"]) {
            // This is a node that we need to restore
            const element = node["elementToRestore"];
            const point = editor.point(path, { edge: "end" });
            editor.insertNodes(element, { at: point });
            editor.delete({ at: path });
            return;
        }
        if (isOdoP(node) && node.indent == 0) {
            // @ts-ignore
            editor.setNodes({ indent: 1 }, { at: path });
        }
        normalizeNode([node, path]);
    };
    return editor;
};
export default withStructureFixing;
