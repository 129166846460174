import { PaginatedData } from "hooks/usePaginatedData";
import ComboBox, { ResultBase } from "./ComboBox";

interface PaginatedComboBoxProps<T> {
  results: T[] | null;
  paginatedData: PaginatedData;
  renderResult: (item: T) => React.ReactNode;
  onSelect?: (item: T) => void;
  className?: string;
  noResultsText?: string;
  placeholder?: string;
  autoFocus?: boolean;
  alwaysShowResults?: boolean;
  defaultKeyboardFocusIndex?: number | null;
  maxResults?: number;
}

const PaginatedComboBox = <T extends ResultBase>({
  results,
  paginatedData,
  renderResult,
  onSelect,
  className,
  placeholder,
  noResultsText,
  autoFocus,
  alwaysShowResults,
  defaultKeyboardFocusIndex,
  maxResults,
}: PaginatedComboBoxProps<T>) => {
  return (
    <ComboBox<T>
      options={results}
      renderOption={renderResult}
      onSelected={onSelect}
      filterOptions={(options, text) => {
        paginatedData.setSearch(text);
        return options;
      }}
      className={className}
      placeholder={placeholder}
      noResultsText={noResultsText}
      autoFocus={autoFocus}
      alwaysShowResults={alwaysShowResults}
      defaultKeyboardFocusIndex={defaultKeyboardFocusIndex}
      maxResults={maxResults}
    />
  );
};

export default PaginatedComboBox;
