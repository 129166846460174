import React from "react";
import { ComboboxItemProps } from "@udecode/plate-combobox";
import {
  EmojiItemData,
  KEY_EMOJI,
  TEmojiCombobox,
  useEmojiComboboxState,
} from "@udecode/plate-emoji";

import { Combobox } from "./Combobox";

export function EmojiComboboxItem({
  item,
}: ComboboxItemProps<EmojiItemData>): JSX.Element {
  const {
    data: { id, emoji },
  } = item;

  return (
    <>
      <span>{emoji}</span>
      <span>{id}</span>
    </>
  );
}

function EmojiCombobox<TData extends EmojiItemData = EmojiItemData>({
  pluginKey = KEY_EMOJI,
  id = pluginKey,
  ...props
}: TEmojiCombobox<TData>) {
  const { trigger, onSelectItem } = useEmojiComboboxState({ pluginKey });

  return (
    <Combobox
      id={id}
      trigger={trigger}
      controlled
      onSelectItem={onSelectItem}
      onRenderItem={EmojiComboboxItem}
      {...props}
    />
  );
}

export const emojiComboboxWithId = (newId: string) => {
  return (props: Record<string, any>) => {
    // You can modify or add additional props here
    const hocProps = {
      ...props,
      id: newId,
    };

    // Return the wrapped component with the new props
    return <EmojiCombobox {...hocProps} />;
  };
};
