import Button from "./Button";
import CurrentUser from "components/CurrentUser";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import OdoLogo from "./OdoLogo";
import { cn } from "lib/utils";
import Rows from "./containers/Rows";
import Columns from "./containers/Columns";
import Spacer from "./containers/Spacer";

type PageType =
  | "proposals"
  | "past-proposals"
  | "rfps"
  | "knowledge-base"
  | "final-check";

interface NavigationViewProps {
  children?: React.ReactNode;
  selected?: PageType;
  containerClassName?: string;
  fullWidth?: boolean;
  scrollable?: boolean;
  padContent?: boolean;

  // Elements next to page links
  extraElements?: React.ReactNode;
}

const ORDERED_PAGES: {
  type: PageType;
  text: string;
  path: string;
  staffOnly?: boolean;
}[] = [
  {
    type: "rfps",
    text: "RFPs",
    path: "/",
  },
  {
    type: "proposals",
    text: "Proposals",
    path: "/proposals/",
  },
  {
    type: "final-check",
    text: "Final Check",
    path: "/final-check/",
  },
  {
    type: "knowledge-base",
    text: "Knowledge Base",
    path: "/knowledge/",
    staffOnly: true,
  },
  {
    type: "past-proposals",
    text: "Past Proposals",
    path: "/past-proposals/",
    staffOnly: true,
  },
];

const NavigationView: FC<NavigationViewProps> = ({
  children,
  extraElements,
  selected,
  containerClassName,
  fullWidth = false,
  scrollable = true,
  padContent = true,
}) => {
  const navigate = useNavigate();
  const { isStaff } = useAuthenticatedUser();

  return (
    <Rows className="grow mt-4xl">
      <Rows
        className={cn(
          "grow items-center",
          scrollable && "overflow-auto",
          padContent && "p-lg",
          containerClassName
        )}
      >
        <Rows
          className={cn(
            "grow w-full",
            !fullWidth && "max-w-[950px]",
            scrollable && "overflow-visible"
          )}
        >
          {children}
        </Rows>
      </Rows>
      {/* Ordered at bottom to show up above main content */}
      <Columns className="border-b px-lg justify-center fixed top-0 left-0 right-0 bg-background">
        <Columns className="grow gap-lg max-w-[950px] items-center">
          <OdoLogo
            size="small"
            path="/"
            className="-ml-8 hidden tablet:block shrink-0"
          />
          <Columns className="overflow-x-auto">
            {ORDERED_PAGES.map((page) => {
              if (page.staffOnly && !isStaff) {
                return null;
              }
              return (
                <Button
                  key={page.type}
                  text={page.text}
                  disabled={selected === page.type}
                  variant="navigation"
                  className={
                    page.staffOnly ? "admin text-primary whitespace-nowrap" : ""
                  }
                  onClick={() => navigate(page.path)}
                />
              );
            })}
            {extraElements}
          </Columns>
          <Spacer />
          <CurrentUser />
        </Columns>
      </Columns>
    </Rows>
  );
};

export default NavigationView;
