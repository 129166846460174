import { isElement } from "@udecode/plate-common";
import { isCollapsed, } from "@udecode/plate";
import { updateRefineElements } from "./utils.js";
import { ELEMENT_SECTION_CONTROL } from "./types.js";
const withOdoEditorOverrides = (editor, plugin) => {
    const { normalizeNode, deleteBackward, setSelection } = editor;
    editor.setSelection = (rangeChange) => {
        const range = Object.assign(Object.assign({}, editor.selection), rangeChange);
        if (range && isCollapsed(range)) {
            let oldSelection = editor.selection;
            if (oldSelection && isCollapsed(oldSelection) && oldSelection !== range) {
                let point = range.anchor;
                if ((point === null || point === void 0 ? void 0 : point.path.length) === 2) {
                    const element = editor.node([point.path[0]])[0];
                    if (isElement(element) && element.type === ELEMENT_SECTION_CONTROL) {
                        // Move selection before or after
                        let newPoint = undefined;
                        if (oldSelection.anchor.path[0] > point.path[0]) {
                            // The selection is moving earlier in the doc
                            // We need to move it to the end of the previous section
                            newPoint = editor.before(point);
                        }
                        else {
                            newPoint = editor.after(point);
                        }
                        if (newPoint) {
                            editor.setSelection({ anchor: newPoint, focus: newPoint });
                            return;
                        }
                    }
                }
            }
        }
        setSelection(range);
    };
    editor.deleteBackward = (unit) => {
        if (editor.selection && isCollapsed(editor.selection)) {
            const point = editor.selection.anchor;
            if (point.path.length === 2 &&
                point.path[0] > 0 &&
                point.path[point.path.length - 1] === 0 &&
                point.offset === 0) {
                // We're at the beginning of a root element that isn't the first one
                // We need to check if the previous element is a section control
                const node = editor.node([point.path[0] - 1])[0];
                if (isElement(node) && node.type === ELEMENT_SECTION_CONTROL) {
                    // We need to delete this refine element first
                    editor.withoutNormalizing(() => {
                        editor.removeNodes({ at: [point.path[0] - 1] });
                        deleteBackward(unit);
                    });
                    return;
                }
            }
        }
        deleteBackward(unit);
    };
    editor.normalizeNode = ([node, path]) => {
        normalizeNode([node, path]);
        if (path.length > 0) {
            // We're only going to normalize from the root because changes to elements
            // can cause changes to the section control element in a different part of the doc
            return;
        }
        updateRefineElements(editor);
    };
    return editor;
};
export default withOdoEditorOverrides;
