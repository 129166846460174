import Button from "components/common/Button";
import { FC, HTMLProps, RefObject, useEffect, useRef, useState } from "react";
import { SuggestionResolver } from "odo";
import { useOdoEditorRef } from "hooks/odo-editor/useOdoEditorRef";
import { focusEditor } from "@udecode/plate-common";
import { useEditorDocData } from "providers/RequirementContentEditorProvider";

interface SuggestionViewProps extends HTMLProps<HTMLDivElement> {
  suggestionId: string;
  setSuggestionRef?: (id: string, ref: RefObject<HTMLElement>) => void;

  onHover?: (isHovering: boolean) => void;
}

const SuggestionControl: FC<SuggestionViewProps> = ({
  suggestionId,
  setSuggestionRef,
  onHover,
  ...props
}) => {
  const ref = useRef<HTMLElement>(null);
  const editor = useOdoEditorRef();
  const { isFocused } = useEditorDocData();
  const suggestionResolver = useRef(new SuggestionResolver(editor));
  const [shiftKeyDown, setShiftKeyDown] = useState(false);
  const [wasFocused, setWasFocused] = useState(isFocused);

  useEffect(() => {
    setTimeout(() => {
      setWasFocused(isFocused);
    }, 100);
  }, [isFocused]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        setShiftKeyDown(true);
      }
    };
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        setShiftKeyDown(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    setSuggestionRef?.(suggestionId, ref);
  }, [ref, setSuggestionRef, suggestionId]);

  const accept = (e: any) => {
    if (shiftKeyDown) {
      suggestionResolver.current.acceptAll();
    } else {
      suggestionResolver.current.acceptSuggestion(suggestionId);
    }
    if (wasFocused) focusEditor(editor);
  };

  const reject = () => {
    if (shiftKeyDown) {
      suggestionResolver.current.rejectAll();
    } else {
      suggestionResolver.current.rejectSuggestion(suggestionId);
    }
    if (wasFocused) focusEditor(editor);
  };

  return (
    <div className="flex gap-xs" ref={ref as any} {...props}>
      <Button
        className="text-additive"
        icon="check"
        iconVariant="solid"
        variant="outline"
        size="xsmall"
        tooltip="Accept odo's suggestions"
        onClick={accept}
      />
      <Button
        className="text-destructive"
        icon="rotate-left"
        iconVariant="solid"
        variant="outline"
        size="xsmall"
        tooltip="Undo odo's suggestions"
        tooltipDelayDuration={0}
        onClick={reject}
        onMouseEnter={() => onHover?.(true)}
        onMouseLeave={() => onHover?.(false)}
      />
    </div>
  );
};

export default SuggestionControl;
