import { ElementRef, forwardRef, ReactNode, useEffect, useState } from "react";
import {
  createPluginFactory,
  PlateElement,
  PlateElementProps,
  TElement,
  Value,
} from "@udecode/plate-common";
import { cn } from "../../../../lib/utils";
import Icon from "../../../common/Icon";
import { usePromptRefineryContext } from "../PromptRefineryProvider";
import Spinner from "../../../common/Spinner";

export const TEXT_VAR = "text-var";

export const generateTextVar = (id: string) => {
  return {
    type: TEXT_VAR,
    id: id,
    children: [{ text: "" }],
  };
};

export const createTextVarPlugin = createPluginFactory({
  key: TEXT_VAR,
  isElement: true,
  isInline: true,
  isVoid: true,
});

export interface TextVarProps extends TElement {
  id: string;
}

const TextVarElement = forwardRef<
  ElementRef<typeof PlateElement>,
  PlateElementProps<Value, TextVarProps>
>(({ children, className, ...props }, ref) => {
  const { data, previewing } = usePromptRefineryContext();
  const variable = data.getVariable(props.element.id!);
  const [status, setStatus] = useState<"valid" | "loading" | "invalid">(
    "loading"
  );
  const [text, setText] = useState<ReactNode>("");

  useEffect(() => {
    const updateText = async () => {
      if (!previewing) {
        if (variable) {
          setText(variable.name);
          setStatus("valid");
        } else {
          setStatus("invalid");
          setText("Not Found");
        }
        return;
      }
      setText(<Spinner size="inherit" />);
      if (!variable) {
        setStatus("invalid");
        setText("Not Found");
        return;
      }
      setText(variable.content);
    };
    updateText();
  }, [variable, previewing]);

  return (
    <PlateElement
      ref={ref}
      {...props}
      className={cn(
        "inline px-xs py-xs rounded-sm mx-[2px]",
        previewing ? "bg-background-selected" : "bg-comment-odo",
        status === "invalid" && "text-destructive",
        className
      )}
    >
      <Icon name="circle-t" variant="solid" className="mr-xs" />
      {text}
      {children}
    </PlateElement>
  );
});

export default TextVarElement;
