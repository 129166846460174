import { PaginatedResponse } from "api/CoreApi";
import { AxiosResponse } from "axios";
import usePaginatedData from "hooks/usePaginatedData";
import { DependencyList, useState } from "react";
import PaginatedComboBox from "./PaginatedComboBox";
import { ResultBase } from "./ComboBox";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/EditorView/Menus/Popover";

interface InternalComboBoxPopoverProps<Local extends ResultBase, Remote> {
  endpoint: (options: any) => Promise<AxiosResponse<PaginatedResponse<Remote>>>;
  map: (remote: Remote) => Local;
  deps?: DependencyList;
  renderResult: (item: Local) => React.ReactNode;
  onSelect?: (item: Local) => void;
}

interface ComboBoxPopoverProps<Local extends ResultBase, Remote>
  extends InternalComboBoxPopoverProps<Local, Remote> {
  children: React.ReactNode;
}

const ComboBoxPopover = <Local extends ResultBase, Remote>({
  children,
  onSelect,
  ...props
}: ComboBoxPopoverProps<Local, Remote>) => {
  const [active, setActive] = useState(false);

  const internalOnSelect = (item: Local) => {
    onSelect?.(item);
    setActive(false);
  };

  return (
    <Popover onOpenChange={setActive} open={active}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent className="p-none">
        {active && (
          <ComboBoxPopoverContent<Local, Remote>
            {...props}
            onSelect={internalOnSelect}
          />
        )}
      </PopoverContent>
    </Popover>
  );
};

const ComboBoxPopoverContent = <Local extends ResultBase, Remote>({
  endpoint,
  map,
  deps,
  renderResult,
  onSelect,
}: InternalComboBoxPopoverProps<Local, Remote>) => {
  const [results, , paginatedData] = usePaginatedData({
    endpoint,
    map,
    deps,
  });

  return (
    <PaginatedComboBox
      results={results}
      paginatedData={paginatedData}
      renderResult={renderResult}
      alwaysShowResults={true}
      onSelect={onSelect}
    />
  );
};

export default ComboBoxPopover;
