import Rows from "components/common/containers/Rows";
import { FC, useEffect, useRef } from "react";
import { useFinalCheckDetails } from "providers/FinalCheckDetailsProvider";
import CenteredContainer from "components/common/containers/CenteredContainer";
import Icon from "components/common/Icon";
import { cn } from "lib/utils";

interface FinalCheckRFPPanelProps {
  className?: string;
}

const FinalCheckRFPPanel: FC<FinalCheckRFPPanelProps> = ({ className }) => {
  const {
    document,
    setCanAnimateScroll,
    setContainerWidth,
    activeRequirement,
  } = useFinalCheckDetails();
  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    setCanAnimateScroll(true);
  }, [setCanAnimateScroll]);

  useEffect(() => {
    // Resize page width when the container resizes
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      console.log("Setting container width", containerRef.current?.clientWidth);
      setContainerWidth(containerRef.current?.clientWidth ?? 0);
    });
    resizeObserver.observe(containerRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef, setContainerWidth]);

  return (
    <Rows className={cn("basis-0 grow relative px-xl", className)}>
      <Rows className="grow" ref={containerRef as any}>
        <Rows className="shrink-0 py-lg">
          <h3 className="text-lg font-semibold">RFP Trace</h3>
          <p>Understand where in the RFP the requirement is coming from</p>
        </Rows>
        {document}
      </Rows>
      {!activeRequirement && (
        <Rows className="absolute inset-0">
          <CenteredContainer>
            <Rows className="items-center gap-md text-secondary">
              <Icon name="square-left" size="xlarge" variant="solid" />
              <p className="text-center">
                Select a requirement from the
                <br />
                list on the left to view details
              </p>
            </Rows>
          </CenteredContainer>
        </Rows>
      )}
    </Rows>
  );
};

export default FinalCheckRFPPanel;
