import Button from "components/common/Button";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Columns from "components/common/containers/Columns";
import Overlay from "components/common/containers/overlays/Overlay";
import Rows from "components/common/containers/Rows";
import Input from "components/common/forms/Input";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import useFetchedData from "hooks/useFetchedData";
import usePaginatedData from "hooks/usePaginatedData";
import { useApiClient } from "providers/ApiClientProvider";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";
import ComparisonTableView from "components/admin/ComparisonTableView";
import RFPCategoriesDropDown from "components/rfps/RFPCategoriesDropDown";

interface RFPDetailsOverlayProps {
  rfpId: string;
  rfpName: string;
  onDismiss?: () => void;
}

const RFPDetailsOverlay: FC<RFPDetailsOverlayProps> = ({
  rfpId,
  rfpName,
  onDismiss,
}) => {
  const apiClient = useApiClient();
  const [comparisons, , fetchData] = usePaginatedData({
    endpoint: (options) => {
      return apiClient.rfp.rfpRfpComparisonList(rfpId!, options);
    },
    map: (remote) => remote,
    deps: [rfpId],
  });

  const handleRegenerateProfile = async () => {
    try {
      await apiClient.rfp.rfpRfpRegenerateProfileCreate(rfpId!);
      toast.success("Profile requeued successfully");
    } catch (e) {
      toast.error(message_from_exception(e));
    }
  };

  return (
    <Overlay title={rfpName ?? undefined} open={!!rfpId} onClose={onDismiss}>
      <Rows className="gap-md">
        <RFPCategoryEditor rfpId={rfpId} onSaved={fetchData.refresh} />
        <LabeledFormInput label="Comparisons">
          <ComparisonTableView
            differentiating={{ column: "Orgs", id: rfpId }}
            results={comparisons}
            paginatedData={fetchData}
          >
            <Button
              icon="address-card"
              text="Regenerate Profile"
              variant="destructive"
              onClick={() => handleRegenerateProfile()}
            />
            <div className="grow" />
          </ComparisonTableView>
        </LabeledFormInput>
        <Columns className="shrink-0">
          {/* <div className="grow" /> */}
          {/* <Button
            icon="refresh"
            text="Rerun All"
            variant="destructive"
            onClick={() => handleRerun()}
          /> */}
        </Columns>
      </Rows>
    </Overlay>
  );
};

const RFPCategoryEditor: FC<{ rfpId: string; onSaved: () => void }> = ({
  rfpId,
  onSaved,
}) => {
  const apiClient = useApiClient();
  const [details, setDetails, { error }] = useFetchedData(async () => {
    return (await apiClient.rfp.rfpRfpRead(rfpId)).data;
  });
  const [changed, setChanged] = useState(false);

  const handleSave = async () => {
    if (!details) return;
    if (!changed) return;
    try {
      await apiClient.rfp.rfpRfpUpdate(rfpId, details);
      toast.success("Category updated successfully");
      setChanged(false);
      onSaved();
    } catch (e) {
      toast.error(message_from_exception(e));
    }
  };

  return (
    <AsyncLoadedDiv
      error={error ? message_from_exception(error) : undefined}
      value={details}
      whileLoaded={(details) => {
        return (
          <LabeledFormInput label="Category">
            <RFPCategoriesDropDown
              className="grow"
              categories={details.categories}
              onChange={(categories) => {
                setDetails((prev) => {
                  if (!prev) return prev;
                  return { ...prev, categories };
                });
                setChanged(true);
              }}
              onSave={handleSave}
            />
          </LabeledFormInput>
        );
      }}
    />
  );
};

export default RFPDetailsOverlay;
