import { useState, useEffect, useMemo } from "react";

function useLocalStorage<T>(
  key: string | undefined,
  initialValue: T,
  options?: { overrideLocalWithInitialValue?: boolean }
): [T, React.Dispatch<React.SetStateAction<T>>] {
  // Get from local storage and then
  // parse stored JSON or if none return initialValue
  const initial = useMemo(() => {
    if (options?.overrideLocalWithInitialValue) {
      return initialValue;
    }
    const storedValue = key ? localStorage.getItem(key) : null;
    return storedValue ? JSON.parse(storedValue) : initialValue;
  }, [initialValue, key, options?.overrideLocalWithInitialValue]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [value, setValue] = useState<T>(initial);

  // Watch for changes on value
  useEffect(() => {
    if (!key) return;
    // Store to local storage on value change
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

export default useLocalStorage;
