import {
  LeftPanel,
  useEditorDocData,
} from "providers/RequirementContentEditorProvider";
import { FC } from "react";
import OutlineNavPanel from "./OutlineNavPanel";
import Rows from "components/common/containers/Rows";
import Button from "components/common/Button";
import { cn } from "lib/utils";

export const LeftEditorToolbar: FC = () => {
  const { activeLeftPanel, setActiveLeftPanel } = useEditorDocData();

  const getClickHandler = (panel: LeftPanel) => () => {
    if (activeLeftPanel === panel) {
      setActiveLeftPanel(null);
    } else {
      setActiveLeftPanel(panel);
    }
  };

  return (
    <Rows className="shrink-0 border-r">
      <Button
        tooltip="Outline"
        icon="list"
        size="large"
        variant="toolbar"
        className={cn(
          activeLeftPanel === "outline-nav" &&
            "border-l-primary hover:border-l-primary"
        )}
        onClick={getClickHandler("outline-nav")}
      />
    </Rows>
  );
};

interface LeftEditorPorotalProps {
  maxSize?: number;
}

export const useLeftEditorContent = () => {
  const { activeLeftPanel } = useEditorDocData();

  let activePanelComponent = null;
  switch (activeLeftPanel) {
    case "outline-nav":
      activePanelComponent = <OutlineNavPanel />;
      break;
    default:
      break;
  }

  return activePanelComponent;
};

// export const LeftEditorPortal: FC<LeftEditorPorotalProps> = ({ maxSize }) => {
//   const { activeLeftPanel } = useEditorDocData();

//   let activePanelComponent = null;
//   switch (activeLeftPanel) {
//     case "outline-nav":
//       activePanelComponent = <OutlineNavPanel />;
//       break;
//     default:
//       break;
//   }

//   if (!activePanelComponent) {
//     return null;
//   }

//   return (
//     <SplitPane uniqueId="left-panel" minSize={200} maxSize={800}>
//       {activePanelComponent}
//     </SplitPane>
//   );

// return (
// <DragResizable
//   defaultSize={400}
//   minSize={200}
//   maxSize={maxSize ?? 800}
//   direction="horizontally"
//   controlSide="trailing"
//   className="flex shrink-0 border-r"
//   savedStateKey="proposal-answer-editor-right-panel"
// >
// { activePanelComponent }
// </DragResizable>
// );
// };
