import { cn } from "lib/utils";
import Spinner from "../Spinner";
import React, { forwardRef } from "react";

interface LoadableViewProps extends React.HTMLAttributes<HTMLDivElement> {
  isLoading: boolean;
  error?: unknown;
}

const LoadableView = forwardRef<HTMLDivElement, LoadableViewProps>(
  ({ isLoading, children, className, error, ...props }, ref) => {
    if (!!error) {
    }

    return (
      <div
        {...props}
        className={cn("overflow-hidden relative", className)}
        ref={ref}
      >
        {children}
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-background opacity-80">
            <Spinner />
          </div>
        )}
      </div>
    );
  }
);

export default LoadableView;
