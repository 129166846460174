import { FC, useState } from "react";
import StatusIcon from "./StatusIcon";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "components/EditorView/Menus/DropdownMenu";
import Icon from "components/common/Icon";
import ComboBox from "components/common/menus/ComboBox";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import Columns from "components/common/containers/Columns";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/EditorView/Menus/Tooltip";
import { cn } from "lib/utils";

interface StatusDropDownProps {
  status: string;
  statusName?: string;
  showText?: boolean;
  onChange?: (status: { status: string; statusName: string }) => void;
  className?: string;
}

interface StatusOption {
  id: string;
  name: string;
  status: string;
  isNew: boolean;
}

const statusNameFromIcon = (icon: string) => {
  switch (icon) {
    case "completed":
      return "Completed";
    case "drafted":
      return "Drafted";
    default:
      return "Not Started";
  }
};

const StatusDropDown: FC<StatusDropDownProps> = ({
  status,
  statusName,
  showText,
  onChange,
  className,
}) => {
  const apiClient = useApiClient();
  const { details } = useProposalData();
  const [choosing, setChoosing] = useState(false);
  const [options] = useFetchedData<StatusOption[]>(async () => {
    if (!choosing) {
      return null;
    }
    const response = await apiClient.rfp.rfpProposalRequirementStatusList(
      details.id
    );

    return response.data.map((status) => ({
      id: status.name,
      status: status.icon ?? "other",
      name: status.name,
      isNew: false,
    }));
  }, [apiClient, choosing]);

  const handleSelected = async (option: StatusOption) => {
    if (option.isNew) {
      try {
        const response = await apiClient.rfp.rfpProposalRequirementStatusCreate(
          details.id,
          {
            name: option.name,
          }
        );
        onChange?.({
          status: response.data.icon ?? "other",
          statusName: response.data.name,
        });
      } catch (e) {
        toast.error(`Failed to create status: ${message_from_exception(e)}`);
      }
    } else {
      onChange?.({ status: option.status, statusName: option.name });
    }
    setChoosing(false);
  };

  return (
    <DropdownMenu onOpenChange={setChoosing} open={choosing}>
      <DropdownMenuTrigger
        className={cn(
          "grow flex items-stretch gap-sm text-sm hover:opacity-60",
          className
        )}
      >
        {showText ? (
          <>
            <StatusIcon statusIcon={status} />
            <span>{statusName ?? statusNameFromIcon(status)}</span>
            <Icon
              name="chevron-down"
              className="w-md shrink-0"
              variant="light"
            />
          </>
        ) : (
          <Tooltip>
            <TooltipTrigger asChild>
              <Columns className="items-center justify-center grow">
                <StatusIcon statusIcon={status} />
              </Columns>
            </TooltipTrigger>
            <TooltipContent>
              {statusName ?? statusNameFromIcon(status)}
            </TooltipContent>
          </Tooltip>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <ComboBox<StatusOption>
          placeholder="Search or add new"
          options={options}
          maxResults={20}
          defaultKeyboardFocusIndex={null}
          filterOptions={(options, text) => {
            let results: StatusOption[] = [];
            let exactMatch = false;
            for (const option of options) {
              if (option.name.toLowerCase() === text.toLowerCase()) {
                exactMatch = true;
                results.push(option);
              } else if (
                option.name.toLowerCase().includes(text.toLowerCase())
              ) {
                results.push(option);
              }
            }
            if (!exactMatch && text.length > 0) {
              results.push({ id: text, status: "", name: text, isNew: true });
            }
            return results;
          }}
          onSelected={handleSelected}
          alwaysShowResults={true}
          autoFocus={choosing}
          renderOption={(option) => (
            <Columns className="items-center gap-sm">
              {option.isNew ? (
                <Icon name="plus" />
              ) : (
                <StatusIcon statusIcon={option.status} />
              )}
              <span>{option.name}</span>
            </Columns>
          )}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default StatusDropDown;
