import { FinalCheckDetails } from "api/Api";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Scrollable from "components/common/containers/Scrollable";
import { createRef, FC, Ref, useEffect, useRef, useState } from "react";
import CenteredContainer from "components/common/containers/CenteredContainer";
import Icon from "components/common/Icon";
import { cn } from "lib/utils";
import {
  ReportElement,
  requirementsMatch,
  useFinalCheckDetails,
} from "providers/FinalCheckDetailsProvider";
import FinalCheckRFPOverlay from "./FinalCheckRFPOverlay";
import FinalCheckRFPPanel from "./FinalCheckRFPPanel";
import Spacer from "components/common/containers/Spacer";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/EditorView/Menus/Tooltip";

interface FinalCheckReportDetailsViewProps {
  finalCheck: FinalCheckDetails;
  report: Record<string, ReportElement[]>;
}

const FinalCheckReportDetailsView: FC<FinalCheckReportDetailsViewProps> = ({
  finalCheck,
  report,
}) => {
  const containerRef = useRef<HTMLDivElement>();
  const {
    activeRequirement,
    setActiveRequirement,
    organizedReport,
    reportContainer,
  } = useFinalCheckDetails();
  const [containerWidth, setContainerWidth] = useState(0);
  const showDetailsInModal = containerWidth <= 1200;

  useEffect(() => {
    // Resize container width when the window resizes
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      setContainerWidth(containerRef.current?.clientWidth ?? 0);
    });
    resizeObserver.observe(containerRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  useEffect(() => {
    // Change requirements with up and down arrows
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!activeRequirement) return;

      const requirements = organizedReport.flatMap(
        (section) => section.requirements
      );
      const index = requirements.findIndex((req) =>
        requirementsMatch(req, activeRequirement)
      );

      if (e.key === "ArrowDown") {
        const nextIndex = (index + 1) % requirements.length;
        setActiveRequirement(requirements[nextIndex]);
      } else if (e.key === "ArrowUp") {
        const nextIndex =
          (index - 1 + requirements.length) % requirements.length;
        setActiveRequirement(requirements[nextIndex]);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeRequirement, organizedReport, setActiveRequirement]);

  const handleSelectRequirement = (req: ReportElement) => {
    setActiveRequirement(req);
  };

  return (
    <Rows className="gap-sm grow w-full" ref={containerRef as any}>
      <Columns className="grow justify-center">
        <Scrollable
          className="grow basis-0 pl-xl pr-md pb-2xl max-w-[900px] relative"
          alwaysShowScrollbar={true}
          ref={reportContainer}
        >
          <h3 className="text-xl pt-lg font-semibold">Compliance Matrix</h3>
          <p>Review what your proposal might have missed</p>
          {organizedReport.map(({ id, name, requirements }) => {
            return (
              <ReportSection
                key={id}
                sectionId={id}
                sectionName={name}
                requirements={requirements}
                handleSelectRequirement={handleSelectRequirement}
                activeRequirement={activeRequirement}
                showDetailsInModal={showDetailsInModal}
              />
            );
          })}
        </Scrollable>
        {showDetailsInModal ? (
          <FinalCheckRFPOverlay />
        ) : (
          <FinalCheckRFPPanel className="max-w-[900px] border-l" />
        )}
      </Columns>
    </Rows>
  );
};

const PassFailStatus = ({ status }: { status: string }) => {
  switch (status) {
    case "pass":
      return (
        <Icon
          name="circle-check"
          variant="solid"
          size="large"
          className="text-[#7CB632]"
        />
      );
    case "fail":
      return (
        <Icon
          name="circle-xmark"
          variant="solid"
          size="large"
          className="text-destructive"
        />
      );
    case "neutral":
    default:
      return (
        <Icon
          name="circle-envelope"
          variant="solid"
          size="large"
          className="text-secondary"
        />
      );
  }
};

interface ReportSectionHeaderProps {
  sectionId: string;
  sectionName: string;
  requirements: ReportElement[];
  onClick: () => void;
  expanded: boolean;
}

const ReportSectionHeader: FC<ReportSectionHeaderProps> = ({
  sectionId,
  sectionName,
  requirements,
  expanded,
  onClick,
}) => {
  const passedRequirementsCount = requirements.filter(
    (req) => req.status === "pass"
  ).length;
  const totalRequirementsCount = requirements.length;

  return (
    <Rows className="sticky top-0">
      <div className="h-2xl bg-background overflow-hidden -mb-sm" />
      <Columns
        className={cn(
          "items-center p-md bg-background-secondary text-lg font-semibold select-none cursor-pointer gap-md border",
          expanded ? "rounded-t-sm" : "rounded-sm"
        )}
        onClick={onClick}
      >
        <Icon
          name={expanded ? "chevron-down" : "chevron-right"}
          variant="solid"
          className="shrink-0 w-[20px] flex items-center justify-center"
        />
        <h3>{sectionName}</h3>
        <Spacer />
        {sectionId === "submission-guideline" ? (
          <p>{totalRequirementsCount}</p>
        ) : (
          <p>
            {passedRequirementsCount} / {totalRequirementsCount}
          </p>
        )}
      </Columns>
    </Rows>
  );
};

interface ReportSectionProps {
  sectionId: string;
  sectionName: string;
  requirements: ReportElement[];
  handleSelectRequirement: (req: ReportElement) => void;
  activeRequirement: ReportElement | null;
  showDetailsInModal: boolean;
}

const ReportSection: FC<ReportSectionProps> = ({
  sectionId,
  sectionName,
  requirements,
  handleSelectRequirement,
  activeRequirement,
  showDetailsInModal,
}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (activeRequirement && requirements.includes(activeRequirement)) {
      setExpanded(true);
    }
  }, [activeRequirement, requirements]);

  return (
    <>
      <ReportSectionHeader
        sectionId={sectionId}
        sectionName={sectionName}
        requirements={requirements}
        expanded={expanded}
        onClick={() => setExpanded((prev) => !prev)}
      />
      <Rows
        className={cn(
          "mx-auto border-x border-b rounded-b-sm w-full",
          !expanded && "border-none"
        )}
      >
        <Rows
          className="overflow-hidden transition-[max-height]"
          style={{
            maxHeight: expanded ? requirements.length * 100 + 200 : 0,
          }}
        >
          {requirements.map((req, i) => (
            <Rows
              key={i}
              ref={req.ref}
              className={cn(
                "py-sm border-t first:border-t-0 cursor-pointer shrink-0",
                requirementsMatch(activeRequirement, req)
                  ? "bg-border"
                  : "hover:bg-background-selected"
              )}
            >
              <Columns
                onClick={() => handleSelectRequirement(req)}
                className="shrink-0"
              >
                <CenteredContainer className="w-[50px] pl-[10px] shrink-0 grow-0">
                  <PassFailStatus status={req.status} />
                </CenteredContainer>
                <p className="grow basis-0 py-sm px-md">{req.requirement}</p>
                <CenteredContainer className="w-[40px] shrink-0 grow-0">
                  <Tooltip>
                    <TooltipTrigger>
                      <Icon
                        name="chevron-right"
                        className={
                          requirementsMatch(activeRequirement, req)
                            ? "opacity-0"
                            : "opacity-100"
                        }
                      />
                    </TooltipTrigger>
                    <TooltipContent>Click to view detials</TooltipContent>
                  </Tooltip>
                </CenteredContainer>
              </Columns>
              <Rows
                className={cn(
                  "overflow-hidden transition-[max-height] ml-[40px] mr-[50px] pl-md shrink-0",
                  requirementsMatch(activeRequirement, req) &&
                    sectionId !== "submission-guideline" &&
                    !showDetailsInModal
                    ? "max-h-[260px]"
                    : "max-h-0"
                )}
              >
                <Columns className="shrink-0 grow items-center gap-md border border-secondary p-md my-sm rounded-sm bg-background">
                  <Rows>
                    <p className="font-semibold">Feedback</p>
                    <p>{activeRequirement?.justification}</p>
                  </Rows>
                </Columns>
              </Rows>
            </Rows>
          ))}
        </Rows>
      </Rows>
    </>
  );
};

export default FinalCheckReportDetailsView;
