import { TElement } from "@udecode/plate-common";
import { getSuggestionIdFromKey, getSuggestionKeys } from "odo";

const useSuggestionId = (element: TElement) => {
  const keys = Array.from(getSuggestionKeys(element, true));
  if (keys.length === 0) {
    return null;
  }
  if (keys.length > 1) {
    console.warn(
      "useSuggestionId: element has more than one suggestion key, returning the first one"
    );
  }
  return getSuggestionIdFromKey(keys[0]);
};

export default useSuggestionId;
