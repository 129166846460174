import React from "react";
import { PlateElement, PlateElementProps } from "@udecode/plate-common";

import { cn } from "lib/utils";
import { useTableState } from "./TableElementProvider";
import { OdoTD } from "odo";

export interface TableCellElementProps extends PlateElementProps {
  hideBorder?: boolean;
  isHeader?: boolean;
}

const TableCellElement = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  TableCellElementProps
>(({ className, element, children, ...props }, ref) => {
  const { getCellState } = useTableState();
  const { row, col, width, selected } = getCellState(element as OdoTD);

  return (
    <PlateElement ref={ref} asChild {...props} element={element}>
      <div
        className={cn(
          "bg-background px-2m py-sm",
          selected ? "bg-highlight" : ""
        )}
        style={{
          gridColumn: col,
          gridRow: row,
          // Subtract for the border
          width: col === 1 ? width - 2 : width - 1,
        }}
      >
        {children}
      </div>
    </PlateElement>
  );
});
TableCellElement.displayName = "TableCellElement";

const TableCellHeaderElement = React.forwardRef<
  React.ElementRef<typeof TableCellElement>,
  TableCellElementProps
>((props, ref) => {
  return <TableCellElement ref={ref} {...props} isHeader />;
});
TableCellHeaderElement.displayName = "TableCellHeaderElement";

export { TableCellElement, TableCellHeaderElement };
