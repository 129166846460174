import { RFPCategory } from "api/Api";
import Pill from "components/common/Pill";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import { FC } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/EditorView/Menus/DropdownMenu";
import { cn } from "lib/utils";
import Columns from "components/common/containers/Columns";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import { message_from_exception } from "utils";
import Rows from "components/common/containers/Rows";
import Icon from "components/common/Icon";

interface RFPCategoriesDropDownProps {
  className?: string;
  categories: RFPCategory[];
  onChange: (categories: RFPCategory[]) => void;
  onSave?: () => void;
}

const RFPCategoriesDropDown: FC<RFPCategoriesDropDownProps> = ({
  className,
  categories,
  onChange,
  onSave,
}) => {
  const apiClient = useApiClient();
  const [allCategories, , { error }] = useFetchedData(async () => {
    const response = await apiClient.rfp.rfpCategoryList();
    return response.data;
  });

  return (
    <DropdownMenu
      onOpenChange={(open) => {
        if (!open && onSave) {
          onSave();
        }
      }}
    >
      <DropdownMenuTrigger className={cn("overflow-x-hidden", className)}>
        <Columns>
          {categories.map((c) => (
            <Pill key={c.name} text={c.display_name} />
          ))}
          {categories.length === 0 && (
            <Pill className="text-secondary" text="No categories" />
          )}
        </Columns>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <AsyncLoadedDiv
          value={allCategories}
          error={error ? message_from_exception(error) : null}
          whileLoaded={(allCategories) => (
            <Rows>
              {allCategories.map((category) => {
                const isSelected =
                  categories.find((value) => value.name === category.name) !==
                  undefined;
                return (
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      if (isSelected) {
                        onChange(
                          categories.filter(
                            (value) => value.name !== category.name
                          )
                        );
                      } else {
                        onChange([...categories, category]);
                      }
                    }}
                  >
                    <Columns className="items-center">
                      <div className="w-[24px]">
                        {isSelected && <Icon name="check" />}
                      </div>
                      <Rows>{category.display_name}</Rows>
                    </Columns>
                  </DropdownMenuItem>
                );
              })}
            </Rows>
          )}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default RFPCategoriesDropDown;
