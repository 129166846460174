import { useDroppable } from "@dnd-kit/core";
import {
  PlateElement,
  PlateElementProps,
  useEditorRef,
} from "@udecode/plate-common";
import Rows from "components/common/containers/Rows";
import useParentSectionInfo, {
  ParentSectionInfo,
} from "hooks/odo-editor/useParentSection";
import { useIsInComment } from "lib/plate/useIsInComment";
import { useIsInRefine } from "lib/plate/useIsInRefine";
import { cn } from "lib/utils";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { useEditorDocData } from "providers/RequirementContentEditorProvider";
import { FC } from "react";
import { ReactEditor } from "slate-react";

export interface BaseElementProps extends PlateElementProps {}

export const BaseElement: FC<BaseElementProps> = ({
  children,
  className,
  element,
  ...props
}) => {
  const editor = useEditorRef();
  const { activeSection, selectedSection } = useEditorDocData();
  const { styleGuide } = useProposalData();
  const parentSection = useParentSectionInfo(element);
  const isInRefine = useIsInRefine(element);
  const isInComment = useIsInComment(element);
  const { activeRightPanel } = useEditorDocData();
  const showDrop =
    (activeSection &&
      activeSection.id === parentSection?.id &&
      activeSection.mode === "into") ||
    parentSection?.hasActiveRequirement === true ||
    (selectedSection &&
      selectedSection.id === parentSection?.id &&
      activeRightPanel === "section-details");

  const path = ReactEditor.findPath(editor as any, element);
  const style = styleGuide.textStyleToCSS(editor as any, element);

  let borderTop: string | number = "0";
  let borderBottom: string | number = "0";
  let dropTop: number = 0;
  let dropBottom: number = 0;
  if (parentSection) {
    if (style.marginBottom !== undefined) {
      if (typeof style.marginBottom === "number") {
        dropBottom = -style.marginBottom;
      } else {
        dropBottom = -parseInt(style.marginBottom);
      }

      if (parentSection.isLastInSection) {
        borderBottom = 20;
      } else {
        borderBottom = "-" + style.marginBottom;
      }
    } else {
      borderBottom = 4;
    }

    if (style.marginTop) {
      let shift = 0;
      if (typeof style.marginTop === "number") {
        shift = -style.marginTop;
      } else {
        shift = -parseInt(style.marginTop);
      }
      dropTop = shift;

      if (parentSection.isFirstInSection) {
        borderTop = "4px";
      } else {
        borderTop = shift;
      }
    }
  }

  return (
    <PlateElement
      {...props}
      element={element}
      className={cn(
        !isInComment && "px-sm",
        !isInRefine && !isInComment && "mx-4xl",
        className
      )}
      style={style}
    >
      <div className="relative">
        {parentSection && (
          <Rows
            contentEditable={false}
            className={cn(
              "absolute inset-0 pointer-events-none overflow-visible select-none",
              showDrop && "border-x -mx-thin border-primary",
              showDrop &&
                (parentSection?.isFirstInSection ?? false) &&
                "border-t -mt-thin rounded-t-md",
              showDrop &&
                (parentSection?.isLastInSection ?? false) &&
                "border-b -mb-thin rounded-b-md"
            )}
          >
            <Rows
              className={cn("absolute left-0 right-0 overflow-visible")}
              style={{
                top: dropTop,
                bottom: dropBottom,
              }}
            >
              {parentSection.isFirstInSection && (
                <DropAboveTarget sectionInfo={parentSection} index={path[0]} />
              )}
              <DropIntoTarget sectionInfo={parentSection} index={path[0]} />
              {parentSection.isLastInSection &&
                parentSection.isLastSectionInDocument && (
                  <DropBelowTarget
                    sectionInfo={parentSection}
                    index={path[0]}
                  />
                )}
            </Rows>
          </Rows>
        )}
        {showDrop && (
          <>
            <div
              contentEditable={false}
              className="absolute -left-thin w-thin bg-primary"
              style={{ top: borderTop, bottom: borderBottom }}
            />
            <div
              contentEditable={false}
              className="absolute -right-thin w-thin bg-primary"
              style={{ top: borderTop, bottom: borderBottom }}
            />
          </>
        )}
        {children}
      </div>
    </PlateElement>
  );
};

interface DropTarget {
  index: number;
  sectionInfo: ParentSectionInfo;
}

const DropIntoTarget: FC<DropTarget> = ({ index, sectionInfo }) => {
  const { setNodeRef } = useDroppable({
    id: sectionInfo.id + "_into_" + index.toString(),
  });

  return <div className={cn("grow")} ref={setNodeRef} />;
};

const DropAboveTarget: FC<DropTarget> = ({ index, sectionInfo }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: sectionInfo.id + "_above_" + index.toString(),
  });

  return (
    <div
      className={cn(
        "h-md",
        isOver && sectionInfo.startIndex > 0 && "border-b-[3px] border-primary",
        isOver && sectionInfo.startIndex == 0 && "border-t-[3px] border-primary"
      )}
      ref={setNodeRef}
    />
  );
};

const DropBelowTarget: FC<DropTarget> = ({ index, sectionInfo }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: sectionInfo.id + "_below_" + index.toString(),
  });

  return (
    <div
      className={cn(
        "h-md",
        // 15 = md + 3px border
        isOver && "border-b-[3px] -mb-[3px] h-[15px] border-primary"
      )}
      ref={setNodeRef}
    />
  );
};

export default BaseElement;
