import { createPluginFactory, isElement } from "@udecode/plate-common";
import { ELEMENT_CODE_BLOCK, ELEMENT_CODE_LINE, } from "@udecode/plate-code-block";
import { getChildren, liftNodes, setNodes } from "@udecode/plate";
/*
  Allow converting code lines (inside a code block) into other types of nodes.

  The default behavior of the code block plugin converts the nodes back to code lines
  in its normalizeNode function. This plugin overrides that behavior.
 */
export const createCodeBlockAdjustmentsPlugin = createPluginFactory({
    key: "code-block-adjustments",
    withOverrides: (editor) => {
        const { normalizeNode } = editor;
        editor.normalizeNode = ([node, path]) => {
            normalizeNode([node, path]);
            if (!isElement(node)) {
                return;
            }
            const isCodeBlockRoot = node.type === ELEMENT_CODE_BLOCK;
            if (isCodeBlockRoot) {
                // Children should all be code lines
                const nonCodeLine = getChildren([node, path]).find(([child]) => child.type !== ELEMENT_CODE_LINE);
                if (nonCodeLine) {
                    const type = nonCodeLine[0].type;
                    editor.withoutNormalizing(() => {
                        liftNodes(editor, { at: nonCodeLine[1] });
                        setNodes(editor, { type });
                    });
                }
            }
        };
        return editor;
    },
});
