import {
  autoformatArrow,
  autoformatLegal,
  autoformatLegalHtml,
  autoformatPunctuation,
  autoformatSmartQuotes,
} from "@udecode/plate-autoformat";

import { autoformatBlocks } from "lib/plate/autoformat/autoformatBlocks";
import { autoformatMarks } from "lib/plate/autoformat/autoformatMarks";
import { autoformatIndentLists } from "lib/plate/autoformat/autoformatIndentLists";

const autoformatRules = [
  ...autoformatBlocks,
  ...autoformatIndentLists,
  ...autoformatMarks,
  ...autoformatSmartQuotes,
  ...autoformatPunctuation,
  ...autoformatLegal,
  ...autoformatLegalHtml,
  ...autoformatArrow,
];

export default autoformatRules;
