import { HocuspocusProvider } from "@hocuspocus/provider";
import { useEffect, useState } from "react";
import { useApiClient } from "../../providers/ApiClientProvider";
import { YjsEditor } from "@slate-yjs/core";
import { PlateEditor, Value, useEditorRef } from "@udecode/plate";
import { PlateYjsEditorProps } from "@udecode/plate-yjs";

export type DocConnectionStatus =
  | "connected"
  | "connecting"
  | "not-found"
  | "syncing";

/**
 * This hook is used to manage the connection of a Yjs provider.
 *
 * This hook is used by useYjs to break out the functionality more logically.
 * DO NOT USE THIS HOOK DIRECTLY. Use useEditorDocData instead if you want
 * access to the status.
 */
const _useYjsConnection = (
  yjsProvider: HocuspocusProvider | null
): DocConnectionStatus => {
  const editor = useEditorRef<Value, PlateEditor & PlateYjsEditorProps>();
  const apiClient = useApiClient();
  const [connectionStatus, setConnectionStatus] = useState<
    "connected" | "connecting" | "not-found"
  >("connecting");
  const [synced, setSynced] = useState(false);

  useEffect(() => {
    if (!yjsProvider) return;
    yjsProvider.connect();
    return () => {
      yjsProvider.destroy();
    };
  }, [yjsProvider]);

  useEffect(() => {
    YjsEditor.connect(editor as any);
    return () => YjsEditor.disconnect(editor as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yjsProvider?.awareness, yjsProvider?.document]);

  useEffect(() => {
    if (!yjsProvider) {
      return;
    }

    yjsProvider.on("status", (event: any) => {
      setConnectionStatus(event.status);
    });

    yjsProvider.on("synced", (event: any) => {
      setSynced(event.state);
    });

    yjsProvider.on(
      "authenticationFailed",
      async (event: { reason: string }) => {
        console.log("authenticationFailed", event.reason);
        switch (event.reason) {
          case "NotLoggedIn":
            apiClient.logout();
            break;
          case "TokenExpired":
            await apiClient.refreshToken();
            // Try to connect again after refreshing (upstream auth handling will
            // redirect to /login if refresh fails)
            await yjsProvider.connect();
            break;
          case "NotFound":
            setConnectionStatus("not-found");
            break;
          default:
            break;
        }
      }
    );

    return () => {
      yjsProvider.off("status");
      yjsProvider.off("authenticationFailed");
      yjsProvider.off("synced");
    };
  }, [yjsProvider, apiClient]);

  switch (connectionStatus) {
    case "connected":
      if (!synced) {
        return "syncing";
      }
      return "connected";
    default:
      return connectionStatus ?? "connecting";
  }
};

export default _useYjsConnection;
