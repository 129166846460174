import { Command as InternalCommand } from "cmdk";
import { FC, ReactNode } from "react";
import { cn } from "lib/utils";
import { ComboboxItemContent } from "components/EditorView/Menus/Combobox";

export const Command = InternalCommand;
export const CommandEmpty: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <InternalCommand.Empty className="px-2m pb-sm text-secondary">
      {children}
    </InternalCommand.Empty>
  );
};

export const CommandList = InternalCommand.List;

interface ItemProps {
  className?: string;
  children: ReactNode;
  selected?: boolean;
  onSelect: () => void;
}

export const CommandItem: FC<ItemProps> = ({
  children,
  className,
  selected,
  onSelect,
}) => {
  return (
    <InternalCommand.Item
      onSelect={onSelect}
      className={cn(
        "aria-selected:bg-popover-selected select-none",
        selected
          ? "bg-primary text-primary-foreground aria-selected:bg-primary aria-selected:text-primary-foreground"
          : undefined,
        className
      )}
    >
      <ComboboxItemContent className="px-2m py-sm text-sm flex gap-md items-center">
        {children}
      </ComboboxItemContent>
    </InternalCommand.Item>
  );
};
