import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import Overlay from "components/common/containers/overlays/Overlay";
import Rows from "components/common/containers/Rows";
import Input from "components/common/forms/Input";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import NaturalHeightTextArea from "components/common/forms/NaturalHeightTextArea";
import { useConfirm } from "providers/AlertProvider";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import {
  PromptTool,
  promptToolToWorking,
  WorkingPromptTool,
  WorkingProperty,
  workingToPromptTool,
} from "types/Prompt";
import { v4 } from "uuid";
import PromptPropertyView from "./PromptPropertyView";

interface PromptToolViewProps {
  tool: PromptTool | null;
  onRemove: () => void;
  onSave: (tool: PromptTool) => void;
  onCancel: () => void;
}

const PromptToolView: FC<PromptToolViewProps> = ({
  onRemove,
  onCancel,
  onSave,
  tool: existingTool,
}) => {
  const confirm = useConfirm();
  const [tool, setTool] = useState<WorkingPromptTool>(
    promptToolToWorking(existingTool)
  );

  // An selected property of "null" means that the user is editing a new property
  // An selected property of "undefined" means that the user is not editing a property
  const [selectedProperty, setSelectedProperty] = useState<
    WorkingProperty | null | undefined
  >(undefined);

  const setName = (value: string) => {
    // Restrict the name to alphanumeric characters, dashes, and underscores
    const validName = value.replace(/[^a-zA-Z0-9-_]+/g, "");
    if (validName !== value) {
      toast.error(
        "Tool names can only contain alphanumeric characters, dashes, and underscores."
      );
    }
    setTool({ ...tool, name: validName });
  };

  const handleRemove = async () => {
    const result = await confirm("Are you sure you want to remove this tool?", {
      yesDestructive: true,
      yesText: "Remove",
    });
    if (result) {
      onRemove();
      onCancel();
    }
  };

  const handleSave = () => {
    if (!tool.name) {
      toast.error("Tool name cannot be empty.");
      return;
    }
    onSave(workingToPromptTool(tool));
  };

  return (
    <Rows className="gap-md">
      <LabeledFormInput label="Name">
        <Input
          placeholder="Unnamed Tool"
          value={tool.name}
          onChange={(e) => setName(e.target.value)}
        />
      </LabeledFormInput>
      <LabeledFormInput label="Description">
        <NaturalHeightTextArea
          value={tool.description}
          className="min-h-[70px]"
          onChange={(text) => setTool({ ...tool, description: text })}
          placeholder="A detailed plaintext description of what the tool does, when it should be used, and how it behaves."
        />
      </LabeledFormInput>
      <LabeledFormInput
        label="Properties"
        extraControls={
          <Button
            icon="plus"
            variant="solid"
            onClick={() => setSelectedProperty(null)}
          />
        }
      >
        {tool.properties.map((property) => (
          <div
            key={property.id}
            className="hover:bg-background-selected px-md py-sm"
            onClick={() => setSelectedProperty(property)}
          >
            {property.name} - {property.description}
          </div>
        ))}
      </LabeledFormInput>
      {selectedProperty !== undefined && (
        <Overlay title="Property" className="w-full max-w-[570px]">
          <PromptPropertyView
            property={selectedProperty}
            onRemove={() => {
              setTool({
                ...tool,
                properties: tool.properties.filter(
                  (p) => p.id !== selectedProperty?.id
                ),
              });
              setSelectedProperty(undefined);
            }}
            onSave={(property) => {
              if (selectedProperty === null) {
                setTool({
                  ...tool,
                  properties: [...tool.properties, { ...property, id: v4() }],
                });
              } else {
                setTool({
                  ...tool,
                  properties: tool.properties.map((p) =>
                    p.id === property.id ? property : p
                  ),
                });
              }
              setSelectedProperty(undefined);
            }}
            onCancel={() => setSelectedProperty(undefined)}
          />
        </Overlay>
      )}
      <Columns className="gap-md mt-sm">
        <Button
          icon="xmark"
          text="Remove Tool"
          variant="destructive"
          onClick={handleRemove}
        />
        <div className="grow" />
        <Button text="Cancel" onClick={onCancel} />
        <Button
          text="Save"
          icon="floppy-disk"
          variant="solid"
          onClick={handleSave}
        />
      </Columns>
    </Rows>
  );
};

export default PromptToolView;
