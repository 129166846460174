import { cn } from "lib/utils";
import { PlateContent, useEditorRef } from "@udecode/plate";
import { LinkFloatingToolbar } from "../../EditorView/Menus/LinkFloatingToolbar";
import React, { useEffect, useRef } from "react";
import FixedToolbar from "../../EditorView/Menus/FixedToolbar";
import { useEditorDocData } from "../../../providers/RequirementContentEditorProvider";
import Spinner from "../../common/Spinner";
import { PlateEditor, Value, focusEditor } from "@udecode/plate-common";
import { OdoCursorData } from "odo";
import { useHooksComments } from "lib/plate/plugins/useHooksComments";
import { PlateYjsEditorProps } from "@udecode/plate-yjs";
import { useProposalRequirementData } from "providers/ProposalRequirementDetailsProvider";
import { RemoteCursorOverlay } from "components/cursors/RemoteCursorOverlay";
import {
  CursorCaret,
  CursorSelectionRect,
} from "components/cursors/RemoteCursor";
import CommentList from "components/comments/CommentList";
import Columns from "components/common/containers/Columns";
import {
  RightEditorToolbar,
  useRightEditorContent,
} from "./panels/RightEditorPanel";
import ProposalDragDrop from "./ProposalDragDrop";
import {
  LeftEditorToolbar,
  useLeftEditorContent,
} from "./panels/LeftEditorPanel";
import { SplitPaneContainer } from "components/common/containers/split-pane-container/SplitPaneContainer";
import { SplitPane } from "components/common/containers/split-pane-container/SplitPane";

interface ProposalAnswerEditorContentProps {
  containerRef: React.RefObject<HTMLDivElement>;
  canvasRef: React.RefObject<HTMLDivElement>;
  isTestEditor?: boolean;
}

const ProposalAnswerEditorContent: React.FC<
  ProposalAnswerEditorContentProps
> = ({ containerRef, canvasRef, isTestEditor }) => {
  const { status, setIsFocused } = useEditorDocData();
  const editor = useEditorRef<Value, PlateEditor & PlateYjsEditorProps>();
  const editorBackgroundRef = useRef<HTMLDivElement>(null);
  const leftContent = useLeftEditorContent();
  const rightContent = useRightEditorContent();
  const { setYjsProvider } = useProposalRequirementData();
  useHooksComments();

  useEffect(() => {
    if (editor) {
      setYjsProvider(editor.yjs.provider);
    }
  }, [editor, editor.yjs.provider, setYjsProvider]);

  const handleClickBelow = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // When the user clicks below the editor, we want to focus the editor
    // at the end. Plus, if the editor does not end in a paragraph, we want
    // to add a paragraph at the end.
    if (!editor.selection || editor.node(editor.selection)) {
      if (editor.children.length > 0) {
        let lastNode = editor.children[editor.children.length - 1];
        if (lastNode.type !== "p") {
          editor.insertNodes(
            {
              // @ts-ignore
              type: "p",
              children: [{ text: "" }],
            },
            { at: [editor.children.length] }
          );
        }
        const end = editor.end([editor.children.length - 1]);
        focusEditor(editor, end);
      } else {
        focusEditor(editor);
      }
    }
  };

  let content: React.ReactNode;
  if (status === "not-found") {
    content = <div className="text-center">Not Found</div>;
  } else {
    content = (
      <>
        <FixedToolbar />
        <ProposalDragDrop>
          <Columns>
            {!isTestEditor && <LeftEditorToolbar />}
            <SplitPaneContainer
              className="grow"
              localStorageKey="main-content-panels"
            >
              {!isTestEditor && leftContent && (
                <SplitPane
                  uniqueId="left-panel"
                  className="border-r overflow-hidden grow flex"
                  minSize={240}
                  defaultSize={500}
                  maxSize={700}
                >
                  {leftContent}
                </SplitPane>
              )}
              <SplitPane
                className="overflow-scroll overflow-x-hidden bg-accent"
                uniqueId="content"
                minSize={200}
                priority={1}
                ref={canvasRef as any}
              >
                <div
                  ref={editorBackgroundRef}
                  className={cn(
                    "flex flex-row min-h-[80vh] bg-accent justify-center w-full"
                  )}
                >
                  <Columns className="bg-background overflow-x-auto">
                    <div
                      className={cn(
                        "basis-0 grow-[7] pt-xl relative overflow-x-visible max-w-[800px] w-[99999999px]",
                        isTestEditor && "pr-0"
                      )}
                      ref={containerRef as any}
                    >
                      <PlateContent
                        id="main"
                        /**
                         * Don't add top padding to the first element
                         * Don't let firefox add an outline when focusing
                         */
                        className="[&>*:first-child]:pt-0 focus:outline-none"
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        renderEditable={(editable) => {
                          return (
                            <>
                              {editable}
                              <RemoteCursorOverlay<OdoCursorData>
                                className="pt-3xl"
                                onRenderCaret={CursorCaret}
                                onRenderSelectionRect={CursorSelectionRect}
                                containerRef={containerRef}
                              />
                              <LinkFloatingToolbar />
                            </>
                          );
                        }}
                      />
                      <div className="h-[90px]" onClick={handleClickBelow} />
                    </div>
                  </Columns>
                  <CommentList
                    hidden={isTestEditor}
                    className="grow-[4] basis-0 pt-[20px] bg-accent"
                  />
                </div>
              </SplitPane>
              {!isTestEditor && rightContent && (
                <SplitPane
                  uniqueId="right-panel"
                  className="border-l flex"
                  maxSize={1000}
                  defaultSize={500}
                  minSize={240}
                >
                  {rightContent}
                </SplitPane>
              )}
            </SplitPaneContainer>
            {!isTestEditor && <RightEditorToolbar />}
          </Columns>
        </ProposalDragDrop>
        {status !== "connected" && (
          <div className="text-center flex gap-sm justify-center items-center absolute inset-0 bg-background opacity-90">
            <Spinner />
            Connecting...
          </div>
        )}
      </>
    );
  }

  return (
    <div className="grow flex flex-row relative border rounded-sm overflow-hidden">
      <div className="grow flex absolute top-0 bottom-0 left-0 right-0 flex-col justify-stretch">
        {content}
      </div>
    </div>
  );
};

export default ProposalAnswerEditorContent;
