class PromptStorage {
  promptId: number;

  constructor(promptId: number) {
    this.promptId = promptId;
  }

  clear() {
    localStorage.removeItem(this.contentKey());
    localStorage.removeItem(this.variablesKey());
    localStorage.removeItem(this.valueGroupsKey());
    localStorage.removeItem(this.variationsKey());
    localStorage.removeItem(this.hasChangedKey());
    localStorage.removeItem(this.promptNameKey());
    localStorage.removeItem(this.modelKey());
    localStorage.removeItem(this.notesKey());
    localStorage.removeItem(this.toolsKey());
    localStorage.removeItem(this.activeVariationKey());
  }

  get content() {
    return this.getDict(this.contentKey());
  }

  set content(value: Record<string, any>) {
    localStorage.setItem(this.contentKey(), JSON.stringify(value));
  }

  get variables() {
    return this.getDict(this.variablesKey());
  }

  set variables(value: Record<string, any>) {
    localStorage.setItem(this.variablesKey(), JSON.stringify(value));
  }

  get valueGroups() {
    return this.getArray(this.valueGroupsKey());
  }

  set valueGroups(value: any[]) {
    localStorage.setItem(this.valueGroupsKey(), JSON.stringify(value));
  }

  get variations() {
    return this.getArray(this.variationsKey());
  }

  set variations(value: any[]) {
    localStorage.setItem(this.variationsKey(), JSON.stringify(value));
  }

  get hasChanged() {
    return this.getBoolean(this.hasChangedKey());
  }

  set hasChanged(value: boolean) {
    localStorage.setItem(this.hasChangedKey(), value ? "true" : "false");
  }

  get promptName() {
    return this.getString(this.promptNameKey(), "");
  }

  set promptName(value: string) {
    if (value === null) {
      localStorage.removeItem(this.promptNameKey());
      return;
    }
    localStorage.setItem(this.promptNameKey(), value);
  }

  get variationModel() {
    return this.getDict(this.modelKey());
  }

  set variationModel(value: Record<number, string>) {
    localStorage.setItem(this.modelKey(), JSON.stringify(value));
  }

  get variationNotes() {
    return this.getDict(this.notesKey());
  }

  set variationNotes(value: Record<number, string>) {
    localStorage.setItem(this.notesKey(), JSON.stringify(value));
  }

  get variationTools() {
    return this.getDict(this.toolsKey());
  }

  set variationTools(value: Record<number, any>) {
    localStorage.setItem(this.toolsKey(), JSON.stringify(value));
  }

  get activeVariation() {
    return this.getNumber(this.activeVariationKey());
  }

  set activeVariation(value: number | null) {
    if (value === null) {
      localStorage.removeItem(this.activeVariationKey());
      return;
    }
    localStorage.setItem(this.activeVariationKey(), value.toString());
  }

  private getString(key: string, defaultValue: string) {
    return localStorage.getItem(key) ?? defaultValue;
  }

  private getNumber(key: string) {
    const rawValue = localStorage.getItem(key);
    if (rawValue === null) {
      return null;
    }
    return parseInt(rawValue) ?? null;
  }

  private getBoolean(key: string) {
    const rawValue = localStorage.getItem(key);
    if (rawValue === null) {
      return false;
    }
    return rawValue === "true";
  }

  private getDict(key: string) {
    const rawValue = localStorage.getItem(key) ?? "{}";
    const object = JSON.parse(rawValue);
    if (typeof object !== "object") {
      return {};
    } else {
      return object;
    }
  }

  private getArray(key: string) {
    const rawValue = localStorage.getItem(key) ?? "[]";
    const object = JSON.parse(rawValue);
    if (!Array.isArray(object)) {
      return [];
    } else {
      return object;
    }
  }

  private contentKey() {
    return `prompt-refinery-content-${this.promptId}`;
  }

  private variablesKey() {
    return `prompt-refinery-variables-${this.promptId}`;
  }

  private valueGroupsKey() {
    return `prompt-refinery-value-groups-${this.promptId}`;
  }

  private variationsKey() {
    return `prompt-refinery-variations-${this.promptId}`;
  }

  private hasChangedKey() {
    return `prompt-refinery-has-changed-${this.promptId}`;
  }

  private promptNameKey() {
    return `prompt-refinery-prompt-name-${this.promptId}`;
  }

  private modelKey() {
    return `prompt-refinery-variation-model-${this.promptId}`;
  }

  private notesKey() {
    return `prompt-refinery-variation-notes-${this.promptId}`;
  }

  private toolsKey() {
    return `prompt-refinery-variation-tools-${this.promptId}`;
  }

  private activeVariationKey() {
    return `prompt-refinery-active-variation-${this.promptId}`;
  }
}

export default PromptStorage;
