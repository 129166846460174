import { isOdoElement, isOdoText } from "./types.js";
export function odoElementHasAttribute(element, key, value) {
    if (key === "text") {
        throw new Error("Cannot check for text attribute");
    }
    // @ts-ignore
    if (element[key] === value) {
        return true;
    }
    for (const child of element.children) {
        if (isOdoText(child)) {
            // @ts-ignore
            if (child[key] === value) {
                return true;
            }
        }
        else if (isOdoElement(child)) {
            // @ts-ignore
            if (child[key] === value) {
                return true;
            }
            if (odoElementHasAttribute(child, key, value)) {
                return true;
            }
        }
    }
    return false;
}
