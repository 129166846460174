import { HocuspocusProvider } from "@hocuspocus/provider";
import useYjsMap from "./useYjsMap";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

type PropertyType =
  | "date"
  // Doesn't need to be normalized
  | "safe";

export type YjsObjectStatus = "not-ready" | "ready" | "lost";

/**
 *
 * @param yjsProvider The provider to sync through
 * @param key The key to sync under
 * @param fullValue The full value from the server
 * @param setFullValue The setter for the full value to keep the local memory in sync
 * @param syncingTypes Type for only the properties that need to be live-synced
 * @returns
 */
const useYjsObject = <SyncedValue extends Record<string, any>>(options: {
  yjsProvider: HocuspocusProvider | null;
  key: string;
  syncingTypes: Record<keyof SyncedValue, PropertyType>;
}): [
  SyncedValue | null,
  Dispatch<SetStateAction<SyncedValue>>,
  { status: YjsObjectStatus }
] => {
  const [hasSynced, setHasSynced] = useState(false);
  const [syncedValue, setSyncedValueProp] = useYjsMap(
    options.yjsProvider,
    options.key,
    {},
    true
  );
  const [localValue, setLocalValue] = useState<SyncedValue | null>(null);
  const [connectionStatus, setConnectionStatus] =
    useState<string>("connecting");

  const set: Dispatch<SetStateAction<SyncedValue>> = (update) => {
    let updatedValue: SyncedValue;
    if (typeof update === "function") {
      updatedValue = update(syncedValue as SyncedValue);
    } else {
      updatedValue = update;
    }
    const dict = prepareForSyncing(updatedValue, options.syncingTypes);
    for (const key of Object.keys(dict)) {
      setSyncedValueProp(key, dict[key]);
    }
  };

  const handleStatusChanged = (e: any) => {
    setConnectionStatus(e.status);
  };

  useEffect(() => {
    if (!syncedValue) {
      setLocalValue(null);
      return;
    }
    const processed = processForLocalMemory(
      syncedValue,
      options.syncingTypes
    ) as SyncedValue;
    setLocalValue(processed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncedValue]);

  useEffect(() => {
    if (!options.yjsProvider) return;

    const handleSynced = (e: any) => {
      setTimeout(() => {
        setHasSynced((prev) => prev || e.state);
      }, 0);
    };

    setHasSynced(options.yjsProvider.isSynced);

    options.yjsProvider.on("synced", handleSynced);
    options.yjsProvider.on("status", handleStatusChanged);

    return () => {
      options.yjsProvider?.off("synced", handleSynced);
      options.yjsProvider?.off("status", handleStatusChanged);
    };
  }, [options.yjsProvider]);

  if (!hasSynced) {
    return [null, set, { status: "not-ready" }];
  }

  let status: YjsObjectStatus = "ready";
  if (connectionStatus === "connected") {
    status = "ready";
  } else {
    status = "lost";
  }

  return [localValue, set, { status }];
};

/**
 * Take the full value and apply the synced changes to it while also
 * converting any JSON types back to their original types
 */
const processForLocalMemory = (
  fromSyncing: Record<string, any>,
  types: Record<string, PropertyType>
) => {
  const toStore: Record<string, any> = {};
  for (const key of Object.keys(types)) {
    toStore[key] = processKeyValueForLocalMemory(
      key,
      fromSyncing[key],
      types[key]
    );
  }
  return toStore;
};

const processKeyValueForLocalMemory = (
  key: string,
  value: any,
  type: PropertyType
) => {
  if (type === "date") {
    if (typeof value === "string") {
      return new Date(value);
    } else if (value instanceof Date) {
      return value;
    } else if (value === undefined) {
      return undefined;
    } else {
      throw new Error(`Expected string for date, got ${typeof value}`);
    }
  } else if (type === "safe") {
    return value;
  } else {
    throw new Error(`Type ${type} for local storage`);
  }
};

/**
 * Take the full value and pare it down to only the properties that need to be synced
 * and also normalize those properties to basic JSON types
 */
const prepareForSyncing = (
  obj: Record<string, any>,
  types: Record<string, PropertyType>
) => {
  let toSync: Record<string, any> = {};
  for (const key of Object.keys(types)) {
    if (types[key] === "date") {
      if (obj[key] instanceof Date) {
        toSync[key] = obj[key].toISOString();
      } else {
        toSync[key] = obj[key];
      }
    } else if (types[key] === "safe") {
      toSync[key] = obj[key];
    } else {
      throw new Error(`Type ${types[key]} not supported for syncing`);
    }
  }
  return toSync;
};

export default useYjsObject;
