import React, { useEffect } from "react";
import { DropdownMenuProps } from "@radix-ui/react-dropdown-menu";
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import {
  collapseSelection,
  findNode,
  focusEditor,
  isBlock,
  isElement,
  TElement,
  toggleNodeType,
} from "@udecode/plate-common";
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
} from "@udecode/plate-heading";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
  useOpenState,
} from "./DropdownMenu";
import { ELEMENT_OL } from "@udecode/plate-list";
import Icon, { IconName } from "components/common/Icon";
import { OdoEditor } from "odo";
import { useOdoEditorRef } from "../../../hooks/odo-editor/useOdoEditorRef";
import { isSelectionInTable } from "../../../lib/utils";

const items: {
  value: string;
  supportedInTables: boolean;
  label: string;
  description: string;
  icon: IconName;
  matches: (node: TElement) => boolean;
  onSelect: (editor: OdoEditor) => void;
}[] = [
  {
    value: ELEMENT_PARAGRAPH,
    supportedInTables: true,
    label: "Paragraph",
    description: "Paragraph",
    icon: "paragraph",
    matches: (node) => node.type === ELEMENT_PARAGRAPH && !node.listStyleType,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_PARAGRAPH });
      editor.setNodes({
        // @ts-ignore
        indent: null,
        listStyleType: null,
      });
    },
  },
  {
    value: ELEMENT_H1,
    supportedInTables: false,
    label: "Heading 1",
    description: "Heading 1",
    icon: "h1",
    matches: (node) => node.type === ELEMENT_H1,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_H1 });
    },
  },
  {
    value: ELEMENT_H2,
    supportedInTables: false,
    label: "Heading 2",
    description: "Heading 2",
    icon: "h2",
    matches: (node) => node.type === ELEMENT_H2,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_H2 });
    },
  },
  {
    value: ELEMENT_H3,
    supportedInTables: false,
    label: "Heading 3",
    description: "Heading 3",
    icon: "h3",
    matches: (node) => node.type === ELEMENT_H3,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_H3 });
    },
  },
  {
    value: ELEMENT_H4,
    supportedInTables: false,
    label: "Heading 4",
    description: "Heading 4",
    icon: "h4",
    matches: (node) => node.type === ELEMENT_H4,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_H4 });
    },
  },
  {
    value: ELEMENT_H5,
    supportedInTables: false,
    label: "Heading 5",
    description: "Heading 5",
    icon: "h5",
    matches: (node) => node.type === ELEMENT_H5,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_H5 });
    },
  },
  {
    value: ELEMENT_H6,
    supportedInTables: false,
    label: "Heading 6",
    description: "Heading 6",
    icon: "h6",
    matches: (node) => node.type === ELEMENT_H6,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_H6 });
    },
  },
  {
    value: ELEMENT_BLOCKQUOTE,
    supportedInTables: false,
    label: "Quote",
    description: "Quote (⌘+⇧+.)",
    icon: "block-quote",
    matches: (node) => node.type === ELEMENT_BLOCKQUOTE,
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_BLOCKQUOTE });
    },
  },
  {
    value: ELEMENT_PARAGRAPH,
    supportedInTables: true,
    label: "Bulleted list",
    description: "Bulleted list",
    icon: "list-ul",
    matches: (node) =>
      node.type === ELEMENT_PARAGRAPH && node.listStyleType === "disc",
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_PARAGRAPH });
      // Set list type on all of the nodes
      editor.setNodes({
        // @ts-ignore
        listStyleType: "disc",
      });
      // Set indent only on nodes without an existing indent
      editor.setNodes(
        {
          // @ts-ignore
          listStyleType: "disc",
          // @ts-ignore
          indent: 1,
        },
        {
          match: (node) =>
            isElement(node) && (!("indent" in node) || !node.indent),
        }
      );
    },
  },
  {
    value: ELEMENT_OL,
    supportedInTables: true,
    label: "Numbered list",
    description: "Numbered list",
    icon: "list-ol",
    matches: (node) =>
      node.type === ELEMENT_PARAGRAPH &&
      ["decimal", "lower-roman", "lower-alpha"].includes(
        node.listStyleType as string
      ),
    onSelect: (editor) => {
      toggleNodeType(editor, { activeType: ELEMENT_PARAGRAPH });
      // Set list type on all of the nodes
      editor.setNodes({
        // @ts-ignore
        listStyleType: "decimal",
      });
      // Set indent only on nodes without an existing indent
      editor.setNodes(
        {
          // @ts-ignore
          listStyleType: "decimal",
          // @ts-ignore
          indent: 1,
        },
        {
          match: (node) =>
            isElement(node) && (!("indent" in node) || !node.indent),
        }
      );
    },
  },
  // {
  //   value: ELEMENT_OL,
  //   supportedInTables: true,
  //   label: "Checklist",
  //   description: "Checklist",
  //   icon: "square-check",
  //   matches: (node) =>
  //     node.type === ELEMENT_PARAGRAPH &&
  //     ["checked", "unchecked"].includes(node.listStyleType as string),
  //   onSelect: (editor) => {
  //     toggleNodeType(editor, { activeType: ELEMENT_PARAGRAPH });
  //     editor.setNodes({
  //       // @ts-ignore
  //       indent: 1,
  //       listStyleType: "unchecked",
  //     });
  //   },
  // },
];

const defaultItem = items.find((item) => item.value === ELEMENT_PARAGRAPH)!;

export function TurnIntoDropDownMenu(props: DropdownMenuProps) {
  const editor = useOdoEditorRef();
  const openState = useOpenState();
  const [filteredItems, setFilteredItems] = React.useState(items);

  useEffect(() => {
    if (!openState.open) {
      return;
    }
    const isInTable = isSelectionInTable(editor);
    setFilteredItems(
      items.filter((item) => !isInTable || item.supportedInTables)
    );
  }, [openState.open, editor]);

  let selectedValue: string = "paragraph";
  const entry = findNode<TElement>(editor!, {
    at: editor?.selection?.focus.path,
    match: (n) => isBlock(editor, n),
  });
  if (entry) {
    selectedValue =
      filteredItems.find((item) => item.matches(entry[0]))?.icon ?? "paragraph";
  }

  const selectedItem =
    filteredItems.find((item) => item.icon === selectedValue) ?? defaultItem;
  const { label: selectedItemLabel } = selectedItem;

  return (
    <DropdownMenu modal={false} {...openState} {...props}>
      <DropdownMenuTrigger asChild>
        <span className="p-sm flex gap-sm items-center text-sm">
          {selectedItemLabel}
          <Icon name="chevron-down" />
        </span>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="start" className="min-w-0">
        <DropdownMenuRadioGroup
          className="flex flex-col gap-0.5"
          value={selectedValue}
          onValueChange={(icon) => {
            const item = filteredItems.find((item) => item.icon === icon)!;
            item.onSelect(editor);

            collapseSelection(editor);
            focusEditor(editor);
          }}
        >
          {filteredItems.map(({ value: itemValue, label, icon }) => (
            <DropdownMenuRadioItem
              key={icon}
              value={icon}
              className="min-w-[180px]"
            >
              <div className="text-center ml-[-4px] pr-xs">
                <Icon name={icon} />
              </div>
              {label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
