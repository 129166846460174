import { isDeletedElementStart, isDeletedText, isSuggestedElementSplit, isSuggestedElementUpdate, isSuggestedTextDelete, isSuggestedTextFormat, isSuggestedTextInsert, isSuggestedTextSplit, isSuggestedElementInsert, MARK_SUGGESTION, isDeletedBlockElement, isSuggestedElementReplace, isSuggestedElementDelete, } from "./types/index.js";
import { getSuggestionIdFromKey, getSuggestionKeyFromId, getSuggestionKeys, } from "./utils.js";
import { findNode, isText } from "@udecode/plate";
import { isElement } from "@udecode/plate-common";
class RestorationState {
    constructor(cursor, isAtEnd) {
        this.cursor = cursor;
        this.isAtEnd = isAtEnd;
    }
    get isReferencingElement() {
        return Array.isArray(this.cursor);
    }
    get path() {
        if (Array.isArray(this.cursor)) {
            return this.cursor;
        }
        else {
            return this.cursor.path;
        }
    }
    get parentPath() {
        return this.path.slice(0, -1);
    }
    setOffset(offset) {
        if (Array.isArray(this.cursor)) {
            this.cursor = {
                path: this.cursor,
                offset,
            };
        }
        else {
            this.cursor.offset = offset;
        }
    }
    moveToNextSibling() {
        if (Array.isArray(this.cursor)) {
            this.cursor[this.cursor.length - 1]++;
        }
        else {
            this.cursor.path[this.cursor.path.length - 1]++;
        }
    }
    /**
     * Move cursor to point directly at parent (without offset)
     */
    moveToParent() {
        this.cursor = this.path.slice(0, -1);
    }
    appendPath(index) {
        if (Array.isArray(this.cursor)) {
            this.cursor.push(index);
        }
        else {
            this.cursor.path.push(index);
        }
    }
}
export class SuggestionResolver {
    constructor(editor) {
        this.editor = editor;
    }
    acceptSuggestion(id) {
        const key = getSuggestionKeyFromId(id);
        this.editor.startNewUndoItem();
        this.editor.withoutNormalizing(() => {
            for (const [node, path] of this.getSuggestionNodes(key)) {
                if (this.resolveSuggestedElementDelete(path[0] - 1, true)) {
                    continue;
                }
                if (isSuggestedTextDelete(node)) {
                    this.acceptDeletedText(path);
                }
                else if (isSuggestedElementDelete(node)) {
                    this.acceptSuggestedElementDelete(path, node);
                }
                else if (isSuggestedTextSplit(node)) {
                    this.acceptTextSplit(path);
                }
                else {
                    this.editor.unsetNodes(key, { at: path });
                }
            }
            this.clearOrphanedSuggestionMarks();
        });
    }
    rejectSuggestion(id) {
        const key = getSuggestionKeyFromId(id);
        this.editor.startNewUndoItem();
        this.editor.withoutNormalizing(() => {
            // Do a first pass just updating elements
            for (const [node, path] of this.getSuggestionNodes(key, false, isSuggestedElementUpdate)) {
                if (this.resolveSuggestedElementDelete(path[0] - 1, false)) {
                    continue;
                }
                this.rejectElementUpdate(node, path, key);
            }
            // Do a second pass doing everything else but text wraps
            for (const [node, path] of this.getSuggestionNodes(key, false)) {
                if (this.resolveSuggestedElementDelete(path[0] - 1, false)) {
                    continue;
                }
                if (path[0] > 0) {
                    const previousElement = this.editor.node([path[0] - 1])[0];
                    if (isSuggestedElementDelete(previousElement)) {
                        this.rejectSuggestedElementDelete([path[0] - 1], previousElement);
                        continue;
                    }
                }
                if (isSuggestedElementSplit(node)) {
                    this.rejectElementSplit(path);
                }
                else if (isSuggestedElementReplace(node)) {
                    this.rejectElementReplace(node, path);
                }
                else if (isSuggestedElementInsert(node)) {
                    this.rejectSuggestedElementInsert(path);
                }
                else if (isSuggestedElementDelete(node)) {
                    this.rejectSuggestedElementDelete(path, node);
                }
                else if (isSuggestedTextFormat(node)) {
                    this.rejectTextFormat(node, path, key);
                }
                else if (isSuggestedTextSplit(node)) {
                    this.rejectTextSplit(path, key);
                }
                else if (isSuggestedTextDelete(node)) {
                    this.rejectTextDelete(node, path);
                }
                else if (isSuggestedTextInsert(node)) {
                    this.rejectTextInsert(node, path);
                }
                else {
                    this.editor.unsetNodes(key, { at: path });
                }
            }
            this.clearAllProcessedDeletions(key);
            this.clearOrphanedSuggestionMarks();
        });
    }
    acceptAll() {
        const keys = new Set();
        for (const [node, path] of this.editor.nodes({ at: [], reverse: true })) {
            for (const key of getSuggestionKeys(node)) {
                keys.add(key);
            }
        }
        const acceptedIds = new Set();
        for (const key of keys) {
            const id = getSuggestionIdFromKey(key);
            this.acceptSuggestion(id);
            acceptedIds.add(id);
        }
        return acceptedIds;
    }
    rejectAll() {
        const keys = new Set();
        for (const [node, path] of this.editor.nodes({ at: [], reverse: true })) {
            for (const key of getSuggestionKeys(node)) {
                keys.add(key);
            }
        }
        const rejectedIds = new Set();
        for (const key of keys) {
            const id = getSuggestionIdFromKey(key);
            this.rejectSuggestion(id);
            rejectedIds.add(id);
        }
        return rejectedIds;
    }
    /**
     * Special case: At least temporarily, delete elements show up at the beginning of the element
     * after it. So we don't show accept/reject for it but instead resolve it at the same time as
     * suggestions in the following block
     */
    resolveSuggestedElementDelete(index, accept) {
        if (index <= 0)
            return false;
        const element = this.editor.node([index])[0];
        if (isSuggestedElementDelete(element)) {
            if (accept) {
                this.acceptSuggestedElementDelete([index], element);
            }
            else {
                this.rejectSuggestedElementDelete([index], element);
            }
            return true;
        }
        return false;
    }
    rejectTextFormat(leaf, path, key) {
        const attributes = leaf.suggestion_meta.attributes;
        // @ts-ignore
        this.editor.setNodes(attributes, { at: path });
        this.editor.unsetNodes(key, { at: path });
    }
    rejectTextSplit(path, key) {
        // Deletes the text node indicating the split
        this.editor.removeNodes({ at: path });
        let parentPath = path.slice(0, -1);
        if (path[path.length - 1] === 0) {
            // This is at the beginning of its parent -> merge with previous
            this.editor.mergeNodes({ at: parentPath });
        }
        else {
            let parent = this.editor.node(parentPath)[0];
            if (!isElement(parent)) {
                throw new Error("Parent is not an element");
            }
            // Check if the path was the last element (it was already deleted)
            if (path[path.length - 1] === parent.children.length) {
                // This is at the end of its parent -> merge with next
                parentPath[parentPath.length - 1]++;
                if (this.editor.hasPath(parentPath)) {
                    this.editor.mergeNodes({ at: parentPath });
                }
            }
            else {
                // This is in the middle of its parent -> do nothing
            }
        }
    }
    acceptDeletedText(path) {
        this.editor.removeNodes({ at: path });
    }
    acceptTextSplit(path) {
        this.editor.removeNodes({ at: path });
    }
    acceptSuggestedElementDelete(path, element) {
        this.editor.removeNodes({ at: path });
    }
    rejectSuggestedElementDelete(path, element) {
        let nextPath = [path[0] + 1];
        for (const next of element.suggestion_meta.deleted) {
            this.editor.insertNodes(next, { at: nextPath });
            nextPath[0]++;
        }
        this.editor.removeNodes({ at: path });
    }
    /**
     * See the comment on [[SuggestedTextDelete]] for an explanation of how this works
     */
    rejectTextDelete(text, path) {
        // @ts-ignore
        this.editor.setNodes({ processed: true }, { at: path });
        // We are rejecting a deletion suggestion, so we need to
        // insert the content that was deleted
        let restorationState = new RestorationState(this.editor.point(path, { edge: "end" }), true);
        // Loop through the set of "instructions" of what needs to be restored
        // As we loop through, we maintain `restorationState` which is essentially a cursor
        // of where the next content should be restored to. After a restoration is done, it is also
        // expected to move the cursor (restorationState) to the end of the restored content
        for (const [index, child] of text.suggestion_meta.deleted.entries()) {
            if (isDeletedText(child)) {
                restorationState = this.handleDeletedText(restorationState, child);
            }
            else if (isDeletedBlockElement(child)) {
                restorationState = this.handleDeletedBlockElement(restorationState, child);
            }
            else if (isDeletedElementStart(child)) {
                restorationState = this.handleDeletedElement(restorationState, child);
            }
        }
        this.editor.normalize();
    }
    handleDeletedText(state, component) {
        // Insert the text
        this.editor.insertNodes(component, { at: state.cursor });
        // Move the cursor to the end of it
        if (state.isAtEnd) {
            // The new text got inserted after where the cursor currently is so we need
            // to increment it by 1
            state.moveToNextSibling();
        }
        else {
            // The new text got inserted in place of where the cursor currently is so we
            // don't need to change the path
        }
        state.setOffset(component.text.length);
        state.isAtEnd = true;
        return state;
    }
    handleDeletedElement(state, component) {
        // Split the existing element and move any remaining children into the specified new element
        let parent = this.editor.node(state.parentPath)[0];
        if (!isElement(parent)) {
            throw new Error("Parent is not an element");
        }
        // 1. Generate the new element with copies of the remaining children
        // deleting the old children from the current element along the way
        const startedElementChildrenCount = component.children.length;
        let newElement = Object.assign(Object.assign({}, component), { children: [...component.children] });
        let childStartIndex;
        if (state.isReferencingElement) {
            // If we are referencing an element, we need to start at the current element
            childStartIndex = state.path[state.path.length - 1];
        }
        else {
            childStartIndex = state.path[state.path.length - 1] + 1;
        }
        for (let i = childStartIndex; i < parent.children.length; i++) {
            let childPath = state.parentPath.concat([childStartIndex]);
            let child = parent.children[i];
            let childCopy = JSON.parse(JSON.stringify(child));
            newElement.children.push(childCopy);
            this.editor.removeNodes({ at: childPath });
        }
        if (newElement.children.length === 0) {
            newElement.children.push({ text: "" });
        }
        // 2. Insert the new element
        if (state.isReferencingElement) {
            // We need to insert this new element and move any leftover children into it
            const newElementPath = [state.path[0] + 1];
            this.editor.insertNodes(newElement, {
                at: newElementPath,
            });
        }
        else {
            // Split the existing element
            this.editor.insertNodes(newElement, { at: state.cursor });
        }
        // 4. Update the cursor
        state.moveToParent();
        state.moveToNextSibling();
        if (startedElementChildrenCount > 0) {
            // There were existing children so move the cursor to the end of them
            state.appendPath(startedElementChildrenCount - 1);
            state.setOffset(newElement.children[startedElementChildrenCount - 1].text.length);
            state.isAtEnd = true;
        }
        else {
            // Place cursor right at the beginning of the new element
            state.appendPath(0);
            state.setOffset(0);
            state.isAtEnd = false;
        }
        return state;
    }
    handleDeletedBlockElement(state, component) {
        // Add the children of this block to the existing element and move any remaining children into the specified new
        // element
        //
        // Note: This implementation is close to restoring text than restoring an element. Restoring a plain element means
        // starting a new element, but restoring a block element means adding the children of the block element to the
        // existing element (just like text).
        let parent = this.editor.node(state.parentPath)[0];
        if (!isElement(parent)) {
            throw new Error("Parent is not an element");
        }
        // 1. Generate copies of the children
        for (let i = 0; i < component.children.length; i++) {
            let childCopy = JSON.parse(JSON.stringify(component.children[i]));
            state.cursor = state.path;
            state.moveToNextSibling();
            this.editor.insertNodes(childCopy, {
                at: state.cursor,
                hanging: false,
            });
        }
        state.moveToNextSibling();
        return state;
    }
    clearAllProcessedDeletions(key) {
        for (const [node, path] of this.getSuggestionNodes(key, true, isSuggestedTextDelete)) {
            // @ts-ignore
            this.editor.removeNodes({ at: path });
        }
    }
    rejectElementSplit(path) {
        if (path[path.length - 1] > 0) {
            this.editor.mergeWithPrevious(path);
        }
        else {
            this.editor.unsetNodes(MARK_SUGGESTION, { at: path });
        }
    }
    rejectSuggestedElementInsert(path) {
        this.editor.removeNodes({ at: path });
    }
    rejectElementReplace(element, path) {
        const old = element.suggestion_meta.old;
        this.editor.removeNodes({ at: path });
        this.editor.insertNodes(old, { at: path });
    }
    rejectTextInsert(element, path) {
        this.editor.removeNodes({ at: path });
    }
    rejectElementUpdate(element, path, key) {
        this.editor.setNodes(element.suggestion_meta.attributes, { at: path });
        if (isSuggestedElementSplit(element)) {
            let meta = Object.assign({}, element.suggestion_meta.attributes);
            delete meta.attributes;
            // @ts-ignore
            this.editor.setNodes({ suggestion_meta: meta }, { at: path });
        }
        else {
            this.editor.unsetNodes(key, { at: path });
        }
    }
    clearOrphanedSuggestionMarks() {
        // Clear leafs first
        this.editor.unsetNodes(MARK_SUGGESTION, {
            at: [],
            match: (node) => {
                return (isText(node) &&
                    // @ts-ignore
                    !!node[MARK_SUGGESTION] &&
                    getSuggestionKeys(node).size === 0);
            },
        });
        this.editor.unsetNodes("suggestion_meta", {
            at: [],
            match: (node) => {
                return (isText(node) &&
                    // @ts-ignore
                    !!node["suggestion_meta"] &&
                    getSuggestionKeys(node).size === 0);
            },
        });
        // Clear elements next
        this.editor.unsetNodes(MARK_SUGGESTION, {
            at: [],
            match: (node) => {
                return (
                // @ts-ignore
                !!node[MARK_SUGGESTION] && getSuggestionKeys(node).size === 0);
            },
        });
        this.editor.unsetNodes("suggestion_meta", {
            at: [],
            match: (node) => {
                return (
                // @ts-ignore
                !!node["suggestion_meta"] && getSuggestionKeys(node).size === 0);
            },
        });
    }
    *getSuggestionNodes(key, processed = false, additionalMatch) {
        while (true) {
            let next = findNode(this.editor, {
                at: [],
                reverse: true,
                match: (node) => {
                    var _a;
                    if (!!node[key] && ((_a = node.processed) !== null && _a !== void 0 ? _a : false) === processed) {
                        if (additionalMatch) {
                            return additionalMatch(node);
                        }
                        return true;
                    }
                    return false;
                },
            });
            if (!next) {
                break;
            }
            yield next;
        }
    }
}
