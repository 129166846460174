import { isElement } from "@udecode/plate-common";
import { v4 } from "uuid";
import { isOdoHeading } from "./types.js";
import lodash from "lodash";
const { cloneDeep } = lodash;
const withHeadingIds = (editor) => {
    const { normalizeNode, getFragment } = editor;
    editor.normalizeNode = ([node, path]) => {
        // Ensure every header has an id
        normalizeNode([node, path]);
        if (isElement(node) && node.type.startsWith("h")) {
            if (!node.id) {
                // @ts-ignore
                editor.setNodes({ id: v4() }, { at: path });
            }
        }
    };
    editor.getFragment = () => {
        const fragment = getFragment();
        const newFragement = [];
        fragment.forEach((node) => {
            if (isOdoHeading(node)) {
                const newNode = cloneDeep(node);
                delete newNode.id;
                delete newNode.requirements;
                delete newNode.blocks;
                newFragement.push(newNode);
                return;
            }
            newFragement.push(node);
        });
        return newFragement;
    };
    return editor;
};
export default withHeadingIds;
