import Button from "components/common/Button";
import NavigationView from "components/common/NavigationView";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import {
  TableCell,
  TableHeaderCell,
  TableRow,
} from "components/common/containers/Table";
import Overlay from "components/common/containers/overlays/Overlay";
import BasicForm from "components/common/forms/BasicForm";
import useFileHash from "hooks/useFileHash";
import useTriggerDownload from "hooks/useTriggerDownload";
import { format_datetime } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";

interface PastProposal {
  id: string;
  name: string;
  created: string;
  isProcessed: boolean;
}

const PastProposalsRoute = () => {
  const apiClient = useApiClient();
  const triggerDownload = useTriggerDownload();
  const [proposals, setProposals] = useState<PastProposal[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isAdding, setIsAdding] = useState(false);
  const { isStaff } = useAuthenticatedUser();
  const hashFile = useFileHash();

  const refresh = useCallback(async () => {
    try {
      const response = await apiClient.rfp.rfpProposalPastListList();
      const proposals: PastProposal[] = response.data.map((proposal) => ({
        id: proposal.id!,
        name: proposal.name!,
        created: proposal.created!,
        isProcessed: proposal.is_processed!,
      }));
      setProposals(proposals);
    } catch (e) {
      setError(message_from_exception(e));
    }
  }, [apiClient, setProposals, setError]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const getDetials = async (id: string) => {
    try {
      const response = await apiClient.rfp.rfpProposalPastRead(id);
      return response.data;
    } catch (e) {
      const message = message_from_exception(e);
      toast.error(message, { position: "bottom-center" });
      return null;
    }
  };

  const handleView = async (id: string) => {
    const details = await getDetials(id);
    if (!details) {
      return;
    }

    if (!details.view_url) {
      toast.error("No PDF available", { position: "bottom-center" });
      return;
    }

    triggerDownload(details.view_url, "tab");
  };

  const handleViewMarkdown = async (id: string) => {
    const details = await getDetials(id);
    if (!details) {
      return;
    }
    if (!details.markdown_url) {
      toast.error("No markdown available", { position: "bottom-center" });
      return;
    }
    triggerDownload(details.markdown_url);
  };

  return (
    <NavigationView selected="past-proposals">
      <h1 className="text-2xl font-semibold w-full mb-md">Past Proposals</h1>
      <AsyncLoadedDiv
        className="w-full"
        value={proposals}
        error={error}
        whileLoaded={(proposals) => (
          <table className="w-full grow">
            <thead>
              <TableRow variant="header">
                <TableHeaderCell>Name</TableHeaderCell>
                <TableHeaderCell variant="minimumWidth">
                  Created
                </TableHeaderCell>
                <TableHeaderCell variant="minimumWidth">View</TableHeaderCell>
                <TableHeaderCell variant="minimumWidth">
                  Processed
                </TableHeaderCell>
                {isStaff && (
                  <TableHeaderCell variant="minimumWidth">
                    Markdown
                  </TableHeaderCell>
                )}
              </TableRow>
            </thead>
            <tbody>
              {proposals.map((proposal) => (
                <TableRow key={proposal.id}>
                  <TableCell>{proposal.name}</TableCell>
                  <TableCell variant="minimumWidth">
                    {format_datetime(proposal.created)}
                  </TableCell>
                  <TableCell variant="minimumWidth">
                    <Button
                      text="View"
                      onClick={() => handleView(proposal.id)}
                    />
                  </TableCell>
                  <TableCell variant="minimumWidth">
                    {proposal.isProcessed ? "Yes" : "No"}
                  </TableCell>
                  {isStaff && (
                    <TableCell variant="minimumWidth">
                      <Button
                        text="Markdown"
                        onClick={() => handleViewMarkdown(proposal.id)}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {proposals.length === 0 && (
                <TableRow>
                  <TableCell colSpan={999} className="text-center">
                    No past proposals
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={999} className="text-center">
                  <Button
                    text="Add"
                    icon="plus"
                    variant="solid"
                    onClick={() => setIsAdding(true)}
                  />
                </TableCell>
              </TableRow>
            </tbody>
          </table>
        )}
      />
      <Overlay
        title="Add Past Proposal"
        maxWidth={600}
        open={isAdding}
        onClose={() => setIsAdding(false)}
      >
        <BasicForm
          initialModel={{
            name: "",
            file: "",
          }}
          inputNames={{
            name: "Name",
            file: "File",
          }}
          inputTypes={{
            name: "text",
            file: "file",
          }}
          onSubmit={async (model) => {
            if (model.name === "") {
              throw new Error("Name is required");
            }

            const file = model.file as any as File;
            const hash = await hashFile(file);

            // Get name without extension
            const createResponse = await apiClient.rfp.rfpProposalPastCreate({
              hash,
              name: model.name,
            });

            // Upload file
            await fetch(createResponse.data.upload_url!, {
              method: "PUT",
              body: file,
            });

            // Confirm the upload
            await apiClient.rfp.rfpProposalPastConfirmCreate(
              createResponse.data.id!
            );
            setIsAdding(false);
            refresh();
          }}
          submitText="Add"
          submitIcon="plus"
        />
      </Overlay>
    </NavigationView>
  );
};

export default PastProposalsRoute;
