export function isOdoEditor(editor) {
    if (!editor) {
        return false;
    }
    return editor.isOdoEditor === true;
}
export function isOdoElement(obj) {
    return obj && typeof obj === "object" && obj.children !== undefined;
}
export function isOdoText(obj) {
    return obj && typeof obj === "object" && obj.text !== undefined;
}
export function isOdoP(obj) {
    return obj && typeof obj === "object" && obj.type === "p";
}
export function isOdoH1(obj) {
    return obj && typeof obj === "object" && obj.type === "h1";
}
export function isOdoH2(obj) {
    return obj && typeof obj === "object" && obj.type === "h2";
}
export function isOdoH3(obj) {
    return obj && typeof obj === "object" && obj.type === "h3";
}
export function isOdoH4(obj) {
    return obj && typeof obj === "object" && obj.type === "h4";
}
export function isOdoH5(obj) {
    return obj && typeof obj === "object" && obj.type === "h5";
}
export function isOdoH6(obj) {
    return obj && typeof obj === "object" && obj.type === "h6";
}
export function levelFromOdoHeading(obj) {
    if (isOdoH1(obj)) {
        return 1;
    }
    if (isOdoH2(obj)) {
        return 2;
    }
    if (isOdoH3(obj)) {
        return 3;
    }
    if (isOdoH4(obj)) {
        return 4;
    }
    if (isOdoH5(obj)) {
        return 5;
    }
    if (isOdoH6(obj)) {
        return 6;
    }
    return 0;
}
export function isOdoHeading(obj) {
    return obj && typeof obj === "object" && obj.type && obj.type[0] === "h";
}
export function getSectionInfoFromElement(obj) {
    if (isOdoHeading(obj) && "id" in obj && obj.id) {
        return {
            id: obj.id,
            name: textFromOdoElement(obj),
            requirements: obj.requirements || [],
            blocks: obj.blocks || [],
            level: levelFromOdoHeading(obj),
            status: obj.status || null,
            statusName: obj.statusName || null,
        };
    }
    if (isOdoRefine(obj)) {
        if (obj.children.length > 0) {
            const firstChild = obj.children[0];
            return getSectionInfoFromElement(firstChild);
        }
        else if (obj.old.length > 0) {
            const firstChild = obj.old[0];
            return getSectionInfoFromElement(firstChild);
        }
    }
    return null;
}
export function isOdoCodeBlock(obj) {
    return obj && typeof obj === "object" && obj.type === "code_block";
}
export function isOdoBlockQuote(obj) {
    return obj && typeof obj === "object" && obj.type === "blockquote";
}
export function isOdoLink(obj) {
    return obj && typeof obj === "object" && obj.type === "a";
}
export function isOdoMention(obj) {
    return obj && typeof obj === "object" && obj.type === "mention";
}
export function isOdoTR(obj) {
    return obj && typeof obj === "object" && obj.type === "tr";
}
export function isOdoImg(obj) {
    return obj && typeof obj === "object" && obj.type === "img" && !!obj.url;
}
export function isOdoTable(obj) {
    return obj && typeof obj === "object" && obj.type === "table";
}
export function isOdoRefine(obj) {
    return obj && typeof obj === "object" && obj.type === "refine";
}
export const textFromOdoElement = (element) => {
    if ("text" in element) {
        return element.text;
    }
    if ("children" in element) {
        let text = "";
        for (const child of element.children) {
            text += textFromOdoElement(child) + "\n";
        }
        return text;
    }
    return "";
};
