import React from "react";
import {
  createNodeHOC,
  createNodesHOC,
  PlaceholderProps,
  usePlaceholderState,
} from "@udecode/plate-common";
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from "@udecode/plate-heading";

import { cn } from "lib/utils";
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import {
  ELEMENT_LI,
  ELEMENT_LIC,
  ELEMENT_OL,
  ELEMENT_TODO_LI,
  ELEMENT_UL,
} from "@udecode/plate-list";

export const Placeholder = (props: PlaceholderProps) => {
  const { children, placeholder, nodeProps } = props;

  const { enabled } = usePlaceholderState(props);

  return React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      className: child.props.className,
      nodeProps: {
        ...nodeProps,
        className: cn(
          enabled &&
            "before:absolute before:cursor-text before:opacity-30 before:content-[attr(placeholder)]"
        ),
        placeholder,
      },
    });
  });
};

export const withPlaceholder = createNodeHOC(Placeholder);
export const withPlaceholdersPrimitive = createNodesHOC(Placeholder);

export const withPlaceholders = (components: any) =>
  withPlaceholdersPrimitive(components, [
    {
      keys: [
        ELEMENT_BLOCKQUOTE,
        ELEMENT_UL,
        ELEMENT_OL,
        ELEMENT_LI,
        ELEMENT_LIC,
        ELEMENT_TODO_LI,
      ],
      placeholder: "Write something or type / for commands...",
      hideOnBlur: true,
    },
    {
      key: ELEMENT_H1,
      placeholder: "Write a new section or type / for commands...",
      hideOnBlur: true,
    },
    {
      keys: [ELEMENT_H2, ELEMENT_H3],
      placeholder: "Write a new heading or type / for commands...",
      hideOnBlur: true,
    },
  ]);
