import { TElement, useEditorRef } from "@udecode/plate-common";
import { ReactEditor } from "slate-react";

export const useIsInRefine = (element: TElement) => {
  const editor = useEditorRef();
  if (editor.id === "nested") return true;

  const path = ReactEditor.findPath(editor as any, element);

  if (!path) return false;
  if (path.length === 0) return false;

  const rootPath = [path[0]];
  const [rootElement] = editor.node(rootPath);
  return rootElement && "type" in rootElement && rootElement.type === "refine";
};
