import { createPluginFactory } from "@udecode/plate-common";
import {
  KEY_SLASH_COMMANDS,
  SlashCommandsPlugin,
} from "lib/plate/plugins/slash-commands/types";

const createSlashCommandsPlugin = createPluginFactory<SlashCommandsPlugin>({
  key: KEY_SLASH_COMMANDS,
  then: (_, { key, options: { trigger } }) => ({
    options: {
      id: key,
      trigger,
    },
  }),
});

export default createSlashCommandsPlugin;
