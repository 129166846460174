import { VariantProps, cva } from "class-variance-authority";
import useDarkMode from "../../hooks/useDarkMode";
import { FC, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";

const logoVariants = cva("mx-auto", {
  variants: {
    size: {
      small: "h-[19px]",
      medium: "h-[40px]",
    },
  },
  defaultVariants: {
    size: "medium",
  },
});

export type LogoVariant = VariantProps<typeof logoVariants>;

interface OdoLogoProps extends LogoVariant {
  includeText?: boolean;
  path?: string;
  className?: string;
}

const OdoLogo: FC<OdoLogoProps> = ({ path, includeText, size, className }) => {
  const navigate = useNavigate();
  const isDarkMode = useDarkMode();

  const handleLogoClick = (e: MouseEvent) => {
    e.preventDefault();
    if (path) {
      navigate(path);
    } else {
      window.location.href = "https://odo.do";
    }
  };

  return (
    <>
      <a
        onClick={handleLogoClick}
        href={path ?? "https://odo.do"}
        tabIndex={-1}
        className={className}
      >
        <img
          src={isDarkMode ? "/logo-dark-mode.svg" : "/logo.svg"}
          className={logoVariants({ size })}
          alt="Odo"
        />
      </a>
      {includeText && (
        <p className="text-center text-md mt-[-15px]">
          Win More Government Bids
        </p>
      )}
    </>
  );
};

export default OdoLogo;
