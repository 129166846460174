import { AdminOrgDetails, Org } from "api/Api";
import ComparisonTableView from "components/admin/ComparisonTableView";
import Button from "components/common/Button";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Columns from "components/common/containers/Columns";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Rows from "components/common/containers/Rows";
import {
  TableCell,
  TableHeaderCell,
  TableRow,
} from "components/common/containers/Table";
import { useOverlayContext } from "components/common/containers/overlays/OverlayContextProvider";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import usePaginatedData from "hooks/usePaginatedData";
import { useConfirm } from "providers/AlertProvider";
import { useApiClient } from "providers/ApiClientProvider";
import { useCallback, useEffect, useState } from "react";
import { message_from_exception } from "utils";

interface OrgDetailsViewProps {
  org: Org;
  onDeleted: () => void;
}

const OrgDetailsView = ({ org, onDeleted }: OrgDetailsViewProps) => {
  const apiClient = useApiClient();
  const [details, setDetails] = useState<AdminOrgDetails | null>(null);
  const [comparisons, , paginatedData] = usePaginatedData({
    endpoint: async (options) => {
      return await apiClient.rfp.rfpComparisonByOrgRead(
        org.public_id!,
        options
      );
    },
    map: (remote) => remote,
    deps: [org.public_id],
  });
  const [error, setError] = useState<string | null>(null);
  const confirm = useConfirm();
  const { dismiss } = useOverlayContext();

  const refresh = useCallback(async () => {
    try {
      setError(null);
      const repsonse = await apiClient.orgs.orgsRead(org.public_id!);
      setDetails(repsonse.data);
    } catch (e) {
      setError(message_from_exception(e));
    }
  }, [apiClient.orgs, org.public_id]);

  useEffect(() => {
    refresh();
  }, [apiClient.orgs, org.public_id]);

  const handleDelete = async () => {
    const confirmed = await confirm("Are you sure?", {
      yesText: `Delete ${details!.name}`,
      yesDestructive: true,
    });

    if (confirmed) {
      try {
        await apiClient.orgs.orgsDelete(org.public_id!);
        onDeleted();
        dismiss?.();
      } catch (error) {
        setError(message_from_exception(error));
      }
    }
  };

  return (
    <AsyncLoadedDiv
      value={details}
      error={error}
      whileLoaded={(details) => {
        return (
          <Rows className="gap-md">
            <LabeledFormInput label="Users">
              <table className="w-full">
                <thead>
                  <TableRow variant="header">
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell variant="minimumWidth">
                      Email
                    </TableHeaderCell>
                  </TableRow>
                </thead>
                <tbody>
                  {details.members.map((user) => (
                    <TableRow key={user.public_id!}>
                      <TableCell>{user.display_name}</TableCell>
                      <TableCell variant="minimumWidth">{user.email}</TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </table>
              <Columns className="shrink-0 justify-end">
                <Button
                  icon="xmark"
                  text="Delete"
                  className="shrink-0"
                  variant="destructive"
                  onClick={handleDelete}
                />
              </Columns>
            </LabeledFormInput>
            <LabeledFormInput label="Comparisons">
              <ComparisonTableView
                differentiating={{ column: "RFP", id: org.public_id! }}
                results={comparisons}
                paginatedData={paginatedData}
              />
            </LabeledFormInput>
          </Rows>
        );
      }}
    />
  );
};

export default OrgDetailsView;
