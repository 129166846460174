import Button from "components/common/Button";
import { PastProposalList } from "api/Api";
import { useApiClient } from "providers/ApiClientProvider";
import { format_date } from "lib/utils";
import Spinner from "components/common/Spinner";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";
import useFileHash from "hooks/useFileHash";
import { useState } from "react";
import { useConfirm } from "providers/AlertProvider";
import Icon from "components/common/Icon";
import MultiFileInput from "components/common/forms/MultiFileInput";
import useTriggerDownload from "hooks/useTriggerDownload";
import Rows from "components/common/containers/Rows";

interface UploadPastProposalsStepProps {
  onFinished: () => void;
}

const UploadPastProposalsStep: React.FC<UploadPastProposalsStepProps> = ({
  onFinished,
}) => {
  const apiClient = useApiClient();
  const triggerDownload = useTriggerDownload();
  const confirm = useConfirm();
  const hashFile = useFileHash();
  const [fileCount, setFileCount] = useState(0);
  const processFile = async (file: File) => {
    const hash = await hashFile(file);

    // Get file name without extension
    const name = file.name.split(".").slice(0, -1).join(".");

    // Create the proposal record
    const createResponse = await apiClient.rfp.rfpProposalPastCreate({
      name,
      hash,
    });

    // Upload the file
    await fetch(createResponse.data.upload_url!, {
      method: "PUT",
      body: file,
    });

    // Confirm the upload
    await apiClient.rfp.rfpProposalPastConfirmCreate(createResponse.data.id!);

    return {
      id: createResponse.data.id!,
      name: file.name,
      created: new Date().toISOString(),
      is_uploaded: true,
    };
  };

  const getDetials = async (id: string) => {
    try {
      const response = await apiClient.rfp.rfpProposalPastRead(id);
      return response.data;
    } catch (e) {
      const message = message_from_exception(e);
      console.error("Error getting details", message);
      toast.error(message, { position: "bottom-center" });
      return null;
    }
  };

  const handleView = async (id: string) => {
    const details = await getDetials(id);
    if (!details) {
      return;
    }

    if (!details.view_url) {
      toast.error("No PDF available", { position: "bottom-center" });
      return;
    }

    triggerDownload(details.view_url, "tab");
  };

  const handleDone = async () => {
    if (fileCount < 3) {
      const addMore = await confirm("More Past Proposals?", {
        body: "Upload three or more past proposals to generate a high quality proposal draft.",
        yesText: "Add More",
        noText: "Skip",
        defaultYes: true,
      });
      if (!addMore) {
        onFinished();
      }
    } else {
      onFinished();
    }
  };

  return (
    <Rows className="grow w-full flex flex-col gap-md items-center justify-center relative">
      <Icon name="folder-arrow-up" size="huge" variant="solid" />
      <h1 className="text-2xl font-semibold">Upload Past Proposals</h1>
      <p className="text-sm text-secondary mb-8">
        Upload three or more past proposals to generate a high quality proposal
        draft
      </p>
      <MultiFileInput<PastProposalList & { id: string }>
        className="min-w-[300px] transition-w"
        validFileTypes={["application/pdf"]}
        onFilesChanged={(files) =>
          setFileCount(
            files.filter((f) => "is_uploaded" in f && f.is_uploaded).length
          )
        }
        renderFile={(proposal) => (
          <div className="flex">
            <div className="flex flex-col">
              <p>{proposal.name}</p>
              <p className="text-sm text-secondary">
                Uploaded: {format_date(proposal.created!)}
              </p>
            </div>
            <div className="grow" />
            {proposal.is_uploaded && (
              <Button
                icon="eye"
                className="ml-sm"
                onClick={() => handleView(proposal.id)}
              />
            )}
          </div>
        )}
        renderPendingFile={(proposal) => (
          <>
            <p className="text-secondary">{proposal.name}</p>
            <div className="flex gap-xs">
              <p className="text-sm text-secondary">Uploading...</p>
              <Spinner size="inherit" />
            </div>
          </>
        )}
        processFile={processFile}
        loadInitialFiles={async () => {
          const response = await apiClient.rfp.rfpProposalPastListList();
          return response.data
            .map((proposal) => ({
              ...proposal,
              id: proposal.id!,
            }))
            .sort((a, b) => {
              return (
                new Date(b.created!).getTime() - new Date(a.created!).getTime()
              );
            });
        }}
      />
      <div className="h-[40px]" />
      <Button
        text="Done"
        variant="solid"
        className="absolute right-0 bottom-0"
        onClick={handleDone}
        disabled={fileCount === 0}
      />
    </Rows>
  );
};

export default UploadPastProposalsStep;
