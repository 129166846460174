import { useActiveComment, useComments } from "providers/CommentsProvider";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import {
  Comment,
  MARK_COMMENT,
  getCommentIdFromKey,
  getCommentKeys,
} from "odo";
import { useEditorRef } from "@udecode/plate";
import { topOffsetFromRefs } from "lib/utils";
import { useEditorDocData } from "../../providers/RequirementContentEditorProvider";

interface CommentLayout {
  threads: Comment[];
  setThreadRef: (id: string, ref: RefObject<HTMLElement> | null) => void;
  threadsContainerRef: RefObject<HTMLElement>;
  areFullCommentsVisible: boolean;
}

interface OrderedThread {
  idealTopOffset: number;
  value: Comment;
}

const commentSpacing = 0;

const useCommentsLayout = (): CommentLayout => {
  const editor = useEditorRef();
  const containerRef = useEditorDocData().containerRef;
  const threadsContainerRef = useRef<HTMLElement | null>(null);
  const activeCommentId = useActiveComment();
  const [pending, all, markRefs] = useComments();
  // The arbitrarily ordered list of visible threads
  // (they will be laid out in absolute positioning inside the same container)
  const [threads, setThreads] = useState<Record<string, Comment>>({});
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const [threadsContainerWidth, setThreadsContainerWidth] = useState<
    number | null
  >(null);

  const areFullCommentsVisible = (threadsContainerWidth ?? 0) > 240;

  // The threads in the order they should be listed and their ideal top offset
  const [orderedThreads, setOrderedThreads] = useState<OrderedThread[] | null>(
    null
  );

  const [orderedComments, setOrderedComments] = useState<Comment[]>([]);

  // The references to the thread elements for checking their height
  const [threadRefs, setThreadRefs] = useState<Record<
    string,
    RefObject<HTMLElement>
  > | null>(null);

  const positionThread = (element: HTMLElement, top: number) => {
    if (element.dataset.pendingFocus === "true") {
      element.dataset.pendingFocus = "false";
      setTimeout(() => {
        element.focus();
      });
    }

    const currentTop = parseInt(element.style.top, 10);
    if (currentTop === top) return;

    element.style.top = top + "px";
    element.style.opacity = "100";

    if (!currentTop || currentTop === 0) {
      setTimeout(() => {
        element.classList.add("transitioning-comment");
      }, 0);
    }
  };

  useEffect(() => {
    if (!containerRef.current) return;

    const observer = new ResizeObserver(() => {
      setContainerWidth(containerRef.current?.clientWidth ?? 0);
    });

    observer.observe(containerRef.current);
    return () => {
      observer.disconnect();
    };
  }, [containerRef]);

  useEffect(() => {
    if (!threadsContainerRef.current) return;

    const observer = new ResizeObserver(() => {
      const clientWidth = threadsContainerRef.current?.clientWidth ?? 0;
      setThreadsContainerWidth(clientWidth);
    });

    observer.observe(threadsContainerRef.current);
    return () => {
      observer.disconnect();
    };
  }, [threadsContainerRef]);

  // Determine which threads should be visible (set threads)
  useEffect(() => {
    const newThreads: Record<string, Comment> = {};
    for (const comment of Object.values(all)) {
      // Don't show resolved comments or those with parents
      if (comment.isResolved || !!comment.parentId) continue;

      // Don't show comments whose marks can't be found
      if (markRefs[comment.id] === undefined) continue;

      newThreads[comment.id] = comment;
    }
    if (pending) {
      newThreads[pending.id] = pending;
    }
    setThreads(newThreads);
  }, [all, pending, markRefs]);

  // Generate ordered threads
  useEffect(() => {
    // Loop through all comment marks in order and create a list of ordered comments with their ideal top offset
    const includedCommentIds: Record<string, boolean> = {};
    const newOrderedThreads: OrderedThread[] = [];
    const newOrderedComments: Comment[] = [];
    const marks = editor.nodes({ at: [], match: (n: any) => n[MARK_COMMENT] });
    for (const mark of marks) {
      const commentKeys = getCommentKeys(mark[0]);
      for (const key of commentKeys) {
        const id = getCommentIdFromKey(key);
        if (includedCommentIds[id]) {
          // This particular comment has already been seen
          // It is critical that a comment only be added to threads once so it doesn't get rendered more than once
          continue;
        }
        const comment = threads[id];
        // Don't show resolved comments
        if (!comment) continue;
        if (comment.isPending && comment.id !== pending?.id) continue;
        includedCommentIds[id] = true;

        const idealTopOffset = topOffsetFromRefs(
          markRefs[comment.id],
          containerRef
        );
        if (idealTopOffset === null) {
          console.warn("Could not find comment ref for comment", comment);
          continue;
        }

        newOrderedThreads.push({ value: comment, idealTopOffset });
        newOrderedComments.splice(0, 0, comment);
      }
    }
    setOrderedThreads(newOrderedThreads);
    setOrderedComments(newOrderedComments);
  }, [
    containerRef,
    editor,
    markRefs,
    threads,
    pending,
    containerWidth,
    threadsContainerWidth,
  ]);

  // Do the actual layout
  useEffect(() => {
    // Ensure the data dependencies have been loaded
    if (
      orderedThreads === null ||
      orderedThreads.length === 0 ||
      threadRefs === null
    )
      return;

    let activeIndex: number | null = null;
    for (const [index, thread] of orderedThreads.entries()) {
      if (!thread.value.id) {
        console.log("no id", thread.value.id);
        return;
      }
      const ref = threadRefs[thread.value.id];
      if (!ref) {
        console.log("no ref", thread.value.id);
        return;
      }
      if (!ref.current) continue;
      ref.current.style.top = "0px";
      if (thread.value.id === activeCommentId) {
        activeIndex = index;
      }
    }

    if (activeIndex === null) {
      // If there is no actual active index, start by ensuring the first thread is positioned at it's ideal
      activeIndex = 0;
    }

    // The ordered threads and their refs have been loaded, we are ready to layout

    // Position the active thread at its ideal position
    const activeThread = orderedThreads[activeIndex];
    const activeElement = threadRefs[activeThread.value.id].current;
    if (!activeElement) {
      return;
    }
    positionThread(activeElement, activeThread.idealTopOffset);

    // Position the elements above the active thread
    let currentMaxBottom = activeThread.idealTopOffset;
    for (let i = activeIndex - 1; i >= 0; i--) {
      const nextThread = orderedThreads[i];
      const nextElement = threadRefs[nextThread.value.id].current;
      if (!nextElement) {
        console.warn("Could not find element for thread", nextThread);
        continue;
      }
      // Default to the lowest possible position
      let nextTop =
        currentMaxBottom - nextElement.offsetHeight - commentSpacing;
      if (nextThread.idealTopOffset < nextTop) {
        // If the ideal position is higher than the default, use that
        nextTop = nextThread.idealTopOffset;
      }
      positionThread(nextElement, nextTop);
      currentMaxBottom = nextTop;
    }

    // Position the elements below the active thread
    let currentMinTop =
      activeThread.idealTopOffset + activeElement.offsetHeight;
    for (let i = activeIndex + 1; i < orderedThreads.length; i++) {
      const nextThread = orderedThreads[i];
      const nextElement = threadRefs[nextThread.value.id].current;
      if (!nextElement) continue;
      // Default to the highest possible position
      let nextTop = currentMinTop + commentSpacing;
      if (nextThread.idealTopOffset > nextTop) {
        // If the ideal position is lower than the default, use that
        nextTop = nextThread.idealTopOffset;
      }
      positionThread(nextElement, nextTop);
      currentMinTop = nextTop + nextElement.offsetHeight;
    }
  }, [orderedThreads, threadRefs, activeCommentId]);

  const setThreadRef = useCallback(
    (id: string, ref: RefObject<HTMLElement> | null) => {
      if (ref) {
        // Set the ref
        setThreadRefs((prev) => ({ ...prev, [id]: ref }));
      } else {
        // Delete the ref
        // setThreadRefs((prev) => {
        //   const newRefs = { ...prev };
        //   delete newRefs[id];
        //   return newRefs;
        // });
      }
    },
    [setThreadRefs]
  );

  return {
    threads: orderedComments,
    setThreadRef,
    threadsContainerRef,
    areFullCommentsVisible,
  };
};

export default useCommentsLayout;
