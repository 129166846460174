import Markdown from "react-markdown";
import React from "react";

const MarkdownView = ({ markdown }: { markdown: string }) => {
  return (
    <Markdown
      className="flex flex-col gap-md"
      components={{
        h1: ({ node, ...props }) => (
          // eslint-disable-next-line jsx-a11y/heading-has-content
          <h1 {...props} className="text-2xl font-bold" />
        ),
        h2: ({ node, ...props }) => (
          // eslint-disable-next-line jsx-a11y/heading-has-content
          <h2 {...props} className="text-xl font-bold" /> // @ts-ignore
        ),
        h3: ({ node, ...props }) => (
          // eslint-disable-next-line jsx-a11y/heading-has-content
          <h3 {...props} className="text-lg font-bold" />
        ),
        ol: ({ node, ...props }) => (
          <ol {...props} className="list-decimal list-outside ml-lg" />
        ),
        ul: ({ node, ...props }) => (
          <ul {...props} className="list-disc list-outside ml-lg" />
        ),
        blockquote: ({ node, ...props }) => (
          <blockquote {...props} className="py-sm pl-2m border-l-xs" />
        ),
        a: ({ node, ...props }) => (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a {...props} className="text-primary underline" />
        ),
      }}
    >
      {markdown}
    </Markdown>
  );
};

export default MarkdownView;
