import { DismissibleLayerContainer } from "../../DismissibleLayerContainer";
import PromptEditor, { PromptEditorRef } from "./PromptEditor";
import { PromptContent } from "./PromptContent";
import PromptRefineryFooter from "./PromptRefineryFooter";
import PromptRefineryOutput from "./PromptRefineryOutput";
import { useRef, useState } from "react";
import { message_from_exception } from "../../../utils";
import { useApiClient } from "../../../providers/ApiClientProvider";
import { usePromptRefineryContext } from "./PromptRefineryProvider";
import { cn } from "lib/utils";
import useLocalStorage from "../../../lib/useLocalStorage";
import CoreApi from "../../../api/CoreApi";
import NaturalHeightTextArea from "components/common/forms/NaturalHeightTextArea";
import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import PromptToolView from "./PromptToolView";
import Overlay from "components/common/containers/overlays/Overlay";
import { PromptTool } from "types/Prompt";

interface PromptVariationProps {
  id: number | null;
  active: boolean;
  className?: string;
  onFocusedEditor: (focused: PromptEditorRef | null) => void;
}

const PromptVariation: React.FC<PromptVariationProps> = ({
  id,
  className,
  active,
  onFocusedEditor,
}) => {
  const apiClient = useApiClient();
  const [output, setOutput] = useLocalStorage<string | null>(
    `prompt-refinery-output-${id}`,
    null
  );
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const systemPromptEditor = useRef<PromptEditorRef>(null);
  const userPromptEditor = useRef<PromptEditorRef>(null);
  const { data } = usePromptRefineryContext();

  // An editing tool of "null" means that the user is editing a new tool
  // An editing tool of "undefined" means that the user is not editing a tool
  const [editingTool, setEditingTool] = useState<PromptTool | null | undefined>(
    undefined
  );

  const saveTool = (tool: PromptTool) => {
    if (editingTool === null) {
      data.setTools(id ?? -1, [
        ...(data.data.variationTools[id ?? -1] ?? []),
        tool,
      ]);
    } else {
      data.setTools(
        id ?? -1,
        data.data.variationTools[id ?? -1].map((t) =>
          t === editingTool ? tool : t
        )
      );
    }
    setEditingTool(undefined);
  };

  const removeSelectedTool = () => {
    if (!editingTool) return;
    data.setTools(
      id ?? -1,
      data.data.variationTools[id ?? -1].filter((t) => t !== editingTool)
    );
  };

  const run = async () => {
    setError(null);
    setIsLoading(true);
    setOutput("");
    try {
      const prompt = {
        messages: await data.renderPrompt(id),
        model: data.data.variationModel[id ?? -1],
        tools: data.data.variationTools[id ?? -1] ?? null,
      };

      const response = await apiClient.postStreamingResponse(
        "/prompt_refinery/arbitrary/",
        prompt
      );
      if (!response.ok) {
        setError(
          await CoreApi.messageFromResponse(
            response,
            "An error occurred while running the prompt."
          )
        );
      } else {
        const reader = response.body!.getReader();
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          setOutput((prev) => (prev ?? "") + new TextDecoder().decode(value));
        }
      }
    } catch (e) {
      debugger;
      setError(message_from_exception(e));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={cn(
        "flex flex-col gap-md rounded-md p-md",
        active && "outline outline-primary outline-offset-[-2px] outline-[2px]",
        className
      )}
    >
      <DismissibleLayerContainer className="grow basis-0 border rounded-sm flex flex-col items-stretch overflow-hidden">
        <div className="flex grow flex-col overflow-y-auto">
          <h2 className="text-xl font-semibold t-md p-md pb-0">Notes</h2>
          <NaturalHeightTextArea
            className="resize-none min-h-[60px] border-none px-md shrink-0"
            value={data.data.variationNotes[id ?? -1]}
            onChange={(text) => {
              data.setNotes(id ?? -1, text);
            }}
          />
          <h2 className="text-xl font-semibold p-md pb-0 border-t">System</h2>
          <PromptEditor
            id={PromptContent.getSystemId(id)}
            ref={systemPromptEditor}
            onFocus={() => onFocusedEditor(systemPromptEditor.current)}
          />
          <h2 className="text-xl font-semibold t-md p-md pb-0 border-t">
            User
          </h2>
          <PromptEditor
            id={PromptContent.getUserId(id)}
            ref={userPromptEditor}
            onFocus={() => onFocusedEditor(userPromptEditor.current)}
          />
          <h2 className="text-xl font-semibold t-md p-md pb-0 border-t">
            Tools
          </h2>
          {(data.data.variationTools[id ?? -1] ?? []).map((tool, index) => (
            <div
              key={index}
              onClick={() => setEditingTool(tool)}
              className="hover:bg-background-selected px-md py-sm cursor-pointer"
              placeholder="UnnamedTool"
            >
              {tool.name}
            </div>
          ))}
          {editingTool !== undefined && (
            <Overlay title="Prompt Tool" className="w-full" maxWidth={600}>
              <PromptToolView
                tool={editingTool}
                onCancel={() => setEditingTool(undefined)}
                onRemove={removeSelectedTool}
                onSave={saveTool}
              />
            </Overlay>
          )}
          <Columns className="px-md my-sm shrink-0">
            <Button
              text="Add Tool"
              icon="plus"
              variant="solid-secondary"
              onClick={() => setEditingTool(null)}
            />
          </Columns>
        </div>
        <PromptRefineryFooter
          className="border-t"
          onRun={run}
          isLoading={isLoading}
          variationId={id}
          active={active}
        />
      </DismissibleLayerContainer>
      <PromptRefineryOutput
        className="grow basis-0"
        output={output}
        error={error}
        onClearOutput={() => setOutput(null)}
      />
    </div>
  );
};

export default PromptVariation;
