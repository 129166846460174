import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOverlayContext } from "components/common/containers/overlays/OverlayContextProvider";
import UploadRFPStep from "./UploadRFPStep";
import UploadPastProposalsStep from "./UploadPastProposalsStep";
import SegmentedContainer from "components/common/containers/SegmentedContainer";
import ConfirmStartStep from "./ConfirmStartStep";
import { useApiClient } from "providers/ApiClientProvider";
import useFetchedData from "hooks/useFetchedData";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import { message_from_exception } from "utils";
import * as Sentry from "@sentry/react";
import MessageView from "components/common/containers/MessageView";
import Button from "components/common/Button";
import Overlay from "components/common/containers/overlays/Overlay";
import ProvideInstructionsStep from "./ProvideInstructionsStep";

interface NewProposalFlowProps {
  open: boolean;
  existingRfp?: { id: string; title: string };
  onStarted?: () => void;
  onClose?: () => void;
}

const StartNewProposalOverlay: React.FC<NewProposalFlowProps> = ({
  existingRfp,
  onStarted,
  onClose,
  open,
}) => {
  const apiClient = useApiClient();
  const [shiftKeyDown, setShiftKeyDown] = useState(false);
  const [eligibility, , { error }] = useFetchedData<string>(async () => {
    if (!open) return null;
    return (
      await apiClient.rfp.rfpProposalEligibilityCreate({ force: shiftKeyDown })
    ).data.eligibility!;
  }, [open]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        setShiftKeyDown(true);
      }
    };
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        setShiftKeyDown(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Overlay scrollable={false} title="" onClose={onClose}>
      <AsyncLoadedDiv
        error={error ? message_from_exception(error) : null}
        value={eligibility}
        className="grow flex overflow-hidden"
        whileLoaded={(eligibility) => {
          if (!eligibility) return null;
          switch (eligibility) {
            case "allowed":
            case "trial":
              if (existingRfp) {
                return (
                  <StartProposalFromExistingRFP
                    existingRfp={existingRfp}
                    onStarted={onStarted}
                  />
                );
              } else {
                return <StartProposalFromNewRFP onStarted={onStarted} />;
              }
            case "demo_required":
              return <DemoSplash fromExistingRFP={!!existingRfp} />;
            case "trial_expired":
              return <TrialExpiredSplash />;
            default:
              Sentry.captureMessage(
                `Unknown proposal creation eligibility: ${eligibility}`
              );
              return <TrialExpiredSplash />;
          }
        }}
      />
    </Overlay>
  );
};

interface StartProposalFromExistingRFPProps {
  existingRfp: { id: string; title: string };
  onStarted?: () => void;
}

const DemoSplash: React.FC<{ fromExistingRFP: boolean }> = ({
  fromExistingRFP,
}) => {
  const bookDemoLink = `https://www.odo.do/book-demo/?source=app-start-proposal${
    fromExistingRFP ? "-from-existing" : "-from-new"
  }`;
  console.log(bookDemoLink);
  return (
    <MessageView
      icon="file-plus"
      title="Start a Proposal"
      className="max-w-[460px] text-center"
    >
      <p>
        We generate a proposal draft so you don’t have to start from scratch.
        Learn more how you can save up to 80% of your time writing proposals.
      </p>
      <Button
        text="Book Demo"
        variant="solid"
        onClick={() => (window.location.href = bookDemoLink)}
      />
    </MessageView>
  );
};

const TrialExpiredSplash: React.FC = () => {
  return (
    <MessageView
      icon="file-plus"
      title="Start a Proposal"
      className="max-w-[440px] text-center"
    >
      <p>Your organization has used its free proposal.</p>
      <p>
        Let's discuss how we can support you in winning more government
        contracts!
      </p>
      <Button
        text="Contact Us"
        variant="solid"
        onClick={() => (window.location.href = "mailto:support@odo.do")}
      />
    </MessageView>
  );
};

const StartProposalFromExistingRFP: React.FC<
  StartProposalFromExistingRFPProps
> = ({ existingRfp, onStarted }) => {
  const navigate = useNavigate();
  const [visiblePanel, setVisiblePanel] = useState(0);
  const { dismiss } = useOverlayContext();
  const [proposalId, setProposalId] = useState<string | null>(null);

  const handleDone = () => {
    dismiss?.();
    navigate(`/proposal/${proposalId}/`);
  };

  const handleConfirmStart = (proposalId: string) => {
    setProposalId(proposalId);
    setVisiblePanel(1);
    onStarted?.();
  };

  return (
    <SegmentedContainer selectedIndex={visiblePanel}>
      <ConfirmStartStep
        rfpId={existingRfp.id}
        rfpTitle={existingRfp.title}
        onConfirmed={handleConfirmStart}
      />
      <ProvideInstructionsStep
        proposalId={proposalId}
        onDone={() => setVisiblePanel(2)}
      />
      <UploadPastProposalsStep onFinished={handleDone} />
    </SegmentedContainer>
  );
};

interface StartProposalFromNewRFPProps {
  onStarted?: () => void;
}

const StartProposalFromNewRFP: React.FC<StartProposalFromNewRFPProps> = ({
  onStarted,
}) => {
  const navigate = useNavigate();
  const [visiblePanel, setVisiblePanel] = useState(0);
  const { dismiss } = useOverlayContext();
  const [proposalId, setProposalId] = useState<string | null>(null);

  const handleDone = () => {
    dismiss?.();
    navigate(`/proposal/${proposalId}/`);
  };

  const handleRFPUploaded = (proposalId: string) => {
    setProposalId(proposalId);
    setVisiblePanel(1);
    onStarted?.();
  };

  return (
    <SegmentedContainer selectedIndex={visiblePanel}>
      <UploadRFPStep onRFPUploaded={handleRFPUploaded} />
      <ProvideInstructionsStep
        proposalId={proposalId}
        onDone={() => setVisiblePanel(2)}
      />
      <UploadPastProposalsStep onFinished={handleDone} />
    </SegmentedContainer>
  );
};

export default StartNewProposalOverlay;
