import { isElement, TNode, Value } from "@udecode/plate-common";
import { PlateEditor, WithPlatePlugin } from "@udecode/plate";
import { comboboxActions } from "@udecode/plate-combobox";
import { ELEMENT_MENTION, MentionsPlugin } from "./types";
import { isOdoTagContent } from "odo";

const withMentionsOverrides = <
  V extends Value = Value,
  E extends PlateEditor<V> = PlateEditor<V>
>(
  editor: E,
  plugin: WithPlatePlugin<MentionsPlugin, V, E>
) => {
  const { insertText, normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      const match = (node: TNode) => {
        return isElement(node) && node.type === ELEMENT_MENTION;
      };
      if (isOdoTagContent(node.children as any[])) {
        // @ts-ignore
        editor.setNodes({ showPlaceholder: true }, { match, at: [] });
      } else {
        // @ts-ignore
        editor.setNodes({ showPlaceholder: undefined }, { match, at: [] });
      }
    }
    normalizeNode([node, path]);
  };

  editor.insertText = (char, ...args) => {
    if (char === "@") {
      insertText(char, ...args);
      const activeId = plugin.options.id ?? ELEMENT_MENTION;
      comboboxActions.open({
        activeId,
        text: "",
        targetRange: editor.selection,
      });
    } else {
      insertText(char, ...args);
    }
  };

  return editor;
};

export default withMentionsOverrides;
