import Button from "components/common/Button";
import CenteredContainer from "components/common/containers/CenteredContainer";
import Columns from "components/common/containers/Columns";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Rows from "components/common/containers/Rows";
import Icon from "components/common/Icon";
import NavigationView from "components/common/NavigationView";
import usePaginatedData from "hooks/usePaginatedData";
import { cn } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { useState } from "react";
import { ProjectDetails } from "api/Api";
import ProjectDetailsView from "./ProjectDetailsView";
import { SaveState } from "components/common/forms/SavedStatusIndicator";
import { useAlert } from "providers/AlertProvider";
import { useNavigate, useParams } from "react-router-dom";

const KnowledgeBaseRoute = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const [projects, setProjects, paginatedData] = usePaginatedData({
    endpoint: apiClient.rfp.rfpProjectList,
    map: (project) => project,
  });
  const [selectedProjectId, doSetSelectedProjectId] = useState<string | null>(
    projectId ?? null
  );
  const alert = useAlert();
  const [selectedProjectSaveState, setSelectedProjectSaveState] =
    useState<SaveState>("unchanged");

  const setSelectedProjectId = (id: string | null) => {
    if (
      selectedProjectSaveState === "saving" ||
      selectedProjectSaveState === "error"
    ) {
      alert("The current project is still saving");
      return;
    }

    doSetSelectedProjectId(id);
    if (id) {
      navigate(`/knowledge/${id}/`);
    } else {
      navigate("/knowledge/");
    }
  };

  const handleAddProject = async () => {
    const result = await apiClient.rfp.rfpProjectCreateCreate();
    setProjects([result.data, ...(projects || [])]);
    setSelectedProjectId(result.data.id ?? null);
  };

  const handleRemoveProject = () => {
    if (!selectedProjectId) return;

    setSelectedProjectId(null);
    setProjects((prev) => {
      if (!prev) return null;
      return prev.filter((project) => project.id !== selectedProjectId);
    });
    setSelectedProjectSaveState("unchanged");
  };

  const handleProjectChanged = (project: ProjectDetails) => {
    setProjects((prev) => {
      if (!prev) return null;
      return prev.map((p) => (p.id === project.id ? project : p));
    });
  };

  return (
    <NavigationView
      selected="knowledge-base"
      containerClassName="max-w-[1600px] w-full mx-auto"
      scrollable={false}
      fullWidth={true}
    >
      <h1 className="text-2xl font-semibold shrink-0 mb-md">Knowledge Base</h1>
      <Columns className="grow border rounded-md">
        {/*********************** Categories ***********************/}
        <Rows className="bg-background-secondary border-r">
          <div className="py-md px-lg">Projects</div>
        </Rows>
        {/*********************** Subcategories ***********************/}
        <Rows className="border-r grow basis-0">
          <PaginatedTableView
            results={projects}
            variant="without-padding"
            paginatedData={paginatedData}
            searchable={true}
            columns={1}
            className="grow basis-0 min-w-[200px] border-b"
            rowSeparators={true}
            renderRow={(project, Cell, Row) => {
              let subtitle = "-";
              if (project.customer && project.location) {
                subtitle = `${project.customer} - ${project.location}`;
              } else if (project.customer) {
                subtitle = project.customer;
              } else if (project.location) {
                subtitle = project.location;
              }
              return (
                <Row key={project.id!}>
                  <Cell
                    className={cn(
                      "px-lg hover:bg-background-selected cursor-pointer",
                      !project.title && "text-secondary",
                      selectedProjectId === project.id &&
                        "bg-background-selected"
                    )}
                    onClick={() => setSelectedProjectId(project.id!)}
                  >
                    <Rows>
                      <div>{project.title || "New Project"}</div>
                      <div className="text-sm text-secondary">{subtitle}</div>
                    </Rows>
                  </Cell>
                </Row>
              );
            }}
          />
          <Button
            text="Add Project"
            icon="plus"
            variant="solid-secondary"
            className="py-sm m-md"
            onClick={handleAddProject}
          />
        </Rows>
        {/*********************** Details ***********************/}
        <Rows className="basis-0 grow-[2]">
          {selectedProjectId ? (
            <ProjectDetailsView
              key={selectedProjectId}
              projectId={selectedProjectId}
              remove={handleRemoveProject}
              onChanged={handleProjectChanged}
              onSaveStateChanged={setSelectedProjectSaveState}
            />
          ) : (
            <NoProjectSelected />
          )}
        </Rows>
      </Columns>
    </NavigationView>
  );
};

const NoProjectSelected = () => {
  return (
    <CenteredContainer>
      <Rows className="items-center gap-md text-secondary">
        <Icon name="square-left" size="xlarge" variant="solid" />
        <p className="text-center">
          Select a project
          <br />
          from the list on the left
        </p>
      </Rows>
    </CenteredContainer>
  );
};

export default KnowledgeBaseRoute;
