const PasswordRequirements = () => {
  return (
    <ul className="text-secondary text-xs flex flex-col px-sm mt-[-12px]">
      <li>8 or more characters</li>
      <li>1 uppercase and 1 lowercase letter</li>
      <li>1 or more special characters</li>
    </ul>
  );
};

export default PasswordRequirements;
