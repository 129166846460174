import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://fa5d70117ac342bbbb68a64ab9fbade4@o4507284578762752.ingest.us.sentry.io/4507284584398848",
  enabled: process.env.NODE_ENV !== "development",
  environment: process.env.REACT_APP_DEPLOY_ENV,
  release: process.env.REACT_APP_DEPLOY_VERSION,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  ignoreErrors: [
    "Request failed with status code 401",
    "Request failed with status code 403",
  ],
});
