import { ComponentPropsWithoutRef } from "react";
import Icon, { IconName, IconProps } from "../Icon";
import Rows from "./Rows";
import { cn } from "lib/utils";
import CenteredContainer from "./CenteredContainer";
import { cva, VariantProps } from "class-variance-authority";

const messageViewVariants = cva("", {
  variants: {
    variant: {
      DEFAULT: "px-3xl py-lg",
      // Show with a shadow and background color
      above: "shadow-lg bg-background py-2xl px-4xl rounded",
    },
  },
  defaultVariants: {
    variant: "DEFAULT",
  },
});

interface MessageviewProps
  extends ComponentPropsWithoutRef<"div">,
    VariantProps<typeof messageViewVariants> {
  icon?: IconName;
  iconVariant?: IconProps["variant"];
  title: string;
}

const MessageView = ({
  icon,
  title,
  children,
  className,
  iconVariant = "solid",
  variant,
  ...props
}: MessageviewProps) => {
  return (
    <CenteredContainer>
      <Rows
        className={cn(
          "items-center gap-lg",
          messageViewVariants({ variant }),
          className
        )}
        {...props}
      >
        {icon && <Icon name={icon} size="huge" variant={iconVariant} />}
        <h2 className="text-2xl font-semibold">{title}</h2>
        {children}
      </Rows>
    </CenteredContainer>
  );
};

export default MessageView;
