import { createPluginFactory, isElement } from "@udecode/plate-common";
import { ListStyleType } from "@udecode/plate-indent-list";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
const orderedListStyleTypes = [
    ListStyleType.Decimal,
    ListStyleType.LowerAlpha,
    ListStyleType.LowerRoman,
];
const unorderedListStyleTypes = [
    ListStyleType.Disc,
    ListStyleType.Circle,
    ListStyleType.Square,
];
export const createListAdjustmentsPlugin = createPluginFactory({
    key: "list-adjustments",
    withOverrides: (editor) => {
        const { normalizeNode } = editor;
        editor.normalizeNode = ([node, path]) => {
            normalizeNode([node, path]);
            // Automatically adjust list style type based on indent level
            if (isElement(node) && node.type === ELEMENT_PARAGRAPH) {
                // @ts-ignore
                if (orderedListStyleTypes.includes(node.listStyleType)) {
                    const listIndent = (node.indent - 1) % orderedListStyleTypes.length;
                    const targetType = orderedListStyleTypes[listIndent];
                    if (node.listStyleType !== targetType) {
                        // @ts-ignore
                        editor.setNodes({ listStyleType: targetType }, { at: path });
                    }
                    // @ts-ignore
                }
                else if (unorderedListStyleTypes.includes(node.listStyleType)) {
                    const listIndent = (node.indent - 1) % unorderedListStyleTypes.length;
                    const targetType = unorderedListStyleTypes[Math.max(0, listIndent)];
                    if (node.listStyleType !== targetType) {
                        // @ts-ignore
                        editor.setNodes({ listStyleType: targetType }, { at: path });
                    }
                }
            }
        };
        return editor;
    },
});
