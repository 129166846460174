import { useCallback, useEffect, useState } from "react";
import * as Y from "yjs";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { debounce } from "lodash";

// Use a Yjs Map while syncing with a local state
// The defaults provided will be added to the remote store and guaranteed to be present in the local store
const useYjsMap = <T>(
  yjsProvider: HocuspocusProvider | null,
  key: string,
  defaults: Record<string, T>,
  applyDefaultsToMap: boolean,
  debounceTime: number = 0
): [Record<string, T>, (id: string, value: T | null) => void] => {
  const [localState, setLocalState] = useState<Record<string, T>>(defaults);
  const [map, setMap] = useState<Y.Map<any> | null>(null);
  // Store changes immediately in a pending state. These changes will be debounced and applied to localState after a delay
  const [pendingState, setPendingState] = useState<Record<string, T>>({});

  useEffect(() => {
    const applyToLocal = debounce((state: Record<string, T>) => {
      setLocalState(state);
    }, debounceTime);
    applyToLocal(pendingState);
  }, [pendingState, debounceTime]);

  useEffect(() => {
    // Doc changed, reset the map
    const map = yjsProvider?.document?.getMap(key) ?? null;
    setMap(map);
  }, [yjsProvider, key]);

  useEffect(() => {
    if (!map) {
      setPendingState(defaults);
      return;
    }

    // Sync all existing remote comments into the local store
    const newData: Record<string, T> = applyDefaultsToMap ? defaults : {};
    map.forEach((value, key) => {
      newData[key] = value as T;
    });
    setPendingState(newData);

    if (applyDefaultsToMap) {
      for (const [key, value] of Object.entries(defaults)) {
        map.set(key, value);
        newData[key] = value;
      }
    }

    // Sync all future changes to the local store
    const onMapEvent = (event: Y.YMapEvent<unknown>) => {
      event.changes.keys.forEach((change, changedKey) => {
        switch (change.action) {
          case "add":
          case "update":
            const value = map.get(changedKey) as T;
            setPendingState((prev) => {
              return { ...prev, [changedKey]: value };
            });
            break;
          case "delete":
            setPendingState((prev) => {
              const updated = { ...prev };
              delete updated[changedKey];
              return updated;
            });
            break;
        }
      });
    };
    map.observe(onMapEvent);
    return () => {
      map.unobserve(onMapEvent);
    };
    // Explicitly not including the defaults as those should only be used initially
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, applyDefaultsToMap]);

  const setValue = useCallback(
    (key: string, value: T | null) => {
      if (value === null) {
        // Remove from local store
        setPendingState((prev) => {
          const updated = { ...prev };
          delete updated[key];
          return updated;
        });

        // Remove from remote store
        map?.delete(key);
      } else {
        // Update the local store
        setPendingState((prev) => {
          return { ...prev, [key]: value };
        });

        // Update the remote store
        map?.set(key, value);
      }
    },
    [map]
  );

  return [localState, setValue];
};

export default useYjsMap;
