"use client";

import React from "react";
import { PlateElement, PlateElementProps } from "@udecode/plate-common";

import { cn } from "lib/utils";
import { SuggestableElement } from "components/proposals/refine/SuggestableElement";

const BlockquoteElement = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateElementProps
>(({ className, children, ...props }, ref) => {
  return (
    <SuggestableElement
      asChild
      ref={ref}
      className={cn("py-sm pl-2m border-l-4", className)}
      {...props}
    >
      <blockquote>{children}</blockquote>
    </SuggestableElement>
  );
});
BlockquoteElement.displayName = "BlockquoteElement";

export { BlockquoteElement };
