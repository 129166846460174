import Button from "components/common/Button";
import Overlay from "components/common/containers/overlays/Overlay";
import { format_absolute_date } from "lib/utils";
import { FC, useRef, useState } from "react";
import { RFPOrgComparison } from "types/RFPOrgComparison";
import RFPDetailsView from "./RFPDetailsView";
import RFPComparisonOptionsView from "./RFPComparisonOptionsView";
import { usePostHog } from "posthog-js/react";

interface RFPComparisionViewProps {
  comparison: RFPOrgComparison;
  remove: () => void;
}

const RFPComparisionView: FC<RFPComparisionViewProps> = ({
  comparison,
  remove,
}) => {
  const [height, setHeight] = useState<number | undefined>(undefined);
  const [marginTop, setMarginTop] = useState<number | undefined>(undefined);
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedComparison, setSelectedComparison] =
    useState<RFPOrgComparison | null>(null);
  const posthog = usePostHog();

  const handleHideRFP = () => {
    setHeight(containerRef.current?.clientHeight ?? 0);
    setTimeout(() => {
      setHeight(0);
      setMarginTop(-12);
      setTimeout(() => {
        remove();
      }, 500);
    });
  };

  const handleSelectComparison = (comparison: RFPOrgComparison) => {
    setSelectedComparison(comparison);
    try {
      posthog.capture("rfp_viewed_details", { rfp_slug: comparison.rfpSlug });
    } catch (e) {
      console.error("Error capturing posthog event", e);
    }
  };

  return (
    <div
      ref={containerRef}
      className={
        "rounded-md flex flex-col tablet:flex-row gap-md overflow-hidden border relative transition-[height,margin-top] duration-500"
      }
      style={{ height, marginTop }}
    >
      <div className="tablet:w-[140px] items-center tablet:items-end flex tablet:flex-col shrink-0 bg-background-secondary p-md gap-sm">
        <div className="grow tablet:hidden" />
        <div className="flex flex-col items-center tablet:items-end grow tablet:grow-0">
          <p className="text-secondary text-xs">Proposal Due</p>
          <p className="text-lg font-semibold">
            {format_absolute_date(comparison.dueDate)}
          </p>
        </div>
        <div className="flex flex-col items-center tablet:items-end grow tablet:grow-0">
          <span className="text-secondary text-xs">Budget </span>
          <div className="vertical-text-elipsis text-right">
            {comparison.budget ? String(comparison.budget) : "-"}
          </div>
        </div>
        <div className="flex flex-col items-center tablet:items-end grow tablet:grow-0">
          <span className="text-secondary text-xs">Location </span>
          <div className="vertical-text-elipsis text-right">
            {comparison.location ? String(comparison.location) : "-"}
          </div>
        </div>
        <div className="grow" />
      </div>
      <div className="grow flex flex-col gap-sm max-w-[800px] p-md relative">
        <p className="font-semibold text-xl">{String(comparison.title)}</p>
        <p className="text-secondary">{String(comparison.supplier)}</p>
        <p className="">{String(comparison.description)}</p>
        <div className="flex gap-sm mt-md">
          <Button
            text="View Details"
            variant="solid-secondary"
            className="hidden tablet:block"
            onClick={() => handleSelectComparison(comparison)}
          />
          <Button
            text="Details"
            variant="solid-secondary"
            className="tablet:hidden"
            onClick={() => handleSelectComparison(comparison)}
          />
          <div className="grow" />
          <RFPComparisonOptionsView
            comparison={comparison}
            hideRFP={handleHideRFP}
          />
        </div>
      </div>
      {selectedComparison && (
        <Overlay
          title={selectedComparison.title}
          className="h-full"
          variant="bordered"
          onClose={() => setSelectedComparison(null)}
        >
          <RFPDetailsView
            onDecline={handleHideRFP}
            comparison={selectedComparison}
          />
        </Overlay>
      )}
    </div>
  );
};

export default RFPComparisionView;
