import { isSuggestionKey, removeAllSuggestionKeysFromNodes, } from "./utils.js";
import { SuggestionsStore } from "./SuggestionsStore.js";
const withSuggestionsOverrides = (editor, plugin) => {
    const { apply, insertNodes } = editor;
    editor.insertNodes = (nodes, options) => {
        removeAllSuggestionKeysFromNodes(nodes);
        return insertNodes(nodes, options);
    };
    editor.apply = (op) => {
        apply(op);
        if (op.type === "remove_text" || op.type === "remove_node") {
            SuggestionsStore.getInstance().clearSuggestionsWithoutMarks(editor);
        }
        else if (op.type === "insert_text" || op.type === "insert_node") {
            // @ts-ignore
            if (op.type === "set_node" && !op.newProperties.suggestion) {
                // No need to try to restore anything
                return;
            }
            const [node] = editor.node(op.path);
            SuggestionsStore.getInstance().restoreSuggestionsForNode(node);
        }
        else if (op.type === "set_node") {
            // Collect added keys
            const addedKeys = new Set();
            const removedKeys = new Set();
            for (const key of Object.keys(op.newProperties)) {
                if (isSuggestionKey(key)) {
                    // @ts-ignore
                    if (op.newProperties[key] === true) {
                        addedKeys.add(key);
                        // @ts-ignore
                    }
                    else if (op.newProperties[key] === null) {
                        removedKeys.add(key);
                    }
                }
            }
            if (addedKeys.size > 0) {
                SuggestionsStore.getInstance().restoreSuggestionForKeys(Array.from(addedKeys.keys()));
            }
            if (removedKeys.size > 0) {
                SuggestionsStore.getInstance().deleteSuggestionsWithKeys(Array.from(removedKeys.keys()));
            }
        }
    };
    return editor;
};
export default withSuggestionsOverrides;
