"use client";

import { useEffect, useRef, useState } from "react";
import { PlateLeafProps } from "@udecode/plate-common";
import { isOdoComment, getCommentIdFromKey, getCommentKeys } from "odo";
import { PlateLeaf } from "@udecode/plate-common";
import {
  useActiveComment,
  useComments,
  useUpdateCommentMarkData,
} from "providers/CommentsProvider";
import { cn } from "lib/utils";
import { v4 } from "uuid";

export function CommentLeaf({ className, ...props }: PlateLeafProps) {
  // Each comment leaf represents a segment of 1 or more pieces of text that is being commented on.
  // This is an example scenario:
  //     This is some text that is being commented on
  //     | Comment 1 |     | Comment 2  |
  //             | Comment 3  |
  //     | Comment 4 |
  // This creates the following segments:
  // |     Text     | Comments |
  //  -------------------------
  // | This is      | 1, 4     |
  // | some         | 1, 3, 4  |
  // | text         | 3        |
  // | that         | 2, 3     |
  // | is being     | 2        |
  // | commented on |          |

  // With that context, this leaf must do two things:
  // 1. Render the mark below the text – If there are multiple comments on the same segment, it should render
  //    multiple semi-transparent backgrounds to indicate that there are multiple comments.
  // 2. Track the top most point of each comment (regardless of the number of comments). This is for laying out the
  //    comments themselves.

  const { children, nodeProps, leaf } = props;
  const [markId] = useState(v4().toString());
  const leafRef = useRef<HTMLElement>(null);
  const updateCommentMarkData = useUpdateCommentMarkData();
  const activeCommentId = useActiveComment();
  const [pendingComment, all] = useComments();

  let isActive: boolean = false;
  const commentKeys = getCommentKeys(leaf);
  const commentIds = commentKeys.map((key) => getCommentIdFromKey(key));
  let foundOdoComment = false;
  const unresolvedComment = commentIds.filter((id) => {
    const comment = all[id] || (pendingComment?.id === id && pendingComment);
    isActive = isActive || comment?.id === activeCommentId;
    if (comment !== undefined && !comment.isResolved) {
      if (isOdoComment(comment)) {
        foundOdoComment = true;
      }
      return true;
    }
    return false;
  });

  useEffect(() => {
    const commentKeys = getCommentKeys(leaf);
    const commentIds = commentKeys.map((key) => getCommentIdFromKey(key));
    for (const commentId of commentIds) {
      updateCommentMarkData(commentId, markId, leafRef);
    }
    return () => {
      for (const commentId of commentIds) {
        updateCommentMarkData(commentId, markId, null);
      }
    };
  }, [leafRef, leaf, updateCommentMarkData, markId]);

  let backgroundColor = "";
  let underlineCommentColor = "";
  if (isActive) {
    if (foundOdoComment) {
      backgroundColor = "bg-comment-odo-selected";
      underlineCommentColor = "border-comment-odo-selected-underline";
    } else {
      backgroundColor = "bg-comment-selected";
      underlineCommentColor = "border-comment-selected-underline";
    }
  } else {
    if (foundOdoComment) {
      backgroundColor = "bg-comment-odo";
      // underlineCommentColor = "border-comment-odo-underline";
    } else if (unresolvedComment.length === 0) {
      backgroundColor = "";
      underlineCommentColor = "";
    } else if (unresolvedComment.length === 1) {
      backgroundColor = "bg-comment";
      // underlineCommentColor = "border-comment-underline";
    } else {
      backgroundColor = "bg-comment-two";
      // underlineCommentColor = "border-comment-two-underline";
    }
  }

  return (
    <PlateLeaf
      {...props}
      ref={leafRef}
      nodeProps={{
        className: cn(
          className,
          underlineCommentColor === "" ? "" : "border-b",
          underlineCommentColor,
          backgroundColor
        ),
        ...nodeProps,
      }}
    >
      {children}
    </PlateLeaf>
  );
}
