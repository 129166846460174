import Button from "components/common/Button";
import Rows from "components/common/containers/Rows";
import { cn } from "lib/utils";
import SectionDetailsPanel from "./SectionDetailsPanel";
import OutlineVerificationPanel from "./OutlineVerificationPanel";
import {
  RightPanel,
  useEditorDocData,
} from "providers/RequirementContentEditorProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";

export const RightEditorToolbar: React.FC = () => {
  const { activeRightPanel, setActiveRightPanel } = useEditorDocData();
  const { isWriter } = useAuthenticatedUser();

  const getClickHandler = (panel: RightPanel) => () => {
    if (activeRightPanel === panel) {
      setActiveRightPanel(null);
    } else {
      setActiveRightPanel(panel);
    }
  };

  return (
    <Rows className="shrink-0 border-l">
      {isWriter && (
        <Button
          tooltip="Section Details"
          icon="memo-circle-info"
          size="large"
          variant="toolbar"
          className={cn(
            activeRightPanel === "section-details" &&
              "border-r-primary hover:border-r-primary"
          )}
          onClick={getClickHandler("section-details")}
        />
      )}
      <Button
        tooltip="RFP Coverage"
        icon="list-check"
        size="large"
        variant="toolbar"
        className={cn(
          activeRightPanel === "outline-verification" &&
            "border-r-primary hover:border-r-primary"
        )}
        onClick={getClickHandler("outline-verification")}
      />
    </Rows>
  );
};

interface RightEditorPorotalProps {
  maxSize?: number;
}

export const useRightEditorContent = () => {
  const { activeRightPanel } = useEditorDocData();

  let activePanelComponent = null;
  switch (activeRightPanel) {
    case "section-details":
      activePanelComponent = <SectionDetailsPanel />;
      break;
    case "outline-verification":
      activePanelComponent = <OutlineVerificationPanel />;
      break;
    default:
      break;
  }

  return activePanelComponent;
};

// export const RightEditorPortal: React.FC<RightEditorPorotalProps> = ({
//   maxSize,
// }) => {
//   const { isWriter } = useAuthenticatedUser();
//   const { activeRightPanel } = useEditorDocData();

//   if (!isWriter) {
//     return null;
//   }

//   let activePanelComponent = null;
//   switch (activeRightPanel) {
//     case "section-details":
//       activePanelComponent = <SectionDetailsPanel />;
//       break;
//     case "outline-verification":
//       activePanelComponent = <OutlineVerificationPanel />;
//       break;
//     default:
//       break;
//   }

//   if (!activePanelComponent) {
//     return null;
//   }

//   return (
//     <SplitPane uniqueId="right-panel" minSize={200} maxSize={800}>
//       {activePanelComponent}
//     </SplitPane>
//   );

// return (
//   <DragResizable
//     defaultSize={400}
//     minSize={200}
//     maxSize={maxSize ?? 800}
//     direction="horizontally"
//     className="flex border-l"
//     savedStateKey="proposal-answer-editor-left-panel"
//   >
//     {activePanelComponent}
//   </DragResizable>
// );
// };
