import { PlateEditor } from "@udecode/plate-common";
import { RFPBlock } from "api/Api";
import { getSectionInfoFromElement, SectionInfo } from "odo";

export const getPreviousSection = (sectionId: string, editor: PlateEditor) => {
  let previousHeading: SectionInfo | null = null;
  for (let i = 0; i < editor.children.length; i++) {
    const [element] = editor.node([i]);
    const sectionInfo = getSectionInfoFromElement(element);
    if (sectionInfo) {
      if (sectionInfo.id === sectionId) {
        return previousHeading;
      }
      previousHeading = sectionInfo;
    }
  }
  return null;
};

export const getPreviousSections = (
  sectionId: string,
  editor: PlateEditor,
  limit: number
): SectionInfo[] | null => {
  let previousHeadings: SectionInfo[] = [];
  for (let i = 0; i < editor.children.length; i++) {
    const [element] = editor.node([i]);
    const sectionInfo = getSectionInfoFromElement(element);
    if (sectionInfo) {
      if (sectionInfo.id === sectionId) {
        return previousHeadings;
      }
      previousHeadings.push(sectionInfo);
      if (previousHeadings.length > limit) {
        // Remove the first element if we've reached the limit
        previousHeadings.shift();
      }
    }
  }
  return null;
};

export const getSection = (
  sectionId: string,
  editor: PlateEditor
): SectionInfo | null => {
  for (let i = 0; i < editor.children.length; i++) {
    const [element] = editor.node([i]);
    const sectionInfo = getSectionInfoFromElement(element);
    if (sectionInfo) {
      if (sectionInfo.id === sectionId) {
        return sectionInfo;
      }
    }
  }
  return null;
};

export const addBlockToSection = (
  block: RFPBlock,
  sectionId: string,
  editor: PlateEditor
) => {
  for (let i = 0; i < editor.children.length; i++) {
    const [element] = editor.node([i]);
    const sectionInfo = getSectionInfoFromElement(element);
    if (!sectionInfo) continue;
    if (sectionInfo.id !== sectionId) continue;

    if (block.requirements && block.requirements.length > 0) {
      let allRequirements = new Set<number>(sectionInfo.requirements ?? []);
      for (const req of block.requirements) {
        allRequirements.add(req.id!);
      }
      editor.setNodes(
        // @ts-ignore
        { requirements: Array.from(allRequirements) },
        { at: [i] }
      );
    } else {
      let allBlocks = new Set<number>(sectionInfo.blocks ?? []);
      allBlocks.add(block.index);
      editor.setNodes(
        // @ts-ignore
        { blocks: Array.from(allBlocks) },
        { at: [i] }
      );
    }

    return;
  }
};
