import ProposalEditor from "./proposal-answer-editor/ProposalAnswerEditor";
import { useProposalData } from "providers/ProposalDetailsProvider";

interface ProposalRequirementDetailsProps {
  className?: string;
}

const ProposalRequirementDetails: React.FC<ProposalRequirementDetailsProps> = ({
  className,
}) => {
  const { details } = useProposalData();

  return (
    // <div className={cn("w-full flex flex-col gap-md mb-lg", className)}>
    //   <ProposalRequriementDetailsHeader />
    <ProposalEditor
      // Setting the key to the requirement id forces the editor to fully
      // re-render when the requirement changes.
      key={details.id}
      proposalId={details.id}
      className="p-lg"
    />
    // </div>
  );
};

export default ProposalRequirementDetails;
