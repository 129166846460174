import {
  createParagraphPlugin,
  createPlugins,
  Plate,
  PlateContent,
  useEditorRef,
} from "@udecode/plate";
import { forwardRef, useImperativeHandle } from "react";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import PromptParagraphElement from "./components/PromptParagraph";
import TextVarElement, {
  createTextVarPlugin,
  generateTextVar,
  TEXT_VAR,
} from "./components/TextVar";
import { PromptVariable } from "./types";
import { focusEditor } from "@udecode/plate-common";
import { usePromptRefineryContext } from "./PromptRefineryProvider";
import IfElseVarElement from "./components/IfElseVar";
import {
  createIfElseVarPlugin,
  generateIfElseVar,
  IF_ELSE_VAR,
} from "./plugins/IfElsePlugin";

interface PromptEditorProps {
  id: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

const PLUGINS = createPlugins(
  [createParagraphPlugin(), createTextVarPlugin(), createIfElseVarPlugin()],
  {
    components: {
      [ELEMENT_PARAGRAPH]: PromptParagraphElement,
      [TEXT_VAR]: TextVarElement,
      [IF_ELSE_VAR]: IfElseVarElement,
    },
  }
);

export interface PromptEditorRef {
  insertVariable: (variable: PromptVariable, as: "text" | "condition") => void;
  focus: () => void;
}

const PromptEditor = forwardRef<PromptEditorRef, PromptEditorProps>(
  (props, ref) => {
    const { data } = usePromptRefineryContext();
    const content = data.data.content;
    return (
      <Plate
        id={props.id}
        plugins={PLUGINS}
        onChange={(value) => content.setContent(props.id, value)}
        initialValue={content.getContent(props.id)}
      >
        <PromptEditorContent ref={ref} {...props} />
      </Plate>
    );
  }
);

const PromptEditorContent = forwardRef<PromptEditorRef, PromptEditorProps>(
  ({ id, onFocus, onBlur }, ref) => {
    const editor = useEditorRef();

    useImperativeHandle(ref, () => ({
      insertVariable: (variable: PromptVariable, as: "text" | "condition") => {
        let selection = editor?.selection;
        if (as === "condition") {
          editor.insertNodes(generateIfElseVar(variable.id));
        } else {
          editor.insertNodes(generateTextVar(variable.id));
        }

        if (selection) {
          let focus = selection.focus;
          focus.offset = 0;
          focus.path[focus.path.length - 1] += 2;
          focusEditor(editor, focus);
        }
      },
      focus: () => {
        focusEditor(editor);
      },
    }));

    return (
      <PlateContent
        className="p-md"
        id={id}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  }
);

export default PromptEditor;
