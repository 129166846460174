import { cn } from "lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import { forwardRef } from "react";

const tableRowVariants = cva("font-medium text-md text-left", {
  variants: {
    variant: {
      DEFAULT: "",
      selectable: "hover:bg-background-selected cursor-pointer",
      selected: "bg-background-selected",
      header: "border-b text-sm",
    },
  },
  defaultVariants: {
    variant: "DEFAULT",
  },
});

const tableCellVariants = cva("px-2m py-sm", {
  variants: {
    variant: {
      DEFAULT: "",
      minimumWidth: "w-thin whitespace-nowrap",
      alignedTop: "align-top",
    },
    importance: {
      DEFAULT: "",
      low: "text-secondary text-sm",
    },
  },
  defaultVariants: {
    variant: "DEFAULT",
  },
});

type TableCellProps = React.ComponentPropsWithoutRef<"td"> &
  VariantProps<typeof tableCellVariants>;
type TableRowProps = React.ComponentPropsWithoutRef<"tr"> &
  VariantProps<typeof tableRowVariants>;

export const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ className, variant, children, ...props }, ref) => {
    return (
      <tr
        ref={ref}
        className={cn(tableRowVariants({ variant }), className)}
        {...props}
      >
        {children}
      </tr>
    );
  }
);

export const TableHeaderCell = forwardRef<HTMLTableCellElement, TableCellProps>(
  ({ variant = "DEFAULT", importance, className, children }, ref) => {
    return (
      <th
        ref={ref}
        className={cn(
          tableCellVariants({ variant, importance }),
          "text-secondary font-medium",
          className
        )}
      >
        {children}
      </th>
    );
  }
);

export const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(
  ({ variant = "DEFAULT", importance, className, children, ...props }, ref) => {
    return (
      <td
        ref={ref}
        className={cn(tableCellVariants({ variant, importance }), className)}
        {...props}
      >
        {children}
      </td>
    );
  }
);
