import { FinalCheckDetails } from "api/Api";
import Button from "components/common/Button";
import CenteredContainer from "components/common/containers/CenteredContainer";
import Columns from "components/common/containers/Columns";
import MessageView from "components/common/containers/MessageView";
import Rows from "components/common/containers/Rows";
import Scrollable from "components/common/containers/Scrollable";
import Spinner from "components/common/Spinner";
import { cn } from "lib/utils";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { createContext, ReactNode } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { message_from_exception } from "utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.mjs",
//   import.meta.url
// ).toString();

// Ordered list of expected sections in the report
const EXPECTED_SECTIONS: { id: string; name: string }[] = [
  { id: "min-qualifications", name: "Minimum Qualifications" },
  { id: "format", name: "Format" },
  { id: "supplier-info", name: "Company Information" },
  { id: "scope-of-work", name: "Scope of Work" },
  { id: "project-schedule", name: "Cost and Timeline" },
  { id: "attachments", name: "Attachments" },
  { id: "submission-guideline", name: "Submission Guidelines" },
  { id: "misc", name: "Miscellaneous" },
];

const IGNORED_SECTIONS = new Set(["legal"]);

export interface ReportElement {
  id: number;
  ref: React.RefObject<HTMLDivElement>;
  requirement: string;
  status: string;
  justification: string;
  bbox: {
    top: number;
    left: number;
    width: number;
    height: number;
    page: number;
  };
}

export const requirementsMatch = (
  a: ReportElement | null,
  b: ReportElement
) => {
  if (!a) return false;
  return a.id === b.id;
};

export interface OrganizedReportSection {
  id: string;
  name: string;
  requirements: ReportElement[];
}

interface FinalCheckDetailsData {
  finalCheck: FinalCheckDetails;
  document: ReactNode;
  organizedReport: OrganizedReportSection[];
  reportContainer: React.RefObject<HTMLDivElement>;

  activeRequirement: ReportElement | null;
  setActiveRequirement: (req: ReportElement | null) => void;

  setContainerWidth: Dispatch<SetStateAction<number>>;
  setCanAnimateScroll: Dispatch<SetStateAction<boolean>>;

  loadingError: string | null;
  isLoaded: boolean;
}

const FinalCheckDetailsContext = createContext<FinalCheckDetailsData | null>(
  null
);

export const FinalCheckDetailsProvider: React.FC<{
  finalCheck: FinalCheckDetails;
  children: ReactNode;
}> = ({ finalCheck, children }) => {
  const [activeRequirement, doSetActiveRequirement] =
    useState<ReportElement | null>(null);

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(1);
  const [pageWidth, setPageWidth] = useState(1);
  const containerHeight = (pageHeight / pageWidth) * containerWidth;
  const [canAnimateScroll, setCanAnimateScroll] = useState(true);
  const [loadingError, setLoadingError] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [organizedReport, setOrganizedReport] = useState<
    OrganizedReportSection[]
  >([]);
  const reportContainer = useRef<HTMLDivElement>();

  const scrollContainer = useRef<HTMLDivElement>();
  const highlightRef = useRef<HTMLDivElement>();
  const [shouldScrollToHighlight, setShouldScrollToHighlight] = useState<
    "no" | "smooth" | "jump"
  >("no");
  const [previewAllRequirements, setPreviewAllRequirements] = useState(true);

  const setActiveRequirement = useCallback(
    (req: ReportElement | null) => {
      if (req) {
        doSetActiveRequirement(req);
        if (req.bbox.page !== currentPage) {
          setShouldScrollToHighlight("jump");
        } else {
          setShouldScrollToHighlight("smooth");
        }
        setCurrentPage(req.bbox.page);
      } else {
        doSetActiveRequirement(null);
      }
    },
    [currentPage]
  );

  useEffect(() => {
    if (finalCheck.report) {
      setOrganizedReport(organizeReport(finalCheck.report as any));
    }
  }, [finalCheck.report]);

  useEffect(() => {
    if (
      activeRequirement &&
      reportContainer.current &&
      activeRequirement.ref.current
    ) {
      reportContainer.current.scrollTo({
        top: activeRequirement.ref.current.offsetTop - 300,
        behavior: "smooth",
      });
    }
  }, [activeRequirement]);

  useEffect(() => {
    // SCroll to the highlighted requirement when it changes
    if (
      isLoaded &&
      highlightRef.current &&
      shouldScrollToHighlight !== "no" &&
      scrollContainer.current
    ) {
      setTimeout(() => {
        if (!scrollContainer.current) return;
        if (!highlightRef.current) return;
        scrollContainer.current.scrollTo({
          top: highlightRef.current.offsetTop - 100,
          behavior: (shouldScrollToHighlight === "jump" || !canAnimateScroll
            ? "instant"
            : "smooth") as any,
        });
      }, 100);
      setShouldScrollToHighlight("no");
    }
  }, [shouldScrollToHighlight, highlightRef, canAnimateScroll, isLoaded]);

  const document = (
    <Rows
      className={cn(
        "grow w-full transition-opacity",
        activeRequirement ? "opacity-100" : "opacity-0"
      )}
    >
      <Scrollable
        className="border grow rounded-sm relative"
        ref={scrollContainer as any}
        style={{ width: containerWidth, height: containerHeight }}
      >
        <Document
          file={finalCheck.rfp_url!}
          className={cn(
            "flex items-center justify-center",
            !!activeRequirement ? "block" : "hidden"
          )}
          onLoadSuccess={(doc) => {
            setPageCount(doc.numPages);
            setIsLoaded(true);
            setLoadingError(null);
          }}
          onLoadError={(error) => {
            setIsLoaded(false);
            setLoadingError(message_from_exception(error));
          }}
        >
          {isLoaded && !loadingError && pageCount > 0 && (
            <Page
              pageNumber={currentPage}
              className="relative"
              renderTextLayer={false}
              renderAnnotationLayer={false}
              width={containerWidth}
              height={containerHeight}
              onLoadSuccess={(page) => {
                setPageHeight(page.height);
                setPageWidth(page.width);
              }}
            >
              {activeRequirement &&
                activeRequirement.bbox &&
                activeRequirement.bbox.page === currentPage &&
                pageHeight > 0 &&
                pageWidth > 0 && (
                  <div
                    ref={highlightRef as any}
                    className={cn(
                      "border border-[3px] rounded-sm absolute",
                      activeRequirement.status === "pass"
                        ? "border-[#7CB632]"
                        : activeRequirement.status === "fail"
                        ? "border-destructive"
                        : "border-secondary"
                    )}
                    style={bboxToStyle(
                      activeRequirement.bbox,
                      pageWidth,
                      pageHeight
                    )}
                  />
                )}
              {previewAllRequirements &&
                organizedReport.flatMap((section) =>
                  section.requirements
                    .filter((req) => req.bbox.page === currentPage)
                    .map((req, i) => (
                      <div
                        className={cn(
                          "hover:border hover:border-[3px] rounded-sm absolute cursor-pointer group opacity-5",
                          req.status === "pass"
                            ? "bg-[#7CB632]"
                            : req.status === "fail"
                            ? "bg-destructive"
                            : "bg-secondary opacity-10"
                        )}
                        onClick={() => setActiveRequirement(req)}
                        style={bboxToStyle(req.bbox, pageWidth, pageHeight)}
                      />
                    ))
                )}
            </Page>
          )}
        </Document>
        {loadingError && (
          <Rows className="absolute inset-0">
            <MessageView
              title="Error Loading RFP"
              icon="circle-exclamation"
              className="grow"
            >
              <p className="text-destructive">{loadingError ?? "Fake error"}</p>
            </MessageView>
          </Rows>
        )}
        {!isLoaded && !loadingError && (
          <Rows className="absolute inset-0">
            <CenteredContainer className="absolute inset-0 bg-background">
              <Spinner text="Loading RFP..." />
            </CenteredContainer>
          </Rows>
        )}
      </Scrollable>
      <Columns className="shrink-0 justify-center py-md shrink-0">
        <Columns
          className={cn(
            "gap-lg items-center transition-opacity",
            isLoaded ? "opacity-100" : "opacity-0"
          )}
        >
          <Button
            icon="arrow-left"
            variant="solid-secondary"
            className="px-sm"
            onClick={() => setCurrentPage((p) => Math.max(p - 1, 1))}
          />
          <p>
            {currentPage} / {pageCount}
          </p>
          <Button
            icon="arrow-right"
            variant="solid-secondary"
            className="px-sm"
            onClick={() => setCurrentPage((p) => Math.min(p + 1, pageCount))}
          />
        </Columns>
      </Columns>
    </Rows>
  );

  return (
    <FinalCheckDetailsContext.Provider
      value={{
        finalCheck,
        document,
        activeRequirement,
        setActiveRequirement,
        setContainerWidth,
        setCanAnimateScroll,
        reportContainer: reportContainer as any,
        isLoaded,
        loadingError,
        organizedReport,
      }}
    >
      {children}
    </FinalCheckDetailsContext.Provider>
  );
};

export const useFinalCheckDetails = () => {
  const context = React.useContext(FinalCheckDetailsContext);
  if (!context) {
    throw new Error(
      "useFinalCheckDetails must be used within a FinalCheckDetailsProvider"
    );
  }
  return context;
};

export const bboxToStyle = (
  bbox: ReportElement["bbox"],
  fullWidth: number,
  fullHeight: number
) => {
  return {
    top: `${bbox.top * fullHeight - 8}px`,
    left: `${bbox.left * fullWidth - 8}px`,
    width: `${bbox.width * fullWidth + 16}px`,
    height: `${bbox.height * fullHeight + 16}px`,
  };
};

/**
 * Organized the report into ordered sections with failed requirements
 * grouped at the top
 */
const organizeReport = (
  report: Record<string, ReportElement[]>
): OrganizedReportSection[] => {
  const organizedReport: OrganizedReportSection[] = [];
  let nextId = 0;

  const addSection = (
    id: string,
    name: string,
    requirements: ReportElement[]
  ) => {
    let sortedRequirements: ReportElement[];
    if (id === "submission-guideline") {
      sortedRequirements = requirements.map((req) => ({
        ...req,
        id: nextId++,
        ref: React.createRef(),
        status: "neutral",
      }));
    } else {
      // const failedRequirements = requirements.filter(
      //   (req) => req.status === "fail"
      // );
      // const passedRequirements = requirements.filter(
      //   (req) => req.status === "pass"
      // );
      // sortedRequirements = [...failedRequirements, ...passedRequirements].map(
      sortedRequirements = requirements.map((req) => ({
        ...req,
        id: nextId++,
        ref: React.createRef(),
      }));
    }

    organizedReport.push({
      id,
      name,
      requirements: sortedRequirements,
    });
  };

  const remainingSections = new Set(Object.keys(report));
  for (const id of IGNORED_SECTIONS) {
    if (report[id]) {
      remainingSections.delete(id);
    }
  }

  for (const { id, name } of EXPECTED_SECTIONS) {
    if (report[id]) {
      addSection(id, name, report[id]);
      remainingSections.delete(id);
    }
  }

  for (const id of remainingSections) {
    // Attempt a converion of the section id to a name
    // (convert dashes to spaces and capitalize the first letter)
    const name = id
      .split("-")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");
    addSection(id, name, report[id]);
  }

  return organizedReport;
};

export default FinalCheckDetailsContext;
