import { createPluginFactory } from "@udecode/plate";
import withLocalStorage from "./withLocalStorage";

export interface LocalStoragePlugin {
  docId: string;
}

const createLocalStoragePlugin = createPluginFactory<LocalStoragePlugin>({
  key: "local-storage",
  withOverrides: withLocalStorage,
});

export default createLocalStoragePlugin;
