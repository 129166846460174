import { SuggestedDelete, SuggestedFormat, SuggestedTextInsert, SuggestedKeep, SuggestedSplit, } from "../suggestions/SuggestedChange.js";
import { RefineCursor } from "./internal/RefineCursor.js";
import { odoElementHasAttribute } from "../odo-editor/odoElementHasAttribute.js";
export class RefinedContentGenerator {
    get currentElement() {
        if (this.refinedContent.length === 0) {
            return null;
        }
        return this.refinedContent[this.refinedContent.length - 1];
    }
    set currentElement(element) {
        if (element) {
            this.refinedContent[this.refinedContent.length - 1] = element;
        }
        else {
            this.refinedContent.pop();
        }
    }
    get currentSuggestionId() {
        return `${this.suggestionId}_${this.suggestionCountState.currentIndex}`;
    }
    constructor(newContent, oldContent, suggestionId) {
        this.refinedContent = [];
        this.pendingChanges = [];
        this.suggestedChange = null;
        this.suggestionCountState = {
            currentIndex: 0,
            writtenSuggestions: {},
        };
        this.cursor = new RefineCursor(newContent, oldContent);
        this.suggestionId = suggestionId;
    }
    generate() {
        this.refinedContent = this.cursor.contentToAddAtStart;
        for (const { value, change, stateChange } of this.cursor.generator()) {
            if (stateChange.hasNewElementPathChanged) {
                // We've moved on to a new block
                this.startNewBlock(change, stateChange);
            }
            switch (change.operation) {
                case "add":
                    if (value === "\n") {
                        this.insertNewline();
                    }
                    else {
                        this.insertCharacter(value, stateChange);
                    }
                    break;
                case "remove":
                    if (value === "\n") {
                        this.deleteNewline(stateChange);
                    }
                    else {
                        this.deleteCharacter(value, stateChange);
                    }
                    break;
                case "keep":
                    if (value === "\n") {
                        this.keepNewline();
                    }
                    else {
                        this.keepCharacter(value, stateChange);
                    }
                    break;
            }
        }
        this.finalizeNode();
        this.refinedContent = this.refinedContent.concat(this.cursor.contentToAddAtEnd);
        return {
            refinedContent: this.refinedContent,
            suggestionIds: Object.keys(this.suggestionCountState.writtenSuggestions),
        };
    }
    /**
     * Finalize the current node and then set up the new node as the current node
     *
     * This applies all of the structural changes that not relevant to the specific characters within a change.
     */
    startNewBlock(change, stateChange) {
        const path = [stateChange.new.path.next[0]];
        if (path.length === 0) {
            throw new Error("Cannot start a new block at the root");
        }
        // 1. Save any pending suggested changes
        this.finalizeNode();
        // 2. Increment the suggestion count to start a new group of suggestions
        this.suggestionCountState.currentIndex += 1;
        // 3. Update the current node
        if (stateChange.unwritableElements.length > 0) {
            this.refinedContent = this.refinedContent.concat(stateChange.unwritableElements);
            path[0] += stateChange.unwritableElements.length;
        }
        // Add the new element
        let element = Object.assign(Object.assign({}, stateChange.new.elementProps.next), { children: [] });
        if (change.operation === "keep" || change.operation === "remove") {
            // 4. Check for any element differences from old to new
            const diff = stateChange.elementChangesRequiredToGoFromNewToOld;
            if (Object.keys(diff).length > 0) {
                // There are differences so mark them as such
                element = RefinedContentGenerator.markSuggestionOnElement(element, { attributes: diff }, this.currentSuggestionId);
                this.suggestionCountState.writtenSuggestions[this.currentSuggestionId] =
                    true;
            }
        }
        this.refinedContent.push(element);
    }
    keepCharacter(char, stateChange) {
        if (!stateChange.havePropsChanged) {
            // We are keeping the character and no other attributes have changed
            if (this.suggestedChange instanceof SuggestedKeep) {
                this.suggestedChange.pushText(char);
            }
            else if (this.suggestedChange instanceof SuggestedFormat) {
                this.suggestedChange.pushText(char);
            }
            else {
                this.writeSuggestedChangeToPendingChanges();
                this.suggestedChange = this.createSuggestedChangeForSameCharacter(char, stateChange);
            }
        }
        else {
            // We are keeping the character but other attributes have changed
            // No matter what changed in old or new content, we will need a new formatting change
            // with the updated diff and attributes
            this.writeSuggestedChangeToPendingChanges();
            this.suggestedChange = this.createSuggestedChangeForSameCharacter(char, stateChange);
        }
    }
    insertCharacter(char, stateChange) {
        if (!stateChange.havePropsChanged) {
            // We are inserting a character but no other attributes have changed
            if (!(this.suggestedChange instanceof SuggestedTextInsert)) {
                this.writeSuggestedChangeToPendingChanges();
                this.suggestedChange = new SuggestedTextInsert(stateChange.new.attributes.next);
            }
            this.suggestedChange.pushText(char);
        }
        else {
            // We are inserting a character and some other attribute has changed
            this.writeSuggestedChangeToPendingChanges();
            // Create a new insert with the new attributes
            this.suggestedChange = new SuggestedTextInsert(stateChange.new.attributes.next);
            this.suggestedChange.pushText(char);
        }
    }
    deleteCharacter(char, change) {
        // 1. Any changes in attributes are handled internally to the delete suggestion
        // 2. Structural props cannot change without a newline where structural changes
        //    are handled
        this.writeDeletedCharacter(char, change.old.attributes.next);
    }
    keepNewline() {
        // Nothing to do
    }
    insertNewline() {
        // We've inserted a split where there wasn't one before
        // 1. Save any pending suggested changes
        this.writeSuggestedChangeToPendingChanges();
        // 2. Create a new suggested split
        this.suggestedChange = new SuggestedSplit();
        this.writeSuggestedChangeToPendingChanges();
    }
    deleteNewline(stateChange) {
        // We've removed a split where there was one before
        if (stateChange.unwritableElements.length > 0) {
            // Unwritable elements can be attached to the newlines to indicate we need to add them back
            this.writeSuggestedChangeToPendingChanges();
            for (const element of stateChange.unwritableElements) {
                const keep = new SuggestedKeep({}, element);
                keep.pushText("#");
                this.suggestedChange = keep;
                this.finalizeNode();
                this.suggestionCountState.currentIndex += 1;
            }
            // Don't insert a new line when rejecting the change
            return;
        }
        if (!(this.suggestedChange instanceof SuggestedDelete)) {
            this.writeSuggestedChangeToPendingChanges();
            this.suggestedChange = new SuggestedDelete();
        }
        this.suggestedChange.pushElementStart(stateChange.old.elementProps.next);
    }
    createSuggestedChangeForSameCharacter(character, stateChange) {
        const diff = stateChange.attributeChangesRequiredToGoFromNewToOld;
        let change;
        if (Object.keys(diff).length === 0) {
            // No difference between old and new props
            change = new SuggestedKeep(stateChange.new.attributes.next);
        }
        else {
            change = new SuggestedFormat(diff, stateChange.new.attributes.next);
        }
        change.pushText(character);
        return change;
    }
    static markSuggestionOnElement(element, metaChange, suggestionId) {
        if (!element) {
            throw new Error("Cannot mark suggestion on null element");
        }
        element.suggestion = true;
        element[`suggestion_${suggestionId}`] = true;
        const currentMeta = element.suggestion_meta;
        if (!currentMeta) {
            element.suggestion_meta = metaChange;
        }
        else {
            element.suggestion_meta = Object.assign(Object.assign({}, currentMeta), metaChange);
        }
        return element;
    }
    writeDeletedCharacter(char, attributes) {
        if (!(this.suggestedChange instanceof SuggestedDelete)) {
            this.writeSuggestedChangeToPendingChanges();
            this.suggestedChange = new SuggestedDelete();
        }
        this.suggestedChange.pushText(char, attributes);
    }
    writeSuggestedChangeToPendingChanges() {
        if (!this.suggestedChange) {
            return;
        }
        if (this.suggestedChange.isEmpty) {
            return;
        }
        if (this.pendingChanges.length > 0 &&
            this.suggestedChange instanceof SuggestedDelete &&
            this.pendingChanges[this.pendingChanges.length - 1] instanceof
                SuggestedDelete) {
            // We are adding a delete when we already have a deleted. We should
            // optimize that into a single deletion
            this.pendingChanges[this.pendingChanges.length - 1].pushFollowUpDeletion(this.suggestedChange);
        }
        else {
            this.pendingChanges.push(this.suggestedChange);
        }
        this.suggestedChange = null;
    }
    /**
     * Apply all of the pending suggestions to the current node
     *
     * Before this is called, it's assumed that the current node is an empty version
     * of the new content. For example, if the new content is a paragraph, the current
     * node wil be a paragraph with no text.
     *
     * Note: The most important edge case to consider here is when the pending
     * suggestions include element insertions. In an ideal world, that would be
     * the only pending suggestion since that element represents what the new
     * current node should be (in its entirety). However, sometimes suggestions
     * to adjust the content of previous or next nodes can leak into this current
     * element. In that situation, we need to move those suggestions to the next
     * node. This is done by leaving the suggestions pending so they are applied
     * to the next node.
     */
    writePendingChangesToCurrentElement() {
        if (!this.currentElement) {
            return;
        }
        this.normalizePendingSuggestions();
        if (this.pendingChanges.length === 0) {
            // Nothing to apply
            return;
        }
        if (this.pendingChanges.length > 0) {
            let updatedElement = this.currentElement;
            for (const suggestion of this.pendingChanges) {
                updatedElement.children.push(suggestion.encode(this.currentSuggestionId));
            }
            this.suggestionCountState.writtenSuggestions[this.currentSuggestionId] =
                true;
            this.currentElement = updatedElement;
            this.pendingChanges = [];
        }
    }
    normalizePendingSuggestions() {
        if (this.pendingChanges.length < 2) {
            return;
        }
        // If the suggestions start in a split and then an insert, we can mark
        // the whole element as a split and remove the text split
        // This is to avoid littering the text with new-line characters
        if (this.pendingChanges[0] instanceof SuggestedSplit &&
            this.pendingChanges[1] instanceof SuggestedTextInsert) {
            this.pendingChanges.splice(0, 1);
            RefinedContentGenerator.markSuggestionOnElement(this.currentElement, { split: true }, this.currentSuggestionId);
            this.suggestionCountState.writtenSuggestions[this.currentSuggestionId] =
                true;
        }
    }
    finalizeNode() {
        if (!this.currentElement) {
            return;
        }
        // 1. Save any pending suggested change
        this.writeSuggestedChangeToPendingChanges();
        // 2. Save any pending deltas
        this.writePendingChangesToCurrentElement();
        // 3. Clear any suggested changes that don't exist in the final content
        this.clearUnusedSuggestedChanges();
    }
    /**
     * Remove any suggested changes that don't exist in the final content
     */
    clearUnusedSuggestedChanges() {
        for (const suggestionId of Object.keys(this.suggestionCountState.writtenSuggestions).reverse()) {
            const key = `suggestion_${suggestionId}`;
            const fakeElement = {
                children: this.refinedContent,
            };
            if (!odoElementHasAttribute(fakeElement, key, true)) {
                // The suggestion doesn't exist in the final content so remove it
                delete this.suggestionCountState.writtenSuggestions[suggestionId];
            }
        }
    }
}
export default RefinedContentGenerator;
