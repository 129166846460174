import { PlateEditor, Value } from "@udecode/plate-common";
import { LocalStoragePlugin } from "./index";
import { WithPlatePlugin } from "@udecode/plate";
import { debounce } from "lodash";

const withLocalStorage = <
  V extends Value = Value,
  E extends PlateEditor<V> = PlateEditor<V>
>(
  editor: E,
  { options }: WithPlatePlugin<LocalStoragePlugin, V, E>
) => {
  const { apply } = editor;

  const saveToLocalStorage = debounce(() => {
    // Save the current value to local storage
    localStorage.setItem(options.docId, JSON.stringify(editor.children));
  }, 500);

  editor.apply = (op) => {
    apply(op);
    saveToLocalStorage();
  };

  // Set the initial value
  const children = localStorage.getItem(options.docId);
  if (!children) {
    // @ts-ignore
    editor.children = [{ type: "p", children: [{ text: "" }] }];
  } else {
    editor.children = JSON.parse(children);
  }

  return editor;
};

export default withLocalStorage;
