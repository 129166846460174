import Button from "components/common/Button";
import { usePromptRefineryContext } from "./PromptRefineryProvider";
import { useState } from "react";
import TextArea from "components/common/forms/TextArea";
import { cn } from "lib/utils";
import { useConfirm } from "providers/AlertProvider";
import LabeledFormInput from "components/common/forms/LabeledFormInput";

interface VariableManagerProps {}

const VariableManager: React.FC<VariableManagerProps> = () => {
  const { data } = usePromptRefineryContext();
  const confirm = useConfirm();
  const variables = data.getVariables();
  const variableGroups = data.data.valueGroups;
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const selectedGroup = variableGroups.find((g) => g.id === selectedGroupId);

  const handleGroupSelected = (groupId: string) => {
    setSelectedGroupId(groupId);
    data.applyValueGroup(groupId);
  };

  const handleDeleteGroup = async (groupId: string) => {
    // Confirm with user before deleting
    const response = await confirm(
      "Are you sure you want to delete this group?"
    );
    if (response) {
      data.deleteValueGroup(groupId);
    }
  };

  return (
    <div className="flex border rounded-sm max-h-[70vh] justify-stretch">
      <div className="flex flex-col border-r">
        <div className="flex flex-col overflow-y-auto overflow-x-hidden">
          {variableGroups.map((group) => {
            return (
              <div
                key={group.id}
                className="hover:bg-background-selected"
                onClick={() => handleGroupSelected(group.id)}
              >
                <input
                  value={group.name}
                  className={cn(
                    "p-md hover:bg-background-selected bg-background",
                    selectedGroupId === group.id && "bg-background-selected"
                  )}
                  onChange={(e) => {
                    data.setValueGroupName(group.id, e.target.value);
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="grow" />
        <div className="border-t flex justify-center">
          <Button
            text="Add Group"
            variant="solid-secondary"
            icon="plus"
            className="m-12"
            onClick={data.addValueGroup}
          />
        </div>
      </div>
      {selectedGroup ? (
        <div className="grow flex flex-col shrink-0">
          <div className="flex border-b">
            <Button
              icon="copy"
              text="Duplicate"
              className="m-12 text-primary"
              onClick={() => data.duplicateValueGroup(selectedGroup.id)}
            />
            <div className="grow" />
            <Button
              icon="trash"
              text="Delete"
              className="m-12 text-destructive"
              onClick={() => handleDeleteGroup(selectedGroup.id)}
            />
          </div>
          <div className="flex flex-col overflow-y-auto shrink grow">
            {variables.map((variable) => (
              <LabeledFormInput
                key={variable.id}
                label={variable.name}
                className="w-full p-md"
              >
                <TextArea
                  value={selectedGroup.values[variable.id] ?? ""}
                  className="min-h-[100px]"
                  onChange={(text) => {
                    data.setValueGroupValue(
                      selectedGroup.id,
                      variable.id,
                      text
                    );
                    data.applyValueGroup(selectedGroup.id);
                  }}
                />
              </LabeledFormInput>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col grow justify-center items-center">
          <p className="text-secondary">Select a group</p>
        </div>
      )}
    </div>
  );
};

export default VariableManager;
