import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
} from "@udecode/plate-heading";

import {
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
  MARK_UNDERLINE,
} from "@udecode/plate-basic-marks";

import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import {
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_SYNTAX,
} from "@udecode/plate-code-block";
import { ELEMENT_LINK } from "@udecode/plate-link";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import {
  ELEMENT_TABLE,
  ELEMENT_TD,
  ELEMENT_TH,
  ELEMENT_TR,
} from "@udecode/plate-table";
import { PlateLeaf, withProps } from "@udecode/plate";
import { CodeLeaf } from "components/EditorView/Leafs/CodeLeaf";
import { HeadingElement } from "components/EditorView/Elements/HeadingElement";
import { withPlaceholders } from "components/EditorView/Elements/Placeholder";
import { ParagraphElement } from "components/EditorView/Elements/ParagraphElement";
import { LinkElement } from "components/EditorView/Elements/LinkElement";
import { BlockquoteElement } from "components/EditorView/Elements/BlockquoteElement";
import { CodeBlockElement } from "components/EditorView/Elements/CodeBlockElement";
import { CodeSyntaxLeaf } from "components/EditorView/Leafs/CodeSyntaxLeaf";
import { TableElement } from "components/EditorView/Elements/TableElement";
import {
  TableCellElement,
  TableCellHeaderElement,
} from "components/EditorView/Elements/TableCellElement";
import { TableRowElement } from "components/EditorView/Elements/TableRowElement";
import { MARK_COMMENT, MARK_SUGGESTION, ELEMENT_REFINE } from "odo";
import { MARK_HIGHLIGHT } from "@udecode/plate-highlight";
import { ELEMENT_IMAGE } from "@udecode/plate-media";
import { ImageElement } from "components/EditorView/Elements/ImageElement";
import { ELEMENT_MENTION } from "./plugins/mentions/types";
import { MentionElement } from "./plugins/mentions/components/MentionElement";
import { ELEMENT_DELETED } from "./plugins/deleted";
import RefineElement from "components/proposals/refine/RefineElement";
import { DeletedElement } from "components/proposals/refine/DeletedElement";
import { CommentLeaf } from "components/comments/CommentLeaf";
import SuggestionLeaf from "components/suggestions/SuggestionLeaf";

const components = withPlaceholders({
  [ELEMENT_BLOCKQUOTE]: BlockquoteElement,
  [ELEMENT_CODE_BLOCK]: CodeBlockElement,
  [ELEMENT_CODE_SYNTAX]: CodeSyntaxLeaf,
  [ELEMENT_H1]: withProps(HeadingElement, { variant: "h1" }),
  [ELEMENT_H2]: withProps(HeadingElement, { variant: "h2" }),
  [ELEMENT_H3]: withProps(HeadingElement, { variant: "h3" }),
  [ELEMENT_H4]: withProps(HeadingElement, { variant: "h4" }),
  [ELEMENT_H5]: withProps(HeadingElement, { variant: "h5" }),
  [ELEMENT_H6]: withProps(HeadingElement, { variant: "h6" }),
  [ELEMENT_IMAGE]: ImageElement,
  [ELEMENT_LINK]: LinkElement,
  [ELEMENT_PARAGRAPH]: ParagraphElement({
    customPlaceholder: "Write something or type / for commands..",
  }),
  [ELEMENT_MENTION]: MentionElement,
  [ELEMENT_TABLE]: TableElement,
  [ELEMENT_TD]: TableCellElement,
  [ELEMENT_TH]: TableCellHeaderElement,
  [ELEMENT_TR]: TableRowElement,
  [ELEMENT_REFINE]: RefineElement,
  [ELEMENT_DELETED]: DeletedElement,
  [MARK_BOLD]: withProps(PlateLeaf, { as: "strong" }),
  [MARK_CODE]: CodeLeaf,
  [MARK_ITALIC]: withProps(PlateLeaf, { as: "em" }),
  [MARK_STRIKETHROUGH]: withProps(PlateLeaf, { as: "s" }),
  [MARK_SUBSCRIPT]: withProps(PlateLeaf, { as: "sub" }),
  [MARK_SUPERSCRIPT]: withProps(PlateLeaf, { as: "sup" }),
  [MARK_UNDERLINE]: withProps(PlateLeaf, { as: "u" }),
  [MARK_HIGHLIGHT]: withProps(PlateLeaf, {
    as: "mark",
    className: "bg-[yellow]",
  }),
  [MARK_COMMENT]: CommentLeaf,
  [MARK_SUGGESTION]: SuggestionLeaf,
});

export default components;
