import React, { KeyboardEventHandler, useMemo, useRef, useState } from "react";
import { useApiClient } from "../../../providers/ApiClientProvider";
import useImageUpload from "../../../api/useImageUpload";
import generatePlugins from "../../../lib/plate/plugins";
import { toast } from "react-toastify";
import RequirementContentEditorProvider from "../../../providers/RequirementContentEditorProvider";
import { Plate } from "@udecode/plate-common";
import { OdoEditor, OdoValue } from "odo";
import { TooltipProvider } from "../../EditorView/Menus/Tooltip";
import DebugPanel from "../../debug/DebugPanel";
import ProposalAnswerEditorContent from "./ProposalAnswerEditorContent";
import { cn } from "lib/utils";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";

interface ProposalRequirementAnswerEditorProps {
  proposalId: string;
  isTestEditor?: boolean;
  className?: string;
}

const ProposalEditor: React.FC<ProposalRequirementAnswerEditorProps> = ({
  proposalId,
  isTestEditor,
  className,
}) => {
  const apiClient = useApiClient();
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLDivElement>(null);
  const { uploadImage } = useImageUpload(apiClient, proposalId);
  const currentUser = useAuthenticatedUser();

  const plugins = useMemo(() => {
    return generatePlugins(
      proposalId,
      false,
      currentUser,
      uploadImage,
      (content: string) => {
        toast.error(content, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    );
  }, [currentUser, proposalId, uploadImage]);
  const [debugValue, setDebugValue] = useState<any>(null);
  const [isDebugging, setIsDebugging] = useState(false);

  const onKeyDown: KeyboardEventHandler<any> = (event) => {
    if (event.ctrlKey && event.altKey && event.shiftKey && event.metaKey) {
      switch (event.code) {
        case "KeyD":
          if (isDebugging) {
            setIsDebugging(false);
            break;
          }
          apiClient.metrics
            .canDebug()
            .then((response) => {
              if (response.data.can_debug) {
                setIsDebugging(true);
              }
            })
            .catch(() => {});
          break;
      }
    }
  };

  return (
    <Plate<OdoValue, OdoEditor>
      id="main"
      plugins={plugins}
      onChange={(value) => {
        setDebugValue(value);
      }}
    >
      <RequirementContentEditorProvider
        containerRef={containerRef}
        canvasRef={canvasRef}
      >
        <TooltipProvider>
          <div
            className={cn("grow flex flex-col", className)}
            onKeyDown={onKeyDown}
          >
            <ProposalAnswerEditorContent
              canvasRef={canvasRef}
              containerRef={containerRef}
              isTestEditor={isTestEditor}
            />
            {isDebugging && (
              <DebugPanel
                className="shrink-0 w-full"
                debugValue={debugValue}
                docId={proposalId}
                onClose={() => setIsDebugging(false)}
              />
            )}
          </div>
        </TooltipProvider>
      </RequirementContentEditorProvider>
    </Plate>
  );
};

export default ProposalEditor;
