const useFileHash = () => {
  return async (file: File) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    return new Promise<string>((resolve) => {
      reader.onload = async (e) => {
        const hash = await crypto.subtle.digest(
          "SHA-256",
          new Uint8Array(e.target?.result as ArrayBuffer)
        );
        resolve(
          Array.from(new Uint8Array(hash), (b) =>
            b.toString(16).padStart(2, "0")
          ).join("")
        );
      };
    });
  };
};

export default useFileHash;
