import { createPluginFactory, TElement } from "@udecode/plate-common";
import { v4 } from "uuid";

export const IF_ELSE_VAR = "if-else-var";

export interface IfElseVarProps extends TElement {
  id: string;
  conditionVarId: string;
  ifTrue: TElement[];
  ifFalse: TElement[];
}

export const createIfElseVarPlugin = createPluginFactory({
  key: IF_ELSE_VAR,
  isElement: true,
  isInline: true,
  isVoid: true,
});

export const generateIfElseVar = (conditionVarId: string) => {
  return {
    id: v4(),
    type: IF_ELSE_VAR,
    conditionVarId,
    ifTrue: [{ type: "p", children: [{ text: "" }] }],
    ifFalse: [{ type: "p", children: [{ text: "" }] }],
    children: [{ type: "p", children: [{ text: "" }] }],
  };
};
