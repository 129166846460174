const getIndentListNode = (element) => {
    let listStyleType = element.style.listStyleType;
    if (listStyleType === "") {
        listStyleType = "disc";
    }
    let indent = Number(element.getAttribute("aria-level")) || 0;
    // if (element.getAttribute("role") === "checkbox") {
    //   if (element.getAttribute("aria-checked") === "true") {
    //     listStyleType = "checked";
    //   } else {
    //     listStyleType = "unchecked";
    //   }
    //   for (let i = element.children.length - 1; i >= 0; i--) {
    //     const child = element.children[i];
    //     if (child instanceof HTMLImageElement) {
    //       // Replace this condition with your own
    //       element.removeChild(child);
    //     }
    //   }
    // }
    return {
        type: "p",
        listStyleType: listStyleType,
        indent: indent,
    };
};
export default getIndentListNode;
