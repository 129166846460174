import { OdoUser } from "odo";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Awareness } from "y-protocols/awareness";
import { useAuthenticatedUser } from "./AuthenticatedUserProvider";
import { FrontendUser } from "types/FrontendUser";

interface UserProviderContextType {
  users: FrontendUser[];
  awareness: Awareness | null;
  setAwareness: (awareness: Awareness | null) => void;
}

const UserProviderContext = createContext<UserProviderContextType | null>(null);

export const PresentUsersProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const currentUser = useAuthenticatedUser();
  const [awareness, setAwareness] = useState<Awareness | null>(null);
  const [userIds, setUserIds] = useState<Set<string>>(new Set());
  const [usersMap, setUsersMap] = useState<Map<string, FrontendUser>>(
    new Map()
  );

  const users = Array.from(userIds)
    .map((userId) => {
      return usersMap.get(userId);
    })
    .filter(
      (user) =>
        !!user &&
        user.publicId !== currentUser?.publicId &&
        user.publicId !== "odo"
    ) as FrontendUser[];

  const processStates = (states: any) => {
    for (const state of states.values()) {
      if (!state.data || !state.data.user) {
        continue;
      }
      // add user id to userIds set
      setUserIds((prev) => {
        const newSet = new Set(prev);
        newSet.add(state.data.user.id);
        return newSet;
      });
      // add user to users map
      setUsersMap((prev) => {
        const newMap = new Map(prev);
        newMap.set(state.data.user.id, state.data.user);
        return newMap;
      });
    }
    return [];
  };

  useEffect(() => {
    if (!awareness) {
      setUsersMap(new Map());
      setUserIds(new Set());
      return;
    }
    processStates(awareness.getStates());
    awareness.on("change", () => {
      processStates(awareness.getStates());
    });
  }, [awareness]);

  return (
    <UserProviderContext.Provider
      value={{
        users: users,
        awareness: awareness,
        setAwareness,
      }}
    >
      {children}
    </UserProviderContext.Provider>
  );
};

export const usePresentUsers = () => {
  const data = useContext(UserProviderContext);
  if (!data) {
    throw new Error(
      "usePresentUsers must be used within a PresentUsersProvider"
    );
  }
  return data.users;
};

export const useSetAwareness = () => {
  const data = useContext(UserProviderContext);
  if (!data) {
    throw new Error(
      "usePresentUsers must be used within a PresentUsersProvider"
    );
  }
  return data.setAwareness;
};
