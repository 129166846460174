import { OrgDetails } from "api/Api";
import Button from "components/common/Button";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Input from "components/common/forms/Input";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import NaturalHeightTextArea from "components/common/forms/NaturalHeightTextArea";
import Toggle from "components/common/forms/Toggle";
import NavigationView from "components/common/NavigationView";
import useFetchedData from "hooks/useFetchedData";
import SavedStateIndicator, {
  SaveState,
} from "components/common/forms/SavedStatusIndicator";
import { cn } from "lib/utils";
import { debounce } from "lodash";
import { useApiClient } from "providers/ApiClientProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { FC, forwardRef, useEffect, useRef, useState } from "react";
import { message_from_exception } from "utils";

interface Category {
  id: string;
  name: string;
  placeholderDescription: string;
  placeholderServices: string;
  placeholderCustomers: string;
}

const CATEGORIES: Category[] = [
  {
    id: "web-development",
    name: "Web Development",
    placeholderDescription:
      "Acme Co. is an interactive design and development agency based in New York City with over 12 years of experience delivering high-quality digital solutions for government clients. We are a certified women-owned small business (WOSB).",
    placeholderServices:
      "Acme has extensive experience in designing and developing interactive websites, with a deep understanding of web technologies and human interaction. The company's approach focuses on visual consistency, responsive design, and user experience, with a development team skilled primarily in Drupal and in other technologies like Wordpress, HTML/CSS, JavaScript/TypeScript, React, and Node.js.",
    placeholderCustomers:
      "Acme has successfully completed projects for clients in higher education in New York and Texas, such as Baruch College and Texas A&M University.",
  },
  {
    id: "marketing",
    name: "Marketing / Advertising",
    placeholderDescription:
      "Acme Co. is a full-service advertising agency in Southern California that specializes in digital creative work. Established in 2020, Acme is certified as a Small Business Enterprise (SBE).",
    placeholderServices:
      "The agency's unique capabilities include digital strategy, content creation, social media management, and data-driven analytics.",
    placeholderCustomers:
      "Acme has extensive experience in tourism and healthcare sectors, such as the California Office of Tourism.",
  },
  {
    id: "economic-consulting",
    name: "Economic Consulting",
    placeholderDescription:
      "Acme Co. is an economic development consultancy that focuses on creating strategic plans and economic development services for U.S. cities and municipalities.",
    placeholderServices:
      "Acme has advised local government, academia, non profits, and cities on topics such as downtown revitalization, feasibility studies, and strategic planning, and has won over $50 million in grant funding for client's projects.",
    placeholderCustomers:
      "Acme served over 30 clients in 15 states. Most of the clients are cities, counties, municipalities in the U.S.",
  },
  {
    id: "transportation",
    name: "Transportation",
    placeholderDescription:
      "Acme Co. is a transportation consulting firm that specializes in providing planning, design, and construction management services for transportation projects.",
    placeholderServices:
      "Acme specializes in transportation planning, traffic engineering, transit planning, and construction management.",
    placeholderCustomers:
      "Acme has completed over 100 projects in the past 10 years. Our clients include state DOTs, local municipalities, and private developers.",
  },
  {
    id: "auditing",
    name: "Auditing",
    placeholderDescription:
      "Acme Co. is an auditing firm with 20+ years of experience. We provide auditing services to both private and public clients.",
    placeholderServices:
      "Acme specializes in providing financial audits, such as annual financial statement audits, internal control audits, and compliance audits.",
    placeholderCustomers:
      "Acme has served over 10 counties in the past 12 years.",
  },
  {
    id: "clean-energy",
    name: "Clean Energy",
    placeholderDescription:
      "Acme Co. is a leading provider of clean energy solutions, offering a wide range of services from project feasibility to full-scale development. With decades of industry experience and technical proficiency, Acme is committed to delivering innovative and sustainable clean energy projects that meet the evolving needs of its clients.",
    placeholderServices:
      "Acme specializes in grant assistance for Solar Plus Storage for Resilient Communities, feasibility studies, C&I and utility-scale solar development, solar PV and energy storage design, community outreach, and comprehensive climate risk assessment.",
    placeholderCustomers:
      "We mostly serve public entities in the State of Washington and California.",
  },
  {
    id: "education",
    name: "Education",
    placeholderDescription:
      "Acme Co. offers a comprehensive learning management system to help schools administer, manage, and deliver engaging lessons to K-12 students.",
    placeholderServices:
      "Acme's software provides course management, user management, progress tracking & reporting, communication, and certification features. In addition, Acme is integrated with the top content management systems so you can turn on the software on day 1.",
    placeholderCustomers: "Acme is used across 40 school districts in the U.S.",
  },
];

const AccountRoute: FC = () => {
  const apiClient = useApiClient();
  const basicInfoRef = useRef<HTMLDivElement>(null);
  const recommendationProfileRef = useRef<HTMLDivElement>(null);
  const [highlightedSection, setHighlightedSection] = useState<
    "basic" | "recommend" | null
  >(null);

  const [saveState, setSaveState] = useState<SaveState>("unchanged");
  const [saveError, setSaveError] = useState<string | null>(null);
  const [localOrgDetails, doSetLocalOrgDetails] = useState<OrgDetails | null>(
    null
  );
  const [orgDetails, , { error }] = useFetchedData(async () => {
    const response = await apiClient.org.orgRead();
    return response.data;
  }, []);
  const currentUser = useAuthenticatedUser();

  const otherCategory = localOrgDetails?.categories.find(
    (c) => !CATEGORIES.map((c) => c.id).includes(c)
  );
  const otherCategorySelected = otherCategory !== undefined;

  const debouncedSave = useRef(
    debounce(async (details: OrgDetails) => {
      try {
        await apiClient.org.orgUpdate({
          ...details,
          name: details.name ? details.name : "Unnammed Company",
          categories: details?.categories.filter((c) => !!c),
        });
        setSaveState("saved");
        setSaveError(null);
      } catch (e) {
        setSaveState("error");
        setSaveError(message_from_exception(e));
      }
    }, 500)
  );

  useEffect(() => {
    if (orgDetails) {
      doSetLocalOrgDetails(orgDetails);
    }
  }, [orgDetails]);

  const setLocalOrgDetails = (details: OrgDetails) => {
    setSaveState("saving");
    doSetLocalOrgDetails(details);
    debouncedSave.current(details);
  };

  const setOtherCategory = (category: string | null) => {
    if (!localOrgDetails) return;

    const categories =
      localOrgDetails?.categories.filter((c) =>
        CATEGORIES.map((c) => c.id).includes(c)
      ) ?? [];

    if (category !== null) {
      categories.push(category);
    }

    setLocalOrgDetails({
      ...localOrgDetails,
      categories,
    });
  };

  const scrollToElement = (ref: React.RefObject<HTMLDivElement>) => {
    if (!ref.current) return;

    // Scroll to the element and flash it
    ref.current.scrollIntoView({ behavior: "smooth" });
    setHighlightedSection(ref === basicInfoRef ? "basic" : "recommend");
    setTimeout(() => {
      setHighlightedSection(null);
    }, 500);
  };

  const tryAgain = () => {
    if (localOrgDetails) {
      setSaveState("saving");
      setSaveError(null);
      debouncedSave.current(localOrgDetails);
    }
  };

  const toggleCategory = (category: string) => (on: boolean) => {
    if (!localOrgDetails) return;
    const newCategories = new Set<string>(localOrgDetails.categories ?? []);
    if (on) {
      newCategories.add(category);
    } else {
      newCategories.delete(category);
    }
    setLocalOrgDetails({
      ...localOrgDetails,
      categories: Array.from(newCategories),
    });
  };

  // Get the first category that the company is in
  let selectedCategory = CATEGORIES.find((c) =>
    localOrgDetails?.categories.includes(c.id)
  );
  if (!selectedCategory && !!otherCategory) {
    selectedCategory = {
      ...CATEGORIES[0],
      id: otherCategory,
      name: "Other",
    };
  }

  return (
    <NavigationView scrollable={false}>
      <AsyncLoadedDiv
        value={localOrgDetails}
        className="grow flex flex-col overflow-hidden"
        error={error ? message_from_exception(error) : null}
        whileLoaded={(details) => (
          <>
            {/********************** Header ***********************************/}
            <Columns className="items-center shrink-0 mb-md">
              <h1 className="text-2xl font-semibold grow shrink-0">
                Company Profile
              </h1>
              {saveError && (
                <p className="grow text-destructive text-right mr-md">
                  {saveError}
                </p>
              )}
              <SavedStateIndicator state={saveState} tryAgain={tryAgain} />
            </Columns>
            <Columns className="gap-lg border rounded-md">
              {/********************** Nav ***********************************/}
              <Rows className="items-start bg-background-secondary rounded-sm overflow-y-auto shrink-0 p-sm border-r hidden tablet:block">
                <Button
                  text="Basic Info"
                  className="pt-sm"
                  onClick={() => scrollToElement(basicInfoRef)}
                />
                <Button
                  text="Profile"
                  className="pt-sm"
                  onClick={() => scrollToElement(recommendationProfileRef)}
                />
              </Rows>
              {/********************** Sections ***********************************/}
              <Rows className="grow gap-2xl overflow-y-auto">
                {/********************** Basic Info ***********************************/}
                <Section
                  title="Basic Info"
                  ref={basicInfoRef}
                  highlighted={highlightedSection === "basic"}
                >
                  <Field title="Company Name*">
                    <Input
                      placeholder="Acme Corporation"
                      value={details.name}
                      onChange={(e) => {
                        setLocalOrgDetails({
                          ...details,
                          name: e.target.value,
                        });
                      }}
                    />
                  </Field>
                  <Field title="Categories*" instructions="Choose at least one">
                    <Rows className="items-start">
                      {CATEGORIES.map((category) => (
                        <Toggle
                          key={category.id}
                          toggleType="checkbox"
                          text={category.name}
                          on={
                            details.categories?.includes(category.id) ?? false
                          }
                          onToggle={toggleCategory(category.id)}
                        />
                      ))}
                      <Toggle
                        toggleType="checkbox"
                        text="Other"
                        on={otherCategorySelected}
                        onToggle={(on) => setOtherCategory(on ? "" : null)}
                      />
                      {otherCategorySelected && (
                        <Input
                          placeholder="Other Category"
                          className="mt-sm"
                          value={otherCategory}
                          onChange={(e) => setOtherCategory(e.target.value)}
                        />
                      )}
                    </Rows>
                  </Field>
                </Section>
                {/********************** Profile ***********************************/}
                <Section
                  title="Profile"
                  ref={recommendationProfileRef}
                  highlighted={highlightedSection === "recommend"}
                  instructions="Tell us about your company to peronalize your RFP recommendations."
                  className={
                    !!selectedCategory ? "" : "opacity-30 pointer-events-none"
                  }
                >
                  <Field
                    title="Company Description"
                    instructions="In one or two sentences, describe your company."
                  >
                    <NaturalHeightTextArea
                      placeholder={selectedCategory?.placeholderDescription}
                      characterLimit={4096}
                      className="tablet:min-h-[90px] min-h-[150px]"
                      value={details.description ?? ""}
                      onChange={(text) => {
                        setLocalOrgDetails({
                          ...details,
                          description: text,
                        });
                      }}
                      disabled={!selectedCategory}
                    />
                  </Field>
                  <Field
                    title="Services"
                    instructions="What are your primary services? What makes you unique?"
                  >
                    <NaturalHeightTextArea
                      placeholder={selectedCategory?.placeholderServices}
                      className="tablet:min-h-[90px] min-h-[150px]"
                      characterLimit={4096}
                      value={details.services ?? ""}
                      onChange={(text) =>
                        setLocalOrgDetails({
                          ...details,
                          services: text,
                        })
                      }
                      disabled={!selectedCategory}
                    />
                  </Field>
                  <Field
                    title="Customers"
                    instructions="Who are your customers? What industries do they work in?"
                  >
                    <NaturalHeightTextArea
                      placeholder={selectedCategory?.placeholderCustomers}
                      className="tablet:min-h-[90px] min-h-[150px]"
                      characterLimit={4096}
                      value={details.customers ?? ""}
                      onChange={(text) =>
                        setLocalOrgDetails({
                          ...details,
                          customers: text,
                        })
                      }
                      disabled={!selectedCategory}
                    />
                  </Field>
                  {currentUser.isStaff && (
                    <Field title="Additional Instructions">
                      <NaturalHeightTextArea
                        className="tablet:min-h-[90px] min-h-[150px] admin border-primary"
                        characterLimit={4096}
                        value={details.profile ?? ""}
                        onChange={(text) =>
                          setLocalOrgDetails({
                            ...details,
                            profile: text,
                          })
                        }
                        disabled={!selectedCategory}
                      />
                    </Field>
                  )}
                </Section>
              </Rows>
            </Columns>
          </>
        )}
      />
    </NavigationView>
  );
};

interface SectionProps {
  title: string;
  instructions?: string;
  children?: React.ReactNode;
  highlighted?: boolean;
  className?: string;
}

const Section = forwardRef<HTMLDivElement, SectionProps>(
  ({ title, instructions, highlighted, className, children }, ref) => {
    return (
      <Rows
        ref={ref}
        className={cn("gap-lg shrink-0 p-md relative", className)}
      >
        <Rows className="gap-sm shrink-0">
          <h2 className="text-xl font-semibold border-b py-sm">{title}</h2>
          {instructions && <p>{instructions}</p>}
        </Rows>
        <Rows className="gap-xl">{children}</Rows>
        <div
          className="bg-background-secondary absolute z-[-1] inset-0 transition-opacity duration-300"
          style={{ opacity: highlighted ? 1 : 0 }}
        />
      </Rows>
    );
  }
);

interface FieldProps {
  title: string;
  instructions?: string;
  children?: React.ReactNode;
}

const Field: FC<FieldProps> = ({ title, instructions, children }) => {
  return (
    <LabeledFormInput label={title} variant="long-form">
      {instructions && <p className="text-secondary">{instructions}</p>}
      <Rows className="mt-sm justify-start">{children}</Rows>
    </LabeledFormInput>
  );
};

export default AccountRoute;
