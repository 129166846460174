import { RFPBlock } from "api/Api";
import Button from "components/common/Button";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Scrollable from "components/common/containers/Scrollable";
import { cn } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { useEffect, useRef, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { message_from_exception } from "utils";
import RFPPDFBlockView from "./RFPPDFBlockView";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface RFPPDFViewProps {
  rfpId: string;
}

const RFPPDFView: React.FC<RFPPDFViewProps> = ({ rfpId }) => {
  const apiClient = useApiClient();
  const [pageCount, setPageCount] = useState(0);
  const [pageHeight, setPageHeight] = useState(1);
  const [pageWidth, setPageWidth] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerHeight = (pageHeight / pageWidth) * containerWidth;
  const containerRef = useRef<HTMLDivElement>();

  const scrollContainer = useRef<HTMLDivElement>();
  const [rfpUrl, setRfpUrl] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [blocks, setBlocks] = useState<RFPBlock[]>([]);

  useEffect(() => {
    const fetchRFP = async () => {
      try {
        const response = await apiClient.rfp.rfpRfpRead(rfpId);
        setRfpUrl(response.data.view_url!);
      } catch (e) {
        setLoadingError(message_from_exception(e));
      }
    };
    fetchRFP();
  }, [apiClient.rfp, rfpId]);

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      setContainerWidth(width);
    });
    resizeObserver.observe(containerRef.current);
    setContainerWidth(containerRef.current.clientWidth);
    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  useEffect(() => {
    setBlocks([]);

    const fetchBlocks = async () => {
      try {
        const response = await apiClient.rfp.rfpRfpBlocksList(
          rfpId,
          currentPage.toString()
        );
        setBlocks(response.data);
      } catch (e) {
        setLoadingError(message_from_exception(e));
      }
    };
    fetchBlocks();
  }, [apiClient.rfp, currentPage, rfpId]);

  return (
    <Rows ref={containerRef as any}>
      <AsyncLoadedDiv
        value={rfpUrl}
        error={loadingError}
        className="grow flex overflow-hidden w-full"
        whileLoaded={(url) => (
          <Rows className={cn("grow w-full temp")}>
            <Scrollable
              className="border grow rounded-sm relative"
              ref={scrollContainer as any}
              style={{ width: containerWidth, height: containerHeight }}
            >
              <Document
                file={url}
                className={cn("flex items-center justify-center")}
                onLoadSuccess={(doc) => {
                  setPageCount(doc.numPages);
                  setIsLoaded(true);
                  setLoadingError(null);
                }}
                onLoadError={(error) => {
                  setIsLoaded(false);
                  setLoadingError(message_from_exception(error));
                }}
              >
                {isLoaded && !loadingError && pageCount > 0 && (
                  <Page
                    pageNumber={currentPage}
                    className="relative"
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    width={containerWidth}
                    height={containerHeight}
                    onLoadSuccess={(page) => {
                      setPageHeight(page.height);
                      setPageWidth(page.width);
                    }}
                  >
                    {/* {activeRequirement &&
                    activeRequirement.bbox &&
                    activeRequirement.bbox.page === currentPage &&
                    pageHeight > 0 &&
                    pageWidth > 0 && (
                      <div
                        ref={highlightRef as any}
                        className={cn(
                          "border border-[3px] rounded-sm absolute",
                          activeRequirement.status === "pass"
                            ? "border-[#7CB632]"
                            : activeRequirement.status === "fail"
                            ? "border-destructive"
                            : "border-secondary"
                        )}
                        style={bboxToStyle(
                          activeRequirement.bbox,
                          pageWidth,
                          pageHeight
                        )}
                      />
                    )} */}
                    {blocks.map((block) => (
                      <RFPPDFBlockView
                        key={block.index}
                        block={block}
                        pageWidth={pageWidth}
                        pageHeight={pageHeight}
                      />
                    ))}
                  </Page>
                )}
              </Document>
              {/* {loadingError && (
              <Rows className="absolute inset-0">
                <MessageView
                  title="Error Loading RFP"
                  icon="circle-exclamation"
                  className="grow"
                >
                  <p className="text-destructive">
                    {loadingError ?? "Fake error"}
                  </p>
                </MessageView>
              </Rows>
            )} */}
              {/* {!isLoaded && !loadingError && (
              <Rows className="absolute inset-0">
                <CenteredContainer className="absolute inset-0 bg-background">
                  <Spinner text="Loading RFP..." />
                </CenteredContainer>
              </Rows>
            )} */}
            </Scrollable>
            <Columns className="shrink-0 justify-center py-md shrink-0">
              <Columns
                className={cn(
                  "gap-lg items-center transition-opacity",
                  isLoaded ? "opacity-100" : "opacity-0"
                )}
              >
                <Button
                  icon="arrow-left"
                  variant="solid-secondary"
                  className="px-sm"
                  onClick={() => setCurrentPage((p) => Math.max(p - 1, 1))}
                />
                <p>
                  {currentPage} / {pageCount}
                </p>
                <Button
                  icon="arrow-right"
                  variant="solid-secondary"
                  className="px-sm"
                  onClick={() =>
                    setCurrentPage((p) => Math.min(p + 1, pageCount))
                  }
                />
              </Columns>
            </Columns>
          </Rows>
        )}
      />
    </Rows>
  );
};

const bboxToStyle = (
  bbox: { top: number; left: number; width: number; height: number },
  fullWidth: number,
  fullHeight: number
) => {
  return {
    top: `${bbox.top * fullHeight - 8}px`,
    left: `${bbox.left * fullWidth - 8}px`,
    width: `${bbox.width * fullWidth + 16}px`,
    height: `${bbox.height * fullHeight + 16}px`,
  };
};

export default RFPPDFView;
