import { useApiClient } from "providers/ApiClientProvider";
import { message_from_exception } from "utils";
import { format_date } from "lib/utils";
import { toast } from "react-toastify";
import Button from "components/common/Button";
import usePaginatedData from "hooks/usePaginatedData";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import { useEffect, useRef, useState } from "react";
import Overlay from "components/common/containers/overlays/Overlay";
import useFileHash from "hooks/useFileHash";
import FileInput, { FileInputRef } from "components/common/forms/FileInput";
import Spinner from "components/common/Spinner";
import Pill from "components/common/Pill";
import { Tooltip, TooltipContent } from "components/EditorView/Menus/Tooltip";
import { TooltipTrigger } from "@radix-ui/react-tooltip";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import CenteredContainer from "components/common/containers/CenteredContainer";
import useTriggerDownload from "hooks/useTriggerDownload";
import RFPDetailsOverlay from "./RFPDetailsOverlay";

interface RFP {
  id: string;
  name: string;
  issuingOrg: string | null;
  created: string;
  processingStatus: string;
  nextStep: string | null;
  foiaId: number | null;
  hasPDF: boolean;
  hasMarkdown: boolean;
  orgs: string[];
  categories: string[];
}

const RFPsRoute = () => {
  const apiClient = useApiClient();
  const triggerDownload = useTriggerDownload();
  const [addingRFP, setAddingRFP] = useState(false);
  const [viewingRFP, setViewingRFP] = useState<RFP | null>(null);
  const [rfps, setRfps, paginatedData] = usePaginatedData({
    endpoint: apiClient.rfp.rfpRfpListList,
    filters: [
      { key: "has_proposals", name: "Only with Proposal", default: false },
    ],
    map: (rfp): RFP => {
      return {
        id: rfp.id!,
        name: rfp.display_name!,
        issuingOrg: rfp.issuing_org ?? "unknown",
        created: rfp.created!,
        processingStatus: rfp.processing_status ?? "unknown",
        nextStep: rfp.next_step ?? null,
        hasMarkdown: rfp.has_markdown!,
        hasPDF: rfp.is_uploaded!,
        orgs: rfp.orgs ?? [],
        categories: rfp.categories ?? [],
        foiaId: rfp.foia_request_job_id ?? null,
      };
    },
  });
  const { refresh } = paginatedData;

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard", { position: "bottom-center" });
  };

  const getDetials = async (rfpId: string) => {
    try {
      const response = await apiClient.rfp.rfpRfpRead(rfpId);
      return response.data;
    } catch (e) {
      const message = message_from_exception(e);
      toast.error(message, { position: "bottom-center" });
      return null;
    }
  };

  const handleViewPDF = async (rfpId: string) => {
    const details = await getDetials(rfpId);
    if (!details) {
      return;
    }

    if (!details.view_url) {
      toast.error("No PDF uploaded yet", { position: "bottom-center" });
      return;
    }

    triggerDownload(details.view_url, "tab");
  };

  const handleViewMarkdown = async (rfpId: string) => {
    const details = await getDetials(rfpId);
    if (!details) {
      return;
    }
    if (!details.markdown_url) {
      toast.error("No markdown generated yet", { position: "bottom-center" });
      return;
    }
    triggerDownload(details.markdown_url);
  };

  const triggerFoia = async (rfpId: string) => {
    try {
      const response = await apiClient.rfp.rfpRfpFoiaCreate(rfpId);
      const foiaId = response.data.job_id;
      if (foiaId) {
        setRfps((rfps) => {
          if (!rfps) return rfps;

          return rfps.map((rfp) =>
            rfp.id === rfpId ? { ...rfp, foiaId: foiaId } : rfp
          ) as RFP[];
        });
      }
    } catch (e) {
      const message = message_from_exception(e);
      toast.error(message, { position: "bottom-center" });
    }
  };

  return (
    <Rows className="p-lg">
      <Columns className="items-center gap-md mb-md shrink-0">
        <h1 className="text-2xl font-semibold">RFP Admin</h1>
        <Button
          icon="plus"
          variant="solid"
          onClick={() => setAddingRFP(true)}
        />
      </Columns>
      <PaginatedTableView
        results={rfps}
        paginatedData={paginatedData}
        searchable={true}
        columns={[
          { name: "Created", size: "min" },
          { name: "Name" },
          { name: "Issuer", size: "min" },
          { name: "Proposals", size: "min" },
          { name: "Categories", size: "min" },
          { name: "PDF", size: "min" },
          { name: "Content", size: "min" },
          { name: "Status", size: "min" },
          { name: "Next", size: "min" },
          { name: "FOIA", size: "min" },
          { name: "Id", size: "min" },
        ]}
        onSelect={(rfp) => setViewingRFP(rfp)}
        renderRow={(rfp, Cell, Row) => (
          <Row key={rfp.id}>
            <Cell>{format_date(rfp.created)}</Cell>
            <Cell>{rfp.name}</Cell>
            <Cell>{rfp.issuingOrg}</Cell>
            <Cell className="overflow-hidden">
              <Tooltip>
                <TooltipTrigger className="flex gap-xs cursor-default">
                  {rfp.orgs.map((org) => {
                    return (
                      <Pill text={org} className="inline-block min-w-[48px]" />
                    );
                  })}
                </TooltipTrigger>
                <TooltipContent>
                  <Rows className="gap-xs">
                    {rfp.orgs.map((org) => (
                      <p className="inline-block min-w-[48px]">{org}</p>
                    ))}
                  </Rows>
                </TooltipContent>
              </Tooltip>
            </Cell>
            <Cell className="overflow-hidden">
              <Tooltip>
                <TooltipTrigger className="flex gap-xs cursor-default">
                  {rfp.categories.map((cat) => {
                    return (
                      <Pill text={cat} className="inline-block min-w-[48px]" />
                    );
                  })}
                </TooltipTrigger>
                <TooltipContent>
                  <Rows className="gap-xs">
                    {rfp.categories.map((cat) => (
                      <p className="inline-block min-w-[48px]">{cat}</p>
                    ))}
                  </Rows>
                </TooltipContent>
              </Tooltip>
            </Cell>
            <Cell center={true}>
              {rfp.hasPDF ? (
                <Button icon="eye" onClick={() => handleViewPDF(rfp.id)} />
              ) : (
                "N/A"
              )}
            </Cell>
            <Cell center={true}>
              {rfp.hasMarkdown ? (
                <Button icon="eye" onClick={() => handleViewMarkdown(rfp.id)} />
              ) : (
                "N/A"
              )}
            </Cell>
            <Cell center={true}>{rfp.processingStatus}</Cell>
            <Cell center={true}>{rfp.nextStep ?? "-"}</Cell>
            <Cell center={true}>
              {rfp.foiaId ? (
                rfp.foiaId
              ) : (
                <Button icon="inbox-in" onClick={() => triggerFoia(rfp.id)} />
              )}
            </Cell>
            <Cell center={true} className="text-center">
              <Button
                icon="copy"
                tooltip={rfp.id}
                onClick={() => copyToClipboard(rfp.id)}
              />
            </Cell>
          </Row>
        )}
      />
      <Overlay
        title="Add RFP"
        maxWidth={500}
        className="w-full"
        open={addingRFP}
        onClose={() => setAddingRFP(false)}
      >
        <AddRFPForm onRfpUploaded={refresh} />
      </Overlay>
      {viewingRFP && (
        <RFPDetailsOverlay
          onDismiss={() => setViewingRFP(null)}
          rfpId={viewingRFP.id}
          rfpName={viewingRFP.name}
        />
      )}
    </Rows>
  );
};

const AddRFPForm = ({ onRfpUploaded }: { onRfpUploaded: () => void }) => {
  const apiClient = useApiClient();
  const fileInputRef = useRef<FileInputRef | null>(null);
  const hashFile = useFileHash();
  const [uploading, setUploading] = useState<File | null>();
  const [error, setError] = useState<string | null>();

  useEffect(() => {
    if (!uploading) {
      return;
    }

    const upload = async () => {
      try {
        const name = uploading.name.split(".").slice(0, -1).join(".");
        const hash = await hashFile(uploading);
        const createResponse = await apiClient.rfp.rfpRfpCreate({
          name,
          hash,
          for_admin: true,
        });

        if (createResponse.data.upload_url) {
          // We have to upload the file to this url
          await fetch(createResponse.data.upload_url, {
            method: "PUT",
            body: uploading,
          });
        } else {
          toast.info("File already uploaded");
        }

        await apiClient.rfp.rfpRfpListMatchCreate(createResponse.data.id!);
        onRfpUploaded();
      } catch (e) {
        setError(message_from_exception(e));
      } finally {
        setUploading(null);
        fileInputRef.current?.clear();
      }
    };

    upload();
  }, [apiClient.rfp, hashFile, uploading]);

  if (error) {
    return (
      <Rows className="gap-md">
        <p className="text-destructive text-center">{error}</p>
        <Button
          variant="solid"
          text="Try Again"
          onClick={() => setError(null)}
        />
      </Rows>
    );
  }

  if (!!uploading) {
    return (
      <CenteredContainer>
        <Spinner className="mx-auto" />
      </CenteredContainer>
    );
  }

  return (
    <FileInput
      ref={fileInputRef}
      accept="application/pdf"
      onFileChanged={(file) => {
        setUploading(file);
      }}
    />
  );
};

export default RFPsRoute;
