import { FC } from "react";
import Pill from "../Pill";

export type SaveState = "unchanged" | "saving" | "saved" | "error";

const SavedStateIndicator: FC<{ state: SaveState; tryAgain: () => void }> = ({
  state,
  tryAgain,
}) => {
  switch (state) {
    case "saved":
      return (
        <Pill
          icon="circle-check"
          iconVariant="solid"
          text="Saved"
          className="bg-background-secondary shrink-0"
        />
      );
    case "saving":
      return (
        <Pill
          icon="circle-check"
          iconVariant="solid"
          text="Saving..."
          className="bg-[#F9D041] text-[black] shrink-0"
        />
      );
    case "error":
      return (
        <Pill
          icon="circle-exclamation"
          iconVariant="solid"
          text="Error"
          className="bg-destructive text-background shrink-0 cursor-pointer"
          onClick={tryAgain}
        />
      );
    default:
      return null;
  }
};

export default SavedStateIndicator;
