import { ProjectDetails } from "api/Api";
import Button from "components/common/Button";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import CollaborativeTextArea from "components/common/forms/CollaborativeTextArea";
import Input from "components/common/forms/Input";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import NaturalHeightTextArea from "components/common/forms/NaturalHeightTextArea";
import SavedStateIndicator, {
  SaveState,
} from "components/common/forms/SavedStatusIndicator";
import useFetchedData from "hooks/useFetchedData";
import { debounce } from "lodash";
import { useConfirm } from "providers/AlertProvider";
import { useApiClient } from "providers/ApiClientProvider";
import { FC, useRef, useState } from "react";
import { message_from_exception } from "utils";

interface ProjectDetailsViewProps {
  projectId: string;
  onChanged: (project: ProjectDetails) => void;
  onSaveStateChanged?: (state: SaveState) => void;
  remove: () => void;
}

const ProjectDetailsView: FC<ProjectDetailsViewProps> = ({
  projectId,
  remove,
  onChanged,
  onSaveStateChanged,
}) => {
  const apiClient = useApiClient();
  const [saveState, doSetSaveState] = useState<SaveState>("unchanged");
  const [saveError, setSaveError] = useState<string | null>(null);
  const [details, doSetDetails, { error }] = useFetchedData(async () => {
    const result = await apiClient.rfp.rfpProjectRead(projectId);
    return result.data;
  }, [projectId]);
  const confirm = useConfirm();

  const setSaveState = (state: SaveState) => {
    doSetSaveState(state);
    onSaveStateChanged?.(state);
  };

  const debouncedSave = useRef(
    debounce(async (details: ProjectDetails) => {
      try {
        await apiClient.rfp.rfpProjectUpdate(projectId, details);
        setSaveState("saved");
        setSaveError(null);
      } catch (e) {
        setSaveState("error");
        setSaveError(message_from_exception(e));
      }
    }, 500)
  );

  const setDetails = (details: ProjectDetails) => {
    setSaveState("saving");
    doSetDetails(details);
    onChanged(details);
    debouncedSave.current(details);
  };

  const tryAgain = () => {
    if (details) {
      setSaveState("saving");
      setSaveError(null);
      debouncedSave.current(details);
    }
  };

  const handleDeleteProject = async () => {
    const result = await confirm(
      "Are you sure you want to delete this project?",
      {
        yesText: "Delete Project",
        yesDestructive: true,
      }
    );
    if (result) {
      await apiClient.rfp.rfpProjectDelete(projectId);
      remove();
    }
  };

  return (
    <AsyncLoadedDiv
      value={details}
      error={!!error ? message_from_exception(error) : undefined}
      className="grow p-lg pt-0 overflow-y-auto flex"
      whileLoaded={(details) => (
        <Rows className="grow">
          <Columns className="items-center sticky top-0 pt-lg pb-md bg-background">
            <h1 className="text-lg font-semibold grow shrink-0">
              Project Details
            </h1>
            {saveError && (
              <p className="grow text-destructive text-right pr-md">
                {saveError}
              </p>
            )}
            <SavedStateIndicator state={saveState} tryAgain={tryAgain} />
          </Columns>
          <Rows className="grow gap-md justify-start">
            <LabeledFormInput label="Project Name">
              <Input
                placeholder="A short name for this project"
                value={details.title}
                onChange={(e) =>
                  setDetails({ ...details, title: e.target.value })
                }
              />
            </LabeledFormInput>
            <LabeledFormInput label="Customer">
              <Input
                placeholder="Who this project was for"
                value={details.customer}
                onChange={(e) =>
                  setDetails({ ...details, customer: e.target.value })
                }
              />
            </LabeledFormInput>
            <LabeledFormInput label="Location">
              <Input
                placeholder="Where this project took place"
                value={details.location}
                onChange={(e) =>
                  setDetails({ ...details, location: e.target.value })
                }
              />
            </LabeledFormInput>
            <LabeledFormInput
              label="Description"
              className="grow overflow-hidden"
            >
              {/* <NaturalHeightTextArea
                placeholder="A detailed description of this project"
                value={details.descriptio}
                className="min-h-[160px] px-md"
                onChange={(text) =>
                  setDetails({ ...details, description: text })
                }
              /> */}
              <CollaborativeTextArea
                placeholder="A detailed description of this project"
                className="grow"
                docId={details.description_id!}
                key={details.description_id}
              />
            </LabeledFormInput>
            <Columns className="justify-end shrink-0">
              <Button
                text="Delete Project"
                icon="trash"
                variant="destructive"
                onClick={handleDeleteProject}
              />
            </Columns>
          </Rows>
        </Rows>
      )}
    />
  );
};

export default ProjectDetailsView;
