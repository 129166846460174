import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { snapCenterToCursor } from "@dnd-kit/modifiers";
import { useEditorRef } from "@udecode/plate-common";
import Pill from "components/common/Pill";
import {
  addBlockToSection,
  getPreviousSections,
  getSection,
} from "lib/plate/getPreviousSection";
import { textFromOdoElement } from "odo";
import { useChoose } from "providers/AlertProvider";
import { useApiClient } from "providers/ApiClientProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import {
  DropLocation,
  useEditorDocData,
} from "providers/RequirementContentEditorProvider";
import { FC } from "react";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";

interface ProposalDragDropProps {
  children?: React.ReactNode;
}

const ProposalDragDrop: FC<ProposalDragDropProps> = ({ children }) => {
  const apiClient = useApiClient();
  const editor = useEditorRef();
  const { details } = useProposalData();
  const { activeSection, setDroppingSection } = useEditorDocData();
  const choose = useChoose();

  const handleDragStart = (event: DragStartEvent) => {};
  const handleDragEnd = async (event: DragEndEvent) => {
    if (activeSection) {
      try {
        const sectionNode = getSection(activeSection.id, editor);
        if (!sectionNode) {
          throw new Error("Section not found");
        }
        const previousSections = getPreviousSections(
          activeSection.id,
          editor,
          3
        );
        let previousNames = previousSections?.map((s) => s.name.trim()) ?? [];
        previousNames = previousNames.filter((n) => n.length > 0);
        if (activeSection.mode === "into") {
          console.log("Dropped into", activeSection.id);
          const result = await choose(
            "How would you like to incorporate this?",
            {
              dismissId: "cancel",
              choices: [
                {
                  id: "update",
                  text: "Update Section",
                },
                {
                  id: "add",
                  text: "Add Subsection",
                },
              ],
            }
          );
          if (result === "add") {
            const level = sectionNode.level + 1;
            const previousName = activeSection.name.trim();
            if (previousName.length > 0) {
              previousNames.push(previousName);
            }
            await apiClient.rfp.rfpProposalAddRequirementCreate(details.id, {
              target_id: activeSection.id,
              target_name: activeSection.name,
              mode: "add-below",
              level,
              rfp_block_id: event.active.id.toString(),
              previous_headings: previousNames,
            });
          } else if (result === "update") {
            addBlockToSection(
              (event.active.data.current as any).block,
              activeSection.id,
              editor
            );
            await apiClient.rfp.rfpProposalAddRequirementCreate(details.id, {
              target_id: activeSection.id,
              target_name: activeSection.name,
              mode: "rewrite",
              level: -1,
              rfp_block_id: event.active.id.toString(),
            });
          } else if (result === "cancel") {
            return;
          } else {
            throw new Error("Invalid choice");
          }
        } else if (activeSection.mode === "above") {
          console.log("Dropped above", activeSection.id);
          let level = 1;
          if (previousSections && previousSections.length > 0) {
            level = previousSections[previousSections.length - 1].level;
          }
          await apiClient.rfp.rfpProposalAddRequirementCreate(details.id, {
            target_id: activeSection.id,
            target_name: activeSection.name,
            mode: "add-above",
            level,
            rfp_block_id: event.active.id.toString(),
            previous_headings: previousNames,
          });
        } else if (activeSection.mode === "below") {
          console.log("Dropped below", activeSection.id);
          const previousName = activeSection.name.trim();
          if (previousName.length > 0) {
            previousNames.push(previousName);
          }
          const level = sectionNode.level;
          await apiClient.rfp.rfpProposalAddRequirementCreate(details.id, {
            target_id: activeSection.id,
            target_name: activeSection.name,
            mode: "add-below",
            level,
            rfp_block_id: event.active.id.toString(),
            previous_headings: previousNames,
          });
        }
      } catch (e) {
        toast.error(message_from_exception(e));
      }
    }
    setDroppingSection(null);
  };
  const handleDragOver = (event: DragOverEvent) => {
    const fullId = (event.over?.id as string) ?? null;
    if (fullId) {
      const id = fullId.split("_")[0];
      const location = fullId.split("_")[1];
      const section = getSection(id, editor);
      setDroppingSection({
        id,
        location: location as DropLocation,
        name: section?.name ?? "",
      });
    } else {
      setDroppingSection(null);
    }
  };

  const handleDragCancel = () => {
    setDroppingSection(null);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    })
  );

  return (
    <DndContext
      collisionDetection={pointerWithin}
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDragCancel={handleDragCancel}
    >
      {children}
      <DragOverlay
        className="flex items-center justify-center"
        modifiers={[snapCenterToCursor]}
        dropAnimation={null}
      >
        <Pill
          text="Requirement"
          icon="square-check"
          className="bg-primary cursor-grabbing text-xs text-[white]"
        />
      </DragOverlay>
    </DndContext>
  );
};

export default ProposalDragDrop;
