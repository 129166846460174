import { createPluginFactory } from "@udecode/plate-common";
import withTrackChanges from "./withTrackChanges";

const KEY_DOC_TRACKING = "doc-tracking";

interface DocTrackingPlugin {}

const createDocTrackingPlugin = createPluginFactory<DocTrackingPlugin>({
  key: KEY_DOC_TRACKING,
  withOverrides: withTrackChanges,
});

export default createDocTrackingPlugin;
