import OdoUserAvatar from "./common/OdoUserAvatar";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import Button from "./common/Button";
import { useNavigate } from "react-router-dom";
import Icon from "./common/Icon";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./EditorView/Menus/DropdownMenu";

const CurrentUser = () => {
  const user = useAuthenticatedUser();
  const navigate = useNavigate();

  if (!user) {
    return null;
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <div className="flex gap-sm items-center">
            <OdoUserAvatar size="small" user={user} />
            <h1
              className="hidden tablet:block"
              onClick={() => navigate("/account/")}
            >
              {user.displayName}
            </h1>
            <Icon name="chevron-down" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={12}>
          <DropdownMenuItem
            onClick={() => navigate("/account/company-profile/")}
          >
            Company Profile
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={(e) => e.preventDefault()}
            className="bg-background-secondary border-t cursor-default hover:bg-background-secondary"
          >
            <p className="text-center text-sm w-full">{user.email}</p>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Button
        icon="arrow-right-from-bracket"
        variant="outline-full"
        onClick={() => {
          navigate("/logout");
        }}
      />
    </>
  );
};

export default CurrentUser;
