export enum LogType {
  WebSockets = "Websockets",
  Auth = "Auth",
  Error = "Error",
}

const disabledLogTypes: LogType[] = [LogType.WebSockets, LogType.Auth];

const log = (logType: LogType, ...args: any[]): void => {
  if (!disabledLogTypes.includes(logType)) {
    if (logType === LogType.Error) {
      console.error(...args);
    } else {
      console.log(...args);
    }
  }
};

export default log;
