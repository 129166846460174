import { FC, useCallback, useRef, useState } from "react";

interface DragHandleProps {
  onMove: (amount: number) => void;
  direction: "horizontally" | "vertically";
}

const DragHandle: FC<DragHandleProps> = ({ onMove, direction }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isHovering, setIsHovering] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const startResizing = useCallback(
    (mouseDownEvent: React.MouseEvent) => {
      mouseDownEvent.preventDefault();
      mouseDownEvent.stopPropagation();

      setIsDragging(true);

      let prevPos = 0;
      if (direction === "horizontally") {
        prevPos = mouseDownEvent.clientX;
      } else {
        prevPos = mouseDownEvent.clientY;
      }

      const doDrag = (mouseMoveEvent: MouseEvent) => {
        if (!ref.current) {
          return;
        }
        const target = ref.current;
        const targetRect = target.getBoundingClientRect();

        if (direction === "horizontally") {
          // Get X position relative to the target element
          const currentX = mouseMoveEvent.clientX;
          //   const delta = currentX - prevPos;
          const delta = currentX - (targetRect.left + targetRect.width / 2);
          onMove(delta);
          prevPos = currentX;
        } else {
          const currentY = mouseMoveEvent.clientY;
          const delta = currentY - prevPos;
          onMove(delta);
          prevPos = currentY;
        }
        mouseMoveEvent.preventDefault();
      };

      const stopDrag = () => {
        document.removeEventListener("mousemove", doDrag);
        document.removeEventListener("mouseup", stopDrag);
        setIsDragging(false);
      };

      document.addEventListener("mousemove", doDrag);
      document.addEventListener("mouseup", stopDrag);
    },
    [direction, onMove]
  );
  return (
    <div className="w-0 relative">
      <div
        onMouseDown={startResizing}
        className="absolute top-0 bottom-0 -left-[6px] w-[12px] cursor-col-resize bg-primary transition-opacity duration-200 z-[999999]"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={{ opacity: isHovering || isDragging ? 0.4 : 0 }}
        ref={ref}
        onDrag={(e) => {}}
      />
    </div>
  );
};

export default DragHandle;
