import React from "react";
import {
  useLinkToolbarButton,
  useLinkToolbarButtonState,
} from "@udecode/plate-link";

import { ToolbarButton } from "./Toolbar";
import Icon from "components/common/Icon";

export function LinkToolbarButton() {
  const state = useLinkToolbarButtonState();
  const { props } = useLinkToolbarButton(state);

  return (
    <ToolbarButton tooltip="Link (⌘K)" {...props}>
      <Icon name="link-simple" />
    </ToolbarButton>
  );
}
