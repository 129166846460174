import {
  cn,
  date_from_string,
  date_string_from_date,
  format_absolute_date_long,
} from "../../lib/utils";
import Button from "../common/Button";
import { useNavigate } from "react-router-dom";
import { useApiClient } from "providers/ApiClientProvider";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";
import React from "react";
import Spinner from "components/common/Spinner";
import { useProposalData } from "providers/ProposalDetailsProvider";
import ProposalAdminPopover from "./ProposalAdminPopover";
import Pill from "components/common/Pill";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProposalCollaborators from "./ProposalCollaborators";
import ProposalTitle from "./ProposalTitle";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import Overlay from "components/common/containers/overlays/Overlay";
import useTriggerDownload from "hooks/useTriggerDownload";

interface ProposalTitleBarProps {
  className?: string;
}

const ProposalTitleBar: React.FC<ProposalTitleBarProps> = ({ className }) => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const { isStaff } = useAuthenticatedUser();
  const [isExporting, setIsExporting] = React.useState(false);
  const { details, setDetails, metadata, setMetadata } = useProposalData();
  const triggerDownload = useTriggerDownload();

  const handleExport = async () => {
    try {
      setIsExporting(true);
      const response = await apiClient.rfp.rfpProposalExportList(details.id);
      triggerDownload(response.data.download_url!);
    } catch (e) {
      toast.error(`Failed to export proposal ${message_from_exception(e)}`);
    } finally {
      setIsExporting(false);
    }
  };

  const handlePublish = async () => {
    try {
      await apiClient.rfp.rfpProposalPartialUpdate(details.id, {
        published_at: new Date().toISOString(),
      } as any);
      setDetails((prev) => ({ ...prev!, isPublished: true }));
      toast.success("Proposal published successfully");
    } catch (e) {
      toast.error(`Failed to publish proposal ${message_from_exception(e)}`);
    }
  };

  const handleDateChange = async (due_date: Date) => {
    setMetadata((prev) => ({
      ...prev,
      dueDate: date_string_from_date(due_date),
    }));
  };

  const handleView = async () => {
    try {
      const response = await apiClient.rfp.rfpRfpRead(details.rfpId);
      const view_url = response.data.view_url;
      if (!view_url) {
        throw new Error("Could not find RFP");
      }
      triggerDownload(view_url, "tab");
    } catch (e) {
      toast.error(`Failed to view RFP ${message_from_exception(e)}`);
    }
  };

  return (
    <div className={cn("flex items-center px-lg py-2m", className)}>
      <Button
        onClick={() => navigate("/proposals/")}
        className="mr-lg"
        icon="home"
        iconVariant="light"
        variant="outline-full"
      />
      <ProposalTitle />
      <ProposalAdminPopover className="mr-lg" />
      {isStaff && !details.isPublished && (
        <Button
          variant="solid"
          text="Publish"
          onClick={handlePublish}
          className="mr-lg admin"
        />
      )}
      <DatePicker
        className="m-0 mr-lg"
        selected={metadata.dueDate ? date_from_string(metadata.dueDate) : null}
        onChange={handleDateChange}
        customInput={
          <Pill
            icon="calendar"
            iconVariant="light"
            text={
              metadata.dueDate
                ? "Due " + format_absolute_date_long(metadata.dueDate)
                : "Set Due Date"
            }
          />
        }
      />
      <div className="grow" />
      <Button
        icon="magnifying-glass"
        iconVariant="light"
        text="RFP"
        variant="outline-full"
        onClick={handleView}
        className="mr-md"
      />
      <ProposalCollaborators />
      <Button
        icon="file-export"
        iconVariant="light"
        text="Export"
        variant="outline-full"
        tooltip="Export Proposal"
        onClick={handleExport}
        className="ml-md"
      />
      <Overlay open={isExporting} maxWidth={300} title="Exporting Proposal...">
        <div className="text-center my-lg flex flex-col gap-sm">
          <Spinner className="mx-auto" />
          <p className="text-sm">This may take a few moments</p>
        </div>
      </Overlay>
    </div>
  );
};

export default ProposalTitleBar;
