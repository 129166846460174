import { isCollapsed, isText } from "@udecode/plate";
import { isElement } from "@udecode/plate-common";
import { isOdoP, isOdoTable, isOdoTR } from "./types.js";
import { Path } from "slate";
const withTableNormalizing = (editor) => {
    const { normalizeNode, delete: editorDelete } = editor;
    editor.normalizeNode = ([node, path]) => {
        // Ensure the last element is always a paragraph
        normalizeNode([node, path]);
        if (!isElement(node) || node.type !== "table") {
            return;
        }
        // Set table headers from markdown underlines
        if (node.children.length >= 3) {
            // There are at least 3 rows (we wait for the third so we
            // don't normalize until the previous row is complete)
            const firstRow = node.children[0];
            const secondRow = node.children[1];
            if (isOdoTR(firstRow) &&
                isOdoTR(secondRow) &&
                firstRow.children.length > 0 &&
                firstRow.children.length === secondRow.children.length &&
                isTableRowAllDashes(secondRow)) {
                // The first two rows have the same number of cells and
                // the second row contains just dashes.
                //
                // Mark the first row as heading and Delete the second row
                // @ts-ignore
                editor.setNodes({ isHeader: true }, { at: [...path, 0] });
                editor.delete({ at: [...path, 1] });
            }
        }
    };
    editor.delete = (options) => {
        // If selection is partially outside and inside a table, update the
        // selection to include the entire table
        const selection = editor.selection;
        if (!selection || isCollapsed(selection)) {
            return editorDelete(options);
        }
        const tableEntries = Array.from(editor.nodes({
            match: (n) => isOdoTable(n),
            at: selection,
        }));
        if (tableEntries.length === 0) {
            return editorDelete(options);
        }
        let anchorTableEntry = null;
        let focusTableEntry = null;
        for (const [table, tablePath] of tableEntries) {
            if (Path.isAncestor(tablePath, selection.anchor.path)) {
                anchorTableEntry = [table, tablePath];
            }
            if (Path.isAncestor(tablePath, selection.focus.path)) {
                focusTableEntry = [table, tablePath];
            }
        }
        console.log("anchorInsideTable", anchorTableEntry, "focusInsideTable", focusTableEntry);
        if (!anchorTableEntry && !focusTableEntry) {
            // Neither anchor nor focus are inside a table
            // Do nothing
            return editorDelete(options);
        }
        if (anchorTableEntry &&
            focusTableEntry &&
            JSON.stringify(anchorTableEntry) === JSON.stringify(focusTableEntry)) {
            // Do nothing
            return editorDelete(options);
        }
        if (anchorTableEntry) {
            // Delete the whole table
            editor.removeNodes({ at: anchorTableEntry[1], mode: "highest" });
        }
        if (focusTableEntry) {
            // Delete the whole table
            editor.removeNodes({ at: focusTableEntry[1], mode: "highest" });
        }
        editorDelete(options);
    };
    return editor;
};
const isTableRowAllDashes = (row) => {
    for (const cell of row.children) {
        if (cell.children.length !== 1) {
            return false;
        }
        const paragraph = cell.children[0];
        if (!isOdoP(paragraph)) {
            return false;
        }
        const cellText = paragraph.children[0];
        if (!isText(cellText)) {
            return false;
        }
        const text = cellText.text;
        if (!text.match(/^ *-+ *$/)) {
            return false;
        }
    }
    return true;
};
export default withTableNormalizing;
