import { MARK_COMMENT } from "./types.js";
import { findNode } from "@udecode/plate-common";
import { isText } from "@udecode/plate";
export const getCommentIdFromKey = (key) => key.replace(`${MARK_COMMENT}_`, "");
export const getCommentKeyFromId = (id) => `${MARK_COMMENT}_${id}`;
export const isCommentKey = (key) => key.startsWith(`${MARK_COMMENT}_`);
export const getCommentKeys = (node) => {
    const keys = [];
    Object.keys(node).forEach((key) => {
        if (isCommentKey(key))
            keys.push(key);
    });
    return keys;
};
export const getCommentCount = (node) => {
    let commentCount = 0;
    Object.keys(node).forEach((key) => {
        if (key === MARK_COMMENT)
            commentCount++;
    });
    return commentCount;
};
export const clearAllOrphanedComments = (editor, allComments) => {
    const getNext = () => {
        // Get the first node with a comment mark that does not exist
        let foundKey = null;
        let allKeyCount = 99999;
        const result = findNode(editor, {
            at: [],
            match: (n) => {
                if (!isText(n))
                    return false;
                const keys = getCommentKeys(n);
                for (const key of keys) {
                    const commentId = getCommentIdFromKey(key);
                    if (!allComments[commentId]) {
                        allKeyCount = keys.length;
                        foundKey = key;
                        return true;
                    }
                }
                return false;
            },
        });
        if (!result || !foundKey)
            return null;
        const [node, path] = result;
        return { key: foundKey, allKeyCount, node, path };
    };
    let next = getNext();
    while (next) {
        const { key, allKeyCount, path } = next;
        // Remove the mark for the specific comment
        editor.unsetNodes(key, { at: path });
        if (allKeyCount <= 1) {
            // If this is the last specific comment mark, remove the overall comment mark
            editor.unsetNodes(MARK_COMMENT, { at: path });
        }
        next = getNext();
    }
};
