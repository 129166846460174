interface PlainTextProps {
  text: string;
}

/**
 * Render text as a component with paragarphs
 * as defined by new lines in the text.
 *
 */
const PlainText: React.FC<PlainTextProps> = ({ text }) => {
  return (
    <>
      {text.split("\n").map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </>
  );
};

export default PlainText;
