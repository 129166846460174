import { cn } from "../../../lib/utils";

interface SegmentedControlProps {
  className?: string;
  count: number;
  selectedIndex: number;
}

const SegmentedControl: React.FC<SegmentedControlProps> = ({
  className,
  count,
  selectedIndex,
}) => {
  const segments = Array.from({ length: count }, (_, i) => i);

  return (
    <div className={cn("flex gap-lg", className)}>
      {segments.map((i) => (
        <div
          key={i}
          className={cn(
            "w-md h-md rounded-full",
            selectedIndex === i ? "bg-primary" : "bg-background-selected"
          )}
        />
      ))}
    </div>
  );
};

export default SegmentedControl;
