export type DownloadMode = "download" | "tab";

const useTriggerDownload = () => {
  return (url: string, mode: DownloadMode = "download") => {
    const link = document.createElement("a");
    link.href = url;
    if (mode === "tab") {
      link.target = "_blank";
    }
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };
};

export default useTriggerDownload;
