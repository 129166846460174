import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { formatDistanceStrict } from "date-fns";

import type { ClassValue } from "clsx";
import {
  isCollapsed,
  isElement,
  PlateEditor,
  TElement,
} from "@udecode/plate-common";
import { findNode, isText } from "@udecode/plate";
import { RefObject } from "react";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function mergeRefs<T>(...refs: React.Ref<T>[]) {
  return (value: T) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}

export function truncateString(str: string, length: number) {
  if (str.length <= length) {
    return str;
  }
  return str.slice(0, length - 3) + "...";
}

/*
 * Parse a date string in format YYYY-DD-YY into a Date object.
 */
export function date_from_string(date: string | Date): Date {
  if (date instanceof Date) {
    return date;
  }
  const components = date.split("-");
  if (components.length !== 3) {
    throw new Error("Invalid date string");
  }

  const year = parseInt(components[0]);
  const month = parseInt(components[1]);
  const day = parseInt(components[2]);
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    throw new Error("Invalid date string");
  }
  return new Date(year, month - 1, day);
}

/**
 * Formate date into YYYY-MM-DD format
 */
export function date_string_from_date(date: Date | null): string | null {
  if (!date) return null;

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

/*
 * Format a date string or Date object into a human-readable date.
 * - If the date is within the last 7 days, report it as how long ago it was.
 * - Otherwise, report it as M/D/YYYY.
 */
export function format_date(date: string | Date) {
  let parsed: Date;
  if (typeof date === "string") {
    parsed = new Date(date);
  } else {
    parsed = date;
  }

  const now = new Date();
  const diff = now.getTime() - parsed.getTime();
  const diff_hours = diff / (1000 * 60 * 60);
  const diff_days = diff_hours / 24;
  if (diff_days < 7) {
    return formatDistanceStrict(parsed, now, { addSuffix: true });
  }
  return format_absolute_date(parsed);
}

/*
 * Format a date string or Date object into a human-readable date and time.
 * - If the time is within 1 minute, report it a "Just now"
 * - If the time is within the last 4 hours, report it as how long ago it was.
 * - If the time is today, report it as "Today at h:mm AM/PM".
 * - If the time is yesterday, report it as "Yesterday at h:mm AM/PM".
 * - Otherwise, report it as M/D/YYYY h:mm AM/PM.
 *
 * Returns the formatted string and a boolean indicating whether the time is
 * going to change soon (should be updated every minute).
 */
export function format_datetime(date: string | Date): [string, boolean] {
  let parsed: Date;
  if (typeof date === "string") {
    parsed = new Date(date);
  } else {
    parsed = date;
  }

  const now = new Date();
  const diff = now.getTime() - parsed.getTime();
  const diff_minutes = diff / (1000 * 60);
  const diff_hours = diff / (1000 * 60 * 60);
  if (diff_minutes < 1) {
    return ["Just now", true];
  } else if (diff_hours < 4) {
    return [formatDistanceStrict(parsed, now, { addSuffix: true }), true];
  } else if (now.getDate() === parsed.getDate()) {
    return [
      parsed.toLocaleTimeString([], {
        hour: "numeric",
        minute: "numeric",
      }),
      false,
    ];
  } else if (now.getDate() - 1 === parsed.getDate()) {
    return [
      `Yesterday at ${parsed.toLocaleTimeString([], {
        hour: "numeric",
        minute: "numeric",
      })}`,
      false,
    ];
  }
  return [format_absolute_date(parsed), false];
}

export function format_absolute_datetime(date: string | Date) {
  let parsed: Date;
  if (typeof date === "string") {
    parsed = new Date(date);
  } else {
    parsed = date;
  }

  return parsed.toLocaleString([], {
    month: "numeric",
    year: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function format_absolute_date(date: string | Date) {
  let parsed: Date;
  if (typeof date === "string") {
    if (date === "null") {
      return "Unknown";
    }
    parsed = date_from_string(date);
  } else {
    parsed = date;
  }

  return parsed.toLocaleString([], {
    month: "numeric",
    year: "numeric",
    day: "numeric",
  });
}

export function format_absolute_date_long(date: string | Date) {
  let parsed: Date;
  if (typeof date === "string") {
    parsed = date_from_string(date);
  } else {
    parsed = date;
  }

  return parsed.toLocaleString([], {
    month: "long",
    year: "numeric",
    day: "numeric",
  });
}

export function format_absolute_date_short(date: string | Date) {
  let parsed: Date;
  if (typeof date === "string") {
    parsed = date_from_string(date);
  } else {
    parsed = date;
  }

  return parsed.toLocaleString([], {
    month: "numeric",
    day: "numeric",
  });
}

export const isOnFirstLine = (
  editor: PlateEditor | null,
  containerRef: RefObject<HTMLElement>
) => {
  if (!editor) return false;

  if (
    !editor.selection ||
    editor.selection.focus.path.length === 0 ||
    editor.selection.focus.path[0] !== 0
  ) {
    // If we aren't in the first block, there's no way we can be at the top
    return false;
  }

  const containerTop = containerRef.current?.getBoundingClientRect().top;
  if (containerTop === undefined) return false;

  const selection = window.getSelection();
  if (!selection?.rangeCount) return false;

  const range = selection.getRangeAt(0).cloneRange();
  range.collapse(true);
  let span = document.createElement("span");
  span.innerHTML = "&nbsp;";
  range.insertNode(span);

  const cursorTop = span.getBoundingClientRect().y;
  const parent = span.parentElement!;
  parent.removeChild(span);
  parent.normalize();

  // Adding 24 is a bit hacky, but it works assuming there are no elements less than 24 pixels tall where
  // an element below it would then be considered at the top. For normal elements, this 24 pixel buffer allows
  // for margins added by parent elements. One of the biggest margin examples is code blocks
  return cursorTop - containerTop <= 24;
};

const isPathInTable = (editor: PlateEditor, path: number[]) => {
  if (path.length <= 2) {
    return false;
  }

  const parentPath = [path[0]];
  const parent = editor.node(parentPath);
  if (!parent) {
    return false;
  }

  const [parentElement] = parent;
  if (!isElement(parentElement) || parentElement.type !== "table") {
    return false;
  }

  return true;
};

export const isSelectionInTable = (editor: PlateEditor) => {
  const selection = editor.selection;
  if (!selection) {
    return false;
  }

  if (isCollapsed(selection)) {
    return isPathInTable(editor, selection.anchor.path);
  } else {
    return (
      isPathInTable(editor, selection.focus.path) &&
      isPathInTable(editor, selection.anchor.path)
    );
  }
};

export const isSelectionNotInTable = (editor: PlateEditor<any>) =>
  !isSelectionInTable(editor);

export const beginningPath = (editor: PlateEditor) => {
  const first = findNode(editor, { match: isText, at: [] });
  if (!first) {
    return null;
  }
  return first[1];
};

export const topOffsetFromRefs = (
  refs: Record<string, any> | undefined,
  containerRef: RefObject<HTMLElement>
): number | null => {
  if (!refs) return null;

  let topOffset: number | null = null;

  Object.values(refs).forEach((ref) => {
    const el = ref.current;
    if (!el || !containerRef.current) return;
    let elementTop = el.getBoundingClientRect().top;
    const elementStyle = window.getComputedStyle(el);
    const elementTopPadding = parseInt(elementStyle.paddingTop, 10);
    elementTop += elementTopPadding;

    const containerTop = containerRef.current.getBoundingClientRect().top;
    const thisTopOffset = elementTop - containerTop;
    if (topOffset === null) {
      topOffset = thisTopOffset;
    } else if (thisTopOffset < topOffset) {
      topOffset = thisTopOffset;
    }
  });

  return topOffset;
};

export const searchBackwardsFromSelectionForTopLevelElement = (
  editor: PlateEditor,
  tagetType: string
) => {
  if (!editor.selection) {
    return null;
  }

  let selectionPath = editor.selection.focus.path;
  if (selectionPath.length === 0) {
    console.warn("failed to search for h1, path length is 0");
    return;
  }

  // Headings are always at the top of the document
  for (let path = [selectionPath[0]]; path[0] >= 0; path = [path[0] - 1]) {
    const [node] = editor.node(path);
    if (!isElement(node)) {
      continue;
    }
    if (node.type === tagetType) {
      return node;
    }
  }

  return null;
};

export const hasContent = (element: TElement) => {
  if (element.children.length === 0) return true;

  for (const child of element.children) {
    if (isText(child)) {
      if (child.text.length > 0) return true;
    } else if (isElement(child)) {
      if (hasContent(child)) return true;
    }
  }
  return false;
};

/**
 * Reads HTML content from the clipboard. Falls back to plain text if HTML is not available.
 * @returns {Promise<string>} A promise that resolves to the clipboard content.
 */
export const readClipboardContent = async (e?: React.ClipboardEvent<any>) => {
  try {
    if (e) {
      const clipboardData = e.clipboardData;
      if (clipboardData) {
        const html = clipboardData.getData("text/html");
        if (html) {
          return html;
        }
        const plain = clipboardData.getData("text/plain");
        if (plain) {
          return plain;
        }
      }
    }

    const clipboardItems = await navigator.clipboard.read();
    for (const clipboardItem of clipboardItems) {
      for (const type of clipboardItem.types) {
        if (type === "text/html") {
          const blob = await clipboardItem.getType(type);
          return await blob.text();
        }
      }
    }
    // Fallback to plain text if no HTML content is found
    return await navigator.clipboard.readText();
  } catch (error) {
    console.error("Failed to read clipboard contents: ", error);
    return "";
  }
};
