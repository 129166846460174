import { cn } from "lib/utils";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";

import * as Sentry from "@sentry/react";
import Button from "../Button";

interface FileInputProps {
  className?: string;
  accept?: string;
  onFileChanged: (file: File | null) => void;
  maxSizeInMB?: number;
  label?: string;
}

export interface FileInputRef {
  clear: () => void;
}

const FileInput = forwardRef<FileInputRef, FileInputProps>(
  ({ onFileChanged, accept, className, label, maxSizeInMB = 30 }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [file, _setFile] = useState<File | null>(null);
    const [dropping, setDropping] = useState<null | "allowed" | "blocked">(
      null
    );

    const handleClick = () => {
      inputRef.current?.click();
    };

    useEffect(() => {
      onFileChanged(file);
    }, [file, onFileChanged]);

    const setFile = (file: File | null) => {
      if ((file?.size ?? 0) > maxSizeInMB * 1024 * 1024) {
        toast.error(
          <p>
            File is too large. Please{" "}
            <a className="underline" href="mailto:support@odo.do">
              contact support
            </a>
          </p>
        );
        Sentry.captureMessage("User tried to upload a file that is too large");
      } else {
        _setFile(file);
      }
    };

    const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.length) {
        return;
      }
      setFile(e.target.files?.[0] || null);
      e.target.value = "";
    };

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();

      for (const type of e.dataTransfer.items) {
        if (type.kind === "file" && !!accept && type.type === accept) {
          setDropping("allowed");
          return;
        }
      }
      setDropping("blocked");
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setDropping(null);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setDropping(null);
      for (const file of e.dataTransfer.files) {
        if (!accept || file.type === accept) {
          setFile(file);
          return;
        }
      }

      toast.error("We don't support that file type");
    };

    useImperativeHandle(ref, () => ({
      clear: () => {
        _setFile(null);
        if (inputRef.current) {
          inputRef.current.value = "";
        }
      },
    }));

    return (
      <>
        <input
          ref={inputRef}
          type="file"
          className="hidden"
          onChange={handleFileSelected}
          accept={accept}
        />
        <div
          className={cn(
            "border border-foreground border-dashed rounded flex flex-col items-center py-lg cursor-pointer group overflow-hidden",
            !!dropping &&
              "outline outline-primary outline-[2px] outline-offset-[-1px] [&_*]:pointer-events-none",
            dropping === "blocked" && "outline-destructive",
            className
          )}
          onClick={handleClick}
          onDrag={handleDragEnter}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {file ? (
            <div className="w-full text-center px-sm overflow-hidden flex">
              <span className="truncate w-full shrink-0">{file.name}</span>
            </div>
          ) : (
            <>
              <p>Drop {label ?? "the file"} here</p>
              <p className="text-sm mb-[4px] text-secondary">or</p>
              <Button
                text="Browse Files"
                icon="magnifying-glass"
                variant="solid-secondary"
                className="pointer-events-none group-hover:opacity-50"
              />
            </>
          )}
        </div>
      </>
    );
  }
);

export default FileInput;
