import Spinner from "components/common/Spinner";
import { useApiClient } from "providers/ApiClientProvider";
import { useRefreshAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LogoutRoute = () => {
  const apiClient = useApiClient();
  const refreshUser = useRefreshAuthenticatedUser();
  const navigate = useNavigate();
  useEffect(() => {
    const logout = async () => {
      await apiClient.auth.authLogoutCreate();
      await refreshUser();
      navigate("/login");
    };
    logout();
  }, [apiClient.auth, navigate, refreshUser]);

  return (
    <div className="grow bg-background-secondary flex flex-col items-center justify-center">
      <Spinner />
      Logging out...
    </div>
  );
};

export default LogoutRoute;
