"use client";

import * as React from "react";
import { ReactNode } from "react";
import * as ToolbarPrimitive from "@radix-ui/react-toolbar";
import { cva, VariantProps } from "class-variance-authority";

import { cn } from "lib/utils";

import { Separator } from "./Separator";
import { ToggleProps, toggleVariants } from "./Toggle";
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from "./Tooltip";
import Icon from "components/common/Icon";

const toolbarVariants = cva(
  "relative flex select-none text-foreground align-items-center overflow-hidden",
  {
    variants: {
      variant: {
        DEFAULT: "bg-popover",
        unstyled: "",
      },
    },
    defaultVariants: {
      variant: "DEFAULT",
    },
  }
);

export const linkVariants = cva("font-medium underline underline-offset-4");

const toolbarButtonVariants = cva("hover:bg-popover-selected", {
  variants: {
    variant: {
      DEFAULT: "",
      ai: "text-primary",
    },
  },
  defaultVariants: {
    variant: "DEFAULT",
  },
});

const ToolbarToggleGroup = ToolbarPrimitive.ToggleGroup;

export interface ToolbarProps
  extends React.ComponentPropsWithoutRef<typeof Toolbar> {}

const Toolbar = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.Root> &
    VariantProps<typeof toolbarVariants>
>(({ className, variant, ...props }, ref) => (
  <ToolbarPrimitive.Root
    ref={ref}
    className={cn(toolbarVariants({ variant }), className)}
    {...props}
  />
));
Toolbar.displayName = ToolbarPrimitive.Root.displayName;

const ToolbarLink = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.Link>,
  React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.Link> &
    VariantProps<typeof linkVariants>
>(({ className, ...props }, ref) => (
  <ToolbarPrimitive.Link
    ref={ref}
    className={cn(linkVariants(), className)}
    {...props}
  />
));
ToolbarLink.displayName = ToolbarPrimitive.Link.displayName;

const ToolbarSeparator = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <ToolbarPrimitive.Separator
    ref={ref}
    className={cn("shrink-0 bg-border", "w-[1px]", className)}
    {...props}
  />
));
ToolbarSeparator.displayName = ToolbarPrimitive.Separator.displayName;

export interface ToolbarButtonProps
  extends React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.Button>,
    VariantProps<typeof toggleVariants>,
    Omit<ToggleProps, "type"> {
  buttonType?: "button" | "toggle";
  pressed?: boolean;
  tooltip?: ReactNode;
  isDropdown?: boolean;
}

const ToolbarButton = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.Button>,
  ToolbarButtonProps & VariantProps<typeof toolbarButtonVariants>
>(
  (
    { className, isDropdown, children, pressed, value, tooltip, ...props },
    ref
  ) => {
    const [isLoaded, setIsLoaded] = React.useState(false);

    React.useEffect(() => {
      setIsLoaded(true);
    }, []);

    const content =
      typeof pressed === "boolean" ? (
        <ToolbarToggleGroup
          type="single"
          value={pressed ? "single" : undefined}
          className={cn(
            toolbarButtonVariants({ variant: props.variant }),
            pressed ? "bg-popover-selected" : null
          )}
        >
          <ToolbarToggleItem
            ref={ref}
            className={cn(
              toggleVariants(),
              isDropdown && "y-thin justify-between pr-lg",
              className
            )}
            value="single"
            {...props}
          >
            <div className="min-w-[32px]">{children}</div>
            <div>
              {isDropdown && (
                <Icon name="chevron-down" className="ml-0.5 h-xs w-xs" />
              )}
            </div>
          </ToolbarToggleItem>
        </ToolbarToggleGroup>
      ) : (
        <ToolbarPrimitive.Button
          ref={ref}
          className={cn(
            toggleVariants(),
            isDropdown && "pr-xs",
            toolbarButtonVariants({ variant: props.variant }),
            "min-w-[32px]",
            className
          )}
          {...props}
        >
          {children}
        </ToolbarPrimitive.Button>
      );

    return isLoaded && tooltip ? (
      <Tooltip>
        <TooltipTrigger asChild={true}>{content}</TooltipTrigger>

        <TooltipPortal>
          <TooltipContent>{tooltip}</TooltipContent>
        </TooltipPortal>
      </Tooltip>
    ) : (
      <>{content}</>
    );
  }
);
ToolbarButton.displayName = ToolbarPrimitive.Button.displayName;

const ToolbarToggleItem = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.ToggleItem>,
  React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.ToggleItem> &
    VariantProps<typeof toggleVariants>
>(({ className, ...props }, ref) => (
  <ToolbarPrimitive.ToggleItem
    ref={ref}
    className={cn(toggleVariants(), className)}
    {...props}
  />
));
ToolbarToggleItem.displayName = ToolbarPrimitive.ToggleItem.displayName;

const ToolbarGroup = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { noSeparator?: boolean }
>(({ noSeparator, className, children }, ref) => {
  const childArr = React.Children.map(children, (c) => c);
  if (!childArr || childArr.length === 0) return null;

  return (
    <div ref={ref} className={cn("flex", className)}>
      {!noSeparator && (
        <div className="h-full">
          <Separator orientation="vertical" />
        </div>
      )}

      <div className="flex">{children}</div>
    </div>
  );
});
ToolbarGroup.displayName = "ToolbarGroup";

export {
  Toolbar,
  ToolbarLink,
  ToolbarToggleGroup,
  ToolbarSeparator,
  ToolbarToggleItem,
  ToolbarButton,
  ToolbarGroup,
};
