import { useEditorRef } from "@udecode/plate";
import { isOdoEditor, OdoEditor, OdoValue } from "odo";

export const useOdoEditorRef = (): OdoEditor => {
  const editor = useEditorRef<OdoValue, OdoEditor>();

  if (!isOdoEditor(editor)) {
    new Error("Editor is not an OdoEditor");
  }

  return editor;
};
