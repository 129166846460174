import "./sentry";

import React, { ReactNode } from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import { PostHogProvider } from "posthog-js/react";

let app: ReactNode = null;
if (!!process.env.REACT_APP_PUBLIC_POSTHOG_HOST) {
  const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  };
  app = (
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <App />
    </PostHogProvider>
  );
} else {
  app = <App />;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<React.StrictMode>{app}</React.StrictMode>);
