import { PaginatedResponse } from "api/CoreApi";
import { AxiosResponse } from "axios";
import { useApiClient } from "providers/ApiClientProvider";
import { DependencyList, useCallback, useEffect, useState } from "react";
import { message_from_exception } from "utils";

interface SearchFilter {
  key: string;
  name: string;
  default: boolean;
}

interface UsePaginatedDataOptions<Local, Remote> {
  endpoint: (options: any) => Promise<AxiosResponse<PaginatedResponse<Remote>>>;
  map: (remote: Remote) => Local;
  deps?: DependencyList;
  filters?: SearchFilter[];
}

interface PaginatedFilter {
  value: boolean;
  setValue: (value: boolean) => void;
}

export const EMPTY_PAGINATED_DATA: PaginatedData = {
  loadingPrevious: false,
  loadingNext: false,
  status: "loaded",
  error: null,
  loadPrevious: null,
  loadNext: null,
  refresh: () => {},
  search: "",
  setSearch: () => {},
  filters: null,
};

export interface PaginatedData {
  loadingPrevious: boolean;
  loadingNext: boolean;
  status: "waiting" | "loading" | "loaded" | "error";
  error: unknown | null;
  loadPrevious: (() => void) | null | string;
  loadNext: (() => void) | null | string;
  refresh: () => void;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  filters: Record<string, PaginatedFilter> | null;
}

export type PaginatedDataReturnType<Local> = [
  Local[] | null,
  React.Dispatch<React.SetStateAction<Local[] | null>>,
  PaginatedData
];

const usePaginatedData = <Local, Remote>(
  options: UsePaginatedDataOptions<Local, Remote>
) => {
  const apiClient = useApiClient();
  const [results, setResults] = useState<Local[] | null>(null);
  const [loadingPrevious, setLoadingPrevious] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [nextError, setNextError] = useState<string | null>(null);
  const [previousError, setPreviousError] = useState<string | null>(null);
  const [status, setStatus] = useState<
    "waiting" | "loading" | "loaded" | "error"
  >("waiting");
  const [error, setError] = useState<unknown | null>(null);
  const [previousCursor, setPreviousCursor] = useState<string | null>(null);
  const [nextCursor, setNextCursor] = useState<string | null>(null);
  const [search, setSearch] = useState<string>("");
  const [filterState, setFilterState] = useState<Record<string, boolean>>(
    options.filters?.reduce((acc, filter) => {
      acc[filter.key] = filter.default;
      return acc;
    }, {} as Record<string, boolean>) ?? {}
  );

  console.log({ error2: error });

  const loadPrevious = useCallback(async () => {
    if (loadingPrevious || !previousCursor) return;
    setLoadingPrevious(true);
    try {
      const response = (await apiClient.fetchFromCursor<Remote>(previousCursor))
        .data;
      const newResults = response.results.map(options.map);
      const updatedResults = [...newResults, ...(results ?? [])];
      setResults(updatedResults);
      setPreviousCursor(response.previous ?? null);
    } catch (error) {
      setPreviousError(message_from_exception(error));
      setPreviousCursor(null);
    } finally {
      setLoadingPrevious(false);
    }
  }, [apiClient, loadingPrevious, options.map, previousCursor, results]);

  const loadNext = useCallback(async () => {
    if (loadingNext || !nextCursor) return;
    setLoadingNext(true);
    try {
      const response = (await apiClient.fetchFromCursor<Remote>(nextCursor))
        .data;
      const newResults = response.results.map(options.map);
      const updatedResults = [...(results ?? []), ...newResults];
      setResults(updatedResults);
      setNextCursor(response.next ?? null);
    } catch (error) {
      setNextError(message_from_exception(error));
      setNextCursor(null);
    } finally {
      setLoadingNext(false);
    }
  }, [apiClient, loadingNext, nextCursor, options.map, results]);

  const refresh = async () => {
    setStatus("loading");
    try {
      let requestOptions: any = {};
      if (search) {
        requestOptions["search"] = search;
      }
      for (const [key, value] of Object.entries(filterState)) {
        if (value) {
          requestOptions[key] = value;
        }
      }
      const response = await options.endpoint(requestOptions);
      const results = response.data.results.map(options.map);
      setResults(results);
      setNextCursor(response.data.next ?? null);
      setPreviousCursor(response.data.previous ?? null);
      setStatus("loaded");
    } catch (error) {
      setResults(null);
      setError(error);
      setStatus("error");
    }
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, (options.deps ?? []).concat([search, filterState]));

  const filters: Record<string, PaginatedFilter> | null =
    options.filters?.reduce((acc, filter) => {
      acc[filter.name] = {
        value: filterState[filter.key],
        setValue: (value: boolean) => {
          setFilterState((state) => ({ ...state, [filter.key]: value }));
        },
      };
      return acc;
    }, {} as Record<string, PaginatedFilter>) ?? null;

  return [
    results,
    setResults,
    {
      loadingPrevious,
      loadingNext,
      status,
      error,
      loadPrevious: previousError
        ? previousError
        : previousCursor
        ? loadPrevious
        : null,
      loadNext: nextError ? nextError : nextCursor ? loadNext : null,
      refresh,
      search,
      setSearch,
      filters,
    },
  ] as PaginatedDataReturnType<Local>;
};

export default usePaginatedData;
