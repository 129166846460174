import React, { useCallback, useEffect, useState } from "react";
import { TEditableProps } from "@udecode/plate-common";
import {
  floatingLinkActions,
  getLinkAttributes,
  useFloatingLinkEdit,
  useFloatingLinkEditState,
  useFloatingLinkInsert,
  useFloatingLinkInsertState,
  useFloatingLinkSelectors,
  useLinkOpenButtonState,
} from "@udecode/plate-link";

import { cn } from "lib/utils";

import { popoverVariants } from "./Popover";
import Icon from "components/common/Icon";
import Input from "components/common/forms/Input";
import {
  Toolbar,
  ToolbarButton,
  ToolbarGroup,
} from "components/EditorView/Menus/Toolbar";
import { useEditorRef } from "@udecode/plate";

export function LinkFloatingToolbar({ readOnly }: TEditableProps) {
  const isEditing = useFloatingLinkSelectors().isEditing();
  const editor = useEditorRef();
  const mode = useFloatingLinkSelectors().mode();
  const { element } = useLinkOpenButtonState();
  const url = useFloatingLinkSelectors().url();
  const [displayUrl, setDisplayUrl] = useState(url);
  const urlInputRef = React.useRef<HTMLInputElement>(null);

  const openURL = useCallback(() => {
    const url = !!element ? getLinkAttributes(editor, element).href : undefined;
    window.open(url);
  }, [element, editor]);

  const state = useFloatingLinkInsertState();
  const {
    props: insertProps,
    ref: insertRef,
    textInputProps,
  } = useFloatingLinkInsert(state);

  const editState = useFloatingLinkEditState();
  const {
    props: editProps,
    ref: editRef,
    editButtonProps,
    unlinkButtonProps,
  } = useFloatingLinkEdit(editState);
  insertProps.style.zIndex = 30;
  editProps.style.zIndex = 30;

  const setUrl = (url: string) => {
    setDisplayUrl(url);
    // Update the official link url allowing for the user to not include the protocol
    // The display url will not include the protocol but the official url will
    try {
      new URL(url);
      // The entered url is valid on its own, use that.
      floatingLinkActions.url(url);
    } catch {
      // The urls is not valid on its own
      if (url.includes(".")) {
        // The url is a domain name, add the protocol
        floatingLinkActions.url(`http://${url}`);
      } else {
        // The url is not a domain name, set the url to empty since it definitely isn't valid
        floatingLinkActions.url("");
      }
    }
  };

  useEffect(() => {
    // Reset the url back to the official one when the mode changes
    setDisplayUrl(url);

    if (mode === "insert") {
      setTimeout(() => {
        urlInputRef.current?.focus();
      });
    }
  }, [mode, isEditing, url]);

  if (readOnly) return null;

  const input = (
    <div className="flex w-[330px] flex-col p-sm gap-sm">
      <div className="flex items-center gap-sm">
        <Icon name="text" className="w-lg text-center" />
        <Input variant="menu" placeholder="Text" {...textInputProps} />
      </div>

      <div className="flex items-center gap-sm">
        <Icon name="link-simple" className="w-lg text-center" />
        <Input
          variant="menu"
          placeholder="Link"
          value={displayUrl}
          ref={urlInputRef}
          onChange={(event) => setUrl(event.target.value)}
        />
      </div>
    </div>
  );

  const editContent = isEditing ? (
    input
  ) : (
    <Toolbar variant="unstyled">
      <ToolbarButton {...editButtonProps}>
        <p className="px-sm">Edit Link</p>
      </ToolbarButton>
      <ToolbarGroup>
        <ToolbarButton onClick={openURL}>
          <Icon name="arrow-up-right-from-square" />
        </ToolbarButton>
        <ToolbarButton {...unlinkButtonProps}>
          <Icon name="link-simple-slash" />
        </ToolbarButton>
      </ToolbarGroup>
    </Toolbar>
  );

  if (
    typeof insertProps.style.left === "number" &&
    insertProps.style.left < 0
  ) {
    insertProps.style.left = 0;
  }
  if (typeof editProps.style.left === "number" && editProps.style.left < 0) {
    editProps.style.left = 0;
  }

  // HACK: Something about the element being removed and re-added to the DOM (through
  // display: none) causes a resize observer error.
  editProps.style.opacity = editProps.style.display === "none" ? 0 : 1;
  editProps.style.pointerEvents =
    editProps.style.display === "none" ? "none" : "auto";
  delete editProps.style.display;

  return (
    <>
      <div
        ref={insertRef}
        className={cn(popoverVariants(), "w-auto p-0")}
        {...insertProps}
      >
        {input}
      </div>

      <div
        ref={editRef}
        className={cn(popoverVariants(), "w-auto p-0 border overflow-hidden")}
        {...editProps}
      >
        {editContent}
      </div>
    </>
  );
}
