import React from "react";
import {
  PlateElement,
  PlateElementProps,
  useEditorState,
  Value,
} from "@udecode/plate-common";
import { TLinkElement } from "@udecode/plate-link";
import { cn } from "../../../../utils";
import { isOdoTagContent, OdoUser } from "odo";

const MentionElement = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateElementProps<Value, TLinkElement>
>(({ className, children, editor, ...props }, ref) => {
  const { user } = props.element as any as {
    user: OdoUser;
    showPlaceholder?: boolean;
  };
  // Make odo placeholder state change as content changes
  useEditorState();

  let showPlaceholder = isOdoTagContent(editor.children);

  return (
    <PlateElement
      asChild
      ref={ref}
      className={cn("inline-block", className)}
      {...(props as any)}
    >
      <span className="text-primary relative" contentEditable={false}>
        @{user.displayName}
        {!!showPlaceholder && (
          <div className="left-[calc(100%+4px)] top-0 absolute text-placeholder w-[800px] pointer-events-none">
            How can I help?
          </div>
        )}
        {children}
      </span>
    </PlateElement>
  );
});
MentionElement.displayName = "MentionElement";

export { MentionElement };
