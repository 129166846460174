import { cn } from "lib/utils";
import { ComponentPropsWithoutRef, forwardRef } from "react";

const Rows = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"div">>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn("flex flex-col overflow-hidden", className)}
        {...props}
      >
        {children}
      </div>
    );
  }
);

export default Rows;
