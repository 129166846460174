import React, { useEffect } from "react";
import { OdoCursorData } from "odo";
import { RenderCaret, RenderSelectionRect } from "./RemoteCursorOverlay";

export const CursorSelectionRect: RenderSelectionRect<OdoCursorData> = ({
  data,
  selectionRect,
}) => {
  return (
    <div
      style={{
        backgroundColor: data.color,
        position: "absolute",
        pointerEvents: "none",
        opacity: 0.2,
        ...selectionRect,
      }}
    />
  );
};

export const CursorCaret: RenderCaret<OdoCursorData> = ({
  caretPosition,
  data,
}) => {
  const [isUsernameVisible, setIsUsernameVisible] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState<any>(null);

  useEffect(() => {
    if (!data.user) {
      console.warn("No user data for cursor", data);
      return;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set username to be visible whenever the cursor moves
    setIsUsernameVisible(true);

    const newTimeoutId = setTimeout(() => {
      setIsUsernameVisible(false);
    }, 2000);

    setTimeoutId(newTimeoutId);

    // Clear timeout when component dismounts
    return () => {
      clearTimeout(newTimeoutId);
    };
    // Only trigger when caretPosition changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caretPosition]);

  if (!data.user) {
    return null;
  }

  return (
    <div
      style={{
        display: "block",
        position: "absolute",
        pointerEvents: "none",
        // zIndex: "1",
        ...caretPosition,
      }}
    >
      <span
        className="whitespace-nowrap transition-opacity duration-500"
        style={{
          backgroundColor: data.color,
          position: "absolute",
          bottom: caretPosition.height,
          padding: "0 0.3em",
          color: "white",
          borderRadius: "8px 8px 8px 0",
          opacity: isUsernameVisible ? 1 : 0,
        }}
      >
        {data.user.displayName}
      </span>
      <div
        className="w-[6px] h-[6px]"
        style={{
          backgroundColor: data.color,
          position: "absolute",
          bottom: caretPosition.height,
          color: "white",
          borderRadius: "0px 2px 2px 0",
        }}
      />
      <div
        style={{
          backgroundColor: data.color,
          width: "2px",
          height: caretPosition.height,
        }}
      ></div>
    </div>
  );
};
