import { Org, RFPComparisonList } from "api/Api";
import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Rows from "components/common/containers/Rows";
import Icon from "components/common/Icon";
import ComboBoxPopover from "components/common/menus/ComboBoxPopover";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/EditorView/Menus/Popover";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/EditorView/Menus/Tooltip";
import { PaginatedData } from "hooks/usePaginatedData";
import { format_absolute_date_short } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { FC, ReactNode } from "react";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";

interface ComparisonTableViewProps {
  results: RFPComparisonList[] | null;
  paginatedData: PaginatedData;
  differentiating: { column: "Orgs" | "RFP"; id: string };
  children?: ReactNode;
}

const ComparisonTableView: FC<ComparisonTableViewProps> = ({
  results,
  paginatedData,
  differentiating,
  children,
}) => {
  const apiClient = useApiClient();

  const handleRerun = async (comparison: RFPComparisonList) => {
    try {
      await apiClient.rfp.rfpRfpComparisonCreate(comparison.rfp_id!, {
        id: comparison.id,
      });
      paginatedData.refresh();
      toast.success("Comparisons requeued successfully");
    } catch (e) {
      toast.error(message_from_exception(e));
    }
  };

  const handleSelectOrg = async (chosen: { id: string; name: string }) => {
    const existingOrg = results?.find((result) =>
      differentiating.column === "Orgs"
        ? result.org_id === chosen.id
        : result.rfp_id === chosen.id
    );
    if (existingOrg) {
      toast.error("Org already exists in comparison list");
      return;
    }

    try {
      if (differentiating.column === "Orgs") {
        await apiClient.rfp.rfpComparisonCreateCreate({
          org_id: chosen.id,
          rfp_id: differentiating.id,
        });
      } else {
        await apiClient.rfp.rfpComparisonCreateCreate({
          org_id: differentiating.id,
          rfp_id: chosen.id,
        });
      }
      paginatedData.refresh();
    } catch (e) {
      toast.error(message_from_exception(e));
    }
  };

  return (
    <Rows className="gap-md">
      <PaginatedTableView
        className="grow"
        searchable={true}
        results={results}
        paginatedData={paginatedData}
        columns={[
          { name: "Date", size: "min" },
          { name: "Score", size: "min" },
          { name: differentiating.column },
          { name: "Reasons", size: "min" },
          { name: "Rerun", size: "min" },
        ]}
        renderRow={(comparison, Cell, Row) => (
          <Row key={comparison.id!}>
            <Cell center>
              {format_absolute_date_short(comparison.rfp_created!)}
            </Cell>
            <Cell>{comparison.score ?? "-"}</Cell>
            <Cell>
              <Tooltip>
                <TooltipTrigger className="text-left">
                  {differentiating.column === "Orgs"
                    ? comparison.org_name ?? "-"
                    : comparison.rfp_name ?? "-"}
                </TooltipTrigger>
                <TooltipContent>{comparison.id}</TooltipContent>
              </Tooltip>
            </Cell>
            <Cell center>
              {comparison.reasons ? (
                <RFPReasonsPopover reasons={comparison.reasons} />
              ) : (
                "-"
              )}
            </Cell>
            <Cell>
              <Button
                icon="refresh"
                className="text-destructive"
                onClick={() => handleRerun(comparison)}
              />
            </Cell>
          </Row>
        )}
      />
      <Columns className="justify-end">
        {children}
        {differentiating.column === "Orgs" && (
          <ComboBoxPopover
            endpoint={apiClient.orgs.orgsList}
            map={(org) =>
              ({ name: org.name, id: org.public_id! } as {
                name: string;
                id: string;
              })
            }
            renderResult={(org) => org.name}
            onSelect={handleSelectOrg}
          >
            <Icon
              name="plus"
              variant="solid"
              className="bg-primary text-background p-sm rounded-sm"
            />
          </ComboBoxPopover>
        )}
        {differentiating.column === "RFP" && (
          <ComboBoxPopover
            endpoint={apiClient.rfp.rfpRfpListList}
            map={(rfp) =>
              ({ name: rfp.display_name, id: rfp.id! } as {
                name: string;
                id: string;
              })
            }
            renderResult={(org) => org.name}
            onSelect={handleSelectOrg}
          >
            <Icon
              name="plus"
              variant="solid"
              className="bg-primary text-background p-sm rounded-sm"
            />
          </ComboBoxPopover>
        )}
      </Columns>
    </Rows>
  );
};

export const RFPReasonsPopover: FC<{ reasons: string }> = ({ reasons }) => {
  let reasonsNode: ReactNode;
  try {
    let reasponList: string[] = [];
    const parsed = JSON.parse(reasons);
    if ("reason1" in parsed) {
      reasponList.push(parsed["reason1"]);
    }
    if ("reason2" in parsed) {
      reasponList.push(parsed["reason2"]);
    }
    if ("reason3" in parsed) {
      reasponList.push(parsed["reason3"]);
    }
    reasonsNode = (
      <ul className="max-w-[500px] list-disc ml-lg my-sm">
        {reasponList.map((reason, index) => (
          <li key={index}>{reason}</li>
        ))}
      </ul>
    );
  } catch (e) {
    reasonsNode = <div className="max-w-[500px]">{reasons}</div>;
  }
  return (
    <Popover>
      <PopoverTrigger>
        <Icon name="circle-info" variant="solid" />
      </PopoverTrigger>
      <PopoverContent>{reasonsNode}</PopoverContent>
    </Popover>
  );
};

export default ComparisonTableView;
