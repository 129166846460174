import { PlateEditor } from "@udecode/plate";
import { Value } from "@udecode/plate-common";
import { posthog } from "posthog-js";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { getApiClient } from "providers/ApiClientProvider";

const withTrackChanges = <
  V extends Value = Value,
  E extends PlateEditor<V> = PlateEditor<V>
>(
  editor: E
) => {
  const { apply } = editor;
  let lastChanged: Date | null = null;

  editor.apply = (op) => {
    apply(op);

    if (lastChanged !== null) {
      const now = new Date();
      // Don't track changes if the last change was less than 15 minutes ago
      if (now.getTime() - (lastChanged as Date).getTime() < 1000 * 60 * 15) {
        return editor;
      }
    }

    // @ts-ignore
    const provider: HocuspocusProvider = editor.yjs?.provider;
    if (provider && provider.isSynced) {
      const modifyTypes: Record<string, boolean> = {
        insert_text: true,
        remove_text: true,
        insert_node: true,
        remove_node: true,
        move_node: true,
        split_node: true,
        merge_node: true,
      };
      if (modifyTypes[op.type]) {
        if (!editor.yjs) return editor;
        const provider = (editor.yjs as any).provider as HocuspocusProvider;
        if (!provider) return;

        const api = getApiClient();
        api.metrics
          .metricsEventCaptureCreate({
            name: "proposal_changed",
            data: {
              proposal_id: provider.configuration.name,
            },
          })
          .catch((e) => {
            console.warn("Failed to capture proposal_changed event", e);
          });
        posthog.capture("requirement_changed", {
          requirement_id: provider.configuration.name,
        });
        lastChanged = new Date();
      }
    }
  };

  return editor;
};

export default withTrackChanges;
