import { cn } from "lib/utils";
import { ComponentPropsWithoutRef, forwardRef } from "react";

const Columns = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"div">>(
  (props, ref) => {
    const { children, className, ...rest } = props;

    return (
      <div
        ref={ref}
        className={cn("flex flex-row overflow-hidden", className)}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

export default Columns;
