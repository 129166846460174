import { PlateElementProps, Value } from "@udecode/plate-common";
import { TImageElement } from "@udecode/plate-media";

export function ImageElement({
  className,
  children,
  nodeProps,
  ...props
}: PlateElementProps<Value, TImageElement>) {
  return null;
  // const { readOnly, focused, selected, align = "center" } = useMediaState();
  // const [imageURL] = React.useState<string>();

  // let url: string;
  // let aspectRatioString: string | undefined;
  // const rawUrl = props.element.url;
  // if (rawUrl.startsWith("id:")) {
  //   // This is our own format: "id:<id:string>|<aspect-ratio:number>"
  //   [url, aspectRatioString] = rawUrl.slice("id:".length).split("|");
  // } else {
  //   url = rawUrl;
  // }

  // const aspectRatio = aspectRatioString
  //   ? parseFloat(aspectRatioString)
  //   : undefined;
  // const containerRef = useRef(null);

  // const width = useResizableStore().get.width();
  // const displayWidth =
  //   typeof width === "number" && width > 0 ? width : undefined;
  // const [height, setHeight] = useState<number | undefined>(undefined);

  // Resize observer to adjust the size dynamically
  // useEffect(() => {
  //   if (!aspectRatio) {
  //     setHeight(undefined);
  //     return;
  //   }
  //   const resizeObserver = new ResizeObserver((entries) => {
  //     for (let entry of entries) {
  //       const { width } = entry.contentRect;
  //       setHeight(width / aspectRatio);
  //     }
  //   });

  //   if (containerRef.current) {
  //     resizeObserver.observe(containerRef.current);
  //   }

  //   return () => {
  //     if (containerRef.current) {
  //       resizeObserver.unobserve(containerRef.current);
  //     }
  //   };
  // }, [aspectRatio]);

  // useEffect(() => {
  //   let active = true;
  //
  //   const updateImageURL = async (url: string) => {
  //     if (!rawUrl.startsWith("id:")) {
  //       setImageURL(url);
  //       return;
  //     }
  //     const res = await apiClient.semanticDocs
  //       .semanticDocsDocImagesReadRead(docId, url)
  //       .catch((err) => {
  //         showError(err);
  //         return null;
  //       });
  //     if (!active) return;
  //     setImageURL(res?.data.read_url);
  //   };
  //
  //   updateImageURL(imageURL ?? url);
  //   return () => {
  //     active = false;
  //   };
  // }, [apiClient.semanticDocs, docId, props.element.url]);

  // return (
  //   <div>
  //     <SuggestableElement
  //       className={cn("py-md", className)}
  //       {...(props as any)}
  //     >
  //       <figure className="group relative m-0" contentEditable={false}>
  //         <Resizable
  //           options={{
  //             align,
  //             readOnly,
  //           }}
  //           ref={containerRef}
  //         >
  //           <ResizeHandle
  //             options={{ direction: "left" }}
  //             className={mediaResizeHandleVariants({ direction: "left" })}
  //           />
  //           <div
  //             className={cn(
  //               "-z-10 bg-ring",
  //               "animate-pulse absolute top-0 left-0"
  //             )}
  //             style={{
  //               width: displayWidth,
  //               height,
  //             }}
  //           ></div>
  //           <Image
  //             key={imageURL}
  //             className={cn(
  //               "block w-full max-w-full cursor-pointer object-cover px-0",
  //               "bg-background",
  //               "animate-fade-in-fast",
  //               focused && selected && "ring-2 ring-ring ring-offset-2"
  //             )}
  //             alt=""
  //             src={imageURL}
  //             style={{
  //               width: displayWidth,
  //               height,
  //             }}
  //             {...nodeProps}
  //           />
  //           <ResizeHandle
  //             options={{ direction: "right" }}
  //             className={mediaResizeHandleVariants({ direction: "right" })}
  //           />
  //         </Resizable>

  //         {/*<Caption align={align} style={{ width }}>*/}
  //         {/*  <CaptionTextarea*/}
  //         {/*    placeholder="Write a caption..."*/}
  //         {/*    readOnly={readOnly}*/}
  //         {/*  />*/}
  //         {/*</Caption>*/}
  //       </figure>
  //       {children}
  //     </SuggestableElement>
  //   </div>
  // );
}
