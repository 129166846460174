import { ProposalDetail } from "api/Api";
import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import TextArea from "components/common/forms/TextArea";
import Icon from "components/common/Icon";
import { useApiClient } from "providers/ApiClientProvider";
import { useState } from "react";
import { message_from_exception } from "utils";

interface ProvideInstructionsStepProps {
  proposalId: string | null;
  onDone: () => void;
}

const ProvideInstructionsStep: React.FC<ProvideInstructionsStepProps> = ({
  proposalId,
  onDone,
}) => {
  const apiClient = useApiClient();
  const [instructions, setInstructions] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleNext = async () => {
    try {
      debugger;
      if (instructions !== "" && proposalId) {
        await apiClient.rfp.rfpProposalPartialUpdate(proposalId, {
          instructions,
        } as any);
      }
      onDone();
    } catch (e) {
      setError(message_from_exception(e));
    }
  };

  return (
    <Rows className="grow gap-md relative items-center">
      <Icon name="thought-bubble" size="huge" variant="solid" />
      <h1 className="text-2xl font-semibold">Initial Thoughts</h1>
      <p className="text-sm text-secondary">
        Share any thoughts you have on writing this proposal
      </p>
      <TextArea
        placeholder="Write your initial thoughts to shape the proposal. For example, who on your team will be working on this project, what past projects are most relevent, or what are the key points you want to make."
        className="w-full grow resize-none"
        value={instructions}
        onChange={(text) => setInstructions(text)}
      />
      <Columns className="justify-end w-full gap-lg items-center">
        {!!error && <p className="text-destructive">{error}</p>}
        <Button
          text={!!instructions ? "Next" : "Skip"}
          variant="solid"
          onClick={handleNext}
        />
      </Columns>
    </Rows>
  );
};

export default ProvideInstructionsStep;
