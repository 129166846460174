import { cn } from "lib/utils";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

interface NaturalHeightTextAreaProps
  extends Omit<ComponentPropsWithoutRef<"div">, "onChange"> {
  value?: string | number;
  placeholder?: string;
  characterLimit?: number;
  onChange?: (text: string) => void;
  disabled?: boolean;
}

/**
 * A textarea that grows in height as the user types
 */
const NaturalHeightTextArea = forwardRef<
  HTMLDivElement,
  NaturalHeightTextAreaProps
>(({ className, placeholder, onChange, value, ...props }, ref) => {
  const internalRef = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);

  const insertText = useCallback(
    (text: string) => {
      if (!internalRef.current) {
        return;
      }
      if (document.activeElement === internalRef.current) {
        // Get the current selection
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          const startOffset = range.startOffset;
          const endOffset = range.endOffset;
          const nodeContent = range.startContainer.textContent || "";

          // Check the character before the insertion point
          const charBefore =
            startOffset > 0 ? nodeContent[startOffset - 1] : null;
          const charAfter =
            endOffset < nodeContent.length ? nodeContent[endOffset] : null;

          if (charBefore && !charBefore.match(/\s/)) {
            text = " " + text;
          }
          if (!charAfter || !charAfter.match(/\s/)) {
            text += " ";
          }

          // Create a text node for the plain text
          const textNode = document.createTextNode(text);

          // Insert the text node at the current position
          range.deleteContents();
          range.insertNode(textNode);

          // Position the cursor after the inserted text node
          range.setStartAfter(textNode);
          range.setEndAfter(textNode);
          selection.removeAllRanges();
          selection.addRange(range);

          // Apply highlighting based on the regex
          onChange?.(internalRef.current.innerText);
        }
      }
    },
    [onChange]
  );

  const handlePaste: React.ClipboardEventHandler<HTMLDivElement> = async (
    event
  ) => {
    event.preventDefault();
    if (typeof document.execCommand === "function") {
      const text = event.clipboardData.getData("text/plain");
      document.execCommand("insertText", false, text);
    } else {
      const text = await navigator.clipboard.readText();
      insertText(text);
    }
  };

  const handleInput = useCallback(() => {
    if (internalRef.current) {
      if (internalRef.current.innerHTML === "<br>") {
        internalRef.current.innerHTML = "";
      }
      onChange?.(internalRef.current.innerText);
    }
  }, [onChange]);

  useEffect(() => {
    let textValue: string;
    if (typeof value === "string") {
      textValue = value;
    } else if (typeof value === "number") {
      textValue = value.toString();
    } else {
      textValue = "";
    }
    if (internalRef.current && internalRef.current.innerText !== textValue) {
      internalRef.current.innerText = textValue;
    }
  }, [value]);

  return (
    <div
      {...props}
      onPaste={handlePaste}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      placeholder={placeholder}
      ref={internalRef}
      className={cn(
        "border rounded-md p-sm bg-background text-lg overflow-y-auto",
        focused && "border-primary",
        className
      )}
      contentEditable
      suppressContentEditableWarning
      onInput={handleInput}
    />
  );
});

export default NaturalHeightTextArea;
