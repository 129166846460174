import { getSuggestionIdFromKey, getSuggestionKeyFromId, getSuggestionKeys, } from "./utils.js";
export class SuggestionsStore {
    constructor() {
        this.suggestions = {};
        this.deletedSuggestions = {};
        this.map = null;
        this.subscribers = [];
        this.onMapEvent = this.onMapEvent.bind(this);
    }
    static getInstance() {
        if (!SuggestionsStore.instance) {
            SuggestionsStore.instance = new SuggestionsStore();
        }
        return SuggestionsStore.instance;
    }
    setSuggestion(id, suggestion) {
        this.doSetSuggestion(id, suggestion);
        this.suggestionsUpdated();
    }
    deleteSuggestion(id) {
        if (this.doDeleteSuggestion(id)) {
            this.suggestionsUpdated();
        }
    }
    deleteSuggestionsWithKeys(keys) {
        let updated = false;
        for (const key of keys) {
            const id = getSuggestionIdFromKey(key);
            updated = this.doDeleteSuggestion(id) || updated;
        }
        if (updated) {
            this.suggestionsUpdated();
        }
    }
    subscribe(callback) {
        this.subscribers.push(callback);
    }
    unsubscribe(callback) {
        const index = this.subscribers.indexOf(callback);
        if (index !== -1) {
            this.subscribers.splice(index, 1);
        }
    }
    setDoc(doc) {
        var _a;
        if (this.map) {
            this.map.unobserve(this.onMapEvent);
        }
        this.map = (_a = doc === null || doc === void 0 ? void 0 : doc.getMap("suggestions")) !== null && _a !== void 0 ? _a : null;
        if (this.map) {
            this.map.observe(this.onMapEvent);
        }
    }
    restoreSuggestionForKeys(keys) {
        let updated = false;
        for (const key of keys) {
            const id = getSuggestionIdFromKey(key);
            if (this.deletedSuggestions[id]) {
                this.suggestions[id] = this.deletedSuggestions[id];
                this.doSetSuggestion(id, this.deletedSuggestions[id]);
                delete this.deletedSuggestions[id];
                updated = true;
            }
        }
        if (updated) {
            this.suggestionsUpdated();
        }
    }
    restoreSuggestionsForNode(node) {
        const keys = getSuggestionKeys(node, true);
        this.restoreSuggestionForKeys(Array.from(keys));
    }
    clearSuggestionsWithoutMarks(editor) {
        let updated = false;
        for (const id in this.suggestions) {
            const key = getSuggestionKeyFromId(id);
            const generator = editor.nodes({
                at: [],
                match: (node) => {
                    // @ts-ignore
                    return node[key] === true;
                },
            });
            const next = generator.next();
            if (next.value) {
                continue;
            }
            updated = this.doDeleteSuggestion(id) || updated;
        }
        if (updated) {
            this.suggestionsUpdated();
        }
    }
    suggestionsUpdated() {
        for (const subscriber of this.subscribers) {
            subscriber(this.suggestions);
        }
    }
    onMapEvent(event, transaction) {
        event.changes.keys.forEach((change, key) => {
            switch (change.action) {
                case "add":
                case "update":
                    const value = event.target.get(key);
                    this.suggestions[key] = value;
                    this.suggestionsUpdated();
                    break;
                case "delete":
                    delete this.suggestions[key];
                    this.suggestionsUpdated();
                    break;
            }
        });
    }
    doSetSuggestion(id, suggestion) {
        if (!this.map) {
            console.warn("Map not initialized");
            return;
        }
        this.suggestions[id] = suggestion;
        this.map.set(id, suggestion);
    }
    doDeleteSuggestion(id) {
        if (!this.map) {
            console.warn("Map not initialized");
            return false;
        }
        if (this.suggestions[id]) {
            this.deletedSuggestions[id] = this.suggestions[id];
            delete this.suggestions[id];
            this.map.delete(id);
            return true;
        }
        return false;
    }
}
