import { createPluginFactory } from "@udecode/plate";
export const createIniitalChecksPlugin = createPluginFactory({
    key: "initial-checks",
    withOverrides: (editor) => {
        const { normalizeNode } = editor;
        // editor.normalizeNode = (entry) => {
        //   // Ensure we don't ever have an empty edtior
        //   if (editor.children.length === 0 || !editor.children[0].type) {
        //     // Set this loading type for debugging purposes.
        //     // No interaction should happen until this is overwritten by a real value
        //     editor.children = [{ type: "loading", children: [{ text: "" }] }];
        //     return;
        //   }
        //   return normalizeNode(entry);
        // };
        return editor;
    },
});
