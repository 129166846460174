import { useEditorRef } from "@udecode/plate-common";
import { isExpanded, useHotkeys } from "@udecode/plate";
import useAddComment from "./useAddComment";
import { emptyContent, odoTagContent } from "odo";

export const useHooksComments = () => {
  const addComment = useAddComment();
  const editor = useEditorRef();

  useHotkeys(
    ["meta+alt+m", "ctrl+alt+m", "meta+shift+m", "ctrl+shift+m"],
    (e) => {
      if (!editor.selection) return;

      e.preventDefault();

      // block comments

      if (!isExpanded(editor.selection)) return;

      addComment(emptyContent);
    },
    {
      enableOnContentEditable: true,
    }
  );

  useHotkeys(
    [
      "meta+o",
      "ctrl+o",
      "meta+shift+o",
      "ctrl+shift+o",
      "meta+option+o",
      "ctrl+option+o",
    ],
    (e) => {
      if (!editor.selection) return;

      e.preventDefault();

      // block comments

      if (!isExpanded(editor.selection)) return;

      addComment(odoTagContent);
    },
    {
      enableOnContentEditable: true,
    }
  );
};
