import { cn } from "../../lib/utils";
import Icon, { IconName } from "./Icon";
import { forwardRef, ForwardRefRenderFunction } from "react";

interface PillProps {
  text: string;
  className?: string;
  icon?: IconName;
  iconVariant?: "DEFAULT" | "solid" | "light";
  onClick?: () => void;
}

const Pill: ForwardRefRenderFunction<HTMLParagraphElement, PillProps> = (
  { text, className, icon, iconVariant, onClick },
  ref
) => {
  return (
    <p
      ref={ref}
      onClick={onClick}
      className={cn(
        "rounded-full px-2m py-xs border text-sm items-center shrink-1 truncate",
        className
      )}
    >
      {icon && <Icon name={icon} variant={iconVariant} className="mr-sm" />}
      {text}
    </p>
  );
};

export default forwardRef(Pill);
