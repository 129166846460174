import { ResetNodePluginRule } from "@udecode/plate-reset-node";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import { isBlockAboveEmpty, isSelectionAtBlockStart } from "@udecode/plate";
import { ELEMENT_CODE_LINE } from "@udecode/plate-code-block";
import { PlateEditor, isCollapsed, isElement } from "@udecode/plate-common";
import { Path } from "slate";

const isNodeAtPathEmpty = (editor: PlateEditor, path: Path) => {
  let entry = editor.node(path);
  if (!entry) {
    return false;
  }
  return editor.isEmpty(entry[0] as any);
};

const resetNodeRules: ResetNodePluginRule[] = [
  {
    types: [ELEMENT_BLOCKQUOTE],
    hotkey: "Backspace",
    predicate: isSelectionAtBlockStart,
    defaultType: ELEMENT_PARAGRAPH,
  },
  {
    types: [ELEMENT_CODE_LINE],
    hotkey: "Backspace",
    predicate: (editor) => {
      const selection = editor.selection;
      if (!selection || !isCollapsed(selection)) {
        return false;
      }

      if (selection.focus.offset > 0) {
        return false;
      }

      const path = selection.focus.path;
      // Don't worry abotu the first path element
      for (let i = 1; i < path.length; i++) {
        if (path[i] > 0) {
          return false;
        }
      }

      return true;
    },
    defaultType: ELEMENT_PARAGRAPH,
    onReset: (editor) => {
      // @ts-ignore
      editor.setNodes({ type: ELEMENT_PARAGRAPH });
    },
  },
  {
    types: [ELEMENT_PARAGRAPH],
    hotkey: "Backspace",
    predicate: (editor) => {
      if (!isSelectionAtBlockStart(editor)) {
        return false;
      }
      for (const [node] of editor.nodes()) {
        if (isElement(node) && node.type === "p") {
          // @ts-ignore
          if (node.indent > 0) {
            return true;
          }
        }
      }
      return false;
    },
    defaultType: ELEMENT_PARAGRAPH,
    onReset: (editor) => {
      for (const [node, path] of editor.nodes()) {
        if (isElement(node) && node.type === "p") {
          // @ts-ignore
          if (node.indent === 1) {
            // @ts-ignore
            editor.setNodes({ indent: null }, { at: path });
            // @ts-ignore
          } else if (node.indent > 0) {
            // @ts-ignore
            editor.setNodes({ indent: node.indent - 1 }, { at: path });
          }
        }
      }
    },
  },
  {
    types: [ELEMENT_BLOCKQUOTE],
    hotkey: "Enter",
    predicate: isBlockAboveEmpty,
    defaultType: ELEMENT_PARAGRAPH,
  },
  {
    types: [ELEMENT_CODE_LINE],
    hotkey: "Enter",
    predicate: (editor) => {
      const selection = editor.selection;
      if (!selection || !isCollapsed(selection)) {
        return false;
      }

      let path = selection.focus.path.slice(0, selection.focus.path.length - 1);
      if (path[path.length - 1] <= 2) {
        return false;
      }

      if (!isNodeAtPathEmpty(editor, path)) return false;
      path[path.length - 1] -= 1;
      if (!isNodeAtPathEmpty(editor, path)) return false;
      return true;
    },
    defaultType: ELEMENT_PARAGRAPH,
    onReset: (editor) => {
      // editor.liftNodes();
      // @ts-ignore
      editor.setNodes({ type: ELEMENT_PARAGRAPH });
    },
  },
];

export default resetNodeRules;
