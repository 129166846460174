import { useEffect, useState } from "react";
import Icon from "../../components/common/Icon";
import Spinner from "../../components/common/Spinner";
import { useApiClient } from "providers/ApiClientProvider";
import { message_from_exception } from "utils";
import { useSearchParams } from "react-router-dom";

const UnsubscribeRoute = () => {
  const apiClient = useApiClient();
  const [searchParams] = useSearchParams();
  const templateId = searchParams.get("template");

  const [isLoading, setIsLoading] = useState(!!templateId);
  const [error, setError] = useState<string | null>(
    !!templateId ? null : "Invalid unsubscribe link"
  );

  useEffect(() => {
    const unsubscribe = async () => {
      if (!templateId) return;
      try {
        setIsLoading(true);
        await apiClient.unsubscribe.unsubscribeCreate({
          template_id: templateId,
        });
        setIsLoading(false);
      } catch (e) {
        setError(message_from_exception(e));
      } finally {
        setIsLoading(false);
      }
    };
    unsubscribe();
  }, [apiClient.unsubscribe, templateId]);

  return (
    <div className="grow bg-backround-secondary flex flex-col items-center justify-center gap-lg h-screen">
      <div className="flex flex-col items-center gap-md">
        <Icon name="envelopes-bulk" variant="solid" size="huge" />
        <h1 className="text-lg font-semibold">Unsubscribe</h1>
      </div>
      {isLoading ? (
        <Spinner />
      ) : error ? (
        <p className="text-destructive">{error}</p>
      ) : (
        <>
          <p>You've been unsubscribed.</p>
          <p className="text-secondary">
            It may take a few days to take effect.
          </p>
        </>
      )}
    </div>
  );
};

export default UnsubscribeRoute;
