export const getSelectedEnv = () => {
  const envOverride = localStorage.getItem("odo_env");
  if (envOverride) {
    return { name: envOverride, isOverride: true };
  } else {
    return {
      name: process.env.REACT_APP_DEPLOY_ENV ?? "dev",
      isOverride: false,
    };
  }
};

export const setSelectedEnv = (env: string) => {
  localStorage.setItem("odo_env", env);
};

const _get_domain = (): {
  core: string | null;
  doc_hub: string | null;
  google_id: string;
} => {
  const raw = process.env.REACT_APP_AVAILABLE_DOMAINS;
  if (!raw) {
    throw new Error("REACT_APP_AVAILABLE_DOMAINS not set");
  }
  const availableDomains = JSON.parse(raw) as Record<
    string,
    { core: string | null; doc_hub: string | null; google_id: string }
  >;

  const { name } = getSelectedEnv();
  if (availableDomains[name]) {
    return availableDomains[name];
  } else {
    throw new Error(
      `No domain found for deploy env ${name} in REACT_APP_AVAILABLE_DOMAINS ${JSON.stringify(
        availableDomains
      )}`
    );
  }
};

export const getGoogleClientId = () => {
  return _get_domain().google_id;
};

export const get_api_url = (protocol: string, path: string) => {
  const customApiUrl = process.env.REACT_APP_API_URL;
  if (customApiUrl) {
    return customApiUrl + path;
  }

  const { core } = _get_domain();
  if (!core) {
    return `${protocol}://localhost:8000${path}`;
  }
  if (protocol === "http") {
    protocol = "https";
  } else if (protocol === "ws") {
    protocol = "wss";
  }
  return `${protocol}://${core}${path}`;
};

export const get_hub_url = (protocol: string, path: string) => {
  const customHubUrl = process.env.REACT_APP_HUB_URL;
  if (customHubUrl) {
    return customHubUrl + path;
  }

  const { doc_hub } = _get_domain();
  if (!doc_hub) {
    return `${protocol}://localhost:8001${path}`;
  }
  if (protocol === "http") {
    protocol = "https";
  } else if (protocol === "ws") {
    protocol = "wss";
  }
  return `${protocol}://${doc_hub}${path}`;
};
