import React from "react";
import { cn } from "../../lib/utils";
import { DragResizable } from "../common/containers/DragResizable";
import Debug, { DebugProps } from "./Debug";

const DebugPanel: React.FC<DebugProps> = ({ className, ...props }) => {
  return (
    <DragResizable
      direction="vertically"
      defaultSize={300}
      minSize={200}
      className="relative"
    >
      <div
        className={cn(
          "w-full m-0 absolute top-0 bottom-0 left-0 right-0 pt-2m flex",
          className
        )}
      >
        <Debug {...props} />
      </div>
    </DragResizable>
  );
};

export default DebugPanel;
