import { PlateEditor, Value } from "@udecode/plate-common";
import { IconName } from "components/common/Icon.js";

export interface SlashCommandsPlugin {
  trigger?: string;
  id?: string;
}

export const KEY_SLASH_COMMANDS = "slash-commands";

export type SlashCommandItemData = {
  icon: IconName;
  onSelect: (editor: PlateEditor<Value>) => void;
};
