import { PaginatedResponse } from "api/CoreApi";
import { AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";

import { debounce } from "lodash";

type Endpoint<Remote> = (
  options: any
) => Promise<AxiosResponse<PaginatedResponse<Remote>>>;

interface FetchOptionsProps<Remote> {
  searchText: string;
  endpoint: Endpoint<Remote>;
}

const useFetchedOptions = <T>(
  options: FetchOptionsProps<T>,
  enabled: boolean = true
) => {
  const [loadedOptions, setLoadedOptions] = useState<T[] | null>(null);

  const fetchOptions = useRef(
    debounce(async (endpoint: Endpoint<any>, searchText: string) => {
      if (!enabled) return null;
      const result = await endpoint({ search: searchText });
      setLoadedOptions(result.data.results);
    }, 500)
  );

  useEffect(() => {
    fetchOptions.current(options.endpoint, options.searchText);
  }, [options.endpoint, options.searchText]);

  return loadedOptions ?? [];
};

export default useFetchedOptions;
