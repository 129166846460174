import { PlateElementProps } from "@udecode/plate-common";
import { cn } from "lib/utils";
import { SuggestableElement } from "./SuggestableElement";
import { useEditorRef } from "@udecode/plate";
import { ReactEditor } from "slate-react";
import { odoElementHasAttribute } from "odo";

export function DeletedElement({
  className,
  children,
  ...props
}: PlateElementProps) {
  const editor = useEditorRef();
  const path = ReactEditor.findPath(editor as any, props.element);
  const nextIndex = path[0] + 1;
  let areSuggestionControlsHidden = false;
  if (nextIndex < editor.children.length) {
    const nextNode = editor.node([path[0] + 1])[0];
    areSuggestionControlsHidden = odoElementHasAttribute(
      nextNode as any,
      "suggestion",
      true
    );
  }
  return (
    <SuggestableElement
      asChild
      className={cn(className, "relative")}
      areControlsHidden={areSuggestionControlsHidden}
      data-slate-zero-width="z"
      {...props}
    >
      {children}
      {/* <div
        className="absolute left-[-10px] right-[-10px] top-[2.25em] bottom-[-1px]"
        contentEditable={false}
      >
        <DeletedIndicator />
      </div> */}
    </SuggestableElement>
  );
}
