import { MARK_SUGGESTION } from "./types/index.js";
import { isNode } from "@udecode/plate";
import { isElement } from "@udecode/plate-common";
export const getSuggestionIdFromKey = (key) => key.replace(`${MARK_SUGGESTION}_`, "");
export const getSuggestionKeyFromId = (id) => `${MARK_SUGGESTION}_${id}`;
export const isSuggestionKey = (key) => key.startsWith(`${MARK_SUGGESTION}_`);
export const getSuggestionKeys = (node, allChildren = false) => {
    const keys = new Set();
    Object.keys(node).forEach((key) => {
        if (isSuggestionKey(key) && key !== "suggestion_meta")
            keys.add(key);
    });
    if (allChildren && isElement(node)) {
        for (const child of node.children) {
            const newKeys = getSuggestionKeys(child, allChildren);
            for (const key of newKeys) {
                keys.add(key);
            }
        }
    }
    return keys;
};
export const removeAllSuggestionKeysFromNodes = (nodes) => {
    if (!Array.isArray(nodes)) {
        return;
    }
    for (const node of nodes) {
        for (const key of getSuggestionKeys(node)) {
            // @ts-ignore
            delete node[key];
        }
        if (node.suggestion) {
            // @ts-ignore
            delete node.suggestion;
        }
        if (node.suggestion_meta) {
            // @ts-ignore
            delete node.suggestion_meta;
        }
        if (isNode(node) && node.children) {
            removeAllSuggestionKeysFromNodes(node.children);
        }
    }
};
