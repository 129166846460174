const withLastParagraphNormalization = (editor) => {
    // const { normalizeNode } = editor;
    // editor.normalizeNode = ([node, path]) => {
    //   // Ensure the last element is always a paragraph
    //   normalizeNode([node, path]);
    //   if (path.length === 0) {
    //     if (editor.children.length > 0) {
    //       const last = editor.children[editor.children.length - 1];
    //       if (!isElement(last)) {
    //         return;
    //       }
    //       if (last.type === ELEMENT_PARAGRAPH) {
    //         // We're good
    //       } else if (last.type === ELEMENT_SECTION_CONTROL) {
    //         // We need to check if the previous element is a paragraph
    //         if (
    //           editor.children.length < 2 ||
    //           editor.children[editor.children.length - 2].type !== "p"
    //         ) {
    //           // We need to add a paragraph
    //           editor.insertNodes(
    //             // @ts-ignore
    //             { type: "p", children: [{ text: "" }] },
    //             { at: [editor.children.length - 1] },
    //           );
    //         }
    //       } else {
    //         // We need to add a paragraph
    //         editor.insertNodes(
    //           // @ts-ignore
    //           { type: "p", children: [{ text: "" }] },
    //           { at: [editor.children.length] },
    //         );
    //       }
    //     }
    // if (editor.children.length >= 2) {
    //   // Make sure there aren't multiple empty paragraphs at the end
    //   const last = editor.children[editor.children.length - 1];
    //   const secondLast = editor.children[editor.children.length - 2];
    //   if (
    //     isElement(last) &&
    //     last.type === "p" &&
    //     isElement(secondLast) &&
    //     secondLast.type === "p" &&
    //     last.children.length === 1 &&
    //     secondLast.children.length === 1 &&
    //     last.children[0].text === "" &&
    //     secondLast.children[0].text === ""
    //   ) {
    //     editor.removeNodes({ at: [editor.children.length - 1] });
    //   }
    // }
    // }
    // };
    return editor;
};
export default withLastParagraphNormalization;
