import { createContext, useContext } from "react";

interface OverlayContextData {
  isOpen: boolean;
  dismiss?: (force?: boolean) => void;
  registerBeforeDismiss: (
    callback: (forced: boolean) => Promise<boolean>
  ) => void;
  unregisterBeforeDismiss: (
    callback: (forced: boolean) => Promise<boolean>
  ) => void;
}

const OverlayContext = createContext<OverlayContextData | undefined>(undefined);

export const OverlayContextProvider = OverlayContext.Provider;

export const useOverlayContext = () => {
  const context = useContext(OverlayContext);
  if (!context) {
    throw new Error(
      "useOverlayContext must be used within a OverlayContextProvider"
    );
  }
  return context;
};
