import { isOdoElement } from "../../odo-editor/index.js";
export function isSuggestedElement(node) {
    return isOdoElement(node) && node.suggestion;
}
export function isSuggestedElementSplit(node) {
    return (isSuggestedElement(node) &&
        node.suggestion_meta !== undefined &&
        node.suggestion_meta.split);
}
export function isSuggestedElementUpdate(node) {
    return (isSuggestedElement(node) &&
        node.suggestion_meta !== undefined &&
        node.suggestion_meta.attributes !== undefined);
}
export function isSuggestedElementInsert(node) {
    return (isSuggestedElement(node) &&
        node.suggestion_meta !== undefined &&
        node.suggestion_meta.insert);
}
export function isSuggestedElementDelete(node) {
    return (isSuggestedElement(node) &&
        node.suggestion_meta !== undefined &&
        Array.isArray(node.suggestion_meta.deleted));
}
export function isSuggestedElementReplace(node) {
    return (isSuggestedElement(node) &&
        node.suggestion_meta !== undefined &&
        node.suggestion_meta.old !== undefined);
}
