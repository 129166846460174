import { isElement } from "@udecode/plate-common";
import { onKeyDownIndent } from "@udecode/plate-indent";
import { isCollapsed } from "@udecode/plate";
/**
 * Determine if the normal indent behavior should happen or if we should
 * fall back to the default behavior which is handled by the table plugin.
 *
 * We want to use default tab behavior from the table unless the user's selection
 * is in, and only in, a list item.
 *
 * @param editor
 */
const onTableKeyDown = (editor) => (e) => {
    if (e.key !== "Tab") {
        // We only want to adjust tab behavior
        onKeyDownIndent(editor)(e);
        return;
    }
    const entries = Array.from(editor.nodes({
        match: (n) => isElement(n),
    }));
    let foundTable = false;
    for (const [node, path] of entries) {
        if (isElement(node) && node.type === "table") {
            foundTable = true;
            break;
        }
    }
    if (!foundTable) {
        // We only want to adjust tab behavior if we're in a table
        onKeyDownIndent(editor)(e);
        return;
    }
    if (!isCollapsed(editor.selection)) {
        // If the selection is not collapsed, we always want to fall back to table behavior
        return;
    }
    const deepestElement = entries[entries.length - 1][0];
    if (isElement(deepestElement) &&
        deepestElement.type === "p" &&
        !!deepestElement.listStyleType) {
        // This is a list item, so we should do the list behavior
        onKeyDownIndent(editor)(e);
        return;
    }
    // Otherwise, we want to fall back to the table behavior
};
export default onTableKeyDown;
