import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../EditorView/Menus/DropdownMenu";

interface ModelSelectProps {
  value: string;
  onChange: (value: string) => void;
}

const ModelSelect: React.FC<ModelSelectProps> = ({ value, onChange }) => {
  const options = ["gpt-4", "gpt-3.5-turbo", "haiku", "sonnet", "opus"];
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="flex gap-xs items-center px-sm h-lg border rounded-sm text-sm">
          {value ?? "gpt-4"}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {options.map((option) => (
          <DropdownMenuItem key={option} onClick={() => onChange(option)}>
            {option}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ModelSelect;
