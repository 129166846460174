import { cva, VariantProps } from "class-variance-authority";
import { cn } from "lib/utils";
import Columns from "../containers/Columns";

const labeledFormVariants = cva("flex flex-col", {
  variants: {
    variant: {
      default: "gap-xs",
      "long-form": "",
    },
  },
});

const labeledFormLabelVariants: typeof labeledFormVariants = cva("grow", {
  variants: {
    variant: {
      default: "text-secondary text-sm",
      "long-form": "font-semibold",
    },
  },
});

interface LabeledFormInputProps
  extends React.HTMLProps<HTMLDivElement>,
    VariantProps<typeof labeledFormVariants> {
  label: string;
  extraControls?: React.ReactNode;
}

const LabeledFormInput: React.FC<LabeledFormInputProps> = ({
  label,
  children,
  className,
  extraControls,
  variant = "default",
  ...props
}) => {
  return (
    <div className={cn(labeledFormVariants({ variant }), className)} {...props}>
      <Columns className="shrink-0">
        <p className={labeledFormLabelVariants({ variant })}>{label}</p>
        {extraControls}
      </Columns>
      {children}
    </div>
  );
};

export default LabeledFormInput;
