import { useEffect, useRef, useState } from "react";
import { PaginatedData } from "./usePaginatedData";

/**
 * Use data out of local memory as paginated data
 * for a paginated table
 */
const useFixedPaginatedData = <T>(
  data: T[],
  searchableFields: Array<keyof T>
): [T[], PaginatedData] => {
  const [results, setResults] = useState<T[]>(data);
  const searchFields = useRef(searchableFields);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    if (searchableFields.length === 0) {
      setResults(data);
      return;
    }
    const searchLower = search.toLowerCase();
    const newResults = data.filter((item) =>
      searchFields.current.some((field) =>
        (item[field] as string).toLowerCase().includes(searchLower)
      )
    );
    setResults(newResults);
  }, [data, search, searchableFields.length]);

  return [
    results,
    {
      loadingPrevious: false,
      loadingNext: false,
      status: "loaded",
      error: null,
      loadPrevious: null,
      loadNext: null,
      refresh: () => {},
      search,
      setSearch,
      filters: null,
    },
  ];
};

export default useFixedPaginatedData;
