import {
  createCorePlugins,
  isEmptyContent,
  OdoEditor,
  OdoElement,
  OdoValue,
  SuggestionResolver,
} from "odo";
import { Plate, PlateContent, useEditorRef } from "@udecode/plate";
import React, { FC, useEffect } from "react";
import { useOdoEditorRef } from "hooks/odo-editor/useOdoEditorRef";
import components from "lib/plate/components";
import { cn } from "lib/utils";
import getRejectedSuggestionContent from "hooks/suggestions/getRejectedSuggestionContent";

interface DeletedPreviewProps {
  suggestionId: string;
  className?: string;
}

const SuggestionAutoResolver = ({
  content,
  setResolved,
}: {
  content: OdoElement<any>[];
  setResolved: (resolved: boolean) => void;
}) => {
  const editor = useOdoEditorRef();

  useEffect(() => {
    // HACK
    // Temporarily add empty children around content to allow for suggestions to be resolved safely
    editor.children.splice(0, 0, { type: "p", children: [{ text: "" }] });
    editor.children.push({ type: "p", children: [{ text: "" }] });

    const resolver = new SuggestionResolver(editor);
    resolver.rejectAll();

    // HACK
    // Remove empty children
    editor.children.splice(0, 1);
    editor.children.splice(editor.children.length - 1, 1);

    if (isEmptyContent(editor.children)) {
      editor.children = [
        {
          type: "paragraph",
          children: [{ text: "No Content" }],
        },
      ];
    }
    setResolved(true);
  }, [content, editor, setResolved]);

  return null;
};

const SuggestionPreview: FC<DeletedPreviewProps> = ({
  suggestionId,
  className,
}) => {
  const [resolved, setResolved] = React.useState(false);
  const editor = useEditorRef();
  const [content, setContent] = React.useState<OdoElement<any>[] | null>(null);

  useEffect(() => {
    setContent(getRejectedSuggestionContent(editor as any, suggestionId));
  }, [editor, suggestionId]);

  if (!content) {
    return null;
  }

  return (
    <div
      className={cn(
        "max-h-[800px] overflow-hidden bg-popover border border-destructive rounded-sm px-sm py-xs text-destructive",
        resolved ? "opacity-100" : "opacity-0",
        className
      )}
    >
      <Plate<OdoValue, OdoEditor>
        id="nested"
        readOnly={true}
        plugins={createCorePlugins(components, (data) => data)}
        initialValue={content as any}
      >
        <PlateContent />
        <SuggestionAutoResolver content={content} setResolved={setResolved} />
      </Plate>
    </div>
  );
};

export default SuggestionPreview;
