import { FC, useEffect, useRef } from "react";

import Overlay from "components/common/containers/overlays/Overlay";
import { useFinalCheckDetails } from "providers/FinalCheckDetailsProvider";
import { cn } from "lib/utils";
import Rows from "components/common/containers/Rows";
import Columns from "components/common/containers/Columns";
import Icon from "components/common/Icon";

interface FinalCheckRFPOverlayProps {}

const FinalCheckRFPOverlay: FC<FinalCheckRFPOverlayProps> = () => {
  const {
    document,
    activeRequirement,
    setActiveRequirement,
    setCanAnimateScroll,
    setContainerWidth,
    isLoaded,
  } = useFinalCheckDetails();

  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    setCanAnimateScroll(false);
  }, [setCanAnimateScroll]);

  useEffect(() => {
    // Resize page width when the container resizes
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      setContainerWidth(containerRef.current?.clientWidth ?? 0);
    });
    resizeObserver.observe(containerRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef, setContainerWidth]);

  return (
    <Overlay
      open={!!activeRequirement}
      title="RFP Details"
      onClose={() => setActiveRequirement(null)}
      className={cn(
        "max-h-[90vh] relative min-h-[300px]",
        isLoaded && "w-full"
      )}
      maxWidth={1000}
    >
      <Rows className="relative grow" ref={containerRef as any}>
        {activeRequirement?.status !== "neutral" && (
          <Columns className="shrink-0 items-center justify-center gap-md">
            <Icon
              variant="solid"
              className={
                activeRequirement?.status === "pass"
                  ? "text-[#7CB632]"
                  : "text-destructive"
              }
              name={
                activeRequirement?.status === "pass"
                  ? "comment-check"
                  : "comment-xmark"
              }
              size="xlarge"
            />
            <Rows className="py-lg">
              <h3 className="font-semibold">
                {activeRequirement?.requirement}
              </h3>
              <p>Feedback: {activeRequirement?.justification}</p>
            </Rows>
          </Columns>
        )}
        {document}
      </Rows>
    </Overlay>
  );
};

export default FinalCheckRFPOverlay;
