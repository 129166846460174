import { cn } from "lib/utils";
import React from "react";
import SegmentedControl from "./SegmentedControl";
import Rows from "./Rows";

interface SegmentedContainerProps {
  selectedIndex: number;
  className?: string;
  children: React.ReactNode;
}

const SegmentedContainer: React.FC<SegmentedContainerProps> = ({
  selectedIndex: visibleIndex,
  className,
  children,
}) => {
  const count = React.Children.toArray(children).length;
  const width = `${100 * count}%`;
  const marginLeft = `-${100 * visibleIndex}%`;

  return (
    <Rows className="grow gap-md w-full relative justify-center">
      <div
        className={cn(
          "grow flex transition-[margin-left] overflow-hidden",
          className
        )}
        style={{ marginLeft, width }}
      >
        {React.Children.map(children, (child) => {
          return <Rows className="w-full grow">{child}</Rows>;
        })}
      </div>
      {count > 1 && (
        <SegmentedControl
          className="mx-auto shrink-0"
          count={count}
          selectedIndex={visibleIndex}
        />
      )}
    </Rows>
  );
};

export default SegmentedContainer;
