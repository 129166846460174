import useFetchedData from "hooks/useFetchedData";
import { usePromptRefineryContext } from "./PromptRefineryProvider";
import { useApiClient } from "providers/ApiClientProvider";
import Button from "components/common/Button";
import Spinner from "components/common/Spinner";
import { useState } from "react";
import { message_from_exception } from "utils";
import { useOverlayContext } from "components/common/containers/overlays/OverlayContextProvider";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import TextArea from "components/common/forms/TextArea";

interface FixtureManagerProps {}

interface Fixture {
  content: string;
}

const FixtureManager: React.FC<FixtureManagerProps> = () => {
  const { data } = usePromptRefineryContext();
  const { id } = data.data;
  const apiClient = useApiClient();

  const [fixtures, setFixtures] = useFetchedData<Fixture[]>(async () => {
    const result =
      await apiClient.promptRefinery.promptRefineryPromptsFixturesRead(id);
    return result.data.fixtures as Fixture[];
  }, [id]);
  const [error, setError] = useState<string | null>(null);
  const { dismiss } = useOverlayContext();
  const [isLoading, setIsLoading] = useState(false);

  const addFixture = () => {
    setFixtures((fixtures) => {
      if (!fixtures) return fixtures;
      return [...fixtures, { content: "" }];
    });
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      setError(null);
      await apiClient.promptRefinery.promptRefineryPromptsFixturesUpdate(id, {
        fixtures: fixtures as Fixture[],
      });
      dismiss?.();
    } catch (error) {
      setError(message_from_exception(error));
    } finally {
      setIsLoading(false);
    }
  };

  const setFixtureContent = (index: number, content: string) => {
    setFixtures((fixtures) => {
      if (!fixtures) return fixtures;
      const newFixtures = [...fixtures];
      newFixtures[index] = { ...newFixtures[index], content };
      return newFixtures;
    });
  };

  if (fixtures === null) {
    return (
      <div className="flex flex-col gap-sm items-center mx-auto">
        <Spinner />
        Loading...
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex flex-col gap-sm items-center mx-auto">
        <Spinner />
        Saving...
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-md">
      <p className="text-sm text-secondary">
        The text below will be returned as if it's coming from the LLM.
        <br />
        If there are multiple, one will be chosen at random.
      </p>
      {fixtures.map((fixture, i) => (
        <LabeledFormInput key={i} label={`Fixture ${i + 1}`}>
          <TextArea
            value={fixture.content}
            onChange={(value) => setFixtureContent(i, value)}
          />
        </LabeledFormInput>
      ))}
      <div className="flex">
        <Button
          variant="solid"
          className="text-sm"
          onClick={addFixture}
          icon="plus"
          text="Add Fixture"
        />
        <div className="grow" />
        <Button
          variant="solid"
          className="text-sm"
          onClick={handleSave}
          icon="floppy-disk"
          text="Save"
        />
      </div>
      {error && <p className="text-destructive text-center">{error}</p>}
    </div>
  );
};

export default FixtureManager;
