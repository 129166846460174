import Button from "components/common/Button";
import NavigationView from "components/common/NavigationView";
import TypingAnimation from "components/common/TypingAnimation";
import MessageView from "components/common/containers/MessageView";
import { cn } from "lib/utils";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface ProposalNotReadyProps {
  className?: string;
}

const ProposalNotReady: FC<ProposalNotReadyProps> = ({ className }) => {
  const navigate = useNavigate();
  return (
    <NavigationView containerClassName="bg-background-secondary">
      <div
        className={cn(
          "flex items-center justify-center bg-background-secondary",
          className
        )}
      >
        <MessageView icon="hourglass-half" title="We’re Working On It">
          <p className="mb-8">
            This could take a couple days.
            <br />
            We'll email you when it's ready.
          </p>
          <TypingAnimation
            type="loop"
            options={["Proposal not ready.", "Check back later."]}
            className="py-2m"
          />
          <Button
            text="Go Back"
            variant="solid-secondary"
            className="mx-auto"
            onClick={() => {
              if (window.history.length > 1) {
                window.history.back();
              } else {
                navigate("/proposals/");
              }
            }}
          />
        </MessageView>
      </div>
    </NavigationView>
  );
};

export default ProposalNotReady;
