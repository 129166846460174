import {
  getSuggestionKeyFromId,
  isSuggestedElementDelete,
  OdoEditor,
  OdoElement,
  odoElementHasAttribute,
} from "odo";

const getRejectedSuggestionContent = (
  editor: OdoEditor,
  suggestionId: string
): OdoElement<any>[] => {
  const key = getSuggestionKeyFromId(suggestionId);
  let pendingDeletedElement: OdoElement<any> | null = null;
  let result: OdoElement<any>[] = [];
  for (const node of editor.children) {
    if (odoElementHasAttribute(node as OdoElement<any>, key, true)) {
      if (pendingDeletedElement) {
        result.push(pendingDeletedElement);
      }
      result.push(node as OdoElement<any>);
    } else {
      if (isSuggestedElementDelete(node)) {
        pendingDeletedElement = node as OdoElement<any>;
      } else {
        pendingDeletedElement = null;
      }
    }
  }
  return JSON.parse(JSON.stringify(result));
};

export default getRejectedSuggestionContent;
