import useYjsMap from "../../api/useYjsMap";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { useEffect, useState } from "react";
import * as Y from "yjs";
import { format } from "date-fns";

/**
 * This hook is used to manage when the doc was last changed.
 *
 * This hook is used by useYjs to break out the functionality more logically.
 * DO NOT USE THIS HOOK DIRECTLY. Use useEditorDocData instead if you want
 * access to the to when the doc was last changed.
 */
const _useYjsLastChanged = (
  requirementId: string,
  yjsProvider: HocuspocusProvider | null,
  ignoreChanges: boolean
) => {
  const [hasFinishedAttemptingToLoad, setHasFinishedAttemptingToLoad] =
    useState(false);
  const [metaData, setMetaData] = useYjsMap<string | undefined>(
    yjsProvider,
    "meta",
    {
      last_changed: "unknown",
    },
    false,
    1000
  );

  useEffect(() => {
    setHasFinishedAttemptingToLoad(false);
    setTimeout(() => {
      setHasFinishedAttemptingToLoad(true);
    }, 2000);
  }, [requirementId]);

  useEffect(() => {
    // @ts-ignore
    if (!yjsProvider) {
      return;
    }
    const content = yjsProvider.document.get("content", Y.XmlText);
    const handleEvent = (event: Y.YEvent<any>[]) => {
      // @ts-ignore
      if (event.length === 0) {
        return;
      }
      if (!event[0].transaction.local) {
        return;
      }

      if (!hasFinishedAttemptingToLoad) {
        return;
      }

      if (ignoreChanges) {
        return;
      }

      // The user changed something locally -> mark the doc as changed
      const currentDate = format(new Date(), "yyyy-MM-dd");
      setMetaData("last_changed", currentDate);
    };
    content.observeDeep(handleEvent);

    return () => {
      content.unobserveDeep(handleEvent);
    };
    // @ts-ignore
  }, [yjsProvider, setMetaData, hasFinishedAttemptingToLoad, ignoreChanges]);

  return metaData["last_changed"];
};

export default _useYjsLastChanged;
