import { AxiosError } from "axios";
import Button from "components/common/Button";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import MessageView from "components/common/containers/MessageView";
import NavigationView from "components/common/NavigationView";
import TypingAnimation from "components/common/TypingAnimation";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import { ReactNode, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { message_from_exception } from "utils";

import FinalCheckReportDetailsView from "components/final-check/FinalCheckReportDetailsView";
import { FinalCheckDetailsProvider } from "providers/FinalCheckDetailsProvider";
import { useOptionalAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import Rows from "components/common/containers/Rows";
import Columns from "components/common/containers/Columns";
import OdoLogo from "components/common/OdoLogo";
import { cn } from "lib/utils";

const FinalCheckDetailsRoute = () => {
  const apiClient = useApiClient();
  const params = useParams();
  const finalCheckId = params.id!;
  const user = useOptionalAuthenticatedUser();

  const [finalCheck, , { error, refresh }] = useFetchedData(async () => {
    const result = await apiClient.rfp.rfpFinalCheckRead(finalCheckId);
    return result.data;
  }, []);

  let content: ReactNode = null;
  let isLoaded = false;

  if (error instanceof AxiosError && error.response?.status === 404) {
    content = (
      <MessageView icon="circle-question" title="Final Check Not Found">
        <p className="text-secondary">
          If you think this is an error,
          <br />
          please{" "}
          <a href="mailto:support@odo.do" className="underline inline">
            contact support
          </a>
          .
        </p>
      </MessageView>
    );
    isLoaded = false;
  } else {
    content = (
      <AsyncLoadedDiv
        value={finalCheck}
        error={error ? message_from_exception(error) : undefined}
        className="grow flex flex-col overflow-hidden items-center justify-center"
        whileLoaded={(finalCheck) => {
          if (finalCheck.is_proposal_uploaded !== true) {
            return <NotUploadedYet />;
          }

          if (!finalCheck.report) {
            return <NoReportYet />;
          }

          return (
            <FinalCheckDetailsProvider finalCheck={finalCheck}>
              <FinalCheckReportDetailsView
                finalCheck={finalCheck}
                report={finalCheck.report as any}
              />
            </FinalCheckDetailsProvider>
          );
        }}
      />
    );
    isLoaded = !!finalCheck?.report;
  }

  useEffect(() => {
    if (!finalCheck) return;
    // Auto refresh every 10 seconds if not loaded
    const interval = setInterval(() => {
      if (!isLoaded) {
        refresh();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [isLoaded, finalCheck, refresh]);

  if (!user) {
    return (
      <Rows className="grow">
        <Columns className="shrink-0 items-center border-b px-xl py-md h-4xl">
          <Rows className="grow basis-0 items-start">
            <OdoLogo size="small" />
          </Rows>
          <Rows className="shrink-0 items-center">
            <h1 className="text-l font-semibold">Final Check Report</h1>
            <h2>{finalCheck?.name ?? ""}</h2>
          </Rows>
          <Rows className="grow basis-0 items-end">
            <Rows className="items-center gap-xs">
              <p>Tired of writing proposals?</p>
              <Button
                icon="phone"
                variant="solid"
                iconVariant="solid"
                text="Book Demo"
                onClick={() => {
                  window.open(
                    "https://www.odo.do/book-demo/?source=final-check",
                    "_blank"
                  );
                }}
              />
            </Rows>
          </Rows>
        </Columns>
        <Rows className={cn("grow", !isLoaded && "bg-background-secondary")}>
          {content}
        </Rows>
      </Rows>
    );
  }
  return (
    <NavigationView
      containerClassName={finalCheck?.report ? "" : "bg-background-secondary"}
      scrollable={false}
      fullWidth={true}
      padContent={false}
    >
      {finalCheck && (
        <>
          <Rows
            className={cn(
              "shrink-0 bg-background-secondary px-2xl py-lg w-full text-center",
              isLoaded ? "border-b" : ""
            )}
          >
            <h1 className="text-2xl font-semibold">{finalCheck.name}</h1>
            <h2 className="text-lg">Final Check Report</h2>
          </Rows>
          <Rows className="grow">{content}</Rows>
        </>
      )}
    </NavigationView>
  );
};

const NotUploadedYet = () => {
  return (
    <MessageView icon="circle-exclamation" title="Proposal Not Uploaded">
      <p>
        Your proposal has not been uploaded yet, which is odd.
        <br />
        please{" "}
        <a href="mailto:support@odo.do" className="underline inline">
          contact support
        </a>
      </p>
    </MessageView>
  );
};

const NoReportYet = () => {
  const navigate = useNavigate();
  return (
    <MessageView
      icon="hourglass-half"
      title="We’re Working On It"
      variant="above"
    >
      <p className="mb-8 text-center">
        This will take 10-20 minutes.
        <br />
        We'll email you when it's ready.
      </p>
      <TypingAnimation
        type="loop"
        options={["Final check not ready.", "Check back later."]}
        className="py-2m"
      />
      <Button
        text="Go Back"
        variant="solid-secondary"
        className="mx-auto"
        onClick={() => {
          if (window.history.length > 1) {
            window.history.back();
          } else {
            navigate("/final-check/");
          }
        }}
      />
    </MessageView>
  );
};

export default FinalCheckDetailsRoute;
