import { ReactNode, useEffect, useState } from "react";
import { cn } from "lib/utils";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CenteredContainer from "../../components/common/containers/CenteredContainer";
import LoginToExistingView from "./LoginToExistingView";
import CreateAccountView from "./CreateAccountView";
import OdoLogo from "../../components/common/OdoLogo";
import ForgotPasswordView from "./ForgotPasswordView";
import { getGoogleClientId } from "api/env";
import { useOptionalAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { Navigate } from "react-router-dom";

const LoginRoute: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [isRecoveringPassword, setIsRecoveringPassword] = useState(false);
  const user = useOptionalAuthenticatedUser();

  useEffect(() => {
    setHasLoaded(true);
  }, []);

  if (user) {
    if (user.isVerified) {
      return <Navigate to="/" />;
    } else {
      return <Navigate to="/verify" />;
    }
  }

  let content: ReactNode;
  if (isRecoveringPassword) {
    content = (
      <ForgotPasswordView
        username={username}
        setUsername={setUsername}
        onLogin={() => setIsRecoveringPassword(false)}
      >
        <OdoLogo />
      </ForgotPasswordView>
    );
  } else if (isCreatingAccount) {
    content = (
      <CreateAccountView
        username={username}
        setUsername={setUsername}
        password={password}
        setPassword={setPassword}
        onLoginToExisting={() => setIsCreatingAccount(false)}
      >
        <OdoLogo />
      </CreateAccountView>
    );
  } else {
    content = (
      <LoginToExistingView
        className={cn(
          "transition-[opacity] duration-1000",
          hasLoaded ? "opacity-100" : "opacity-0"
        )}
        username={username}
        setUsername={setUsername}
        password={password}
        setPassword={setPassword}
        onCreateAccount={() => setIsCreatingAccount(true)}
        onForgotPassword={() => setIsRecoveringPassword(true)}
      >
        <OdoLogo includeText={true} />
      </LoginToExistingView>
    );
  }

  return (
    <GoogleOAuthProvider clientId={getGoogleClientId()}>
      <CenteredContainer>{content}</CenteredContainer>
    </GoogleOAuthProvider>
  );
};

export default LoginRoute;
