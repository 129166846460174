import { Prompt, PromptVariable, VariableValueGroup } from "./types";
import { useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";
import { useApiClient } from "../../../providers/ApiClientProvider";
import { PromptContent } from "./PromptContent";
import PromptRenderer from "./PromptRenderer";
import { TElement } from "@udecode/plate-common";
import { PromptTool } from "types/Prompt";

type RenderPromptType = (variationId: number | null) => Promise<Prompt>;
type RenderNodeType = (node: TElement) => Promise<string>;

interface PromptData {
  id: number;
  name: string;
  variationModel: Record<number, string>;
  variationNotes: Record<number, string>;
  variationTools: Record<number, PromptTool[]>;
  content: PromptContent;
  variables: Record<string, PromptVariable>;
  valueGroups: VariableValueGroup[];
  variations: number[];
  activeVariation: number | null;
}

export interface PromptDetailResult {
  data: PromptData;
  updateVariable: (variable: Partial<PromptVariable>) => void;
  addVariable: () => void;
  removeVariable: (id: string) => void;
  getVariable: (id: string) => PromptVariable | null;
  getVariables: () => PromptVariable[];
  applyValueGroup: (groupId: string) => void;
  addValueGroup: () => void;
  duplicateValueGroup: (groupId: string) => void;
  deleteValueGroup: (groupId: string) => void;
  setValueGroupValue: (
    groupId: string,
    variableId: string,
    value: string
  ) => void;
  setValueGroupName: (groupId: string, name: string) => void;
  addVariation: () => void;
  setActiveVariation: (id: number | null) => void;
  setModel: (variationId: number, model: string) => void;
  setNotes: (variationId: number, notes: string) => void;
  setTools: (variationId: number, tools: PromptTool[]) => void;
  removeVariation: (id: number) => void;
  renderPrompt: RenderPromptType;
  renderNode: RenderNodeType;
}

const usePromptDetails = (
  id: number,
  shouldLoad: boolean
): PromptDetailResult | null => {
  const apiClient = useApiClient();
  const [data, doSetData] = useState<PromptData | null>(null);
  const [nextVariationId, setNextVariationId] = useState(0);

  const setData = useCallback(
    (update: (prev: PromptData | null) => PromptData | null) => {
      doSetData((prev) => {
        const updated = update(prev);
        doSetData(updated);
        data?.content.saveMeta({
          variables: updated?.variables ?? {},
          valueGroups: updated?.valueGroups ?? [],
          variations: updated?.variations ?? [],
          variationModel: updated?.variationModel ?? {},
          variationNotes: updated?.variationNotes ?? {},
          variationTools: updated?.variationTools ?? {},
          activeVariation: updated?.activeVariation ?? null,
        });
        return updated;
      });
    },
    [data]
  );

  useEffect(() => {
    if (data !== null) {
      return;
    }
    const fetchData = async () => {
      const result = await PromptContent.load(id, apiClient);
      setData(() => ({
        id,
        name: result.name,
        variationModel: result.variationModel,
        content: result.content,
        variables: result.variables,
        valueGroups: result.valueGroups,
        variations: result.variations,
        variationNotes: result.variationNotes,
        activeVariation: result.activeVariation,
        variationTools: result.variationTools,
      }));
      let maxVariationId = Math.max(...result.variations);
      if (maxVariationId === -Infinity) {
        maxVariationId = 0;
      }
      setNextVariationId(maxVariationId + 1);
    };
    fetchData();
  }, [id, apiClient, setData, data]);

  const addVariation = useCallback(() => {
    setData((prev) => {
      if (!prev) {
        return null;
      }
      return {
        ...prev,
        variations: [...prev.variations, nextVariationId],
      };
    });
    if (data) {
      let mostRecentVariationId = null;
      if (data.variations.length > 0) {
        mostRecentVariationId = Math.max(...data.variations);
      }
      data.content.copyContent(
        PromptContent.getSystemId(mostRecentVariationId),
        PromptContent.getSystemId(nextVariationId)
      );
      data.content.copyContent(
        PromptContent.getUserId(mostRecentVariationId),
        PromptContent.getUserId(nextVariationId)
      );
      data.variationModel[nextVariationId] =
        data.variationModel[mostRecentVariationId ?? -1];
    }
    setNextVariationId((prev) => prev + 1);
  }, [setData, data, nextVariationId]);

  const removeVariation = useCallback(
    (id: number) => {
      setData((prev) => {
        if (!prev) {
          return null;
        }
        return {
          ...prev,
          variations: prev.variations.filter((v) => v !== id),
        };
      });
    },
    [setData]
  );

  const setModel = useCallback(
    (variationId: number, model: string) => {
      setData((prev) => {
        if (!prev) {
          return null;
        }
        return {
          ...prev,
          variationModel: {
            ...prev.variationModel,
            [variationId]: model,
          },
        };
      });
    },
    [setData]
  );

  const setNotes = useCallback(
    (variationId: number, notes: string) => {
      setData((prev) => {
        if (!prev) {
          return null;
        }
        return {
          ...prev,
          variationNotes: {
            ...prev.variationNotes,
            [variationId]: notes,
          },
        };
      });
    },
    [setData]
  );

  const setTools = useCallback(
    (variationId: number, tools: PromptTool[]) => {
      setData((prev) => {
        if (!prev) {
          return null;
        }
        return {
          ...prev,
          variationTools: {
            ...prev.variationTools,
            [variationId]: tools,
          },
        };
      });
    },
    [setData]
  );

  const updateVariable = useCallback(
    (variable: Partial<PromptVariable>) => {
      setData((prev) => {
        if (!prev) {
          return null;
        }
        if (!variable.id) {
          throw new Error("Variable id is required");
        }
        return {
          ...prev,
          variables: {
            ...prev.variables,
            [variable.id]: { ...prev.variables[variable.id], ...variable },
          },
        };
      });
    },
    [setData]
  );

  const addVariable = useCallback(() => {
    const id = v4();
    setData((prev) => {
      if (!prev) {
        return null;
      }
      return {
        ...prev,
        variables: {
          ...prev.variables!,
          [id]: {
            id,
            name: "New Variable",
            content: "",
            cachedValueId: null,
            cachedVariableId: null,
          },
        },
      };
    });
  }, [setData]);

  const removeVariable = useCallback(
    (id: string) => {
      setData((prev) => {
        if (!prev) {
          return null;
        }
        const newVariables = { ...prev.variables! };
        delete newVariables[id];
        return {
          ...prev,
          variables: newVariables,
        };
      });
    },
    [setData]
  );

  const getVariable = useCallback(
    (id: string) => {
      if (!data) {
        return null;
      }
      return data.variables[id] ?? null;
    },
    [data]
  );

  const addValueGroup = useCallback(() => {
    setData((prev) => {
      if (!prev) {
        return null;
      }
      return {
        ...prev,
        valueGroups: [
          ...prev.valueGroups,
          { id: v4(), name: "New Group", values: {} },
        ],
      };
    });
  }, [setData]);

  const duplicateValueGroup = useCallback(
    (groupId: string) => {
      setData((prev) => {
        if (!prev) {
          return null;
        }
        const group = prev.valueGroups.find((g) => g.id === groupId);
        if (!group) {
          throw new Error("Group not found");
        }
        return {
          ...prev,
          valueGroups: [
            ...prev.valueGroups,
            { id: v4(), name: `${group.name} (copy)`, values: group.values },
          ],
        };
      });
    },
    [setData]
  );

  const deleteValueGroup = useCallback(
    (groupId: string) => {
      setData((prev) => {
        if (!prev) {
          return null;
        }
        return {
          ...prev,
          valueGroups: prev.valueGroups.filter((g) => g.id !== groupId),
        };
      });
    },
    [setData]
  );

  const setValueGroupName = useCallback(
    (groupId: string, name: string) => {
      setData((prev) => {
        if (!prev) {
          return null;
        }
        return {
          ...prev,
          valueGroups: prev.valueGroups.map((g) => {
            if (g.id === groupId) {
              return {
                ...g,
                name,
              };
            }
            return g;
          }),
        };
      });
    },
    [setData]
  );

  const setActiveVariation = useCallback(
    (id: number | null) => {
      setData((prev) => {
        if (!prev) {
          return null;
        }
        return {
          ...prev,
          activeVariation: id,
        };
      });
    },
    [setData]
  );

  const setValueGroupValue = useCallback(
    (groupId: string, variableId: string, value: string) => {
      setData((prev) => {
        if (!prev) {
          return null;
        }
        const group = prev.valueGroups.find((g) => g.id === groupId);
        if (!group) {
          throw new Error("Group not found");
        }
        return {
          ...prev,
          valueGroups: prev.valueGroups.map((g) => {
            if (g.id === groupId) {
              return {
                ...g,
                values: {
                  ...g.values,
                  [variableId]: value,
                },
              };
            }
            return g;
          }),
        };
      });
    },
    [setData]
  );

  const applyValueGroup = useCallback(
    (groupId: string) => {
      setData((prev) => {
        if (!prev) {
          return null;
        }
        const group = prev.valueGroups.find((g) => g.id === groupId);
        if (!group) {
          throw new Error("Group not found");
        }
        return {
          ...prev,
          variables: {
            ...Object.keys(prev.variables).reduce((acc, key) => {
              return {
                ...acc,
                [key]: {
                  ...prev.variables[key],
                  content: "",
                },
              };
            }, {}),
            ...Object.keys(group.values).reduce((acc, key) => {
              return {
                ...acc,
                [key]: {
                  ...prev.variables[key],
                  content: group.values[key],
                },
              };
            }, {}),
          },
        };
      });
    },
    [setData]
  );

  const getVariables = useCallback(() => {
    if (!data) return [];
    return Object.values(data.variables).sort((a, b) =>
      a.name?.toLowerCase().localeCompare(b.name?.toLowerCase())
    );
  }, [data]);

  const renderPrompt = useCallback<RenderPromptType>(
    async (variationId) => {
      if (!data) return [];
      const renderer = new PromptRenderer(data.content, data.variables);
      const system = await renderer.renderEditor(
        PromptContent.getSystemId(variationId)
      );
      const user = await renderer.renderEditor(
        PromptContent.getUserId(variationId)
      );
      return [
        { role: "system", content: system },
        { role: "user", content: user },
      ];
    },
    [data]
  );

  const renderNode = useCallback<RenderNodeType>(
    async (node) => {
      if (!data) return "";
      const renderer = new PromptRenderer(data.content, data.variables);
      return await renderer.renderNode(node);
    },
    [data]
  );

  if (!data) {
    return null;
  }

  return {
    data,
    updateVariable,
    addVariable,
    removeVariable,
    getVariable,
    getVariables,
    addVariation,
    removeVariation,
    addValueGroup,
    deleteValueGroup,
    duplicateValueGroup,
    applyValueGroup,
    setValueGroupName,
    setValueGroupValue,
    setActiveVariation,
    setModel,
    setNotes,
    setTools,
    renderPrompt,
    renderNode,
  };
};

export default usePromptDetails;
