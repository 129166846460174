import { cn } from "lib/utils";
import { ComponentPropsWithoutRef, forwardRef } from "react";

const CenteredContainer = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn("grow flex justify-center items-center", className)}
      {...props}
    >
      {children}
    </div>
  );
});

export default CenteredContainer;
