import { useCallback } from "react";
import CoreApi from "./CoreApi";

const useImageUpload = (apiClient: CoreApi, docId: string) => {
  const uploadImage = useCallback(
    async (dataUrl: string | File): Promise<string> => {
      // let buf: Buffer;
      // let contentType: string;
      // let aspectRatio: number;
      // if (typeof dataUrl === "string") {
      //   const stringDataUrl = dataUrl.toString();
      //   aspectRatio = await getImageAspectRatio(stringDataUrl);
      //   contentType = stringDataUrl.substring(
      //     stringDataUrl.indexOf(":") + 1,
      //     stringDataUrl.indexOf(";")
      //   );
      //   buf = Buffer.from(
      //     stringDataUrl.replace(/^data:image\/\w+;base64,/, ""),
      //     "base64"
      //   );
      // } else {
      //   contentType = dataUrl.type;
      //   buf = Buffer.from(await dataUrl.arrayBuffer());
      //   aspectRatio = await getImageAspectRatio(URL.createObjectURL(dataUrl));
      // }
      // const data = (
      //   await apiClient.semanticDocs
      //     .semanticDocsDocImagesUploadList(docId, { content_type: contentType })
      //     .catch((err) => {
      //       showError(err);
      //       return null;
      //     })
      // )?.data;
      //
      // if (!data || !data.upload_url) {
      //   showError("Failed to get upload url");
      //   return "";
      // }
      //
      // await fetch(data.upload_url, {
      //   method: "PUT",
      //   body: buf,
      //   headers: {
      //     "Content-Type": contentType,
      //     "Content-Encoding": "base64",
      //   },
      // }).catch((err) => {
      //   showError(err);
      //   return null;
      // });
      // return `id:${data.name!}|${aspectRatio}`;
      throw new Error("Not implemented");
    },
    []
  );
  return { uploadImage };
};

// const getImageAspectRatio = (base64: string): Promise<number> => {
//   return new Promise((resolved, rejected) => {
//     try {
//       let i = new Image();
//       i.onload = function () {
//         resolved(i.width / i.height);
//       };
//       i.src = base64;
//     } catch (err) {
//       showError(err);
//       rejected(err);
//     }
//   });
// };

export default useImageUpload;
