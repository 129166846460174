import { AxiosError } from "axios";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import NavigationView from "components/common/NavigationView";
import TypingAnimation from "components/common/TypingAnimation";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import MessageView from "components/common/containers/MessageView";
import Rows from "components/common/containers/Rows";
import RFPComparisionView from "components/rfps/RFPComparisonView";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  RFPOrgComparison,
  rfpOrgComparisonFromApi,
} from "types/RFPOrgComparison";
import { message_from_exception } from "utils";

const RFPsRoute = () => {
  const apiClient = useApiClient();
  const [comparisons, setComparisons, { error, refresh }] =
    useFetchedData(async () => {
      try {
        const response = await apiClient.rfp.rfpComparisonList();
        if (response.status === 204) {
          return "pending";
        }
        return response.data.results.reduce((acc, comparison) => {
          const rfpOrgComparison = rfpOrgComparisonFromApi(comparison);
          if (rfpOrgComparison) {
            acc.push(rfpOrgComparison);
          }
          return acc;
        }, [] as RFPOrgComparison[]);
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 404) {
          return "unapproved";
        } else if (
          error instanceof AxiosError &&
          error.response?.status === 425
        ) {
          return "no-profile";
        } else if (
          error instanceof AxiosError &&
          error.response?.status === 418
        ) {
          return "not-started";
        } else {
          throw error;
        }
      }
    }, []);

  const handleRemove = (comparison: RFPOrgComparison) => {
    setComparisons((prev) => {
      if (
        prev === "unapproved" ||
        prev === "no-profile" ||
        prev === "not-started" ||
        prev === "pending"
      ) {
        return prev;
      }
      return prev?.filter((c) => c.id !== comparison.id) ?? null;
    });
  };

  return (
    <NavigationView
      selected="rfps"
      containerClassName={
        typeof comparisons === "string" || comparisons?.length === 0
          ? "bg-background-secondary"
          : undefined
      }
    >
      <AsyncLoadedDiv
        value={comparisons}
        error={error ? message_from_exception(error) : null}
        className="grow flex flex-col w-full gap-md items-center overflow-visible"
        whileLoaded={(comparisons) => {
          if (typeof comparisons === "string") {
            if (comparisons === "unapproved") {
              return <ComingSoonSplash />;
            }
            if (comparisons === "not-started") {
              return <StartComparisonsSplash refresh={refresh} />;
            }
            if (comparisons === "pending") {
              return <PendingResultsSplash refresh={refresh} />;
            }
            return <SetProfileSplash />;
          }

          if (comparisons.length === 0) {
            return <NoResultsSplash />;
          }

          return (
            <Rows className="grow gap-md">
              <h1 className="text-2xl font-semibold w-full">
                RFP Recommendations
              </h1>
              <p className="text-secondary">
                Discover RFPs that match your company’s profile.
              </p>
              <Rows className="grow flex flex-col gap-3xl">
                {comparisons.map((comparison) => (
                  <RFPComparisionView
                    key={comparison.id}
                    comparison={comparison}
                    remove={() => handleRemove(comparison)}
                  />
                ))}
              </Rows>
            </Rows>
          );
        }}
      />
    </NavigationView>
  );
};

const ComingSoonSplash = () => {
  return (
    <div className="grow flex flex-col items-center justify-center gap-md">
      <Icon name="lightbulb-on" size="huge" variant="solid" />
      <h1 className="text-2xl font-semibold">RFP Recommendations</h1>
      <p>Discover RFPs that match your company’s profile.</p>
      <TypingAnimation
        className="mt-md text-xl"
        options={["Coming Soon"]}
        type="once"
      />
    </div>
  );
};

const NoResultsSplash = () => {
  return (
    <MessageView
      icon="lightbulb-on"
      title="RFP Recommendations"
      variant="above"
    >
      <p className="text-center">
        We don't currently have any RFPs that are a good match.
      </p>
      <p>We'll email you once we find some.</p>
    </MessageView>
  );
};

const PendingResultsSplash = ({ refresh }: { refresh: () => void }) => {
  // Refresh the data every 5 seconds
  useEffect(() => {
    const interval = setInterval(refresh, 5000);
    return () => clearInterval(interval);
  });

  return (
    <MessageView
      icon="lightbulb-on"
      title="RFP Recommendations"
      variant="above"
    >
      <p className="text-center">
        We're looking for RFPs that match your profile.
      </p>
      <TypingAnimation options={["Hang tight for a minute"]} type="loop" />
    </MessageView>
  );
};

const SetProfileSplash = () => {
  const navigate = useNavigate();
  return (
    <MessageView
      icon="lightbulb-on"
      title="RFP Recommendations"
      variant="above"
    >
      <p>Discover RFPs that match your company’s profile</p>
      <Button
        variant="solid"
        icon="pen"
        iconVariant="solid"
        text="Fill Out Profile"
        onClick={() => navigate("/account/company-profile/")}
      />
    </MessageView>
  );
};

const StartComparisonsSplash = ({ refresh }: { refresh: () => void }) => {
  const apiClient = useApiClient();

  const handleStart = async () => {
    try {
      await apiClient.rfp.rfpComparisonStartCreate();
      refresh();
    } catch (error) {
      toast.error(message_from_exception(error));
    }
  };

  return (
    <MessageView
      icon="lightbulb-on"
      title="RFP Recommendations"
      variant="above"
    >
      <p>Discover RFPs that match your company’s profile</p>
      <Button
        variant="solid"
        icon="play"
        iconVariant="solid"
        text="Find RFPs"
        onClick={handleStart}
      />
    </MessageView>
  );
};

export default RFPsRoute;
