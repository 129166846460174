import { createContext, ReactNode, useContext, useState } from "react";
import { PromptDetailResult } from "./usePromptDetails";

interface PromptRefineryData {
  data: PromptDetailResult;
  previewing: boolean;
  setPreviewing: (previewing: boolean) => void;
}

const PromptRefineryContext = createContext<PromptRefineryData | null>(null);

const PromptRefineryProvider: React.FC<{
  children: ReactNode;
  data: PromptDetailResult;
}> = ({ children, data }) => {
  const [previewing, setPreviewing] = useState(false);

  return (
    <PromptRefineryContext.Provider
      value={{
        data,
        previewing,
        setPreviewing,
      }}
    >
      {children}
    </PromptRefineryContext.Provider>
  );
};

export const usePromptRefineryContext = (): PromptRefineryData => {
  const context = useContext(PromptRefineryContext);
  if (!context) {
    throw new Error(
      "usePromptRefineryContext must be used within a PromptRefineryProvider"
    );
  }
  return context;
};

export default PromptRefineryProvider;
